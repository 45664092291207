import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../../Constants/styles";
import {localTestFileData} from "./localTestFileData";
import {testFileData} from "./testFileData";
import {toggleUseTestData} from "../../Actions/ToggleUseTestData";
import {modifyAiInput} from "../../Actions/ModifyAiInput";
import {modifyAiFiles} from "../../Actions/ModifyAiFiles";
import {modifyAiFileSelected} from "../../Actions/ModifyAiFileSelected";
import {toggleIsAiResultSelected} from "../../Actions/ToggleIsAiResultSelected";
import {modifySidebarSelection} from "../../Actions/ModifySidebarSelection";
import {changeToFilesTab} from "../../Constants/changeToFilesTab";
import {toggleFileSidebarSelection} from "../../Actions/ToggleFileSidebarSelection";
import {modifyAppName} from "../../Actions/ModifyAppName";

class UseTestFileLink extends Component {
    initTestData = () => {
        this.props.toggleUseTestData(true)
        const testData = window.location.href.includes("localhost:2000") ? localTestFileData : testFileData;
        this.updateAIFiles(testData);
        changeToFilesTab(this.props, true)
        this.props.modifyAiInput(this.props.chatMode ? "Describe what this dataset is about" : "Combine both files on the title column")

        if (!this.props.chatMode){
           this.props.modifyAppName("merge")
        }
    }

    updateAIFiles = (newFiles) => {
        const currFileData = JSON.parse(JSON.stringify(this.props.aiFiles))

        for (let i=0; i<newFiles.length; i++){
            currFileData.push(newFiles[i])
        }

        this.props.modifyAiFiles(currFileData, false)

        if (this.props.aiFileSelected === null && currFileData.length > 0){
            this.props.modifyAiFileSelected(0)
            this.props.toggleIsAiResultSelected(false)
        }
    }

    render() {
        const isMobile = 'isMobile' in this.props ? this.props.isMobile : false;

        return (
            <div style={{ fontWeight: "700", fontSize: "14px", color: styles.blue, cursor: "pointer", textAlign: isMobile ? 'left' : 'center'}} onClick={this.initTestData}>
                Use Test Files
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    aiFiles: state.mainState.aiFiles,
    aiFileSelected: state.mainState.aiFileSelected,
    chatMode: state.mainState.chatMode
})

const mapActionsToProps = {
    toggleUseTestData: toggleUseTestData,
    modifyAiInput: modifyAiInput,
    modifyAiFiles: modifyAiFiles,
    modifyAiFileSelected: modifyAiFileSelected,
    toggleIsAiResultSelected: toggleIsAiResultSelected,
    modifySidebarSelection: modifySidebarSelection,
    toggleFileSidebarSelection: toggleFileSidebarSelection,
    modifyAppName: modifyAppName
}

export default connect(mapStateToProps, mapActionsToProps)(UseTestFileLink)