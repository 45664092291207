import React, {Component} from 'react'

class FooterTermsAndPrivacy extends Component {
    render() {
        return (
            <span style={{ fontSize: "12px" }}><a href="/terms" target="_blank" rel="noopener noreferrer"> Terms &
                                Conditions</a> | <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy
                                Policy</a></span>
        )
    }
}

export default FooterTermsAndPrivacy