import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../../../Constants/styles";
import KeyValueInputIndex from "./KeyValueInputIndex"
import AceEditor from 'react-ace';
import 'brace/mode/json';
import 'brace/theme/monokai';

class APINoCodeBodyIndex extends Component {
    constructor(props) {
        super(props);
    }

    changeBodyTypeValue = (value) => {
        this.props.changeState({ requestBodyType: value })
    }

    getReportSelectedIdx = () => ('reportLandingIdx' in this.props ? this.props.reportLandingIdx : this.props.reportSelectedIdx)

    getCheckbox = (value, label) => {
        return <div style={{ marginRight: '16px' }}>
            <input type="radio"
                   name={"requestBodyType" + (this.getReportSelectedIdx()).toString()}
                   id={"requestBodyType" + (this.getReportSelectedIdx()).toString() + value.toString()}
                   value={value}
                   checked={this.props.requestBodyType === value}
                   onClick={e => this.changeBodyTypeValue(value)}
                   style={{ cursor: 'pointer'}}
            />
            <label htmlFor={"requestBodyType" + (this.getReportSelectedIdx()).toString() + value.toString()} style={{...this.props.labelHeaderStyle, ...{paddingLeft: '4px',  cursor: 'pointer'}}}>
                {label}
            </label>
        </div>
    }

    editJson = (editObj) => {
        this.props.changeState({requestBodyJson: editObj})
    }

    getBody = () => {
        switch (this.props.requestBodyType){
            case 'none':
                return <div style={{ fontSize: '12px', color: styles.subText }}>No body needed for request API</div>
            case 'json':
                return <div>
                    {/*<textarea*/}
                        {/*value={this.props.requestBodyJson}*/}
                        {/*onChange={e => this.props.changeState({requestBodyJson: e.target.value})}*/}
                        {/*rows={5}*/}
                    {/*/>*/}
                    <AceEditor
                        mode="json"
                        theme="monokai"
                        onChange={this.editJson}
                        value={this.props.requestBodyJson}
                        name="UNIQUE_ID_OF_DIV"
                        editorProps={{$blockScrolling: true}}
                        height={125}
                        width="auto"
                    />
                </div>
            case 'formData':
                return <div>
                    <KeyValueInputIndex
                        keyValuePairs={this.props.requestBodyFormData}
                        changeKeyValPairs={this.props.changeKeyValPairs}
                        name={'requestBodyFormData'}
                        labelHeaderStyle={this.props.labelHeaderStyle}
                    />
                </div>
            case 'formUrlEncoded':
                return <div>
                    <KeyValueInputIndex
                        keyValuePairs={this.props.requestBodyFormUrlEncoded}
                        changeKeyValPairs={this.props.changeKeyValPairs}
                        name={'requestBodyFormUrlEncoded'}
                        labelHeaderStyle={this.props.labelHeaderStyle}
                    />
                </div>
            default:
                return null
        }
    }

    render() {
        return (
            <div>
                <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto auto 1fr', margin: "8px 16px"}}>
                    {this.getCheckbox("none", "None")}
                    {this.getCheckbox("json", "JSON")}
                    {this.getCheckbox("formData", "form-data")}
                    {this.getCheckbox("formUrlEncoded", "x-www-form-urlencoded")}
                    <div> </div>
                </div>
                <div style={{ margin: "0px 16px 16px 16px"}}>
                    {this.getBody()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    reportSelectedIdx: state.mainState.reportSelectedIdx
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(APINoCodeBodyIndex)