import React, {Component} from 'react'
import {connect} from 'react-redux'
import ColumnInfoBox from "./ColumnInfoBox";

class ColumnInfoRow extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const labels = ['Field', 'Type', 'Null', 'Key', 'Default']

        return (
            <div style={{ display: "grid", gridTemplateColumns: "2fr 2fr 1fr 1fr 1fr", columnGap: "8px", borderTop: "1px solid #F0F0F0", padding: "8px"}}>
                {
                    labels.map((x, idx) =>
                        <div key={idx}>
                            <ColumnInfoBox
                                label={x}
                                value={this.props.info[x]}
                            />
                        </div>)
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ColumnInfoRow)