import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifySidebarSelection } from "../../Actions/ModifySidebarSelection";
import capitalizeHyphenated from "../../Constants/capitalizeHyphenated";
import styles from "../../Constants/styles";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {modifyModalInfo} from "../../Actions/ModifyModalInfo";
import {toggleModal} from "../../Actions/ToggleModal";
import {toggleToast} from "../../Actions/ToggleToast";
import AuthComponent from "../../SharedComponents/AuthComponent"

class FileOptionsToggle extends Component {
    constructor(props) {
        super(props);
    }

    changeSelection = (selection) => {
        this.props.modifySidebarSelection(selection);
    }

    getDivStyle = (selectionName) => {
        const headingStyle = { textAlign: "center", padding: "8px 0 8px 0", cursor: "pointer", borderBottom: "1px solid #F0F0F0" }

        let selectedStyle = {...headingStyle}

        if (selectionName === this.props.sidebarSelection){
            selectedStyle['backgroundColor'] = styles.blue
            selectedStyle['color'] = "white"
            selectedStyle['borderRight'] = "none"
        } else {
            selectedStyle['borderRight'] = "1px solid #F0F0F0"
        }

        return selectedStyle
    }

    getIconStyle = (selectionName) => {
        const iconStyle = { color: "#000000", fontSize: "14px"}

        if (selectionName === this.props.sidebarSelection){
            let selectedIconStyle = {...iconStyle}

            selectedIconStyle['color'] = 'white'

            return selectedIconStyle
        } else {
            return {...iconStyle}
        }
    }

    getMenuIcon = (iconName, iconType, popupText) => {
        const icon = <div style={this.getDivStyle(iconType)} onClick={e => this.changeSelection(iconType)}>
            <i className={iconName} style={this.getIconStyle(iconType)}></i>
        </div>

        return <Popup trigger={open => (icon)}
                      position="right"
                      on={['hover', 'focus']}
                      closeOnDocumentClick>
            <div style={{ color: styles.subText, paddingLeft: "8px"}}>{popupText}</div>
        </Popup>
    }

    render() {
        return (
            <div style={{ borderBottom: "1px solid #F0F0F0", height: '100%', borderRight: "1px solid #F0F0F0" }}>
                {this.getMenuIcon("fa-solid fa-plug", "connectDataSources", "Connect data sources")}
                {/*{this.getMenuIcon("fa-solid fa-database", "databaseList", "Your databases")}*/}
                {this.getMenuIcon("fa-solid fa-file-lines", "file", "Your reports")}
                {this.getMenuIcon("fa-solid fa-users", "team", "Your team")}
                {this.getMenuIcon("fa-solid fa-ticket", "requestDataSources", "Request data sources connection")}
                <AuthComponent
                    auth={this.getMenuIcon("fa-solid fa-circle-info", "help", "Support")}
                    nonAuth={null}
                />
                {/*{this.getMenuIcon("fa-brands fa-google", "googleSheets", "Manage Google Account")}*/}
                {/*{this.getMenuIcon("fa-solid fa-gear", "settings", "Account settings")}*/}
                {/*<div style={this.getDivStyle("help")} onClick={e => this.changeSelection("help")}>*/}
                    {/*<span className="material-icons-outlined" style={this.getIconStyle("help")}>help_outline</span>*/}
                {/*</div>*/}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    appName: state.mainState.appName,
    sidebarSelection: state.mainState.sidebarSelection,
    isAuth: state.mainState.isAuth,

})

const mapActionsToProps = {
    modifySidebarSelection: modifySidebarSelection,
    modifyModalInfo: modifyModalInfo,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(FileOptionsToggle)