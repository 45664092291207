import React, {Component} from 'react'
import styles from "../Constants/styles";

class LeadershipRow extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-sm-4">
                    <img src={this.props.img} style={{ width: "100%" }} alt={this.props.name} />
                </div>
                <div className="col-sm-8">
                    <div style={{ fontWeight: "700", fontSize: "24px" }}>
                        {this.props.name}
                    </div>
                    <div style={{ fontWeight: "700", fontSize: "18px", color: styles.secondaryText }}>
                        {this.props.title}
                    </div>
                    <div style={{ paddingTop: "16px" }}>
                        {this.props.desc.map((x, idx) => <p key={idx}>
                            {x}
                        </p>)}
                    </div>
                </div>
            </div>
        )
    }
}

export default LeadershipRow