import React, {Component} from 'react'
import PageBanner from "../PageBanner"
import careers from "./careers.svg"
import OurValues from "./OurValues";
import PerksAndJobOpeningsIndex from "./PerksAndJobOpeningsIndex";

class CareersIndex extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const title = 'Work on the future'
        const desc = "Help usher in the AI era of Data Analysis"
        const bannerHeaderImg = { maxWidth: "240px" }
        const browserDivStyle = { marginTop: "48px" };
        const mobileDivStyle = { padding: "24px 20px 0 20px" }

        return (
            <div style={{ minHeight: "100vh"}}>
                <div id="landingPageBrowser">
                    <PageBanner
                        title={title}
                        desc={desc}
                        img={careers}
                        imgStyle={bannerHeaderImg}
                    />
                    <div style={{ paddingBottom: "20%"}}>
                        <OurValues divStyle={browserDivStyle} />
                        <div style={{ margin: "150px 48px 0 48px" }}>
                            <PerksAndJobOpeningsIndex/>
                        </div>
                    </div>
                </div>
                <div id="landingPageMobile">
                    <PageBanner
                        title={title}
                        desc={desc}
                        img={careers}
                        imgStyle={bannerHeaderImg}
                        isMobile
                    />
                    <div style={{ paddingBottom: "50%"}}>
                        <OurValues divStyle={mobileDivStyle} isMobile/>
                        <div style={{ padding: "72px 20px 0 20px" }}>
                            <PerksAndJobOpeningsIndex isMobile/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CareersIndex