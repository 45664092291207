import React, {Component} from 'react'
import styles from "../Constants/styles";

class DropdownMenuItem extends Component {
    render() {
        const divId = this.props.isMobile ? "" : "productMenuRow"
        const isExternalLink = 'external' in this.props;

        return (
            <a href={this.props.website} target={isExternalLink ? "_blank" : ""} rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                <div style={{ padding: "6px 8px 6px 6px" }} id={divId}>
                    <div style={{ display: "grid", gridTemplateColumns: "auto 1fr",  columnGap: "16px",  paddingTop: this.props.isMobile ? "4px" : "0"}}>
                        <div>
                            <span style={{ color: this.props.isMobile ? "white" : "black", backgroundColor: this.props.isMobile ? styles.red : "white" }}>{this.props.icon}</span>
                        </div>
                        <div>
                            <div style={{ fontSize: "16px", fontWeight: "700", color: this.props.isMobile ? "white" : "#161E16", marginBottom: this.props.isMobile ? "0" : "8px", paddingTop: this.props.isMobile ? "4px" : "0" }}>
                                {this.props.name}
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        )
    }
}

export default DropdownMenuItem