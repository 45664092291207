import React, {Component} from 'react'

class Accordion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: this.initIsOpen()
        }
    }

    initIsOpen = () => ('open' in this.props)

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.initIsOpen()){
            if (prevProps.title !== this.props.title && prevProps.body !== this.props.body) {
                this.setState({
                    isOpen: false
                })
            }
        }
    }

    toggleAccordion = () => {
        const toggleReplace = !this.state.isOpen

        this.setState({ isOpen: toggleReplace })
    }

    render() {
        return (
            <div style={{ border: "1px solid #F0F0F0", borderRadius: "6px" }}>
                <div style={{ padding: "16px", backgroundColor: "#F9F9F9", fontWeight: "700", fontSize: "16px", display: "grid", gridTemplateColumns: "1fr auto", columnGap: "8px", cursor: "pointer" }} onClick={this.toggleAccordion}>
                    <div>
                        {this.props.title}
                    </div>
                    <div>
                        <span style={{ color: "#9696A0" }}><i className={this.state.isOpen ? "fas fa-chevron-up" : "fas fa-chevron-down"} /></span>
                    </div>
                </div>
                {
                    this.state.isOpen ?
                        <div style={{ padding: "16px", backgroundColor: "#FFFFFF", fontSize: "14px" }}>
                            {this.props.body}
                        </div> : null
                }
            </div>
        )
    }
}

export default Accordion