import React, {Component} from 'react'
import styles from "../../../Constants/styles";
import ModalHeaders from "../ModalHeaders";
import PromptDatabaseInput from "./PromptDatabaseInput"

class PromptDatabaseBody extends Component {
    render() {
        const headerStyle = { color: styles.subText, fontWeight: "700", marginBottom: "12px", textAlign: "center", fontSize: "16px"}

        return (
            <div>
                <div style={{ headerStyle }}>
                    <PromptDatabaseInput
                        mode="prompt"
                        value="I want to see all user emails and sign up dates for last 7 days"
                        isMobile={this.props.isMobile}
                    />
                </div>
                {/*<div style={{ marginTop: "24px" }}>*/}
                    {/*<ModalHeaders*/}
                        {/*header={"Or select what you need in a form"}*/}
                    {/*/>*/}
                {/*</div>*/}
                {/*<div style={{ headerStyle }}>*/}
                    {/*<PromptDatabaseInput*/}
                        {/*mode="noCode"*/}
                        {/*value="SELECT email, sign_up_dt*/}
                        {/*FROM users WHERE sign_up_dt > now() - interval 7 day;"*/}
                    {/*/>*/}
                {/*</div>*/}
                {/*<div style={{ marginTop: "24px" }}>*/}
                    {/*<ModalHeaders*/}
                        {/*header={"Or edit the SQL code generated (for databases)"}*/}
                        {/*subHeader*/}
                    {/*/>*/}
                {/*</div>*/}
                {/*<div style={{ headerStyle }}>*/}
                    {/*<PromptDatabaseInput*/}
                        {/*mode="code"*/}
                        {/*value="SELECT email, sign_up_dt*/}
                        {/*FROM users WHERE sign_up_dt > now() - interval 7 day;"*/}
                    {/*/>*/}
                {/*</div>*/}
            </div>
        )
    }
}

export default PromptDatabaseBody