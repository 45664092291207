import React, {Component} from 'react'
import EmailToSubjectMsgInput from "./EmailToSubjectMsgInput";
import EmailToList from "./EmailToList";

class EmailReportIndex extends Component {
    constructor(props) {
        super(props);

        // email_report_info={this.state.email_report_info}
        // changeState={this.changeState}
    }

    updateVal = (key, e) => {
        const copiedEmailReportInfo = JSON.parse(JSON.stringify(this.props.email_report_info));
        copiedEmailReportInfo[key] = e.target.value;

        this.props.changeState({ email_report_info: copiedEmailReportInfo })
    }

    getVal = (key) => {
        let val;
        try{
            val = this.props.email_report_info[key]

            if (val === undefined){
                val = ""
            }
        } catch (e) { val = "" }

        return val
    }

    render() {
        return (
            <div>
                <div style={{marginBottom: "16px"}}>
                    <EmailToSubjectMsgInput
                        label="Email Subject"
                        type="subject"
                        val={this.getVal("subject")}
                        updateVal={this.updateVal}
                        placeholder="Daily practice logs"
                    />
                </div>
                <div style={{marginBottom: "16px"}}>
                    <EmailToSubjectMsgInput
                        label="From Name"
                        type="from"
                        val={this.getVal("from")}
                        updateVal={this.updateVal}
                        placeholder="Ted Lasso"
                    />
                </div>
                <div style={{marginBottom: "16px"}}>
                    <EmailToSubjectMsgInput
                        label="Email Message"
                        type="msg"
                        val={this.getVal("msg")}
                        updateVal={this.updateVal}
                        placeholder="Dear Rebecca, please find report attached. Yeehaw!"
                    />
                </div>
                <div style={{marginBottom: "16px"}}>
                    <EmailToList
                        changeState={this.props.changeState}
                        email_report_info={this.props.email_report_info}
                    />
                </div>
            </div>
        )
    }
}

export default EmailReportIndex