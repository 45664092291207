import React, {Component} from 'react'
import {connect} from 'react-redux'
import { APISpreadsheetsImporter } from 'apispreadsheets'
import AceEditor from 'react-ace';
import 'brace/mode/sql';
import 'brace/theme/monokai';
import Spreadsheet from "react-spreadsheet";
import styles from "../Constants/styles";
import IconLabel from "../SharedComponents/IconLabel";
import API_Backend_Root from "../Constants/API_Backend_Root";
import {toggleModal} from "../Actions/ToggleModal";
import LoadingModal from "../SharedComponents/LoadingModal";
import { isMobile } from 'react-device-detect';
import SQLRulesModal from "./SQLRulesModal";
import SQLUploadModal from "./SQLUploadModal";

class SQLIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            importer: new APISpreadsheetsImporter("a2cbc2acd49665cec9a530f468886553", this.importComplete),
            fileInfo: null,
            data: null,
            query: "",
            fetching: null,
            columnNames: null,
            queryRun: false,
            downloadUrl: "",
            originalData: null,
            originalColNames: null,
            menuItemSelected: 0
        }
    }

    importComplete = (success, data) => {
        this.closeImporter()
        if (data !== null && success){
            this.setFileInfoAndSetData(data.slice(0, 3), this.closeImporter)
        } else {
            alert("There was a problem uploading your file. Please try again!")
        }
    }

    setFileInfoAndSetData = (fileInfo, success=null, error=null) => {
        this.setState({
            fileInfo: fileInfo,
            originalData: fileInfo.map(x => null),
            originalColNames: fileInfo.map(x => null),
            queryRun: false,
            query: "",
            fetching: "Reading your file(s)...",
            menuItemSelected: 0
        }, () => {
            this.props.toggleModal("loadingModalSQL")

            for (let i=0; i<fileInfo.length; i++){
                this.getFileData(fileInfo[i].apiUrl, success, error, i)
            }
        })
    }

    getFileData = (apiUrl, success, error, idx=0) => {
        const alwaysError = () => {
            this.setState({
                fetching: null,
                queryRun: false
            }, () => {
                this.props.toggleModal(null)
                alert("There was an error reading your file. Please try again!");
            })
        }

        fetch(apiUrl).then(
            res => {
                if (res.status === 200){
                    res.json().then(data => {
                        const formattedData = this.formatData(data.data, null, idx)

                        this.setState({
                            originalData: this.getUpdatedStateInArray("originalData", idx, formattedData.data),
                            originalColNames: this.getUpdatedStateInArray("originalColNames", idx, formattedData.colNames),
                            queryRun: false,
                            query: "",
                            fetching: null
                        }, () => this.props.toggleModal(null))

                        this.runFunc(success)
                    }).catch(err => this.runFunc(error, alwaysError))
                } else {
                    this.runFunc(error, alwaysError)
                }
            }).catch(err => this.runFunc(error, alwaysError))
    }

    getUpdatedStateInArray = (name, idx, value) => {
        const copiedState = JSON.parse(JSON.stringify(this.state[name]))
        copiedState[idx] = value

        return copiedState
    }

    runFunc = (func, otherFunc=null) => {
        if (func !== null){
            func()
        } else {
            if (otherFunc !== null){
                otherFunc()
            }
        }
    }

    formatData = (data, paramColNames=null, idx=0) => {
        let formattedData = [];
        let colNames;

        if (data.length > 0){
            let fileColNames;

            if (paramColNames === null){
                fileColNames = this.state.fileInfo[idx].columnNames;
            } else {
                fileColNames = paramColNames
            }

            let selectedColNames = [];

            for (let i=0; i<fileColNames.length; i++){
                if (fileColNames[i] in data[0]){
                    selectedColNames.push(fileColNames[i])
                }
            }

            for (let row=0; row<data.length; row++){
                let rowValues = [];

                for (let i=0; i<selectedColNames.length; i++){
                    let cName = selectedColNames[i];
                    rowValues.push({value: data[row][cName]})
                }

                formattedData.push(rowValues)
            }

            colNames = selectedColNames

        } else {
            formattedData.push([{value: "No results found"}])
            colNames = ["A"]
        }

        return { data: formattedData, colNames: colNames }
    }

    // getColumnNames = () => (this.state.fileInfo.columnNames)

    closeImporter = () => this.state.importer.closeImporter()

    changeSQLQuery = (sqlCode) => this.setState({ query: sqlCode })

    initTestData = () => {
        const colNames = ["Title", "Genre", "Director", "Actors", "Year", "Runtime (Minutes)", "Description"]
        const colNames2 = ["Title", "Rating", "Votes", "Revenue (Millions)", "Metascore"]

        const success = () => this.setState({ query: 'SELECT file1.Title, file1.Genre, file1."Runtime (Minutes)", file2.Metascore\nFROM file1, file2\nWHERE file1.Genre LIKE \'%Drama%\' \nAND file2.Metascore IS NULL\nAND file1.Title=file2.Title\nORDER BY file1.Title;' })

        this.setFileInfoAndSetData([{
            columnNames: colNames,
            apiUrl: "https://api.apispreadsheets.com/data/NUhJCtWeeaLHrAsf/?accessKey=ddf8f98bb91cb7d913bcee2e845f9631&secretKey=acfd6a9a42bf5583ad47e20e4f958f97",
            name: "IMDB-Movie-Data-Metadata.xlsx"
        }, {
            columnNames: colNames2,
            apiUrl: "https://api.apispreadsheets.com/data/7LH0hxmt4MVr9Fwe/?accessKey=85c23edb1c4e172d0733954cf19963b4&secretKey=05afc00e31f688b944d4a9a403ad7432",
            name: "IMDB-Movie-Data-Metrics.csv"
        }], success)
    }

    runQuery = () => {
        this.setState({
            fetching: "Running your SQL Query..."
        }, () => {
            const alwaysError = (err="There was an error running your query. Please try again!") => {
                this.setState({
                    fetching: null,
                    queryRun: false
                }, () => {
                    this.props.toggleModal(null)
                    alert(err);
                })
            }

            this.props.toggleModal("loadingModalSQL")

            fetch(API_Backend_Root + "run-lovespreadsheets-query/", {
                method: "POST",
                body: JSON.stringify({
                    data: this.state.originalData.map(x => this.unformatData(x)),
                    columns: this.state.originalColNames,
                    query: this.state.query
                })
            }).then(
                res => {
                    if (res.status === 200){
                        res.json().then(data => {
                            const formattedData = this.formatData(data.data, data.colNames)
                            this.setState({
                                fetching: null,
                                data: formattedData.data,
                                columnNames: formattedData.colNames,
                                queryRun: true,
                                menuItemSelected: "query"
                            }, () => this.props.toggleModal(null))
                        }).catch(err => alwaysError())
                    } else if (res.status === 400){
                        res.json().then(data => {
                            alwaysError(data.error)
                        }).catch(err => alwaysError("Your Query is Invalid. Please check and try again!"))
                    } else {
                        alwaysError("Your Query is Invalid. Please check and try again!")
                    }
                }).catch(err => alwaysError())
        })

    }

    importFile = () => {
        this.setState({
            importer: null
        }, () => {
            this.setState({
                importer: new APISpreadsheetsImporter("a2cbc2acd49665cec9a530f468886553", this.importComplete)
            }, () => {
                this.state.importer.importFiles()
            })
        })
    }

    clearQueryResults = () => {
        this.setState({
            fetching: "Reading your file..."
        }, () => {
            this.props.toggleModal("loadingModalSQL")
            this.getFileData(this.state.fileInfo.apiUrl, null, null)
        })
    }

    downloadFile = () => {
        this.setState({
            fetching: "Preparing your file for download..."
        }, () => {
            this.props.toggleModal("loadingModalSQL")
            const error = () => {
                this.setState({
                    fetching: null
                }, () => {
                    this.props.toggleModal(null);
                    alert("There was a problem downloading your file. Please try again!")
                })
            }

            fetch(API_Backend_Root + "download-data/", {
                method: "POST",
                body: JSON.stringify({
                    data: this.unformatData(this.state.data),
                    colNames: this.state.columnNames,
                    fileName: "LoveSpreadsheets_Query_Results"
                })
            }).then(res => {
                if (res.status === 200){
                    res.json().then(data => {
                        this.setState({
                            downloadUrl: data.downloadUrl,
                            fetching: null
                        }, () => {
                            this.props.toggleModal(null)
                            this.sqlDownloadLink.click()
                        })
                    }).catch(err => error())
                } else {
                    error()
                }
            }).catch(err => console.log(err))
        })
    }

    unformatData = (formattedData) => {
        let unformattedData = [];

        for (let row=0; row<formattedData.length; row++){
            let newRow = [];

            for (let col=0; col<formattedData[row].length; col++){
                newRow.push(formattedData[row][col].value)
            }

            unformattedData.push(newRow)
        }

        return unformattedData
    }

    runQueryDisabled = () => {
        let disabled = true;

        if (this.state.fileInfo !== null){
            if (this.state.query.trim() !== ""){
                if (this.state.query.substring(0, 6).toLowerCase() === "select"){
                    disabled = false
                }
            }
        }

        return disabled
    }

    changeMenuItem = (menuItem) => this.setState({ menuItemSelected: menuItem })

    getDataToDisplay = () => {
        if (this.state.menuItemSelected === "query"){
            if (this.state.data !== null){
                return this.state.data
            } else {
                return null
            }

        } else {
            if (this.state.originalData !== null){
                return this.state.originalData[this.state.menuItemSelected]
            } else {
                return null
            }
        }
    }

    getColumnNamesToDisplay = () => {
        if (this.state.menuItemSelected === "query"){
            if (this.state.columnNames !== null){
                return this.state.columnNames
            } else {
                return null
            }

        } else {
            if (this.state.originalColNames !== null){
                return this.state.originalColNames[this.state.menuItemSelected]
            } else {
                return null
            }
        }
    }

    canDisplayData = () => {
        if (this.state.originalData === null){
            return false
        } else {
            if (Array.isArray(this.state.originalData)){
                if (this.state.originalData.includes(null)){
                    return false
                } else {
                    return true
                }
            } else {
                return false
            }
        }
    }

    getMenuColumnDisplay = () => {
        let display = "";

        for (let i=0; i<this.state.fileInfo.length; i++){
            display += "auto "
        }

        display += "1fr"

        return display
    }


    getMenuStyle = (isSelected) => {
        return {
            fontSize: "18px",
            border: isSelected ? "none" : "1px solid #F0F0F0",
            backgroundColor: isSelected ? styles.secondaryText : "white",
            color: isSelected ? "white" : styles.subText,
            padding: "16px",
            cursor: "pointer",
            borderRadius: "4px",
            fontWeight: "700"
        }
    }

    render() {
        const runQueryDisabled = this.runQueryDisabled();

        return (
            <div style={{ padding: isMobile ? "30px" : "30px 60px 240px 60px" }}>
                <SQLRulesModal/>
                <SQLUploadModal/>
                <LoadingModal name="loadingModalSQL" message={this.state.fetching}/>
                <div style={{ fontSize: "24px", color: styles.mainText, fontWeight: "700", marginBottom: "24px"}}>
                    Run <span style={{ color: styles.red}}>SQL Queries</span> on Your Spreadsheets and CSV Files
                </div>
                <div className="row" style={{ marginBottom: "24px"}}>
                    <div className="col-sm-6">
                        {/*<div className="row">*/}
                            {/*<div className="col-sm-6">*/}
                                <button style={{ border: "none", backgroundColor: styles.blue, borderRadius: "4px", padding: "8px 16px" }} className="hover" onClick={this.importFile}>
                                    <IconLabel
                                        icon="bx bx-upload"
                                        label="Upload Files"
                                        style={{ color: "white" }}
                                    />
                                </button>
                            {/*</div>*/}
                            {/*<div className="col-sm-6">*/}
                                <button style={{ marginLeft: isMobile ? "0":"12px", marginTop: isMobile ? "12px" : "0", display: isMobile ? "block" : "inline", border: "1px solid " + styles.blue, backgroundColor: "white", borderRadius: "4px", padding: "8px 16px" }} className="hover" onClick={this.initTestData}>
                                    <IconLabel
                                        icon="bx bx-table"
                                        label="Use Test Data"
                                        style={{ color: styles.blue }}
                                    />
                                </button>
                            {/*</div>*/}
                        {/*</div>*/}
                    </div>
                    <div className="col-sm-6" style={{ textAlign: "right"}}>
                            <span className="linkStyle" onClick={e => this.props.toggleModal("sqlUploadModal")}>
                                <i className='bx bx-question-mark' /> Upload Files FAQs</span>
                    </div>
                </div>
                <div style={{ border: "1px solid #F0F0F0", padding: "16px", borderRadius: "4px"}}>
                    <div className="row">
                        <div className="col-sm-8">
                            <div className="row">
                                <div className="col-sm-6">
                                    <IconLabel
                                        icon="bx bx-edit-alt"
                                        label="Write your SQL Query Here"
                                        style={{ fontWeight: "700" }}
                                    />
                                </div>
                                <div className="col-sm-6" style={{ textAlign: "right"}}>
                                    <span className="linkStyle" onClick={e => this.props.toggleModal("sqlRulesModal")}>
                                        <i className='bx bx-list-check' /> Query Writing Rules</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4" />
                    </div>
                    <div className="row">
                        <div className="col-sm-8">
                            <AceEditor
                                mode="sql"
                                theme="monokai"
                                onChange={this.changeSQLQuery}
                                value={this.state.query}
                                name="UNIQUE_ID_OF_DIV"
                                editorProps={{$blockScrolling: true}}
                                height={125}
                                width="auto"
                            />
                        </div>
                        <div className="col-sm-4">
                            <div style={{ marginBottom: "24px"}}>
                                <button style={{ border: "none",
                                    backgroundColor: styles.mainGreen,
                                    cursor: runQueryDisabled ? "not-allowed" : "pointer",
                                    opacity: runQueryDisabled ? "0.5" : "1",
                                    borderRadius: "4px", padding: "8px 16px" }}
                                        disabled={runQueryDisabled}
                                        className={runQueryDisabled ? "" : "hover"}
                                        onClick={this.runQuery}>
                                    <IconLabel
                                        icon="bx bxs-data"
                                        label="Run Query"
                                        style={{ color: "white" }}
                                    />
                                </button>
                                <button style={{ border: "none",
                                    marginLeft: isMobile ? "0":"12px",
                                    marginTop: isMobile ? "12px" : "0",
                                    display: isMobile ? "block" : "inline",
                                    backgroundColor: "red",
                                    cursor: !this.state.queryRun ? "not-allowed" : "pointer",
                                    opacity: !this.state.queryRun ? "0.5" : "1",
                                    borderRadius: "4px",
                                    padding: "8px 16px" }}
                                        disabled={!this.state.queryRun}
                                        className={!this.state.queryRun ? "" : "hover"}
                                        onClick={this.clearQueryResults}>
                                    <IconLabel
                                        icon="bx bx-undo"
                                        label="Undo"
                                        style={{ color: "white" }}
                                    />
                                </button>
                            </div>
                            <div>
                                {
                                    this.state.queryRun ? <div>
                                        <a href={this.state.downloadUrl} style={{ display: "none"}} ref={sqlDownloadLink => this.sqlDownloadLink = sqlDownloadLink}> </a>
                                        <div>
                                            <span onClick={this.downloadFile}>
                                                <IconLabel
                                                    icon="bx bx-cloud-download"
                                                    label="Download Query Results"
                                                    style={{
                                                        color: styles.blue,
                                                        cursor: "pointer"
                                                    }}
                                                />
                                            </span>
                                        </div>
                                    </div> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.canDisplayData() ?
                        <div style={{ marginTop: "24px" }}>
                            <div style={{
                                display: "grid",
                                gridTemplateColumns: this.getMenuColumnDisplay(),
                                columnGap: "16px"
                            }}>
                                {
                                    this.state.fileInfo.map((x, idx) =>
                                    <div
                                    className="hover"
                                    style={this.getMenuStyle(idx === this.state.menuItemSelected)} onClick={e => this.changeMenuItem(idx)}>
                                        <div>{x.name}</div>
                                        <div>
                                            <code
                                                style={{ color: this.state.menuItemSelected === idx ? "white" : "red"}}
                                            >file{(idx+1).toString()}</code>
                                        </div>
                                    </div>)
                                }
                                {
                                    this.state.data !== null ?
                                    <div
                                        className="hover"
                                        style={this.getMenuStyle(this.state.menuItemSelected === "query")} onClick={e => this.changeMenuItem("query")}>
                                        Query Results
                                    </div> : null
                                }
                            </div>
                            <div id="uploadFilesSampleTableContainer">
                                <div id="uploadFilesSampleTable">
                                <Spreadsheet data={this.getDataToDisplay()}
                                             columnLabels={this.getColumnNamesToDisplay()}
                                             className="sampleDataTable"/>
                                </div>
                            </div>
                        </div> : null
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(SQLIndex)