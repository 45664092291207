import React, {Component} from 'react'
import {connect} from 'react-redux'
import EditableText from "../../SharedComponents/EditableText";

class ReportName extends Component {
    render() {
        let report_name = this.props.allDBReports[this.props.reportSelectedIdx].report_name;

        if (report_name.trim() === ""){
            report_name = this.props.allDBReports[this.props.reportSelectedIdx].created_dt;
        }

        return (
            <div style={{ fontSize: "20px", marginTop: "8px"}}>
                <EditableText
                    text={report_name}
                    textType="report_name"
                    idx={this.props.reportSelectedIdx}
                    pk={this.props.allDBReports[this.props.reportSelectedIdx].pk}
                    input
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    reportSelectedIdx: state.mainState.reportSelectedIdx,
    allDBReports: state.mainState.allDBReports
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ReportName)