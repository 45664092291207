import {modifyFileInfoForCallback} from "../Apps/Upload/modifyFileInfoForCallback";

export const modifyFileInfo = (allFileInfo, filePK, sheetRead) => {
    let updatedFileInfo = [];

    if (filePK.length > 0) {
        const copiedAllFileInfo = JSON.parse(JSON.stringify(allFileInfo));

        for (let i = 0; i < filePK.length; i++) {
            const id = filePK[i].id;
            const pk = filePK[i].pk;

            const fileInfo = modifyFileInfoForCallback(copiedAllFileInfo, id, pk, sheetRead);

            if (fileInfo !== null) {
                if ("sheetInfo" in fileInfo) {
                    const allSheetInfo = JSON.parse(JSON.stringify(fileInfo.sheetInfo))
                    // console.log(allSheetInfo)
                    for (let sheetName in allSheetInfo) {
                        const sheetInfo = allSheetInfo[sheetName]
                        const sheetPk = sheetInfo.pk;
                        const sheetID = sheetInfo.id;
                        const sheetReadObj = {[sheetID]: sheetName}
                        const copiedAllSheetInfo = {[sheetID]: sheetInfo}

                        const modifiedSheetInfo = modifyFileInfoForCallback(copiedAllSheetInfo, sheetID, sheetPk, sheetReadObj)

                        allSheetInfo[sheetName] = modifiedSheetInfo
                    }

                    fileInfo.sheetInfo = allSheetInfo
                }

                updatedFileInfo.push(fileInfo)
            }
        }
    }

    return updatedFileInfo
}