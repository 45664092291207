import {Component} from 'react'
import {connect} from 'react-redux'

class AuthComponent extends Component {
    render() {
        if (this.props.isAuth){
            return this.props.auth
        } else {
            return this.props.nonAuth
        }
    }
}

const mapStateToProps = (state) => ({
    isAuth: state.mainState.isAuth
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(AuthComponent)