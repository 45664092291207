export const addParamToUrl = (url, param, value) => {
    if (url.includes(param)){
        let href = new URL(url)
        href.searchParams.set(param, value)

        return href
    } else {
        if (url.indexOf('?') > -1){
            url += "&"
        } else{
            url += "?"
        }

        url += param.toString() + "=" + value.toString()

        return url
    }
}