import React, {Component} from 'react'
import AddCommaToNumbers from "../../Constants/AddCommaToNumbers";
import { isMobile } from 'react-device-detect'
import { connect } from 'react-redux'
import styles from "../../Constants/styles";

const boxShadowValues = [
    "inset 1px 0px 0px #F0F0F0, inset 0px -1px 0px #F2F2F2",
    "inset 1px 0px 0px #F0F0F0, inset 0px -1px 0px #F2F2F2",
    "inset 1px 0px 0px rgba(240, 240, 240, 0.2), inset 0px -1px 0px rgba(242, 242, 242, 0.2)",
    "inset 1px 0px 0px rgba(240, 240, 240, 0.2), inset 0px -1px 0px rgba(242, 242, 242, 0.2)",
    "inset 1px 0px 0px rgba(240, 240, 240, 0.2), inset 0px -1px 0px rgba(242, 242, 242, 0.2)"
]

const borderValues = [
    "1px solid #F0F0F0",
    "1px solid #F0F0F0",
    "1px solid #89C6AA",
    "1px solid #F0F0F0",
    "1px solid #F0F0F0"
]

class PlanPricingBoxFeature extends Component {
    getDisplayText = (value, planIdx) => {
        let color = planIdx >= 2? "white" : "#5E645E";

        if (typeof value === "number"){
            let dispValue;
            if (planIdx === 4){
                dispValue = "UNLIMITED"
            } else { dispValue = AddCommaToNumbers(value.toString())}

            return <span style={{color: color}}>{dispValue}</span>
        } else if (typeof value === "string"){
            return <span style={{color: color}}>{value}</span>
        } else if (typeof value === "boolean"){
            if (value && planIdx < 2){
                color = styles.mainGreen
            }

            return <span style={{color: color}}><i className={value ? "fa-solid fa-check" : "fa-solid fa-xmark"} /> </span>
        } else {
            return <span>{value.toString()}</span>
        }
    }

    getLabelValue = (featureInfo) => {
        if (featureInfo === undefined){
            return ""
        } else {
            return featureInfo.label
        }
    }

    render() {
        // console.log(this.props.info)
        const baseStyle = {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "8px 0px",
            gap: "8px",
            textAlign: "center",
            fontWeight: "500",
            fontSize: "14px",
            // border: "1px solid #F0F0F0"
            boxShadow: boxShadowValues[this.props.planIdx]
        };

        if (this.props.isNotPricingPage) {
            if (this.props.lastItem){
                baseStyle.padding = "8px 16px"

                if (!('content' in this.props)){
                    baseStyle.borderBottom = borderValues[this.props.planIdx]
                }
            } else {
                baseStyle.padding = "8px 16px 0 16px"
            }

            delete baseStyle['boxShadow']
            delete baseStyle['justifyContent']
            baseStyle.textAlign = 'left'
            baseStyle.borderRight = borderValues[this.props.planIdx]
            baseStyle.borderLeft = borderValues[this.props.planIdx]
        }

        if ('content' in this.props){
            const boxStyle = {...baseStyle}

            if (this.props.isNotPricingPage) {
                boxStyle.borderTop = borderValues[this.props.planIdx]
                delete boxStyle['display']
                delete boxStyle['flexDirection']
                delete boxStyle['textAlign']
                boxStyle.padding = "16px"
            }

            return (
                <div style={boxStyle}>
                    {this.props.content}
                </div>
            )
        } else {
            const pricingKey = this.props.info.key;
            const value = this.props.pricing[pricingKey]

            if (this.props.isNotPricingPage){
                return (
                    <div style={baseStyle}>
                        <div style={{textAlign: "left", color: this.props.planIdx >= 2 ? "white" : "#161E16"}}> <b style={{ fontSize: "16px"}}>{this.getDisplayText(value, this.props.planIdx)}</b> <small>{this.getLabelValue(this.props.info)}</small></div>
                    </div>
                )
            } else {
                return (
                    <div style={baseStyle}>
                        {this.getDisplayText(value, this.props.planIdx)}
                    </div>
                )
            }
        }
    }
}

const mapStateToProps = (state) => ({

})

export default connect(mapStateToProps, null)(PlanPricingBoxFeature)