import React, {Component} from 'react'
import Accordion from "../Accordion";
import ColumnInfoIndex from "./ColumnInfoIndex";

class TableRow extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Accordion
                title={this.props.tableName}
                body={<ColumnInfoIndex columnInfo={this.props.columnInfo} />}
            />
        )
    }
}

export default TableRow