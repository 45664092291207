import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifyAppName} from "../Actions/ModifyAppName";
import DemoModalBody from "../Demo/DemoModalBody"
import Joyride, { ACTIONS, STATUS,  EVENTS } from 'react-joyride';
import {testFileData} from "./Upload/testFileData";
import {localTestFileData} from "./Upload/localTestFileData";
import {modifyAiInput} from "../Actions/ModifyAiInput";
import {modifyDemoTourStep} from "../Actions/ModifyDemoTourStep";
import {toggleRunProductDemo} from "../Actions/ToggleRunProductDemo";
import {modifyAiFileSelected} from "../Actions/ModifyAiFileSelected";
import {toggleUseTestData} from "../Actions/ToggleUseTestData";
import {toggleModal} from "../Actions/ToggleModal";
import {modifyAiFiles} from "../Actions/ModifyAiFiles";
import {toggleIsAiResultSelected} from "../Actions/ToggleIsAiResultSelected";
import FileOptionsSidebarIndex from "./FileOptionsSidebar/FileOptionsSidebarIndex"
import {modifyProjectSelectedIdx} from "../Actions/ModifyProjectSelectedIdx";
import {modifyAllProjects} from "../Actions/ModifyAllProjects";
import ConversationsIndex from "./Conversations/ConversationsIndex"
import {isMobile} from "react-device-detect";
import MobileLandingPage from "./MobileLandingPage";
import {toggleChatMode} from "../Actions/ToggleChatMode";
import TeamInvitesIndex from "../Team/TeamInvitesIndex";

class AppsIndex extends Component {
    constructor(props) {
        super(props);

        this.runBtnRef = React.createRef();

        this.state = {
            isMenuOpen: true,
            vizInfo: null,
            steps: [
                {
                    target: '.my-first-step',
                    content: <DemoModalBody step={0}/>,
                    disableBeacon: true,
                    placement: 'right'
                },
                {
                    target: '.my-second-step',
                    content: <DemoModalBody step={1}/>,
                    disableBeacon: true,

                },
                {
                    target: '.my-third-step',
                    content: <DemoModalBody step={2}/>,
                    disableBeacon: true,
                },
                {
                    target: '.my-fourth-step',
                    content: <DemoModalBody step={3}/>,
                    disableBeacon: true,
                    // placement: 'left'
                },
                // {
                //     target: '.my-fifth-step',
                //     content: <DemoModalBody step={5}/>,
                //     disableBeacon: true,
                //     placement: 'bottom'
                // },
            ]
        }
    }

    componentDidMount(){
        // this.createNewProject()
    }

    changeState = (key, value) => this.setState({[key]: value})

    toggleIsMenuOpen = (isMenuOpen) => this.setState({ isMenuOpen: isMenuOpen })

    changeAppName = (appName) => this.props.modifyAppName(appName)

    showUpgradeBtn = () => {
        let show = false;

        if (this.props.aiResultSelected !== null){
            if (this.props.userPlan === null){
                if (this.props.aiResultSelected !== 0){
                    show = true
                }
            }
        }

        return show
    }

    getDisplayStyleForResult = () => {
        const style = { paddingTop: "24px", paddingBottom: "24px"};

        return style
    }

    handleCallback = (data) => {
        const { status, type, index, action } = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            if (index === 1){
                this.props.toggleChatMode(false)
            }
            // if (index === 0){
            //     //    init test data here and then change the step
            //     this.initTestData()
            // } else if (index === 1){
            //     this.props.modifyAiInput('Show me all the movie titles where Chris Pratt acted in')
            //     this.props.modifyDemoTourStep(index + 1)
            // } else if (index === 3) {
            //     this.runBtnRef.current.click()
            // } else {
                this.props.modifyDemoTourStep(index + (action === ACTIONS.PREV ? -1 : 1))
            // }
        } else if (finishedStatuses.includes(status)) {
            this.props.toggleRunProductDemo(false)
        }
    }

    initTestData = () => {
        this.props.toggleUseTestData(true)
        const testData = window.location.href.includes("localhost:2000") ? localTestFileData : testFileData;
        this.updateAIFiles(testData);
        this.props.modifyDemoTourStep(1)
        // this.props.modifyAiInput("Show me all the movie titles where Chris Pratt acted in")
    }

    updateAIFiles = (newFiles) => {
        const currFileData = JSON.parse(JSON.stringify(this.props.aiFiles))

        for (let i=0; i<newFiles.length; i++){
            currFileData.push(newFiles[i])
        }

        this.props.modifyAiFiles(currFileData)

        if (this.props.aiFileSelected === null && currFileData.length > 0){
            this.props.modifyAiFileSelected(0)
            this.props.toggleIsAiResultSelected(false)
        }
    }

    render() {
        // const segmentStyle = { backgroundColor: "white", padding: "24px", border: "1px solid #F0F0F0", borderRadius: "4px", boxShadow: "0px 5px 6px -4px rgba(2, 34, 19, 0.02)"}
        const segmentStyle = { backgroundColor: "white", padding: "12px", border: "1px solid #F0F0F0", borderRadius: "4px", boxShadow: "0px 5px 6px -4px rgba(2, 34, 19, 0.02)"}
        // line 112 <div style={{...segmentStyle, ...{marginTop: "24px"}}}>

        return (
            <div style={{minHeight: '100vh'}}>
                <Joyride
                    callback={this.handleCallback}
                    steps={this.state.steps}
                    run={this.props.runProductDemo}
                    stepIndex={this.props.demoTourStep}
                    hideBackButton={true}
                    hideCloseButton={true}
                    showProgress={true}
                    scrollToFirstStep
                    continuous
                />
                <div>
                    <TeamInvitesIndex/>
                    <div style={{display: "grid", gridTemplateColumns: "400px 1fr", backgroundColor: "#E5E5E5"}}>
                        <div>
                            <FileOptionsSidebarIndex />
                        </div>
                        <div>
                            <div>
                                <ConversationsIndex segmentStyle={segmentStyle} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    aiFileSelected: state.mainState.aiFileSelected,
    aiResults: state.mainState.aiResults,
    aiResultSelected: state.mainState.aiResultSelected,
    isAiResultSelected: state.mainState.isAiResultSelected,
    userPlan: state.mainState.userPlan,
    appName: state.mainState.appName,
    runProductDemo: state.mainState.runProductDemo,
    demoTourStep: state.mainState.demoTourStep,
    allAiResults: state.mainState.allAiResults,
    aiFiles: state.mainState.aiFiles,
    allProjects: state.mainState.allProjects,
    isAuth: state.mainState.isAuth
})

const mapActionsToProps = {
    modifyAppName: modifyAppName,
    toggleRunProductDemo: toggleRunProductDemo,
    toggleModal: toggleModal,
    modifyDemoTourStep: modifyDemoTourStep,
    toggleUseTestData: toggleUseTestData,
    modifyAiInput: modifyAiInput,
    modifyAiFiles: modifyAiFiles,
    modifyAiFileSelected: modifyAiFileSelected,
    toggleIsAiResultSelected: toggleIsAiResultSelected,
    modifyAllProjects: modifyAllProjects,
    modifyProjectSelectedIdx: modifyProjectSelectedIdx,
    toggleChatMode: toggleChatMode,
}

export default connect(mapStateToProps, mapActionsToProps)(AppsIndex)