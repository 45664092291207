import React, {Component} from 'react'
import {connect} from 'react-redux'
import WoyeraInc from "../Footer/WoyeraInc";
import FooterTermsAndPrivacy from "../Footer/FooterTermsAndPrivacy";

class GooglePrivacyPolicyFooter extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{ borderTop: "1px solid #F0F0F0", padding: "24px"}}>
                <div style={{paddingTop: "16px"}}>
                    <div style={{display: "grid", gridTemplateColumns: "1fr 1fr"}}>
                        <div>
                            <WoyeraInc/>
                        </div>
                        <div style={{textAlign: "right"}}>
                            <FooterTermsAndPrivacy/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(GooglePrivacyPolicyFooter)