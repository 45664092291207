import React, {Component} from 'react'
import capitalizeHyphenated from "./Constants/capitalizeHyphenated";

class EmailProductSelection extends Component {
    toggle = () => {
        this.props.changeProductSelection(this.props.product)
    }

    render() {
        const id = this.props.product + "Checkbox";
        return (
            <div>
                <input
                    id={id}
                    value={this.props.checkedValue}
                    onChange={this.toggle}
                />
                <label htmlFor={id}>{capitalizeHyphenated(this.props.product)}</label>
            </div>
        )
    }
}

export default EmailProductSelection