import React, {Component} from 'react'
import ModalApp from "../SharedComponents/Modal"
import DeleteDatabaseModalBody from "./DeleteDatabaseModalBody"

class DeleteDatabaseModal extends Component {
    render() {
        return (
            <ModalApp name="deleteDatabaseModal"
                      header={null}
                      size="small"
                      body={<DeleteDatabaseModalBody />}
            />
        )
    }
}

export default DeleteDatabaseModal