import React, {Component} from 'react'
import ModalApp from '../Modal'
import LanguageModalBody from "./LanguageModalBody";

class LanguageModal extends Component {
    render() {
        return (
            <ModalApp name="languageModal"
                      header={null}
                      size="medium"
                      body={<LanguageModalBody/>}
            />
        )
    }
}

export default LanguageModal