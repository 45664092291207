import React, {Component} from 'react'
import {connect} from 'react-redux'
import TeamInvitesRow from "./TeamInvitesRow"

class TeamInvitesIndex extends Component {
    render() {
        const teamInvites = [];

        for (let i=0; i<this.props.allTeamsInfo.length; i++){
            if (!this.props.allTeamsInfo[i].has_joined){
                teamInvites.push(this.props.allTeamsInfo[i])
            }
        }

        if (teamInvites.length > 0){
            return (
                <div>
                    {
                        teamInvites.map((x, idx) =>
                            <TeamInvitesRow key={idx} idx={idx} info={x}/>)
                    }
                </div>
            )
        }

        return (
            null
        )
    }
}

const mapStateToProps = (state) => ({
    allTeamsInfo: state.mainState.allTeamsInfo
})

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(TeamInvitesIndex)
