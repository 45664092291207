import React, {Component} from 'react'
import {connect} from 'react-redux'
import APINoCodeInputIndex from "./APINoCodeInputIndex"
import WebsiteInputIndex from "./Website/WebsiteInputIndex"
import DatabaseNoCodeIndex from "./Database/DatabaseNoCodeIndex"

class NoCodeInputIndex extends Component {
    constructor(props) {
        super(props);
    }

    keepParamsUpdated = (newParams) => {
        const currParams = JSON.parse(JSON.stringify(this.props.params));

        for (let paramKey in newParams){
            currParams[paramKey] = newParams[paramKey]
        }

        this.props.changeState({params: currParams})
    }

    initState = (defaultState) => {
        defaultState = JSON.parse(JSON.stringify(defaultState));

        for (let key in defaultState){
            if (key in this.props.params){
                if (typeof this.props.params[key] === typeof defaultState[key]){
                    defaultState[key] = this.props.params[key]
                }
            }
        }

        this.keepParamsUpdated(defaultState)

        return defaultState
    }

    render() {
        const labelHeaderStyle = {fontFamily: "Lato", fontSize: "14px", fontWeight: "400", color: "#5E645E", marginBottom: "8px"}

        if (this.props.dataSource === 'api'){
            return <div>
                <APINoCodeInputIndex
                    keepParamsUpdated={this.keepParamsUpdated}
                    labelHeaderStyle={labelHeaderStyle}
                    params={this.props.params}
                    initState={this.initState}
                />
            </div>
        } else if (this.props.dataSource === 'website'){
            return <div>
                <WebsiteInputIndex
                    keepParamsUpdated={this.keepParamsUpdated}
                    labelHeaderStyle={labelHeaderStyle}
                    params={this.props.params}
                    initState={this.initState}
                />
            </div>
        } else if (this.props.dataSource === 'database'){
            return <div>
                <DatabaseNoCodeIndex
                    keepParamsUpdated={this.keepParamsUpdated}
                    labelHeaderStyle={labelHeaderStyle}
                    params={this.props.params}
                    initState={this.initState}
                />
            </div>
        }

        return (
            null
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(NoCodeInputIndex)