import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../../../Constants/styles";
import {toggleModal} from "../../../Actions/ToggleModal";

class APINoCodeAuthIndex extends Component {
    constructor(props) {
        super(props);
    }

    changeBodyTypeValue = (value) => {
        this.props.changeState({ requestAuthType: value })
    }

    getReportSelectedIdx = () => ('reportLandingIdx' in this.props ? this.props.reportLandingIdx : this.props.reportSelectedIdx)


    getCheckbox = (value, label) => {
        return <div style={{ marginRight: '16px' }}>
            <input type="radio"
                   name={"requestAuthType" + (this.getReportSelectedIdx()).toString()}
                   id={"requestAuthType" + (this.getReportSelectedIdx()).toString() + value.toString()}
                   value={value}
                   checked={this.props.requestAuthType === value}
                   onClick={e => this.changeBodyTypeValue(value)}
                   style={{ cursor: 'pointer'}}
            />
            <label htmlFor={"requestAuthType" + (this.getReportSelectedIdx()).toString() + value.toString()} style={{...this.props.labelHeaderStyle, ...{paddingLeft: '4px',  cursor: 'pointer'}}}>
                {label}
            </label>
        </div>
    }

    changeBasicParams = (e) => {
        const copiedParams = JSON.parse(JSON.stringify(this.props.requestAuthBasicParams));

        copiedParams[e.target.name] = e.target.value;

        this.props.changeState({ requestAuthBasicParams: copiedParams })
    }

    getBody = () => {
        switch (this.props.requestAuthType){
            case 'none':
                return <div style={{ fontSize: '12px', color: styles.subText }}>No auth needed for API</div>
            case 'bearer':
                return <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', columnGap: '16px' }}>
                    <div style={{ paddingTop: '8px' }}>
                        <div style={this.props.labelHeaderStyle}>
                            Bearer Token
                        </div>
                    </div>
                    <div>
                        <input
                            className="inputGray"
                            style={{ width: '100%' }}
                            placeholder="abcdefasd123"
                            value={this.props.requestAuthBearerToken}
                            onChange={e => this.props.changeState({ requestAuthBearerToken: e.target.value })}
                        />
                    </div>
                </div>
            case 'basic':
                return <div>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 8fr', columnGap: '16px' }}>
                        <div style={{ paddingTop: '8px' }}>
                            <div style={this.props.labelHeaderStyle}>
                                Username
                            </div>
                        </div>
                        <div>
                            <input
                                className="inputGray"
                                style={{ width: '100%' }}
                                placeholder="yourusername"
                                name='username'
                                value={this.props.requestAuthBasicParams.username}
                                onChange={this.changeBasicParams}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 8fr', columnGap: '16px' }}>
                        <div style={{ paddingTop: '8px' }}>
                            <div style={this.props.labelHeaderStyle}>
                                Password
                            </div>
                        </div>
                        <div>
                            <input
                                type="password"
                                name='password'
                                className="inputGray"
                                style={{ width: '100%' }}
                                placeholder="abcdefghijkl"
                                value={this.props.requestAuthBasicParams.password}
                                onChange={this.changeBasicParams}
                            />
                        </div>
                    </div>
                </div>
            case 'oAuth':
                return <div style={{ fontSize: '12px', color: styles.subText }}>
                    <span className="linkStyle" onClick={e => this.props.toggleModal('getSupportModal')}>Contact us</span> to set up OAuth2.0
                </div>
            default:
                return null
        }
    }

    render() {
        return (
            <div>
                <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto auto 1fr', margin: "8px 16px"}}>
                    {this.getCheckbox("none", "No Auth")}
                    {this.getCheckbox("bearer", "Bearer Auth")}
                    {this.getCheckbox("basic", "Basic Auth")}
                    {this.getCheckbox("oAuth", "OAuth2.0")}
                    <div> </div>
                </div>
                <div style={{ margin: "0px 16px 16px 16px"}}>
                    {this.getBody()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    reportSelectedIdx: state.mainState.reportSelectedIdx
})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(APINoCodeAuthIndex)