import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleModal} from "../../Actions/ToggleModal";
import {modifyModalInfo} from "../../Actions/ModifyModalInfo";

class DataSourcesHelpToolbar extends Component {
    constructor(props) {
        super(props);
    }

    openModal = () => {
        this.props.toggleModal("connectHelpModal")

        this.props.modifyModalInfo({
            dataSourceLabel: this.getLabel(this.props.dataSource),
            dataSource: this.props.dataSource
        })
    }

    getLabel = () => {
        switch (this.props.dataSource){
            case 'mysql':
                return "MySQL"
            case 'postgres':
                return "Postgres"
            case 'api':
                return "APIs"
            case 'website':
                return "Websites"
            default:
                return this.props.dataSource
        }
    }

    render() {
        return null
        // return (
        //     <div>
        //         <span className="linkStyle" style={{ fontSize: "14px"}} onClick={this.openModal}>Quickstart guide to connect {this.getLabel()}</span>
        //     </div>
        // )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo
}

export default connect(mapStateToProps, mapActionsToProps)(DataSourcesHelpToolbar)