import React, {Component} from 'react'
import { isMobile } from 'react-device-detect'
import styles from "../Constants/styles";
import IconLabel from "../SharedComponents/IconLabel";

class RequestDemoSegment extends Component {
    render() {
        return (
            <div style={{backgroundColor: styles.red,padding:'30px 50px 30px 50px',borderRadius:'10px'}}>
                <div style={ isMobile ? null : {display: "grid", gridTemplateColumns: "1fr auto", columnGap: "30px"}}>
                    <div style={isMobile ? {marginBottom: "24px"} : null}>
                        <div style={{color: "white", fontWeight: "700", fontSize: "24px", fontFamily: "Lato", marginBottom: "8px"}}>
                            Book a free 25 minute call with us
                        </div>
                        <div>
                            Let us be your sherpa for AI data analysis
                        </div>
                    </div>
                    <div style={{verticalAlign: "middle"}}>
                        <button onClick={() => {window.open("https://calendly.com/love-spreadsheets/25min", "_blank");}}
                                style={{backgroundColor:'black',width:'auto',color:'white',fontSize:'120%', border:"none", borderRadius: "4px", padding: "12px 24px 12px 24px"}}>
                            <IconLabel
                                icon="bx bxs-phone"
                                label="Book a Call"
                                style={{ columnGap: "4px" }}
                            />
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default RequestDemoSegment