import React, {Component} from 'react'
import {CardElement, injectStripe} from 'react-stripe-elements';
import API_Root from "../../Constants/API_Root";
import isEmailValid from '../../Constants/isEmailValid'
import AuthComponent from "../AuthComponent";
import BannerAlert from "../../SharedComponents/BannerAlert";
import Button from "../../SharedComponents/Button";
import styles from "../../Constants/styles";
import {gtag, install} from "ga-gtag";

class CreditCardDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            passwordOne:'',
            tandc: false,
            cardSuccess: null,
            name: '',
            submitDisabled: true,
            loading: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.submit = this.submit.bind(this);
        this.dismissMessage = this.dismissMessage.bind(this);
        this.signUp = this.signUp.bind(this);
        this.emailValid = this.emailValid.bind(this);
        this.passwordOneValid = this.passwordOneValid.bind(this);
        this.updateLocalStorage = this.updateLocalStorage.bind(this);

    }

    dismissMessage(){
        this.setState({ cardSuccess: null })
    }

    handleInputChange(e){
        let elementName = e.target.name;

        if (elementName === "tandc"){
            this.setState({
                tandc: !this.state.tandc
            })
        }
        else{
            let elementValue = e.target.value;

            if (elementName === "email" || elementName === "name"){
                this.setState({
                    [elementName]: (elementValue).trim()
                })
            } else if (elementName === "code"){
                this.props.changeState("code", elementValue)
            } else{
                this.setState({
                    [elementName]: elementValue
                })
            }
        }
    }

    gtag_report_conversion = (url) => {
        install('G-P5NRN98WNT', { 'send_page_view': false });
        //
        var callback = function () {
            // if (typeof(url) != 'undefined') {
            //     window.location = url;
            // }
        };

        gtag('event', 'conversion', {
            'send_to': 'AW-10835522777/IHhJCLf94YoYENnp464o',
            'event_callback': callback
        });

        return false;
    }

    updateLocalStorage(email, token){
        return new Promise((resolve, reject) => {
            let updated;
            try{
                localStorage.setItem("email", email)
                localStorage.setItem("token", token)
                updated = true
            }
            catch(error){
                updated = false
            }
            resolve(updated)
        })
    }

    serverErrorMessage = (header="There was an error on our end creating the account", content=<span>Please <a href={"mailto:info@lovespreadsheets.com?Subject=Create%20Account%20Error%20for%20" + this.state.email}>Email Us</a></span>) =>
        (<BannerAlert type="error"
                      action="close"
                      header={header}
                      onDismiss={this.dismissMessage}
                      content={content} />)

    signUp(e){
        this.setState({
            loading: true
        })

        const email = this.state.email;
        const passwordOne = this.state.passwordOne;

        const API = API_Root + 'api/save-user/'; /*save user email to our db*/
        const formData = {
            "email": email,
            "username": email,
            "password": passwordOne,
            "source": localStorage.getItem("source"),
            "orderName": "",
            "code": this.props.isCode ? this.props.code.trim() : "",
            "sessionId": localStorage.getItem("sessionId")
        };

        // localStorage.setItem("email", email);
        // localStorage.setItem("token", data.token);

        this.setState(() => ({
            username: '',
            email: '',
            passwordOne: '',
            passwordTwo: '',
            tandc: false,
            error: null,
        }));

        fetch(API, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        }).then((res) => {
                if (res.status === 200) {
                    res.json().then((data) => {
                        const updateLocalStorage = this.updateLocalStorage(email, data.token);

                        updateLocalStorage.then(
                            (success) => {
                                if (success){
                                    if (this.props.isCode){
                                        if (data.codeError){
                                            this.props.toggleAuth(true)

                                            this.setState({
                                                cardSuccess: this.serverErrorMessage("Either your code was wrong or it has already been used", "If you think this is in error, please contact us at info@lovespreadsheets.com"),
                                                loading: false
                                            })
                                        } else {
                                            this.props.changeProcess("completed")
                                        }
                                    } else {
                                        this.props.toggleAuth(true)

                                        this.submit(e)
                                    }
                                }
                                else{
                                    this.setState({
                                        cardSuccess: this.serverErrorMessage(),
                                        loading: false
                                    })
                            }
                        })
                    }).catch(error => this.setState({cardSuccess: this.serverErrorMessage(), loading: false}))
                } else if (res.status === 400){
                    this.setState({
                        cardSuccess: this.serverErrorMessage("An account with this email already exists", "Please try another email"),
                        loading: false
                    })
                } else {
                    this.setState({cardSuccess: this.serverErrorMessage(), loading: false})
                    // res.json().then((data) => {
                    //
                    // }).catch(err => )
                }
        }).catch(error => this.setState({cardSuccess: this.serverErrorMessage(), loading: false}))
    }

    submitCode = () => {
        this.setState({
            loading: true
        })

        fetch(API_Root + "api/lovespreadsheets-promo-code/", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                code: this.props.code.trim()
            })
        }).then(res => {
                if (res.status === 200) {
                    this.props.changeProcess("completed")
                } else if (res.status === 400){
                    this.setState({
                        cardSuccess: this.serverErrorMessage("Either your code was wrong or it has already been used", "If you think this is in error, please contact us at info@lovespreadsheets.com"),
                        loading: false
                    })
                } else {
                    this.setState({
                        cardSuccess: this.serverErrorMessage("There was an error on our end", "Please contact us at info@lovespreadsheets.com if this error persists"),
                        loading: false
                    })
                }
        }).catch(err => this.setState({cardSuccess: this.serverErrorMessage("There was an error on our end", "Please contact us at info@lovespreadsheets.com if this error persists"), loading: false}))
    }

    async submit(e) {
        try{
            this.gtag_report_conversion(window.location.href)
        } catch (e) { console.log(e) }

        this.setState({
            loading: true
        })

        const failedMessage = "Your Payment Failed!";
        let {token} = await this.props.stripe.createToken({name: "Name"});

        if (token !== undefined){
            let response = await fetch(API_Root + "api/charge-lovespreadsheets-lifetime/", {
                method: "POST",
                headers: {"Content-Type": "text/plain"},
                body: JSON.stringify({
                    stripeToken: token.id,
                    email: localStorage.getItem("email"),
                    name: this.state.name,
                    token: localStorage.getItem("token"),
                    currency: this.props.currency,
                    amount: this.props.currencyAmount
                })
            }).then().catch(err =>
                this.setState({
                    loading: false,
                    cardSuccess: <BannerAlert type="error"
                                              action="close"
                                              onDismiss={this.dismissMessage}
                                              header={failedMessage}
                                              content="Please try again!"
                    />
                })
            );

            if (response !== undefined){
                if (response.ok){
                    this.setState({
                        loading: false,
                        cardSuccess: <BannerAlert type="positive"
                                                  action="close"
                                              onDismiss={this.dismissMessage}
                                              header="Your payment was a success!"
                        />
                    })

                    this.props.changeProcess("completed")
                }
                else{
                    response.json().then(data => {
                        const errorMsg = "errorMsg" in data ? data['errorMsg'] : "Please try again!"

                        this.setState({
                            loading: false,
                            cardSuccess: <BannerAlert type="error"
                                                      action="close"
                                                  onDismiss={this.dismissMessage}
                                                  header={failedMessage}
                                                  content={errorMsg}
                            />
                        })
                    }).catch(err =>
                        this.setState({
                            loading: false,
                            cardSuccess: <BannerAlert type="error"
                                                      action="close"
                                                  onDismiss={this.dismissMessage}
                                                  header={failedMessage}
                                                  content="Please try again!"
                            />
                        })
                    )
                }
            }

        } else{
            this.setState({
                loading: false,
                cardSuccess: <BannerAlert type="error"
                                          action='close'
                                      onDismiss={this.dismissMessage}
                                      header={failedMessage}
                                      content="Please enter all details and try again!"
                />
            })
        }
    }

    emailValid(){
        if (this.state.email.trim() !== ""){
            return isEmailValid(this.state.email)
        }
        else{
            return true
        }
    }

    passwordOneValid(){
        if (this.state.passwordOne.trim() !== ""){
            return this.state.passwordOne.length >= 8
        }
        else{
            return true
        }
    }

    render() {
        const {
            email,
            passwordOne,
            tandc
        } = this.state;

        const isCodeValid = (this.props.isCode && this.props.code.trim().length === 0)
        const isInvalid = passwordOne.trim() === '' || passwordOne.length < 8 || email.trim() === ''|| tandc === false || !isEmailValid(email.trim()) || isCodeValid;
        const emailError = !this.emailValid() ? <small className="inputError">Enter a valid email</small> : null;
        const passwordOneError = !this.passwordOneValid() ? <small className="inputError">Password must be at least 8 characters</small> : null;
        const labelStyle = { fontSize: "16px", fontWeight: "700", color: styles.mainText}

        return (
            <div className="checkout">
                <AuthComponent auth={null}
                               nonAuth={
                                   <div>
                                       <div style={{ marginTop: "24px" }}>
                                           <div style={labelStyle}>
                                               Email
                                           </div>
                                           <input type="email"
                                                  name="email"
                                                  className="semanticBorder"
                                                  id="accountEmail"
                                                  placeholder="Account Email"
                                                  onChange={this.handleInputChange}
                                                  value={email}
                                                  style={{ width: '100%' }}
                                           />
                                           {emailError}
                                       </div>
                                       <div style={{ marginTop: "24px" }}>
                                           <div style={labelStyle}>
                                               Password
                                           </div>
                                           <input id="accountPassword"
                                                  className="semanticBorder"
                                                  type="password"
                                                  name="passwordOne"
                                                  placeholder="Enter a Password"
                                                  onChange={this.handleInputChange}
                                                  value={passwordOne}
                                                  style={{ width: '100%' }}
                                           />
                                           {passwordOneError}
                                       </div>
                                   </div>
                               }
                />
                <div style={{ marginTop: "24px" }}>
                    <div style={labelStyle}>
                        {
                            this.props.isCode ? "Upgrade Code" : "Credit Card Details"
                        }
                    </div>
                    {
                        this.props.isCode ?
                        <div>
                            <input id="accountEmail"
                                   className="semanticBorder"
                                   name="code"
                                   placeholder="X98F1B"
                                   onChange={this.handleInputChange}
                                   value={this.props.code}
                                   style={{ width: '100%' }}
                            />
                        </div> :
                        <div className="semanticBorder">
                            <CardElement />
                        </div>
                    }
                </div>
                <AuthComponent
                    nonAuth={<div style={{ marginTop: "24px" }}>
                        <input type="checkbox" name="tandc" id="tandc" onChange={this.handleInputChange} checked={tandc} />
                        <label htmlFor="tandc">
                            <span style={{paddingLeft: '7px'}}>I agree with Love Spreadsheets'</span>
                            <a href="/terms" target="_blank"> Terms and Conditions</a>
                        </label>
                    </div>}
                    auth={null}
                />
                <div style={{ marginTop: "24px" }}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Button loading={this.state.loading}
                                onClick={this.props.isAuth ? this.props.isCode ? this.submitCode : this.submit : this.signUp}
                                disabled={this.props.isAuth ? this.props.isCode ? isCodeValid : false : isInvalid}
                                label={<span>{"Upgrade to Lifetime Account for " + (this.props.isCode ? "FREE" : "$" + this.props.currencyAmount)}
                                    <span role="img" aria-label="smile" style={{paddingLeft: '7px'}}>😀</span></span>}
                                id="upgradeButton"
                                style={{textAlign: 'center', backgroundColor: styles.orange, color: "white"}}>
                        </Button>
                    </div>
                </div>
                <br/>
                {this.state.cardSuccess}
            </div>
        )
    }
}

export default injectStripe(CreditCardDetails)