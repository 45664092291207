import React, {Component} from 'react'
import { TailSpin } from 'react-loader-spinner'
import styles from "./Constants/styles";

class LoaderApp extends Component {
    render() {
        return (
            <TailSpin
                height={'height' in this.props ? this.props.height.toString() : "50"}
                width={'width' in this.props ? this.props.width.toString() : "50"}
                color={styles.mainText}
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
        )
    }
}

export default LoaderApp