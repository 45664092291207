import React, {Component} from 'react'
import {connect} from 'react-redux'
import Select from 'react-select'

class ConnectAPIIndex extends Component {
    constructor(props) {
        super(props);
    }

    getAiMode = () => (this.props.getValuesFromConnectionParams(this.props.connectionParams, 'isAiMode', false))

    onChange = (e) => {
        const copiedParams = JSON.parse(JSON.stringify(this.props.connectionParams));
        const eName = e.target.name;

        if (eName === 'isAiMode'){
            copiedParams[eName] = !this.getAiMode();
            copiedParams['docsUrl'] = ''
        } else {
            copiedParams[e.target.name] = e.target.value
        }


        this.props.changeState({connectionParams: copiedParams})
    }

    render() {
        const isAiMode = this.getAiMode();

        return (
            <div>
                <div>
                    <div style={this.props.displayHeaderStyle}>Use AI?</div>
                    <label className="switch">
                        <input type="checkbox" checked={isAiMode} onChange={this.onChange} name='isAiMode'/>
                        <span className="slider round"></span>
                    </label>
                </div>
                {
                    isAiMode ?
                        <div style={{ marginTop: '16px'}}>
                            <div style={this.props.displayHeaderStyle}>API { isAiMode ? 'Docs' : ''} URL<span style={{color: 'red'}}>*</span></div>
                            <input style={{width: '100%'}}
                                   className="inputGray"
                                   value={this.props.getValuesFromConnectionParams(this.props.connectionParams, 'docsUrl', 'str')}
                                   type='text'
                                   placeholder={ isAiMode ? 'https://www.stripe.com/api-docs/' : 'https://your-rest-api-url.com/endpoint'}
                                   name={'docsUrl'}
                                   onChange={this.onChange}/>
                        </div> : null
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ConnectAPIIndex)