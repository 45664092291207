import React, {Component} from 'react'

class BannerAlert extends Component {
    constructor(props) {
        super(props);
    }

    getProp = (propName) => {
        if (propName in this.props){
            return this.props[propName]
        } else {
            return null
        }
    }

    getIcon = () => {
        return this.getProp("icon")
    }

    getColor = () => {
        const bannerTypeColorMap = {
            "success": "#6FBB98",
            "error": "#EE685F",
            "warning": "#E27837",
            "display": "#5499F8",
            "info": "#9696A0"
        }

        let bannerType = this.getProp("type")
        let color = "#9696A0";

        if (bannerType !== null){
            if (bannerType in bannerTypeColorMap){
                color = bannerTypeColorMap[bannerType]
            }
        }

        return color
    }

    getDisplayComponent = (propName) => {
        const color = this.getColor()
        const prop = this.getProp("header")

        let propDisplay = null;
        if (typeof prop === "string"){
            let style  = null;

            if (propName === "header"){
                style = {color: color, fontFamily: 'Lato', fontWeight: "700", fontSize: "16px", marginBottom: "4px"}
            } else if (propName === "content"){
                style = {color: color, fontFamily: 'Lato', fontWeight: "400", fontSize: "12px"}
            }

            propDisplay = <span style={style}>{prop}</span>
        } else {
            propDisplay = prop
        }

        return propDisplay
    }

    close = () => {
        if ("close" in this.props){
            this.props.close()
        } else if ("onDismiss" in this.props){
            this.props.onDismiss()
        }
    }

    render() {
        const color = this.getColor()
        const icon = this.getIcon();
        const action = this.getProp("action");

        return (
            <div className="bannerAlert" style={{border: "1px solid " + color}}>
                <div className="bannerIconDiv">
                    {
                        icon !== null ?
                            <div className="bannerIcon">
                                <div style={{borderRadius: "40px", padding: "10px", background: "#F0F0F0", width: "40px", height: "40px", textAlign: "center"}}>
                                    <i className={this.getIcon()} style={{color: color, fontSize: "130%"}}/>
                                </div>
                            </div> : null
                    }
                </div>
                <div className="bannerTextDiv">
                    <div className="bannerHeader">
                        {this.getDisplayComponent("header")}
                    </div>
                    <div className="bannerContent">
                        <span style={{color: "#5E645E", fontFamily: 'Lato', fontWeight: "400", fontSize: "14px"}}>{this.getProp("content")}</span>
                    </div>
                </div>
                <div className="bannerActionDiv" style={{textAlign: "right"}}>
                    {action === "close" ? <i className='bx bx-x' onClick={this.close} style={{ cursor: "pointer" }} /> : action}
                </div>
            </div>
        )
    }
}

export default BannerAlert