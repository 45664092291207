import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../../Constants/styles";
import 'brace/mode/sql';
import AceEditor from 'react-ace';
import {modifyAllDBReports} from "../../Actions/ModifyAllDBReports";
import Button from "../../SharedComponents/Button"
import API_Root from "../../Constants/API_Root";
import {modifyTestResultData} from "../../Actions/ModifyTestResultData";
import NoCodeInputIndex from "./NoCode/NoCodeInputIndex"

class ReportsInputIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // could also be "query" or "noCode"
            mode: "prompt",
            asking: false,
            params: {}
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps.dbSelectedIdx !== this.props.dbSelectedIdx){
            this.setState({
                mode: 'prompt',
                asking: false
            })
        }
    }

    changeSelection = (mode) => this.setState({ mode: mode })

    getDivStyle = (selectionName) => {
        const headingStyle = { textAlign: "center",
            padding: "8px 16px",
            cursor: "pointer",
            // borderBottom: "1px solid #F0F0F0",
            fontSize: "12px",
            borderRadius: selectionName === "prompt" ? "4px 0 0 0" : "0 4px 0 0",
            fontWeight: "700" }

        let selectedStyle = {...headingStyle}

        if (selectionName === this.state.mode){
            selectedStyle['backgroundColor'] = styles.blue
            selectedStyle['color'] = "white"
            selectedStyle['borderRight'] = "none"
        } else {
            selectedStyle['borderRight'] = "1px solid #F0F0F0"
            selectedStyle['backgroundColor'] = "white"
        }

        return selectedStyle
    }

    getIconStyle = (selectionName) => {
        const iconStyle = { color: "#000000", fontSize: "14px"}

        if (selectionName === this.state.mode){
            let selectedIconStyle = {...iconStyle}

            selectedIconStyle['color'] = 'white'

            return selectedIconStyle
        } else {
            return {...iconStyle}
        }
    }

    getReportInfo = () => (this.props.allDBReports[this.props.reportSelectedIdx])

    updateNewReportInfo = (newReportInfo) => {
        const copiedAllADBReports = JSON.parse(JSON.stringify(this.props.allDBReports));
        copiedAllADBReports[this.props.reportSelectedIdx] = newReportInfo

        this.props.modifyAllDBReports(copiedAllADBReports)
    }

    getValue = () => {
        const reportInfo = this.getReportInfo();

        return this.state.mode === "query" ? reportInfo.query : reportInfo.prompt
    }

    canBtnBeClicked = () => {
        const val = this.getValue();

        if (this.state.mode !== 'noCode'){
            return val.trim() !== ''
        } else {
            const dataSource = this.getDataSource();

            if (dataSource === 'api'){
                let requestUrl;

                try{
                    requestUrl = this.state.params.requestUrl;

                    if (requestUrl === undefined){
                        requestUrl = ''
                    }
                } catch (e) {
                    requestUrl = ''
                }

                if (requestUrl.trim() === ""){
                    return false
                }


                if (this.state.params.requestBodyType === 'json'){
                    let jsonObj = this.state.params.requestBodyJson;

                    try {
                        JSON.parse(jsonObj);
                        return true;
                    } catch (e) {
                        return false;
                    }
                }


                return true
            }

            return true
        }
    }

    changeUserInput = (e) => {
        let val;
        if (this.state.mode === "query"){
            val = e
        } else {
            val = e.target.value
        }

        const copiedAllDBReports = JSON.parse(JSON.stringify(this.props.allDBReports));
        let currReportInfo = copiedAllDBReports[this.props.reportSelectedIdx];

        if (this.state.mode === "query"){
            currReportInfo.query = val
        } else {
            currReportInfo.prompt = val
        }

        copiedAllDBReports[this.props.reportSelectedIdx] = currReportInfo

        this.props.modifyAllDBReports(copiedAllDBReports)
    }

    ask = () => {
        this.setState({ asking: true })

        const error = (err) => {
            this.setState({
                asking: false
            }, () => alert("There was an error getting your data. Please try again."))
        }

        const dataSource = this.getDataSource()

        if (dataSource === 'database'){
            fetch(API_Root + "api/test-db-query/", {
                method: "POST",
                body: JSON.stringify({
                    email: localStorage.getItem("email"),
                    token: localStorage.getItem("token"),
                    databasePK: this.getDataPK(),
                    query: this.getValue(),
                    params: this.state.params,
                    useAI: this.state.mode === "prompt",
                    reportPK: this.getReportInfo()['pk'],
                    teamPK: this.props.selectedTeamPK,
                    mode: this.state.mode
                }),
                headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}
            }).then(res => {
                res.json().then(data => {
                    console.log(data)
                    // errorMessage: {
                    //     "header": "There was an error converting your text to a database query",
                    //     "content": "Please refresh and try again. If the error persists, contact support at support@dbspreadsheets.com"
                    // }
                    this.setState({
                        asking: false
                    }, () => {
                        if ("errorMessage" in data){
                            alert(data.errorMessage.header + ". " + data.errorMessage.content)
                        } else {
                            this.updateNewReportInfo(data)
                        }

                    })
                }).catch(err => error(err))
            }).catch(err => error(err))
        } else {
            fetch(API_Root + "api/run-prompt-on-love-data-sources/", {
                method: "POST",
                body: JSON.stringify({
                    email: localStorage.getItem("email"),
                    token: localStorage.getItem("token"),
                    databasePK: this.getDataPK(),
                    prompt: this.getReportInfo()['prompt'],
                    query: this.getReportInfo()['query'],
                    params: this.state.params,
                    useAI: this.state.mode === "prompt",
                    reportPK: this.getReportInfo()['pk'],
                    teamPK: this.props.selectedTeamPK,
                    mode: this.state.mode
                }),
                headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}
            }).then(res => {
                res.json().then(data => {
                    this.setState({
                        asking: false
                    }, () => this.updateNewReportInfo(data))
                }).catch(err => error(err))
            }).catch(err => error(err))
        }
    }

    getDataSource = () => {
        let source;

        try{
            source = this.props.allDatabases[this.props.dbSelectedIdx].dataSource
        } catch (e) {
            source = null
        }

        if (source === undefined){
            source = null
        }

        return source
    }

    getDataPK = () => {
        let pk;

        try{
            pk = this.props.allDatabases[this.props.dbSelectedIdx].pk
        } catch (e) {
            pk = null
        }

        if (pk === undefined){
            pk = null
        }

        return pk
    }

    changeState = (newState) => this.setState(newState)

    render() {
        let inputArea;
        const dataSource = this.getDataSource();

        if (this.state.mode === "query"){
            inputArea = <AceEditor
                            mode="sql"
                            theme="monokai"
                            onChange={this.changeUserInput}
                            value={this.getValue()}
                            name="UNIQUE_ID_OF_DIV"
                            editorProps={{$blockScrolling: true}}
                            height={125}
                            width="auto"
                        />
        } else if (this.state.mode === "noCode") {
            inputArea = <div>
                <NoCodeInputIndex
                    dataSource={dataSource}
                    params={this.state.params}
                    changeState={this.changeState}
                />
            </div>
        } else {
            inputArea = <textarea
                className="inputGray"
                rows={1}
                style={{ width: "100%"}}
                placeholder={"Show me top 5 best performing sales people in 2022"}
                value={this.getValue()}
                onChange={this.changeUserInput}
            />
        }

        const isDisabled = !this.canBtnBeClicked();

        return (
            <div>
                <div style={{ display: "grid", gridTemplateColumns: dataSource === "database" ? "auto auto auto 1fr" : "auto auto 1fr"}}>
                    <div style={this.getDivStyle("prompt")} onClick={e => this.changeSelection("prompt")}>
                        <i className="fa-regular fa-message"></i> <span style={{ paddingLeft: "4px"}}>Ask</span>
                    </div>
                    {
                        dataSource === "database" ?
                            <div style={this.getDivStyle("query")} onClick={e => this.changeSelection("query")}>
                                <i className="fa-solid fa-code"></i> <span style={{ paddingLeft: "4px"}}>SQL</span>
                            </div> : null
                    }
                    <div style={this.getDivStyle("noCode")} onClick={e => this.changeSelection("noCode")}>
                        <i className="fa-solid fa-computer-mouse"></i> <span style={{ paddingLeft: "4px"}}>No Code</span>
                    </div>
                    <div> </div>
                </div>
                <div style={{ backgroundColor: "white", padding: "12px", border: "1px solid #F0F0F0", borderRadius: "0 0 4px 4px", boxShadow: "0px 5px 6px -4px rgba(2, 34, 19, 0.02)"}}>
                    {inputArea}
                    <div style={{ marginTop: "12px"}}>
                        <Button style={{
                            backgroundColor: styles.orange,
                            cursor: isDisabled ? "not-allowed" : "pointer",
                            color: "white",
                            border: "none",
                            borderRadius: "4px",
                            fontSize: "18px",
                            outline: "none",
                            padding: "6px 16px" }}
                                small
                                label="Ask"
                                disabled={isDisabled}
                                loading={this.state.asking}
                                onClick={this.ask}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    reportSelectedIdx: state.mainState.reportSelectedIdx,
    allDBReports: state.mainState.allDBReports,
    selectedTeamPK: state.mainState.selectedTeamPK,
    dbSelectedIdx: state.mainState.dbSelectedIdx,
    allDatabases: state.mainState.allDatabases
})

const mapActionsToProps = {
    modifyAllDBReports: modifyAllDBReports,
    modifyTestResultData: modifyTestResultData
}

export default connect(mapStateToProps, mapActionsToProps)(ReportsInputIndex)