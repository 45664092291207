import React, {Component} from 'react'
import { connect } from 'react-redux'
import {toggleToast} from "../Actions/ToggleToast";

class TestAPISpreadsheetsForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            email: "",
            age: ''
        }
    }

    handleChange = (e) => this.setState({ [e.target.name]: e.target.value })

    saveData = () => {
        this.props.toggleToast({
            show: true,
            type: "info",
            message: "Saving data. Please wait..."
        })

        fetch("https://api.apispreadsheets.com/data/3QaaKtH6ickCBqpL/", {
            method: "POST",
            headers: {"accessKey":"d22d83ebc4133dd7527c9a8cc0e712e3", "secretKey":"c815a16d7fe13c37a925d1fb26c475d5"},
            body: JSON.stringify({"data": {"age": this.state.age, "name": this.state.name, "email":this.state.email}}),
        }).then(res =>{
            if (res.status === 201){
                this.props.toggleToast({
                    show: true,
                    type: "success",
                    message: "Form Data Saved!"
                })
            }  else{
                this.props.toggleToast({
                    show: true,
                    type: "error",
                    message: "Error saving data :("
                })
            }
        }).catch(err => this.props.toggleToast({
            show: true,
            type: "error",
            message: "Error saving data :("
        }))
    }

    render() {
        const labelStyle = { fontWeight: "700", fontSize: "14px", marginBottom: "12px"}

        return (
            <div style={{ padding: "48px 48px"}}>
                <div style={labelStyle}>
                    Name
                </div>
                <div style={{ marginBottom: "24px"}}>
                    <input className="inputGray" name="name" value={this.state.name} onChange={this.handleChange} />
                </div>
                <div style={labelStyle}>
                    Email
                </div>
                <div style={{ marginBottom: "24px"}}>
                    <input className="inputGray" name="email" value={this.state.email} onChange={this.handleChange} />
                </div>
                <div style={labelStyle}>
                    Age
                </div>
                <div style={{ marginBottom: "24px"}}>
                    <input className="inputGray" name="age" type="number" value={this.state.age} onChange={this.handleChange} />
                </div>
                <div>
                    <button onClick={this.saveData}>
                        Save Data
                    </button>
                </div>
            </div>
        )
    }
}

const mapActionsToProps = {
    toggleToast: toggleToast
}

export default connect(null, mapActionsToProps)(TestAPISpreadsheetsForm)