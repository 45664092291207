import React, {Component} from 'react'
import ModalApp from '../Modal'
import UpgradeModalBody from "./UpgradeModalBody"
import styles from "../../Constants/styles";

class UpgradeModal extends Component {
    render() {
        return (
            <ModalApp name="upgradeModal"
                      header={null}
                      // bodyColor={styles.mainText}
                      body={<UpgradeModalBody/>}
            />
        )
    }
}

export default UpgradeModal