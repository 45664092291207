import React, {Component} from 'react'
import styles from "../Constants/styles";

class PageBanner extends Component {
    render() {
        const isMobile = 'isMobile' in this.props;

        return (
            <div style={{ backgroundColor: "#F9F9F9",
                padding: isMobile ? "60px 20px" : "60px 120px"}}>
                <div className="row">
                    <div className="col-sm-6">
                        <div style={{ fontWeight: "700", fontSize: "32px", color: styles.mainText}}>
                            {this.props.title}
                        </div>
                        <div style={{ fontSize: "16px", color: styles.subText, marginTop: "16px"}}>
                            {this.props.desc}
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div style={isMobile ? { marginTop: "16px", textAlign: "center"} : {textAlign: 'right'}}>
                            <img src={this.props.img}
                                 style={'imgStyle' in this.props ? this.props.imgStyle : { maxWidth: "360px" }}
                                 alt={this.props.title}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PageBanner