import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../Constants/styles";
import HeroFlow from "../Illustrations/HeroFlow-01.svg"
import {toggleRunProductDemo} from "../Actions/ToggleRunProductDemo";
import UserInputArea from "../Apps/InputComponent/UserInputArea"
import {modifyModalInfo} from "../Actions/ModifyModalInfo";
import {toggleModal} from "../Actions/ToggleModal";
import {scheduleCallText} from "../Constants/scheduleCallText";

const steps = [
    {num: 1, text: "Connect your data sources", modalType: "connectDatabase"},
    {num: 2, text: "Type in natural language what you want", modalType: "promptDatabase"},
    // {num: 3, text: "Download results in Excel file", modalType: "downloadResults"},
    {num: 3, text: "Automatically get data in Google Sheets", modalType: "getDataGoogleSheets"},
    {num: 4, text: "Invite team members to help with analysis", modalType: "teamHelp"},
]

class HomeIndex extends Component {
    openModal = (modalType, headerText) => {
        this.props.modifyModalInfo({
            landingPageDescBodyType: modalType,
            header: headerText
        })

        this.props.toggleModal("landingPageDescModal")
    }

    homeStep = (num, text, modalType) => (
        <div style={{ display: "grid", gridTemplateColumns: 'auto 1fr', columnGap: "16px", marginBottom: "16px"}}
             key={num}>
            <div className="circleBg" style={{ backgroundColor: styles.red, color: "white" }}>
                {num}
            </div>
            <div style={{ paddingTop: "6px" }}>
                <span style={{
                    color: styles.mainText,
                    fontSize: "16px",
                    fontWeight: "700",
                    textDecoration: "underline",
                    textDecorationStyle: 'dotted',
                    textDecorationColor: styles.blue,
                    textDecorationThickness: "2px",
                    cursor: "pointer"
                }}
                    onClick={e => this.openModal(modalType, text)}
                >
                    {text}</span>
            </div>
        </div>
    )

    signUp = () => {
        this.props.modifyModalInfo({
            signUpMsg: "Sign up to get started"
        })

        this.props.toggleModal("signUpModal")
    }

    render() {
        return (
            <div style={{ padding: "24px 48px 24px", minHeight: '100vh'}}>
                <div style={this.props.segmentStyle}>
                    <div style={{ color: styles.mainText, fontSize: "24px", fontWeight: "700"}}>
                        Automatically get data from databases, APIs & websites <br/>into Google Sheets using natural language <span style={{ fontWeight: "500", fontSize: "12px", textAlign: "right", textDecoration: "underline", textDecorationStyle: 'dotted', cursor: "pointer"}} onClick={e => this.props.toggleModal("languageModal")}>95+ languages supported</span>
                    </div>
                    <div style={{ marginTop: "12px"}}>
                        <UserInputArea demo />
                    </div>
                </div>
                <div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: "48px"}}>
                        <div>
                            <div style={{ marginTop: "24px"}}>
                                {steps.map(x => this.homeStep(x.num, x.text, x.modalType))}
                            </div>
                            <div style={{marginTop: "30px"}}>
                                <button style={{
                                    border: "none",
                                    backgroundColor: styles.blue,
                                    color: "white",
                                    padding: "12px 16px 12px 16px",
                                    fontSize: "20px",
                                    fontWeight: "700",
                                    borderRadius: "4px"}}
                                        onClick={this.signUp}
                                >
                                    Sign up to get started
                                </button>
                            </div>
                            <div style={{marginTop: "24px"}}>
                                <div>Want us to demo the software? {scheduleCallText("Schedule a call")}</div>
                            </div>
                        </div>
                        <div>
                            <img src={HeroFlow} alt="data going from files to visualization"
                                 style={{ maxWidth: "400px", maxHeight: '' }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleRunProductDemo: toggleRunProductDemo,
    modifyModalInfo: modifyModalInfo,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(HomeIndex)