import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../../Constants/styles";
import {toggleModal} from "../../Actions/ToggleModal";

class DeleteReportButton extends Component {
    constructor(props) {
        super(props);
    }

    deleteReport = () => {
        this.props.toggleModal("deleteReportModal")
    }

    render() {
        if (this.props.selectedTeamRole === "analyst" || this.props.selectedTeamRole === null){
            return null
        }

        return (
            <div style={{ paddingTop: "6px"}}>
                <i className="fa-solid fa-trash-can" style={{ color: styles.red, fontSize: "14px", cursor: "pointer" }} onClick={this.deleteReport}></i>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    selectedTeamRole: state.mainState.selectedTeamRole
})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(DeleteReportButton)