import React, {Component} from 'react'
import API_Root from "../Constants/API_Root";
import ContactEmail from "./ContactEmail";
import IconLabel from "../SharedComponents/IconLabel";
import isEmailValid from "../Constants/isEmailValid";
import styles from "../Constants/styles";
import { isMobile } from 'react-device-detect';
import bytesToMB from "../Constants/bytesToMB";
import {formatBytesToDisplay} from "../Constants/formatBytesToDisplay";
import { TailSpin } from 'react-loader-spinner'
import { connect } from 'react-redux'
import {toggleModal} from "../Actions/ToggleModal";
import {modifyModalInfo} from "../Actions/ModifyModalInfo";

class ContactUsForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            subject: '',
            message: '',
            allFiles: [],
            sending: false
        }

        this.contactUs = this.contactUs.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    contactUs(e){
        this.setState({
            sending: true
        })
        const err = () => this.setState({
            sending: false
        }, () => alert("There was an error submitting your information. Please email us at info@lovespreadsheets.com"))

        let formData = new FormData();
        // Add Files to Form Data
        if (this.state.allFiles.length > 0){
            for (let i=0; i<this.state.allFiles.length; i++){
                formData.append("file" + i.toString(), this.state.allFiles[i]);
            }
        }

        formData.append("email", this.state.email)
        formData.append("subject", this.state.subject)
        formData.append("message", this.state.message)

        fetch(API_Root + 'api/love-contact-us/', {
            method: "POST",
            body: formData
        }).then(res => {
            if (res.status === 200){
                const email  = JSON.parse(JSON.stringify(this.state.email));

                this.setState({
                    sending: false,
                    email: '',
                    subject: '',
                    message: '',
                    allFiles: []
                }, () => { this.props.modifyModalInfo({ successMsg: <p>Thank you for contacting us!<br/>We wil respond within 12 hours to {email}!</p> }); this.props.toggleModal("successMsgModal") })
            } else {
                err()
            }
        }).catch(err => err())
    }

    handleInputChange(e){
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    clickUpload = () => this.contactFileUpload.click()

    uploadFiles = (e) => {
        const allFiles = this.state.allFiles.map(x => x);

        if (allFiles.length < 5){
            for (let i=0; i<e.target.files.length; i++){
                allFiles.push(e.target.files[i])
            }

            this.setState({ allFiles: allFiles })
        }
    }

    remove = (idx) => {
        let removedFiles = [];

        for (let i=0; i<this.state.allFiles.length; i++){
            if (i !== idx){
                removedFiles.push(this.state.allFiles[i])
            }
        }

        this.setState({ allFiles: removedFiles })
    }

    render() {
        const isValid = isEmailValid(this.state.email);
        const color = "color" in this.props ? this.props.color : styles.mainText;
        const canUploadFiles = this.state.allFiles.length < 5;

        return (
            <div>
                <div style={{ marginBottom: "16px"}}>
                    <ContactEmail
                        value={this.state.email}
                        changeInput={this.handleInputChange}
                        width="100%"
                        color={color}
                    />
                </div>
                <div style={{ marginBottom: "16px"}}>
                    <div className="row">
                        <div className="col-sm-4">
                            <IconLabel
                                icon="bx bx-user"
                                label="Subject"
                                style={{ color: color}}
                            />
                        </div>
                        <div className="col-sm-8">
                            <input className="inputGray"
                                   value={this.state.subject}
                                   style={{width: "100%"}}
                                   name="subject"
                                   placeholder="Integrate Data Chat Bot with Database"
                                   onChange={this.handleInputChange}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ marginBottom: "16px"}}>
                    <IconLabel
                        icon="bx bx-edit-alt"
                        label="Your problem"
                        style={{ color: color }}
                    />
                    <textarea
                            placeholder="I want to implement the data chat bot for my team. We use Postgres, MySQL and Spreadsheets"
                            value={this.state.message}
                            name="message"
                            style={{ width: "100%" }}
                            className="inputGray"
                            rows={4}
                            onChange={this.handleInputChange} />
                </div>
                <div style={{ marginBottom: "16px"}}>
                    {/*<div className="row">*/}
                        {/*<div className="col-sm-8">*/}
                            {/*<IconLabel*/}
                                {/*icon="bx bx-file-blank"*/}
                                {/*label="Attach up to 5 files"*/}
                                {/*style={{ color: color }}*/}
                            {/*/>*/}
                        {/*</div>*/}
                        {/*<div className="col-sm-4" style={{ textAlign: isMobile ? "left" : "right" }}>*/}
                            {/*<input style={{display: "none"}}*/}
                                   {/*type="file"*/}
                                   {/*multiple={true}*/}
                                   {/*ref={contactFileUpload => this.contactFileUpload = contactFileUpload}*/}
                                   {/*onChange={this.uploadFiles}*/}
                            {/*/>*/}
                            {/*<button style={{*/}
                                {/*backgroundColor: styles.mainGreen,*/}
                                {/*border: "none",*/}
                                {/*color: "white",*/}
                                {/*padding: "4px 8px",*/}
                                {/*borderRadius: "20px",*/}
                                {/*fontWeight: "700",*/}
                                {/*opacity: canUploadFiles ? "1" : "0.5",*/}
                                {/*cursor: canUploadFiles ? "pointer" : "not-allowed",*/}
                                {/*fontSize: "12px"}}*/}
                                    {/*disabled={!canUploadFiles}*/}
                                    {/*onClick={this.clickUpload}>*/}
                                {/*Upload Files*/}
                            {/*</button>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                    {
                        this.state.allFiles.length > 0 ?
                            this.state.allFiles.map((x, idx) =>
                            <div key={idx} style={{ fontSize: "12px"}}>
                                <span><i className="fa fa-paperclip" aria-hidden="true"> </i><span> </span> <b>{x.name}</b> <span style={{ fontSize: "10px" }}>{formatBytesToDisplay(x.size)}</span> <span style={{ color: "red", fontWeight: "700", cursor: "pointer" }} onClick={e => this.remove(idx)}>x</span></span>
                            </div>) : null
                    }
                </div>
                <div style={{ marginBottom: "16px"}}>
                    <div className="row">
                        <div className="col-sm-6">
                            <button className="heroButton heroContactButton" onClick={this.contactUs}
                                style={{
                                    backgroundColor: isValid ? "" : "#F0F0F0",
                                    cursor: isValid && !this.state.sending ? "pointer" : "not-allowed"
                                }} disabled={!isValid || this.state.sending}>
                                {
                                    this.state.sending ?   <TailSpin
                                        height="20"
                                        width="20"
                                        color="white"
                                        ariaLabel="tail-spin-loading"
                                        radius="1"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                    /> : <span>Contact Us</span>
                                }
                            </button>
                        </div>
                        <div className="col-sm-6">
                            {
                                isEmailValid(this.state.email) ? null :
                                    <div style={{ color: "red" }}>
                                        <small><i>Enter a valid email</i></small>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
                {/*<div style={{ marginBottom: "16px", color: color}}>*/}
                    {/*<small><i>Did you know, on average a small business saves over $10,000 per month by automating their data processes</i></small>*/}
                {/*</div>*/}
            </div>
        )
    }
}

const mapActionsToProps = {
    modifyModalInfo: modifyModalInfo,
    toggleModal: toggleModal,
}

export default connect(null, mapActionsToProps)(ContactUsForm)