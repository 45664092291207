import React, {Component} from 'react'
import {isMobile} from "react-device-detect";
import StartPlanButton from "../Pricing/StartPlanButton"
import styles from "../../Constants/styles";

class EnterpriseUpgradeBox extends Component {
    render() {
        const isVertical = 'vertical' in this.props;

        const featuresText = {fontSize: "14px", color: "white", marginBottom: "4px"}
        const featureLabel = {paddingLeft: "8px"}

        const boxHeader = <div>
            <div style={{fontWeight: "700", color: "white", fontSize: "20px", marginBottom: "8px"}}>ENTERPRISE</div>
            <div style={{marginBottom: "8px"}}>
                <span style={{fontWeight: "700", color: "white", fontSize: "32px"}}>CONTACT US</span>
            </div>
        </div>

        const featureHeader = <div style={{color: "white"}}>
            Everything in the <b>BUSINESS</b> plan plus...
        </div>

        const featureList1 = <div>
                <div style={featuresText}><i className="fa-solid fa-table" /><span style={featureLabel}><b>Unlimited</b> Rows per report</span></div>
                <div style={featuresText}><i className="fa-solid fa-file-lines" /><span style={featureLabel}><b>Unlimited</b> Reports</span></div>
                <div style={featuresText}><i className="fa-solid fa-users" /><span style={featureLabel}><b>Unlimited</b> Users</span></div>
        </div>

        const featureList2 = <div>
            <div style={featuresText}><i className="fa-solid fa-clock" /><span style={featureLabel}><b>Custom</b> Report Frequency</span></div>
            <div style={featuresText}><i className="fa-solid fa-server" /><span style={featureLabel}><b>Custom</b> AI Server</span></div>
            <div style={featuresText}><i className="fa-solid fa-user" /><span style={featureLabel}><b>Dedicated</b> Data Consultant</span></div>
        </div>

        const contactBtn = <StartPlanButton
            url="/contact"
            backgroundColor={styles.orange}
            color="white"
            text="Contact Us"
            planIdx={4}
        />

        if (!isVertical){
            return (
                <div style={{ backgroundColor: "#161E16", borderRadius: "8px", padding: isMobile ? "24px" : "50px", textAlign: "left"}}>
                    <div className="row">
                        <div className="col-sm-3">
                            {boxHeader}
                        </div>
                        <div className="col-sm-6">
                            <div className="row" style={{marginBottom: "8px"}}>
                                {featureHeader}
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    {featureList1}
                                </div>
                                <div className="col-sm-6">
                                    {featureList2}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div style={{textAlign: isMobile ? "left": "right", paddingTop: "24px"}}>
                                {contactBtn}
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{ backgroundColor: "#161E16", borderRadius: "8px", padding: "24px", textAlign: "left"}}>
                    {boxHeader}
                    <div style={{color: "white", marginBottom: "24px"}}>
                        Everything in <b>BUSINESS</b> plus...
                    </div>
                    <div style={{ marginBottom: "24px"}}>
                        {featureList1}
                        {featureList2}
                    </div>
                    {contactBtn}
                </div>
            )
        }
    }
}

export default EnterpriseUpgradeBox