import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../../../Constants/styles";
import API_Root from "../../../Constants/API_Root";
import loadScript from 'load-script';
import {toggleModal} from "../../../Actions/ToggleModal";
import {modifyModalInfo} from "../../../Actions/ModifyModalInfo";
import {modifyUserIntegrations} from "../../../Actions/ModifyUserIntegrations";
import {readFiles} from "../../../Constants/readFiles";
import {modifyAiFiles} from "../../../Actions/ModifyAiFiles";
import {modifySidebarSelection} from "../../../Actions/ModifySidebarSelection";

// const GOOGLE_SDK_URL = 'https://apis.google.com/js/api.js';
const GOOGLE_SDK_URL = 'https://accounts.google.com/gsi/client';
const GOOGLE_PICKER_URL = 'https://apis.google.com/js/api.js';


class GoogleSheetsConnect extends Component {
    constructor(props){
        super(props);

        this.state = {
            clientId: '307583181930-rsajrsor5s7p6964flj1ea6rkcgji29d.apps.googleusercontent.com',
            developerKey: 'AIzaSyA9vSMxH1KT-cqNQCkz6Li-4w4T8sV4uWc',
            scope:['https://www.googleapis.com/auth/drive.file', 'https://www.googleapis.com/auth/spreadsheets'],
            onChange: data => this.onChange(data),
            onAuthFailed: data => console.log('on auth failed:', data),
            multiselect: true,
            navHidden: false,
            authImmediate: false,
            mimeTypes: ['application/vnd.google-apps.spreadsheet'],
            query: '',
            viewId:'DOCS',
            origin: API_Root.includes("api") ? 'https://lovespreadsheets.com' : 'http://localhost:2000',
            email: ''
        }
    }

    isGoogleReady() {
        // return !!window.gapi;
        return true
    }

    componentDidMount(){
        // if(this.isGoogleReady()) {
            // google api is already exists
            // init immediately
            // console.log("google api already exists")
            // this.onApiLoad();
        // } else if (!scriptLoadingStarted) {
            console.log("load script")
            // loadScript(GOOGLE_PICKER_URL, () => {
            //     loadScript(GOOGLE_SDK_URL, this.onApiLoad)
            // })
            loadScript(GOOGLE_SDK_URL, () => {
                console.log("loaded")
            })

            loadScript(GOOGLE_PICKER_URL, this.onApiLoad)
        // }
    }

    onApiLoad() {
        // con
        // window.gapi.load('auth');
        window.gapi.load('picker');
    }

    startGoogleSheetPickProcess = () => {
        if (!this.props.isAuth){
            this.props.modifyModalInfo({signUpMsg: "Sign up to connect your Google Sheets"})
            this.props.toggleModal("signUpModal")
        } else {
            this.getGoogleSheet()
        }
    }

    getGoogleSheet = () => {
        // if (!this.isGoogleReady() || !this.isGoogleAuthReady() || !this.isGooglePickerReady()){
        //     console.log("not ready")
        //     return null;
        // }

        // const token = window.gapi.auth.getToken();
        // console.log(window.google.accounts.oauth2)
        // const oauthToken = token && token.access_token;
        // const oauthToken = false;

        const scope = this.state.scope;

        // if ("googleSheets" in this.props.userIntegrations) {
        //     let hint;
        //     try{
        //         // get the first key of integration object
        //         hint = Object.keys(this.props.userIntegrations)[0]
        //     } catch (e) {
        //         hint = ""
        //     }
        //
        //     const res = window.google.accounts.oauth2.initTokenClient({
        //         client_id: this.state.clientId,
        //         scope: scope.join(" ") + " profile email",
        //         hint: hint,
        //         error_callback: (err) => {
        //             console.log("there was an error")
        //         },
        //         callback: (authresult) => {
        //             console.log(authresult)
        //             this.createPicker(authresult.access_token)
        //         }
        //     })
        //
        //     res.requestAccessToken()

            // if (this.state.email.trim() === ""){
            //     fetch("https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=" + token.access_token)
            //         .then((res) => res.json())
            //         .then((data) => {
            //             this.setState({
            //                 email: data.email
            //             }, () => this.createPicker(token.access_token))
            //         })
            //         .catch(err => console.log(err)).catch(err => console.log(err))
            // } else {
            //     this.createPicker(token.access_token);
            // }
        // } else {
        console.log("auth")

        const res = window.google.accounts.oauth2.initCodeClient({
                client_id: this.state.clientId,
                scope: scope.join(" ") + " profile email",
                error_callback: (err) => {
                    console.log("there was an error")
                    // console.log(err)
                },
                callback: (authresult) => {
                    console.log(authresult)
                    if (authresult.code){
                        const code = authresult.code
                        fetch(API_Root + "api/api-store-google-sheets-auth/",
                            {
                                method: 'POST',
                                headers: {
                                    Accept: 'application/json, text/plain, */*',
                                },
                                body: JSON.stringify({
                                    email: localStorage.getItem("email"),
                                    token: localStorage.getItem("token"),
                                    code: code
                                })
                            }).then(res => {
                              if (res.status === 200){
                                  res.json().then(data => {
                                      this.setState({
                                          email: data.email
                                      }, () => {
                                          this.props.modifyUserIntegrations(data.integrations);
                                          this.createPicker(data.accessToken)
                                      })
                                      console.log(data)

                                  }).catch(err => console.log(err))
                              }  else {

                              }
                        }).catch(err => console.log(err))
                    }
                }

        })
            // response_type:"code permission"
            // res()
            // console.log(res.requestAccessToken())
        res.requestCode()

            // Use this with token client
            // tokenClient.requestAccessToken({prompt: 'consent'});
            // tokenClient.requestAccessToken({prompt: ''});

        // }
    }


    isGoogleAuthReady() {
        // return !!window.gapi.auth;
        return true
    }

    isGooglePickerReady() {
        return true
        // return !!window.google.picker;
    }

    createPicker(accessToken) {
        console.log(accessToken)
        const view = new window.google.picker.DocsView();

        if (this.state.mimeTypes) {
            view.setMimeTypes(this.state.mimeTypes.join(','))
        }
        if (this.state.query) {
            view.setQuery(this.state.query)
        }

        if (!view) {
            throw new Error('Can\'t find view by viewId');
        }

        const picker = new window.google.picker.PickerBuilder()
            .addView(view)
            .setOAuthToken(accessToken)
            .setDeveloperKey(this.state.developerKey)
            .setCallback(this.state.onChange);

        if (this.state.navHidden) {
            picker.enableFeature(window.google.picker.Feature.NAV_HIDDEN)
        }

        if (this.state.multiselect) {
            picker.enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
        }

        picker.build().setVisible(true);
    }

    onChange = (data) => {
        console.log(data)

        const syncError = (errorMessage="There was an error syncing your file! Please try again!") => {
            this.props.toggleModal(null)
            alert(errorMessage)
            // this.props.toggleModal("googleSheetsErrorModal");
        }

        if (data.action === "picked"){
            if (data.docs){
                if (data.docs.length > 0){
                    this.props.toggleModal("loadingModalImportFile");
                    fetch(API_Root + 'api/api-save-sheets-dropbox/', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json, text/plain, */*',
                        },
                        body: JSON.stringify({
                            documentType: "googleSheets",
                            documents: data.docs,
                            googleEmail: this.state.email,
                            email: localStorage.getItem("email"),
                            productId: "1007"
                        })
                    }).then(res => res.json())
                        .then((data) => {
                            if (!data.error){
                                // Open up WebSocket when files are saved
                                this.storeSheetsInfo(data.filePK);
                            }
                            else{
                                if ('message' in data){
                                    this.props.toggleModal(null)
                                    alert(data.message)
                                }
                                else{
                                    syncError()
                                }
                            }

                        }).catch((err) => { syncError() })
                        .catch(err => syncError()).catch(err => syncError())
                }
            }
            // else{
            //     syncError()
            // }
        }
    }

    storeSheetsInfo(filePK){
        console.log(filePK)
        const socketRequest = {
            utcOffset: new Date().getTimezoneOffset(),
            filePK: filePK,
            email: localStorage.getItem("email"),
            token: localStorage.getItem("token"),
            sheetRead: {},
            productId: '1007'
        }
        //1
        const route = "read-api-sheets-dropbox"

        // const updatedFileInformation = this.modifyFileInfo(fileInformation, filePK, sheetRead)
        readFiles(socketRequest, filePK, this.props, route, true, true)
    }

    render(){
        return (
            <div
                style={{
                backgroundColor: styles.subGreen,
                width: "100%",
                border: "1px dashed #F0F0F0",
                borderRadius: "4px",
                padding: "16px 24px",
                textAlign: "center", cursor: "pointer"}}
                onClick={this.startGoogleSheetPickProcess}
            >
                <div>
                    <div style={{ marginBottom: "12px"}}>
                        <span style={{
                            display: "inline-block",
                            borderRadius: "50%",
                            padding: "0.5em 0.7em",
                            backgroundColor: "white",
                            fontSize: "12px"
                        }}><i className="fa-brands fa-google" style={{ color: styles.mainGreen }}></i></span>
                    </div>
                    <div style={{ fontWeight: "700", fontSize: "16px", color: styles.secondaryText}}>
                        Connect Google Sheets
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    isAuth: state.mainState.isAuth,
    userIntegrations: state.mainState.userIntegrations,
    aiFiles: state.mainState.aiFiles
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo,
    modifyUserIntegrations: modifyUserIntegrations,
    modifyAiFiles: modifyAiFiles,
    modifySidebarSelection: modifySidebarSelection
}

export default connect(mapStateToProps, mapActionsToProps)(GoogleSheetsConnect)