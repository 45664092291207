import React, {Component} from 'react'
import {authorDetails} from "./authorDetails";

class UseCaseCard extends Component {
    constructor(props){
        super(props);

        this.goToUseCase = this.goToUseCase.bind(this);
    }

    goToUseCase(e){
        this.useCaseLink.click()
    }

    render() {
        let header = null;
        let writtenBy = null;
        let writtenDt = null;

        if (!this.props.landing){
            header = <h4 onClick={this.goToUseCase}>{this.props.tutorialInfo.heading}</h4>
            writtenBy = <span><img src={authorDetails[this.props.tutorialInfo.writtenBy]['pic']} className="imgAvatar" alt="Author" /> <a href={authorDetails[this.props.tutorialInfo.writtenBy]['social']} target="_blank" rel="noopener noreferrer">{this.props.tutorialInfo.writtenBy}</a></span>
            writtenDt = <span><i>{this.props.tutorialInfo.date}</i></span>
        }

        return (
            <div className="useCaseCard">
                <a href={"/" + this.props.url + ".html"} style={{display: "none"}} ref={useCaseLink => this.useCaseLink = useCaseLink}>Read more</a>
                <div onClick={this.goToUseCase} style={{cursor: 'pointer'}}>
                    {header}
                    <img className="useCaseCardImg" src={this.props.img} alt="Use Case" style={{color: 'orange'}} onClick={this.goToUseCase}/>
                    <span onClick={this.goToUseCase}><br/><br/></span>
                    <p onClick={this.goToUseCase}>{this.props.title}</p>
                </div>
                <div style={{paddingTop: '10px'}}>{writtenBy} {writtenDt}</div>
            </div>
        )
    }
}

export default UseCaseCard