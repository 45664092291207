import React, {Component} from 'react'
import styles from "../../../../Constants/styles";
import ChooseCurrentFileSelection from "./ChooseCurrentFileSelection";
import CreateNewFileFolderSelection from "./CreateNewFileFolderSelection";

class SelectGoogleAccountIndex extends Component {
    constructor(props) {
        super(props);
    }

    isAccountChecked = (accountEmail) => {
        if ("accountEmails" in this.props.google_sheets_info){
            if (Array.isArray(this.props.google_sheets_info.accountEmails)){
                if (this.props.google_sheets_info.accountEmails.includes(accountEmail)){
                    return true
                }
            }
        }

        return false
    }

    toggleAccountChecked = (accountEmail) => {
        const copiedGoogleSheetsInfo = JSON.parse(JSON.stringify(this.props.google_sheets_info));

        if ("accountEmails" in copiedGoogleSheetsInfo){
            if (Array.isArray(copiedGoogleSheetsInfo.accountEmails)){
                const accountEmails = copiedGoogleSheetsInfo.accountEmails;

                if (copiedGoogleSheetsInfo.accountEmails.includes(accountEmail)){
                    accountEmails.splice(accountEmails.indexOf(accountEmail), 1)
                } else {
                    accountEmails.push(accountEmail)
                    copiedGoogleSheetsInfo[accountEmail] = {}
                }

                copiedGoogleSheetsInfo['accountEmails'] = accountEmails
            } else {
                copiedGoogleSheetsInfo['accountEmails'] = [accountEmail]

                copiedGoogleSheetsInfo[accountEmail] = {}
            }
        } else {
            copiedGoogleSheetsInfo['accountEmails'] = [accountEmail]
            copiedGoogleSheetsInfo[accountEmail] = {}
        }

        this.props.changeState({ google_sheets_info: copiedGoogleSheetsInfo })
    }

    getProPicURL = (accountInfo) => {
        try{
            return accountInfo['id_token']['picture']
        } catch (e) {
            return "https://upload.wikimedia.org/wikipedia/commons/b/b5/Windows_10_Default_Profile_Picture.svg"
        }
    }

    isSendTypeSelected = (sendType, accountEmail) => {
        try{
            return this.props.google_sheets_info[accountEmail].sendType === sendType
        } catch (e) {
            return false
        }
    }

    toggleSendType = (sendType, accountEmail) => {
        const copiedGoogleSheetsInfo = JSON.parse(JSON.stringify(this.props.google_sheets_info));

        copiedGoogleSheetsInfo[accountEmail].sendType = sendType

        this.props.changeState({ google_sheets_info: copiedGoogleSheetsInfo })
    }

    getSendTypeStyle = (sendType, accountEmail) => {
        const isSelected = this.isSendTypeSelected(sendType, accountEmail);

        return {
            backgroundColor: isSelected ? styles.blue : "white",
            color: isSelected ? "white" : styles.subText,
            border: "1px solid " + (isSelected ? styles.blue : "#F0F0F0"),
            padding: "4px 8px",
            cursor: "pointer",
            fontSize: "14px"
        }
    }

    render() {
        let accountRows = [];

        for (let accountEmail in this.props.userIntegrations.googleSheets){
            const accountInfo = this.props.userIntegrations.googleSheets[accountEmail];

            let sendTypeSelection = null;
            if (this.isAccountChecked(accountEmail)){
                sendTypeSelection = <div style={{ padding: "16px 24px"}}>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr"}}>
                        <div style={this.getSendTypeStyle("create", accountEmail)} onClick={e => this.toggleSendType("create", accountEmail)}>
                            <i className="fa-solid fa-table"></i> <span style={{ paddingLeft: "4px"}}>Create new file</span>
                        </div>
                        <div style={this.getSendTypeStyle("add", accountEmail)} onClick={e => this.toggleSendType("add", accountEmail)}>
                            <i className="fa-solid fa-plus"></i> <span style={{ paddingLeft: "4px"}}>Add to file</span>
                        </div>
                    </div>
                    <div style={{ padding: "4px", border: "1px solid #F0F0F0", borderRadius: "0 0 4px 4px"}}>
                        {
                            this.isSendTypeSelected("create", accountEmail) ?
                                <CreateNewFileFolderSelection
                                    accountInfo={accountInfo}
                                    accountEmail={accountEmail}
                                    google_sheets_info={this.props.google_sheets_info}
                                    changeState={this.props.changeState}
                                /> :
                                this.isSendTypeSelected("add", accountEmail) ?
                                    <ChooseCurrentFileSelection
                                        accountInfo={accountInfo}
                                        accountEmail={accountEmail}
                                        google_sheets_info={this.props.google_sheets_info}
                                        changeState={this.props.changeState}
                                    />
                                    : null
                        }
                    </div>
                </div>
            }

            accountRows.push(<div key={accountEmail} style={{ marginBottom: "8px", paddingBottom: "8px", borderBottom: "1px solid #F0F0F0"}}>
                <div style={{ display:  "grid", gridTemplateColumns: "auto auto 1fr", columnGap: "8px"}}>
                    <div>
                        <input type="checkbox"
                               checked={this.isAccountChecked(accountEmail)}
                               onClick={e => this.toggleAccountChecked(accountEmail)}
                               id={accountEmail + "Checkbox"}
                               style={{ cursor: 'pointer' }}
                       />
                    </div>
                    <div>
                        <label htmlFor={accountEmail + "Checkbox"} style={{ cursor: 'pointer' }}>
                            <img className="avatar"
                                 style={{ height: "20px", width: "20px"}}
                                 src={this.getProPicURL(accountInfo)}
                                 alt="googleProfile"/>
                        </label>
                    </div>
                    <div>
                        <label htmlFor={accountEmail + "Checkbox"} style={{ cursor: 'pointer' }}>
                            {accountEmail}
                        </label>
                    </div>
                </div>
                {sendTypeSelection}
            </div>)
        }

        return (
            <div>
                {accountRows}
                {/*Create new file or add to existing file*/}
                {/*Create new file (pick folder) or add to existing file (pick sheet or create new sheet)*/}
            </div>
        )
    }
}

export default SelectGoogleAccountIndex