import React, {Component} from 'react'
import { connect } from 'react-redux'
import {toggleModal} from "../../Actions/ToggleModal";
import Button from "../../SharedComponents/Button"

class CompletedCelebration extends Component {
    closeModal = (e) => {
        this.props.toggleModal(null);

    }

    render() {
        const emojiStyle = {fontSize: '250%'};
        // Todo: change the description message in what the users will receive

        return (
            <div className="segment-raised" style={{ backgroundColor: "white" }}>
                <div style={{textAlign: 'center'}}>
                    <br/>
                    <h1>
                        Congratulations on becoming a lifetime member!
                        <br/>
                        <div style={{marginTop: '20px'}}>
                            <span role="img" aria-label="smile" style={emojiStyle}>😀</span>
                            <span role="img" aria-label="confetti" style={emojiStyle}>🎉</span>
                            <span role="img" aria-label="heart" style={emojiStyle}>❤️</span>
                            <span role="img" aria-label="beer" style={emojiStyle}>🍻</span>
                            <span role="img" aria-label="poppers" style={emojiStyle}>🎊</span>
                        </div>
                    </h1>
                    <br/>
                    <h1>
                        You can now save and view results and have access to any future features that will be released!
                    </h1>
                    <Button
                            iconPosition="right"
                            icon="right-arrow-alt"
                            onClick={this.closeModal}
                            label="Proceed"
                            style={{ backgroundColor: "green", color: "white"}}>
                    </Button>
                    <br/><br/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(CompletedCelebration)