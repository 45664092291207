import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../Constants/styles";
import {modifyDBSelectedIdx} from "../Actions/ModifyDBSelectedIdx";

class DatabaseListItem extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const isSelected = this.props.dbSelectedIdx === this.props.idx;

        return (
            <div style={{
                padding: this.props.isMobile ? "6px 12px 6px 12px" : "12px 12px 12px 12px",
                border: "1px solid #F0F0F0",
                borderRadius: "4px",
                color: isSelected ? "white" : styles.subText,
                fontWeight: isSelected ? "700" : "400",
                backgroundColor: isSelected ? styles.blue : "white",
                cursor: "pointer"}} onClick={e => this.props.modifyDBSelectedIdx(this.props.idx)}>
                <div style={{ display: "grid", gridTemplateColumns: "1fr 12px", columnGap: "8px"}}>
                    <div className="ellipses">
                        {this.props.info.dbName}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    dbSelectedIdx: state.mainState.dbSelectedIdx
})

const mapActionsToProps = {
    modifyDBSelectedIdx: modifyDBSelectedIdx
}

export default connect(mapStateToProps, mapActionsToProps)(DatabaseListItem)