import React, {Component} from 'react'
// import SocialMediaComponents from "../SocialMediaComponents";
import FullFooter from "./FullFooter";
import MobileFooter from "./MobileFooter";
import {hidePricing} from "../Constants/hidePricing";

class FooterIndex extends Component {
    constructor(props) {
        super(props);

        this.changeIndex = this.changeIndex.bind(this);
    }

    changeIndex(e, change){
        this.props.changeIndex(change)
    }

    shouldDisplay = () => {
        let shouldDisplay = !hidePricing();

        return shouldDisplay
    }

    render() {
        if (this.shouldDisplay()){
            return (
                <div>
                    <div id="landingPageBrowser">
                        <FullFooter/>
                    </div>
                    <div id="landingPageMobile">
                        <MobileFooter/>
                    </div>
                </div>
            )
        } else {
            return null
        }
    }
}

export default FooterIndex