import React, {Component} from 'react'
import Select from 'react-select'
import AddCustomTimes from "./AddCustomTimes"

const timeFrequencies = [{value: "15-minute", label: "Every 15 minutes"}, {value: "30-minute", label: "Every 30 minutes"}, {value: "60-minute", label: "Every 60 minutes"}, {value: "custom", label: "Custom"}]

class TimeSelection extends Component {
    constructor(props) {
        super(props);
    }

    change = (freqObj) => {
        this.props.changeState({interval: freqObj.value})
    }

    getValue = () => {
        for (let i=0; i<timeFrequencies.length; i++){
            if (timeFrequencies[i].value === this.props.interval){
                return timeFrequencies[i]
            }
        }

        return this.props.interval
    }

    getTimeOptions = () => {
        switch (this.props.userReportFrequency){
            case "monthly":
                return [{value: "custom", label: "Custom"}]
            case "daily":
                return [{value: "custom", label: "Custom"}]
            case "hourly":
                return [{value: "60-minute", label: "Every 60 minutes"}, {value: "custom", label: "Custom"}]
            case "15-minutes":
                return [{value: "15-minute", label: "Every 15 minutes"}, {value: "30-minute", label: "Every 30 minutes"}, {value: "60-minute", label: "Every 60 minutes"}, {value: "custom", label: "Custom"}]
            default:
                return [{value: "15-minute", label: "Every 15 minutes"}, {value: "30-minute", label: "Every 30 minutes"}, {value: "60-minute", label: "Every 60 minutes"}, {value: "custom", label: "Custom"}]
        }
    }

    render() {
        return (
            <div>
                <Select
                    onChange={this.change}
                    value={this.getValue()}
                    options={this.getTimeOptions()}
                />
                {
                    this.props.interval === "custom" ?
                        <AddCustomTimes
                            userReportFrequency={this.props.userReportFrequency}
                            report_times={this.props.report_times}
                            changeState={this.props.changeState}
                        /> : null
                }
            </div>
        )
    }
}

export default TimeSelection