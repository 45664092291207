import React, {Component} from 'react'
import FooterIconAndTagline from "./FooterIconAndTagline";
import CompanyLinks from "./CompanyLinks";
import WoyeraInc from "./WoyeraInc";
import ProductsLinks from "./ProductsLinks";
import SupportLinks from "./SupportLinks";
import FooterTermsAndPrivacy from "./FooterTermsAndPrivacy";
import NewsletterSignUp from "../Landing/NewsletterSignUp";

class MobileFooter extends Component {
    render() {
        const headerStyle = {fontFamily: "Lato", fontWeight: "700", fontSize: "16px", color: "#161E16", marginBottom: "12px"}

        return (
            <div style={{
                borderTop: "1px solid #F0F0F0",
                margin: "0 20px 0 20px",
                padding: "50px 10px 10px 10px",
                color: "#161E16"
            }}>
                <NewsletterSignUp/>
                <div style={{marginBottom: "24px"}}>
                    <FooterIconAndTagline/>
                </div>
                <div style={{display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: "24px", paddingBottom: "24px"}}>
                    <div>
                        <CompanyLinks headerStyle={headerStyle}/>
                    </div>
                    <div>
                        <ProductsLinks headerStyle={headerStyle}/>
                    </div>
                </div>
                <div style={{paddingTop: "12px", paddingBottom: "24px", marginBottom: "12px", borderBottom: "1px solid #F0F0F0"}}>
                    <SupportLinks headerStyle={headerStyle}/>
                </div>
                <div style={{paddingTop: "12px"}}>
                    <div style={{marginBottom: "12px"}}>
                        <WoyeraInc/>
                    </div>
                    <div style={{marginBottom: "12px"}}>
                        <FooterTermsAndPrivacy/>
                    </div>
                </div>
            </div>
        )
    }
}

export default MobileFooter