import React, {Component} from 'react'
import ConnectDatabase from "./ConnectDatabase";
import styles from "../Constants/styles";

class ConnectDatabaseIndex extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <ConnectDatabase type={this.props.type}/>
            </div>
        )
    }
}

export default ConnectDatabaseIndex