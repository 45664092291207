import isObjectEmpty from "./isObjectEmpty";

export const areGoogleAccountsConnected = (userIntegrations) => {
    try{
        if ("googleSheets" in userIntegrations){
            if (!isObjectEmpty(userIntegrations.googleSheets)){
                return true
            }
        }

        return false
    } catch (e) {
        return false
    }

}