import React, {Component} from 'react'
import { connect } from 'react-redux'
import {pricing} from "./pricing";

class PlanPricing extends Component {
    render() {
        const yearlyPricingStyle = {fontSize: "12px", color: "#6FBB98"}

        if (this.props.planIdx >= 2){
            yearlyPricingStyle.color = "white"
        }

        if (this.props.isNotPricingPage){
            yearlyPricingStyle.fontSize = '10px'
            yearlyPricingStyle.marginTop = '4px'
        }

        const planIdx = this.props.planIdx;
        const pricingText = "$" + (this.props.pricingType === "lifetime" ? pricing[planIdx].lifetimePrice.toString() : pricing[planIdx].price.toString())

        console.log(pricingText)
        return (
            <div>
                {
                    this.props.pricingType === "lifetime" ?
                    <div>
                        <span style={{
                            fontWeight: "700",
                            color: this.props.planIdx >= 2 ? "white" : "#161E16",
                            fontSize: this.props.isNotPricingPage ? "20px" : "32px",
                            textAlign: "center"}}>{pricingText}</span>
                    </div>
                    :
                    <div>
                        <span style={{
                            fontWeight: "700",
                            color: this.props.planIdx >= 2 ? "white" : "#161E16",
                            fontSize: this.props.isNotPricingPage ? "20px" : "32px",
                            textAlign: "right"}}>{pricingText}</span>
                        <span style={{
                            color: this.props.planIdx >= 2 ? "white" : "#9696A0",
                            fontWeight: "400",
                            fontSize: "14px"}}>/month</span>
                    </div>
                }
                {
                    this.props.yearlyPricing ?
                        <div style={yearlyPricingStyle}>Billed yearly</div> :
                        this.props.pricingType === "lifetime" ?
                            <div style={yearlyPricingStyle}>One time payment</div> :
                            <div style={{...yearlyPricingStyle, visibility: "hidden"}}>billed monthly</div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    pricingType: state.mainState.pricingType
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(PlanPricing)