import React, {Component} from 'react'
import {connect} from 'react-redux'
import {APISpreadsheetsImporter} from "apispreadsheets";
import styles from "../../Constants/styles";
import IconLabel from "../../SharedComponents/IconLabel"
import {modifyAiFiles } from "../../Actions/ModifyAiFiles";
import {testFileData} from "./testFileData";
import {modifyAiFileSelected} from "../../Actions/ModifyAiFileSelected";
import {modifyAiInput} from "../../Actions/ModifyAiInput";
import {localTestFileData} from "./localTestFileData";
import bytesToMB from "../../Constants/bytesToMB";
import API_Root from "../../Constants/API_Root";
import isObjectEmpty from "../../Constants/isObjectEmpty";
import {toggleModal} from "../../Actions/ToggleModal";
import {modifyAiResultSelected} from "../../Actions/ModifyAiResultSelected";
import {toggleIsAiResultSelected} from "../../Actions/ToggleIsAiResultSelected";
import {importInfo} from "../../Constants/importInfo";
import {toggleUseTestData} from "../../Actions/ToggleUseTestData";
import UseTestFileLink from "./UseTestFileLink"
import {modifyFileInfo} from "../../Constants/modifyFileInfo";
import {changeToFilesTab} from "../../Constants/changeToFilesTab";
import {modifySidebarSelection} from "../../Actions/ModifySidebarSelection";

class UploadFiles extends Component {
    constructor(props) {
        super(props);

        this.state = {
            importer: new APISpreadsheetsImporter(importInfo.key, this.importComplete),
            useTestData: false,
            uploading: false,
            error: false,
            sheetMap: {},
            updatedPK: []
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.aiFiles.length !== this.props.aiFiles.length) {
            if (this.props.aiFiles.length === 0){
                this.props.toggleUseTestData(false)
            }
        }
    }

    handleFileUpload = (droppedFiles, e) => {
        const isError = (err) => {
            this.props.toggleModal(null)
            // this.props.toggleToast({show: true, message: "There was an error uploading your file(s)! Please try again!", type: "error"});
            // this.props.modifyStatus("upload");
            alert(err)
        }

        const files = droppedFiles === null ? e.target.files : droppedFiles;

        this.props.toggleModal("loadingModalImportFile")

        const formData = new FormData();

        let allFileInfo = [];

        for (let i=0; i<files.length; i++){
            const fileObj = files[i];
            formData.append("file" + i.toString(), fileObj);
            allFileInfo.push({
                name: fileObj.name,
                size: bytesToMB(fileObj.size)
            })
        }

        formData.append("importKey", importInfo.key);
        formData.append("readAllSheets", "true")
        formData.append("email", this.props.isAuth ? localStorage.getItem("email") : localStorage.getItem("sessionId"))

        this.setState({ sheetMap: {}, updatedPK: [] })

        fetch(API_Root + 'api/save-api-files/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: formData
        }).then(res => res.json())
            .then(data => {
                if (!data.error){
                    // Modify the File Primary Keys
                    let filePK = JSON.parse(JSON.stringify(data.filePK));

                    if (filePK.length > 0){
                        if ("sheetMap" in data){
                            if (!isObjectEmpty(data.sheetMap)){
                                this.setState({ sheetMap: data.sheetMap })
                            }
                        }

                        // for excel files in the backend, get their sheetNames
                        // read file n number of times for sheetNames
                        // create {pk_id: sheetread, pk_id2: sheetread} mapping
                        // another redux object with first file's [{pk: {sheetName1: pk1, sheetName2: pk2, ...}}, ...]

                        // Open up WebSocket when files are saved
                        this.getStatusAndResults(data.filePK);
                    } else {
                        this.props.toggleModal(null)
                    }
                }
                else{
                    isError(data.error)
                }
            }).catch(err => isError(err)).catch(err => isError(err));
    }

    getStatusAndResults = (filePK) => {
        let newSheetRead = {};
        for (let i=0; i < filePK.length; i++){
            const pkInfo = filePK[i];
            if ("sheetName" in pkInfo){
                newSheetRead[pkInfo.id] = pkInfo['sheetName']
            }
        }

        const copiedSheetRead = {...newSheetRead}
        // Offset to get created dt
        const current = new Date();
        const utcOffset = current.getTimezoneOffset();

        const shouldMatchColumns = false
        const columnsMap = shouldMatchColumns ? [] : null;

        const websocketRoute = API_Root.substring(0, 5) === "https" ? API_Root.replace("https", "wss") : API_Root.replace("http", "ws");
        const socket = new WebSocket(websocketRoute + "ws/api/read-api-files/");

        // Send the PKs and if any column names
        socket.onopen = (event) => {
            socket.send(JSON.stringify({
                utcOffset: utcOffset,
                filePK: filePK,
                sheetRead: copiedSheetRead,
                // sheetRead: this.props.importSheetRead,
                importKey: importInfo.key,
                email: importInfo.email,
                importName: "",
                columnsMap: columnsMap
            }));
        };

        socket.onmessage = (event) => {
            const reply = JSON.parse(event.data);
            const status = reply['status'];

            if (status === "read") {
                // console.log("read")
            }

            if (status === "api") {
                this.setState({
                    updatedPK: filePK
                })

                let fileInformation = JSON.parse(JSON.stringify(reply['fileInformation']));
                let sheetRead = reply['sheetRead'];

                // console.log("api")
                // console.log(filePK)
                try {
                    if (!isObjectEmpty(this.state.sheetMap)){
                        let pkIndicesToDelete = [];

                        for (let sheetFileIdx in this.state.sheetMap){
                            sheetFileIdx = parseInt(sheetFileIdx)
                            pkIndicesToDelete.push(sheetFileIdx)

                            const parentFileIdx = this.state.sheetMap[sheetFileIdx];

                            const parentFilePKInfo = filePK[parentFileIdx];
                            const sheetFilePKInfo = filePK[sheetFileIdx]

                            const parentFileInfo = fileInformation[parentFilePKInfo.id]
                            const sheetFileInfo = fileInformation[sheetFilePKInfo.id]

                            const key = sheetRead[sheetFilePKInfo.id]
                            const sheetFileInfoKey = {[key]: {...sheetFileInfo, ...{id: sheetFilePKInfo.id, pk: sheetFilePKInfo.pk}}}

                            let parentSheetInfo;
                            try{
                                parentSheetInfo = parentFileInfo['sheetInfo']
                            } catch (e) {
                                parentSheetInfo = {}
                            }

                            parentFileInfo['sheetInfo'] = {...sheetFileInfoKey, ...parentSheetInfo}
                            fileInformation[parentFilePKInfo.id] = parentFileInfo

                            delete fileInformation[sheetFilePKInfo.id]
                            delete sheetRead[sheetFilePKInfo.id]
                        }

                        let updatedPK = [];

                        // console.log(pkIndicesToDelete)

                        for (let i=0; i<filePK.length; i++){
                            if (!pkIndicesToDelete.includes(i)){
                                updatedPK.push(filePK[i])
                            }
                        }

                        filePK = updatedPK

                        // this.props.modifyImportSheetsUploadPK(updatedPK)
                        this.setState({
                            updatedPK: updatedPK
                        })

                    }
                } catch (e) {
                    console.log(e)
                }

                const updatedFileInformation = modifyFileInfo(fileInformation, filePK, sheetRead)
                // console.log(updatedFileInformation)

                this.updateAIFiles(updatedFileInformation)
                this.props.toggleModal(null)

                changeToFilesTab(this.props)

                socket.close()
            }

            if (status === "error") {

                this.props.toggleModal(null)

                alert("There was an error uploading your file. Please try again.")

                socket.close()
            }
        };


        // this.props.toggleModal(null)
    }
    //


    importFiles = () => {
        this.setState({
            importer: null
        }, () => {
            this.setState({
                importer: new APISpreadsheetsImporter("ce1f9a495311149f7e70a0b6f32634fd", this.importComplete)
            }, () => {
                this.state.importer.importFiles()
            })
        })
    }

    importComplete = (success, data) => {
        this.closeImporter()
        if (data !== null && success){
            this.updateAIFiles(data)
        } else {
            alert("There was a problem uploading your file. Please try again!")
        }
    }

    closeImporter = () => this.state.importer.closeImporter()

    initTestData = () => {
        this.props.toggleUseTestData(true)
        const testData = window.location.href.includes("localhost:2000") ? localTestFileData : testFileData;
        this.updateAIFiles(testData);
        this.props.modifyAiInput("Show me all the movie titles where Chris Pratt acted in")
    }

    updateAIFiles = (newFiles) => {
        const currFileData = JSON.parse(JSON.stringify(this.props.aiFiles))

        for (let i=0; i<newFiles.length; i++){
            currFileData.push(newFiles[i])
        }

        this.props.modifyAiFiles(currFileData)
        this.props.toggleUseTestData(false)

        if (this.props.aiFileSelected === null && currFileData.length > 0){
            this.props.modifyAiFileSelected(currFileData.length - 1)
            this.props.toggleIsAiResultSelected(false)
        }
    }

    render() {
        const inputFileStyle = {
            width: '0.1px',
            height: '0.1px',
            opacity: '0',
            overflow: 'hidden',
            position: 'absolute',
            zIndex: '-1'
        };

        const isMobile = 'isMobile' in this.props

        const labelText = "Upload .xlsx or .csv file(s)"
        const icon = <i className="fa-solid fa-file-excel" style={{ color: styles.blue }}/>
        const testFileDivStyle = { marginTop: isMobile ? "8px" : "16px", marginBottom: isMobile ? "0px" : "16px", textAlign: "center"}

        if (isMobile){
            testFileDivStyle['display'] = "grid"
            testFileDivStyle['gridTemplateColumns'] = "1fr 1fr"
            testFileDivStyle['columnGap'] = "8px"
        }

        return (
            <div>
                <input
                    type="file"
                    name="inputFileUpload"
                    id="inputFileUpload"
                    className="form-control-file"
                    onChange={(e) => this.handleFileUpload(null, e)}
                    style={inputFileStyle}
                    multiple={true}
                    accept=".xlsx,.csv"
                />
                <label htmlFor="inputFileUpload" style={{width: "100%", cursor: "pointer"}}>
                    <div style={{
                        backgroundColor: "#F9F9F9",
                        border: "1px dashed #F0F0F0",
                        borderRadius: "4px",
                        padding: isMobile ? '8px 12px' : "16px 24px",
                        textAlign: "center", cursor: "pointer"}}
                         // onClick={this.importFiles}
                    >
                        {
                            isMobile ?
                                <div style={{ fontWeight: "700", fontSize: "16px", color: "#9696A0"}}>
                                    {icon} <span style={{ paddingLeft: "8px"}}>{labelText}</span>
                                </div> :
                                <div>
                                    <div style={{ marginBottom: isMobile ? "6px" : "12px"}}>
                                        <span style={{
                                            display: "inline-block",
                                            borderRadius: "50%",
                                            padding: "0.5em 0.7em",
                                            backgroundColor: "white",
                                            fontSize: "12px"
                                        }}>{icon}</span>
                                    </div>
                                    <div style={{ fontWeight: "700", fontSize: "16px", color: "#9696A0"}}>
                                        {labelText}
                                    </div>
                                </div>
                        }
                    </div>
                </label>
                {
                    this.props.useTestData ? null :
                        <div style={testFileDivStyle}>
                            <div style={{
                                color: "#9696A0",
                                fontSize: isMobile ? "14px" : "12px",
                                textAlign: isMobile ? 'right' : 'center'
                            }}>Don’t have files?</div>
                            <UseTestFileLink isMobile={isMobile}/>
                        </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    aiFiles: state.mainState.aiFiles,
    aiFileSelected: state.mainState.aiFileSelected,
    useTestData: state.mainState.useTestData,
    isAuth: state.mainState.isAuth
})

const mapActionsToProps = {
    modifyAiFiles: modifyAiFiles,
    modifyAiFileSelected: modifyAiFileSelected,
    modifyAiInput: modifyAiInput,
    toggleModal: toggleModal,
    toggleIsAiResultSelected: toggleIsAiResultSelected,
    toggleUseTestData: toggleUseTestData,
    modifySidebarSelection: modifySidebarSelection
}

export default connect(mapStateToProps, mapActionsToProps)(UploadFiles)