import React, {Component} from 'react'
import {jobPostDetails} from "./jobPostDetails";
import Accordion from "../Accordion";
import JobOpeningsAccordionBody from "./JobOpeningsAccordionBody";

class JobOpeningsIndex extends Component {
    render() {
        return (
            <div>
                <div style={{ fontSize: "28px", fontWeight: "700", textAlign: this.props.isMobile ? "center" : "left"}}>
                    Current Openings
                </div>
                {jobPostDetails.map((x, idx) => <div key={idx} style={{ marginTop: "24px"}}>
                    <Accordion
                        title={x.title}
                        body={<JobOpeningsAccordionBody info={x}/>}
                    />

                </div>)}
            </div>
        )
    }
}

export default JobOpeningsIndex