import React, {Component} from 'react'
import MonthlyDateSelection from "./MonthlyDateSelection";
import styles from "../../../Constants/styles";
import MonthlyCustomSelection from "./MonthlyCustomSelection";

class MonthlySelection extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount(){
        if (!("reportingMode" in this.props.report_info)){
            const reportInfo = JSON.parse(JSON.stringify(this.props.report_info))
            reportInfo.reportingMode = "dates"

            this.props.changeState({report_info: reportInfo})
        }
    }

    isModeChecked = (mode) => {
        if ("reportingMode" in this.props.report_info){
            return this.props.report_info['reportingMode'] === mode
        } else {
            return false
        }
    }

    toggleMode = (mode) => {
        const reportInfo = JSON.parse(JSON.stringify(this.props.report_info))
        reportInfo.reportingMode = mode

        this.props.changeState({report_info: reportInfo})
    }

    render() {
        return (
            <div>
                <div style={{ marginBottom: "12px"}}>
                    <input type="radio"
                           style={{ cursor: "pointer", fontSize: "14px" }}
                           id={'reportingModeDates'}
                           name="reportingMode"
                           value="dates"
                           checked={this.isModeChecked("dates")}
                           onChange={e => this.toggleMode("dates")}
                    />
                    <label htmlFor={'reportingModeDates'}
                           style={{ cursor: "pointer", paddingLeft: "8px", fontSize: "14px", color: styles.subText}}>
                        On these dates
                    </label>
                    <MonthlyDateSelection
                        userReportFrequency={this.props.userReportFrequency}
                        report_info={this.props.report_info}
                        changeState={this.props.changeState}
                        isDatesChecked={this.isModeChecked("dates")}
                    />
                </div>
                <div style={{ marginBottom: "12px"}}>
                    <input type="radio"
                           style={{ cursor: "pointer", fontSize: "14px" }}
                           id={'reportingModeCustom'}
                           name="reportingMode"
                           value="custom"
                           checked={this.isModeChecked("custom")}
                           onChange={e => this.toggleMode("custom")}
                    />
                    <label htmlFor={'reportingModeCustom'}
                           style={{ cursor: "pointer", paddingLeft: "8px", fontSize: "14px", color: styles.subText}}>
                        On the
                    </label>
                    <MonthlyCustomSelection
                        report_info={this.props.report_info}
                        changeState={this.props.changeState}
                        isCustomChecked={this.isModeChecked("custom")}
                    />
                </div>
            </div>
        )
    }
}

export default MonthlySelection