import React, {Component} from 'react'
import HelpBtn from "./HelpBtn";
import {toggleModal} from "../../Actions/ToggleModal";
import { connect } from 'react-redux'
import API_Root from "../../Constants/API_Root";

class VidChatLink extends Component {
    constructor(props) {
        super(props);

        this.state = {
            canMeet: false,
            isAvailable: false,
            secondsElapsed: 0,
            intervalId: null
        }
    }

    componentDidMount(){
        fetch("https://api.apispreadsheets.com/data/5nwfKziWrWfgdp2b/?accessKey=b9859bc6178881451d827e5ae88304b9&secretKey=445cc5fa0ed33eb8185d72306e041b3d")
            .then(res => {
                res.json().then(data => {
                    this.setState({ canMeet: data.canMeet, isAvailable: data.isAvailable })
                }).catch(err => console.log(err))
            }).catch(err => console.log(err))
    }

    goToVidChat = () => {
        this.props.toggleModal('loadingModalVidChat')

        const error = () => {
            this.props.toggleModal(null);
            alert("Sorry we are not available right now! Please try another help method")
        }

        fetch(API_Root + "api/alert-for-lovespreadsheets-vid-chat/" + localStorage.getItem("email") + "/")
            .then(res => {
                if (res.status === 200){
                    this.setupVidChat()
                }
            }).catch(err => error())
    }

    setupVidChat = () => {
        const id = setInterval(this.getMeetData, 2000)

        this.setState({
            intervalId: id
        })
    }

    getMeetData = () => {
        if (this.state.secondsElapsed < 120 && !this.state.canMeet){
            fetch("https://api.apispreadsheets.com/data/5nwfKziWrWfgdp2b/?accessKey=b9859bc6178881451d827e5ae88304b9&secretKey=445cc5fa0ed33eb8185d72306e041b3d")
                .then(res => {
                    res.json().then(data => {
                        const currSec = this.state.secondsElapsed;

                        this.setState({ canMeet: data.canMeet, isAvailable: data.isAvailable, currSec: currSec + 2 })
                    }).catch(err => console.log(err))
                }).catch(err => console.log(err))
        } else {
            clearInterval(this.state.intervalId)

            this.props.toggleModal(null);

            if (this.state.canMeet){
                this.props.clickUrl("https://whereby.com/lovespreadsheets", "_blank")
            } else {
                alert("Sorry we are not available right now! Please try another help method")
            }
        }

    }

    render() {
        if (this.state.isAvailable){
            return (
                <div onClick={this.goToVidChat} style={{ cursor: "pointer" }}>
                    <HelpBtn
                        color="#0081ff"
                        icon="fa-solid fa-video"
                        header="Video chat with us RIGHT NOW"
                        desc="Start a video call with our customer support"
                    />
                </div>
            )
        } else {
            return (
                null
            )
        }
    }
}

const mapStateToProps = {
    toggleModal: toggleModal
}

export default connect(null, mapStateToProps)(VidChatLink)