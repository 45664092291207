import React, {Component} from 'react'
import  {images } from "../Constants/images";

class DatabaseTypeTag extends Component {
    render() {
        return (
            <span style={'style' in this.props ? this.props.style : null}>
                <img src={images[this.props.dbType.toLowerCase() + "Logo"]} className="imgAvatar"/>
            </span>
        )
    }
}

export default DatabaseTypeTag