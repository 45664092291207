import React, {Component} from 'react'
import API_Root from "../Constants/API_Root";
import styles from "../Constants/styles";
import Button from "../SharedComponents/Button"
import BannerAlert from "../SharedComponents/BannerAlert"
import HeroFlow from "../Illustrations/HeroFlow-01.svg"
import isEmailValid from "../Constants/isEmailValid";
import UserInputArea from "../Apps/InputComponent/UserInputArea"
import LandingPageBrowserIndex from "../Landing/LandingPageBrowserIndex"

class MobileLandingPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            message: null,
            sending: false
        }

        this.sendEmail = this.sendEmail.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event){
        this.setState({
            email: event.target.value
        })
    }

    sendEmail(e){
        this.setState({
            sending: true,
            email: '',
            message: <BannerAlert type="error"
                                  header="There was an error sending an email to you"
                                  content="Don't worry, just visit www.lovespreadsheets.com on your desktop!" />,
        })

        const error = () => {
            this.setState({
                sending: false,

            })
        }
        fetch(API_Root + "api/send-email/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: this.state.email,
                fromEmail: 'info@lovespreadsheets.com'
            })
        }).then(res =>
        {
            if (res.status === 201){
                this.setState({
                    message: <BannerAlert type="positive"
                                          header="Email sent successfully!"
                                          content="It is possible the email is sent to the spam or junk folder" />,
                    email: '',
                    sending: false
                })
            } else {

            }
        }).catch(err => error());
    }

    render() {
        // const segmentStyle = { backgroundColor: "white", padding: "12px", border: "1px solid #F0F0F0", borderRadius: "4px", boxShadow: "0px 5px 6px -4px rgba(2, 34, 19, 0.02)"}
        // const displayText = {color: styles.subText, fontSize: "16px", marginBottom: "24px"}

        return (
            <div style={{marginTop: '48px', marginBottom: '24px', marginLeft: '20px', marginRight: '20px', textAlign: "center"}}>
                <LandingPageBrowserIndex isMobile={true} />
            </div>
        )
    }
}

export default MobileLandingPage