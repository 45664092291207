import React, {Component} from 'react'
import {connect} from 'react-redux'
import ResourcesMenu from "./ResourcesMenu"

class DropdownMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showMenu: false
        }
    }

    toggleMenu = (e, show) => {
        this.setState({
            showMenu: show
        })
    }

    render() {
        // const body = {
        //     resources: <ResourcesMenu
        //         isMobile={this.props.isMobile}
        //         showMenu={this.state.showMenu}
        //     />
        // }[this.props.name]

        return (
            <div>
                <span className="navLink"
                      onMouseEnter={e => this.toggleMenu(e, true)}
                      onMouseLeave={e => this.toggleMenu(e, false)}>{this.props.header} </span>
                {
                    this.state.showMenu ? <div style={{
                        position: "absolute",
                        right: '0',
                        left: 'auto',
                        zIndex: "500",
                        backgroundColor: "white",
                        padding: "32px",
                        // borderWidth: "1px 0px 1px 1px",
                        // borderStyle: "solid",
                        // borderColor: "#F0F0F0",
                        boxShadow: "0px -2px 40px rgba(10, 39, 1, 0.1)",
                        borderRadius: "0px 0px 8px 8px",
                        textAlign: "left" }}
                                               onMouseEnter={e => this.toggleMenu(e, true)}
                                               onMouseLeave={e => this.toggleMenu(e, false)}>
                        <div style={{ fontSize: "20px", fontWeight: "700", marginBottom: "16px" }}>
                            {this.props.header}
                        </div>
                        {
                            this.props.name === "resources" ? <ResourcesMenu
                                isMobile={this.props.isMobile}
                                showMenu={this.state.showMenu}
                            /> : null
                        }
                    </div>: null
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(DropdownMenu)