import React, {Component} from 'react'
import IconLabel from "../SharedComponents/IconLabel";
import styles from "../Constants/styles";

class ContactEmail extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-sm-4">
                    <IconLabel
                        icon="bx bx-envelope"
                        label="Email"
                        required
                        style={{ color: "color" in this.props ? this.props.color : styles.mainText }}
                    />
                </div>
                <div className="col-sm-8">
                    <input className="inputGray"
                           value={this.props.value}
                           style={{width: 'width' in this.props ? this.props.width : "100%"}}
                           name="email"
                           placeholder="first.last@company.xyz"
                           onChange={e => this.props.changeInput(e)}
                    />
                </div>
            </div>
        )
    }
}

export default ContactEmail