import React, {Component} from 'react'
import NavbarLogo from "./NavbarLogo"
import {toggleIsMobileSidebarOpen} from "../Actions/ToggleIsMobileSidebarOpen";
import { connect } from 'react-redux'

class MobileNavbarHeader extends Component {
    open = () => {
        // window.scrollTo({
        //     top: 0,
        //     behavior: "smooth"
        // });

        this.props.toggleIsMobileSidebarOpen(true)
    }

    close = () => {
        this.props.toggleIsMobileSidebarOpen(false)
    }

    render() {
        let iconName;
        let func;

        if (this.props.isSidebar){
            iconName = "bx bx-x"
            func = this.close
        } else {
            iconName = "bx bx-menu"
            func = this.open
        }

        return (
            <div style={{ display: "grid", gridTemplateColumns: "50% 50%"}}>
                <div>
                    <NavbarLogo isAuth={this.props.isAuth} isSidebar={this.props.isSidebar}/>
                </div>
                <div style={{ padding: "4px 24px 4px 0", textAlign: "right"}}>
                    <span style={{ cursor: "pointer", fontSize: "36px" }} onClick={func}><i className={iconName} > </i></span>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    isAuth: state.mainState.isAuth
})

export const mapActionsToProps = {
    toggleIsMobileSidebarOpen: toggleIsMobileSidebarOpen
}

export default connect(mapStateToProps, mapActionsToProps)(MobileNavbarHeader)