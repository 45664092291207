import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../../Constants/styles";

class AskDemoStep extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{ paddingBottom: "0px" }}>
                <div style={{ fontSize: "16px" }}><b>You can ask specific questions depending on the BI task</b></div>
                <div style={{ fontSize: "12px", marginTop: "8px", color: styles.subText}}>
                    <div style={{ marginBottom: "4px"}}>
                        <b>1. Filter</b>
                        <br/>
                        Get all movies that starred Chris Pratt and had run time more than 120 minutes
                    </div>
                    <div style={{ marginBottom: "4px"}}>
                        <b>2. Merge</b>
                        <br/>
                        Combine both files on the Title column
                    </div>
                    <div style={{ marginBottom: "4px"}}>
                        <b>3. Clean</b>
                        <br/>
                        Come up with 2-4 keywords for the Movie descriptions
                    </div>
                    <div style={{ marginBottom: "4px"}}>
                        <b>4. Visualize</b>
                        <br/>
                        Create a scatter plot of movies vs. their runtime
                    </div>
                    <div style={{ marginBottom: "4px"}}>
                        <b>5. SQL</b>
                        <br/>
                        Write SQL code to run on your files
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(AskDemoStep)