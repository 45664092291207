import API_Root from "../../Constants/API_Root"

// getFileURL(copiedAllFileInfo, pk, id, "jsonRow", "readAction", currRowLength, currRowLength, false).replace(pk, fileInfo['fileHash'])

export const getFileURL = (fileInformation, pk, pkID, outputFormat, apiType, rowLen, rows, count=false) => {
    const outputType = getOutputType(outputFormat);

    let API = API_Root === "https://api-woyera.com/" ? "https://api.apispreadsheets.com/" : API_Root.replace("8000", "8080");
    let url = API + "data/" + pk + "/";

    if (apiType === "readAction"){
        if (isPrivate(fileInformation, pkID)){
            const accessKey = fileInformation[pkID]['accessKey'];
            const secretKey = fileInformation[pkID]['secretKey'];

            let dataFormat = outputType !== "" ? "&dataFormat=" + outputType : "";
            url += "?accessKey=" + accessKey + "&secretKey=" + secretKey + dataFormat

        }
        else{
            let dataFormat = outputType !== "" ? "?dataFormat=" + outputType : "";
            url += dataFormat
        }

        if (rowLen.toString() !== rows.toString()){
            if (url.includes("?")){
                url += "&limit=" + rows.toString()
            }
            else{
                url += "?limit=" + rows.toString()
            }

        }

        if (count){
            url += url.includes("?") ? "&" : "?"
            url += "count"
        }

    }

    return url
}

const getOutputType = (outputFormat) => {
    let outputType = "";

    if (outputFormat === "matrix"){
        outputType = "matrix"
    }
    else if (outputFormat === "jsonColumn"){
        outputType = "column"
    }

    return outputType
}

const isPrivate = (fileInformation, pkID) => {
    return fileInformation[pkID]['private']
}

