import React, {Component} from 'react'
import {connect} from 'react-redux'
import VidChatLink from "./VidChatLink";
import HelpBtn from "./HelpBtn"
import styles from "../../Constants/styles";
import {toggleToast} from "../../Actions/ToggleToast";
import {toggleModal} from "../../Actions/ToggleModal";
import {modifyModalInfo} from "../../Actions/ModifyModalInfo";

class HelpToolbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            url: '/',
            target: "_self"
        }
    }

    clickUrl = (url, target) => {
        this.setState({
            url: url,
            target: target
        }, () => this.helpLink.click())
    }

    copyNumber = () => {
        navigator.clipboard.writeText("+1-415-915-9398")

        this.props.toggleToast({show: true, type: "success", message:"Phone number copied"})
    }

    openConnectDatabaseGuide = (e) => {
        this.props.modifyModalInfo({
            guideType: "connectDatabase"
        })

        this.props.toggleModal("guidesModal")
    }

    showToolbar = (toolbarName) => {
        if ('notInclude' in this.props){
            if (this.props.notInclude.includes(toolbarName)){
                return false
            }
        }

        return true
    }

    render() {
        return (
            <div>
                <a href={this.state.url} style={{ display: "none" }} ref={helpLink => this.helpLink = helpLink} target={this.state.target} rel="noopener noreferrer"> </a>
                    <div style={{ marginBottom: "4px"}}>
                        <VidChatLink clickUrl={this.clickUrl} />
                    </div>
                    {
                        this.showToolbar("connectGuide") ?
                            <div style={{ marginBottom: "4px"}}>
                                <div style={{ cursor: "pointer"}} onClick={this.openConnectDatabaseGuide}>
                                    <HelpBtn
                                        color={styles.subText}
                                        icon="fa-solid fa-book"
                                        header="Read connect database guide"
                                        desc="Follow steps for MySQL or Postgres"
                                    />
                                </div>
                            </div> : null
                    }
                    <div style={{ marginBottom: "4px"}}>
                        <div style={{ cursor: "pointer"}} onClick={this.copyNumber}>
                            <HelpBtn
                                color={styles.orange}
                                icon="fa-solid fa-comment-sms"
                                header="Text or WhatsApp us"
                                desc="+1-415-915-9398 (click to copy)"
                            />
                        </div>
                    </div>
                    <div style={{ marginBottom: "4px"}}>
                        <div style={{ cursor: "pointer"}} onClick={e => this.clickUrl("mailto:info@lovespreadsheets.com?subject=Help%20me", "_self")}>
                            <HelpBtn
                                color={styles.red}
                                icon="fa-solid fa-paper-plane"
                                header="Send us an email"
                                desc="Email us at info@lovespreadsheets.com"
                            />
                        </div>
                    </div>
                    <div style={{ marginBottom: "4px"}}>
                        <div style={{ cursor: "pointer"}} onClick={e => this.clickUrl("https://calendly.com/love-spreadsheets/25min", "_target")}>
                            <HelpBtn
                                color={styles.mainGreen}
                                icon="fa-solid fa-calendar"
                                header="Schedule a call with us"
                                desc="We will help you on the call"
                            />
                        </div>
                    </div>
                    <div style={{ marginBottom: "4px"}}>
                        <div style={{ cursor: "pointer"}} onClick={e => this.props.toggleModal("getSupportModal")}>
                            <HelpBtn
                                color={styles.mainText}
                                icon="fa-solid fa-paper-plane"
                                header="Submit a support ticket"
                                desc="12 hour reply. Guaranteed."
                            />
                        </div>
                    </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleToast: toggleToast,
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo
}

export default connect(mapStateToProps, mapActionsToProps)(HelpToolbar)