import React, {Component} from 'react'
import ModalApp from '../../SharedComponents/Modal'
// import GetSupportModalBody from './GetSupportModalBody'
import {connect} from 'react-redux'
import ConnectAPIAuthInfoModalBody from "./ConnectAPIAuthInfoModalBody"

class ConnectAPIAuthInfoModal extends Component {
    getApiAccountName = () => {
        let apiAccountName;
        try{
            apiAccountName = this.props.modalInfo['name']
        } catch (e) {
            apiAccountName = ""
        }

        if (apiAccountName === undefined){
            apiAccountName = ""
        }

        return apiAccountName
    }

    render() {
        const apiAccountName = this.getApiAccountName();

        return (
            <div>
                <ModalApp name="connectAPIAuthInfoModal"
                          header={<span style={{ fontSize: "20px", fontWeight: "700"}}><i className="fa-solid fa-code"></i> <span style={{ paddingLeft: "4px"}}>Enter Auth Info for {apiAccountName} API</span></span>}
                          body={<ConnectAPIAuthInfoModalBody />}
                          size="medium"
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ConnectAPIAuthInfoModal)