import React, {Component} from 'react'

class CircleIcon extends Component {
    render() {
        let iconStart;
        let icon = this.props.icon + " fa-stack-1x"
        if ("solid" in this.props){
            iconStart = "fa-solid"
            icon += " fa-inverse"
        } else {
            iconStart = "fa-regular"
        }

        iconStart += " fa-circle fa-stack-2x"



        return (
            <span className="fa-stack fa-small">
                <i className={iconStart} style={'circleColor' in this.props ? { color: this.props.circleColor } : null}></i>
                <i className={icon} style={'color' in this.props ? { color: this.props.color } : null}></i>
            </span>
        )
    }
}

export default CircleIcon