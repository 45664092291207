import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../../Constants/styles";
import TableRow from "./TableRow";

class ViewTablesAndColumnBody extends Component {
    constructor(props) {
        super(props);
    }

    getTablesAndCols = () => {
        let tablesAndCols;
        try{
            tablesAndCols = this.props.modalInfo.tablesAndColumnInfo
        } catch (e) {
            tablesAndCols = null
        }

        if (tablesAndCols === undefined){
            return null
        } else{
            return tablesAndCols
        }
    }

    render() {
        const tablesAndCols = this.getTablesAndCols();

        if (tablesAndCols !== null){
            let tableRows = [];

            for (let tableName in tablesAndCols){
                tableRows.push(<div key={tableName} style={{ marginBottom: "8px"}}>
                    <TableRow
                        tableName={tableName}
                        columnInfo={tablesAndCols[tableName]}
                    />
                </div>)
            }

            return (
                <div style={{ marginTop: "12px"}}>
                    {tableRows}
                </div>
            )
        } else {
            return (
                <div style={{ color: styles.red}}>
                    There was an error getting your tables and columns. Refresh the page. Contact support if error persists
                </div>
            )
        }


    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ViewTablesAndColumnBody)