import React, {Component} from 'react'
import {connect} from 'react-redux'
import {isMobile} from "react-device-detect";
import BannerAlert from "../BannerAlert";
import AddCommaToNumbers from "../../Constants/AddCommaToNumbers";
import {pricing} from "./pricing";
import PricingDescriptionBox from './PricingDescriptionBox';
import {featureList} from "./featureList";
import PlanPricingBox from "./PlanPricingBox";
import styles from "../../Constants/styles";
import { planBgColor } from "../../Constants/planBgColor";
import EnterpriseUpgradeBox from "../UpgradeModal/EnterpriseUpgradeBox";
import TogglePricingType from "./TogglePricingType"

class PricingIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            headerBoxHeight: 162
        }
    }

    changeHeaderBoxHeight = (boxHeight) => this.setState({ headerBoxHeight: boxHeight })

    render() {
        const browserStyle = {
            paddingLeft: "100px",
            paddingRight: "100px",
            paddingTop: "40px",
            textAlign: "center"
        }

        const mobileStyle = {
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "25px",
            textAlign: "center"
        }

        return (
            <div style={{ minHeight: "100vh"}}>
                <div>
                </div>
                <div style={isMobile ? mobileStyle : browserStyle}>
                    <div className="row" style={{marginBottom: "24px"}}>
                        <div className="col-sm-1" />
                        <div className="col-sm-10">
                            <div style={{fontWeight: "700", fontSize: "32px", color: "#161E16", textAlign: "center"}}>
                                Simple Pricing For Individuals & Businesses Of All Sizes
                            </div>
                        </div>
                        <div className="col-sm-1" />
                    </div>
                    {/*<div className="row" style={{marginBottom: "24px"}}>*/}
                        {/*<div className="col-sm-1" />*/}
                        {/*<div className="col-sm-10">*/}
                            {/*<div style={{fontWeight: "700", color: "#161E16", textAlign: "center"}}>*/}
                                {/*<TogglePricingType />*/}
                            {/*</div>*/}
                        {/*</div>*/}
                        {/*<div className="col-sm-1" />*/}
                    {/*</div>*/}
                    {/*<div className="row">*/}
                        {/*<div className="col-sm-3" />*/}
                        {/*<div className="col-sm-6">*/}
                            {/*<BannerAlert*/}
                                {/*type="success"*/}
                                {/*header="Plans include FREE consulting hours during public BETA"*/}
                                {/*content={"That's like having an extra $" + AddCommaToNumbers(pricing[pricing.length-1].freeConsultingHours * 100) + " worth of work per month for FREE"}*/}
                            {/*/>*/}
                        {/*</div>*/}
                        {/*<div className="col-sm-3" />*/}
                    {/*</div>*/}
                    <div style={{ marginBottom: "24px"}}>
                        <div className={isMobile ? "row": ""} style={isMobile ? null : {display: "grid",  gridTemplateColumns: "auto 1fr 1fr 1fr 1fr"}}>
                            <div className={isMobile ? "col-sm-3" : "pricingTableDescriptionBoxDiv"}>
                                <PricingDescriptionBox
                                    headerBoxHeight={this.state.headerBoxHeight}
                                    featureList={featureList}
                                />
                            </div>
                            <div className={isMobile ? "col-sm-2" : "pricingTableBox0Div"}>
                                <PlanPricingBox
                                    planIdx={0}
                                    featureList={featureList}
                                    backgroundColor={planBgColor[0]}
                                    changeHeaderBoxHeight={this.changeHeaderBoxHeight}
                                />
                            </div>
                            <div className={isMobile ? "col-sm-2" : "pricingTableBox1Div"}>
                                <PlanPricingBox
                                    planIdx={1}
                                    featureList={featureList}
                                    backgroundColor={planBgColor[1]}
                                    changeHeaderBoxHeight={this.changeHeaderBoxHeight}
                                />
                            </div>
                            <div className={isMobile ? "col-sm-2" : "pricingTableBox2Div"}>
                                <PlanPricingBox
                                    planIdx={2}
                                    featureList={featureList}
                                    backgroundColor={planBgColor[2]}
                                    changeHeaderBoxHeight={this.changeHeaderBoxHeight}
                                />
                            </div>
                            <div className={isMobile ? "col-sm-2" : "pricingTableBox3Div"}>
                                <PlanPricingBox
                                    planIdx={3}
                                    featureList={featureList}
                                    backgroundColor={planBgColor[3]}
                                    changeHeaderBoxHeight={this.changeHeaderBoxHeight}
                                />
                            </div>
                            {isMobile ? <div className="col-sm-1" /> : null}
                        </div>
                    </div>
                    <div style={{ marginBottom: "24px"}}>
                        <EnterpriseUpgradeBox/>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(PricingIndex)