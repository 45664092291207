import React, {Component} from 'react'
import {connect} from 'react-redux'
import {getPricingInfoFromUserPlan} from "../../../Constants/getPricingInfoFromUserPlan";
import BannerAlert from "../../../SharedComponents/BannerAlert"
import {getEmailFromUsername} from "../../../Constants/getEmailFromUsername";
import {getAdminEmailFromTeamPK} from "../../../Constants/getAdminEmailFromTeamPK";

class SetupTimeRestrictionBanner extends Component {
    isUserTeamSelected = () => (this.props.userTeamPK === this.props.selectedTeamPK);

    getPricingInfo = () => (getPricingInfoFromUserPlan(this.props.userPlan))

    reportingAddlText = () => {
        switch (this.props.userReportFrequency){
            case "monthly":
                return "1 day a month, 1 time per day"
            case "daily":
                return "1 time per day"
            case "hourly":
                return "every 60 minutes"
            default:
                return "any day, any time"
        }
    }

    getAdminEmail = () => {
        let adminEmail = getAdminEmailFromTeamPK(this.props.allTeamsInfo, this.props.selectedTeamPK);

        if (adminEmail === null){
            return localStorage.getItem("email")
        } else {
            return adminEmail
        }
    }

    render() {
        const planName = this.getPricingInfo()['name'];
        const planValue = this.props.userReportFrequency;

        const header = "You can only set up " + planValue + " reporting (" + this.reportingAddlText() +") on the " + planName.toUpperCase() + " plan"

        let content;
        if (this.isUserTeamSelected()){
            content = <span><a href="/pricing" target="_blank" rel="noopener noreferrer">Upgrade here</a> to a higher tier plan for more reporting options</span>
        } else {
            const adminEmail = this.getAdminEmail()
            content = <span><a href={`mailto:${adminEmail}?Subject=Upgrade%20Love%20Spreadsheets%20Plan%20for%20more%20reporting%20options`}>Email your team admin</a> to request upgrade for a higher tier plan</span>
        }

        return (
            <div>
                <BannerAlert
                    type="display"
                    header={header}
                    content={content}
                    icon="fa-solid fa-triangle-exclamation"
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userTeamPK: state.mainState.userTeamPK,
    selectedTeamPK: state.mainState.selectedTeamPK,
    selectedTeamInfo: state.mainState.selectedTeamInfo,
    allTeamsInfo: state.mainState.allTeamsInfo
})

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(SetupTimeRestrictionBanner)