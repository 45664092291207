import React, {Component} from 'react'
import {featureList} from "../Pricing/featureList";
import PlanPricingBox from "../../SharedComponents/Pricing/PlanPricingBox"
import { planBgColor } from "../../Constants/planBgColor";
import EnterpriseUpgradeBox from "./EnterpriseUpgradeBox";

class UpgradePricingBoxesIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            headerBoxHeight: 162
        }
    }

    getEnterpriseBoxStyle = () => {
        return {"marginLeft": "24px"}
    }

    changeHeaderBoxHeight = (boxHeight) => this.setState({ headerBoxHeight: boxHeight })

    getPlanPricingBox = (pricingIdx) => {
        return <PlanPricingBox
            profilePage
            planIdx={pricingIdx}
            featureList={featureList}
            backgroundColor={planBgColor[pricingIdx]}
            changeHeaderBoxHeight={this.changeHeaderBoxHeight}
        />
    }

    getFirstPricingBox = (pricingIdx) => {
        if (pricingIdx === 0){
            return this.getPlanPricingBox(1)
        } else if (pricingIdx === 1){
            return this.getPlanPricingBox(2)
        } else if (pricingIdx === 2){
            return this.getPlanPricingBox(3)
        } else {
            return <div style={this.getEnterpriseBoxStyle()}>
                <EnterpriseUpgradeBox vertical />
            </div>
        }
    }

    getSecondPricingBox = (pricingIdx) => {
        if (pricingIdx === 0){
            return this.getPlanPricingBox(2)
        } else if (pricingIdx === 1){
            return this.getPlanPricingBox(3)
        } else if (pricingIdx === 2){
            return <div style={this.getEnterpriseBoxStyle()}>
                <EnterpriseUpgradeBox vertical />
            </div>
        } else {
            return null
        }
    }

    getThirdPricingBox = (pricingIdx) => {
        if (pricingIdx === 0){
            return this.getPlanPricingBox(3)
        } else if (pricingIdx === 1){
            return <div style={this.getEnterpriseBoxStyle()}>
                <EnterpriseUpgradeBox vertical />
            </div>
        } else if (pricingIdx === 2){
            return null
        } else {
            return null
        }
    }

    getGridTemplateColumns = (pricingIdx) => {
        if (pricingIdx === 0){
            return "auto auto auto"
        } else if (pricingIdx === 1){
            return "auto auto auto"
        } else if (pricingIdx === 2){
            return "auto auto 1fr"
        } else {
            return "auto 1fr 1fr"
        }
    }

    render() {
        return (
            <div style={{ padding: '40px' }}>
                <div style={{ display: 'grid', gridTemplateColumns: this.getGridTemplateColumns(this.props.pricingIdx)}}>
                    <div>
                        {
                            this.getFirstPricingBox(this.props.pricingIdx)
                        }
                    </div>
                    <div>
                        {
                            this.getSecondPricingBox(this.props.pricingIdx)
                        }
                    </div>
                    <div>
                        {
                            this.getThirdPricingBox(this.props.pricingIdx)
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default UpgradePricingBoxesIndex