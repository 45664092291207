import React, {Component} from 'react'

class OAuthTest extends Component {
    constructor(props) {
        super(props);
    }

    getURL = () => {
        return "https://api.outreach.io/oauth/authorize?client_id=ocmbEtCW5rGjK1FWRxhfaSeP_2TSo43-xHhKQ7RULfmC&redirect_uri=http%3A%2F%2Flocalhost%3A2000%2Foauth-test&response_type=code&scope=accounts.read"
    }

    render() {
        return (
            <div>
                <a href={this.getURL()}>Authorize</a>
            </div>
        )
    }
}

export default OAuthTest