import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../../../Constants/styles";
import 'brace/mode/sql';
import AceEditor from 'react-ace';

class ReportsInputIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // could be "code" or "prompt"
            mode: this.props.mode
        }
    }

    getDivStyle = (selectionName) => {
        const headingStyle = { textAlign: "center",
            padding: "8px 16px",
            // cursor: "pointer",
            // borderBottom: "1px solid #F0F0F0",
            fontSize: "12px",
            borderRadius: selectionName === "prompt" ? "4px 0 0 0" : "0 4px 0 0",
            fontWeight: "700" }

        let selectedStyle = {...headingStyle}

        if (selectionName === this.state.mode){
            selectedStyle['backgroundColor'] = styles.blue
            selectedStyle['color'] = "white"
            selectedStyle['borderRight'] = "none"
        } else {
            selectedStyle['borderRight'] = "1px solid #F0F0F0"
            selectedStyle['borderTop'] = "1px solid #F0F0F0"
            selectedStyle['borderLeft'] = "1px solid #F0F0F0"
            selectedStyle['backgroundColor'] = "white"
        }

        return selectedStyle
    }

    getIconStyle = (selectionName) => {
        const iconStyle = { color: "#000000", fontSize: "14px"}

        if (selectionName === this.state.mode){
            let selectedIconStyle = {...iconStyle}

            selectedIconStyle['color'] = 'white'

            return selectedIconStyle
        } else {
            return {...iconStyle}
        }
    }

    changeUserInput = (e) => {

    }

    render() {
        let inputArea;
        if (this.state.mode === "code"){
            inputArea = <AceEditor
                mode="sql"
                theme="monokai"
                onChange={this.changeUserInput}
                value={this.props.value}
                name="UNIQUE_ID_OF_DIV"
                editorProps={{$blockScrolling: true}}
                height={50}
                width="auto"
                readOnly={true}
            />
        } else {
            inputArea = <textarea
                className="inputGray"
                rows={this.props.isMobile ? 3 : 1}
                style={{ width: "100%"}}
                placeholder={"Show me top 5 best performing sales people in 2022"}
                value={this.props.value}
                onChange={this.changeUserInput}
            />
        }

        return (
            <div>
                <div style={{ display: "grid", gridTemplateColumns: "auto 1fr"}}>
                    <div style={this.getDivStyle("prompt")}>
                        <i className="fa-regular fa-message"></i> <span style={{ paddingLeft: "4px"}}>Ask</span>
                    </div>
                    {/*<div style={this.getDivStyle("noCode")}>*/}
                        {/*<i className="fa-solid fa-computer-mouse"></i> <span style={{ paddingLeft: "4px"}}>No Code</span>*/}
                    {/*</div>*/}
                    {/*<div style={this.getDivStyle("code")}>*/}
                        {/*<i className="fa-solid fa-code"></i> <span style={{ paddingLeft: "4px"}}>Code</span>*/}
                    {/*</div>*/}
                    <div> </div>
                </div>
                <div style={{ backgroundColor: "white", padding: "12px", border: "1px solid #F0F0F0", borderRadius: "0 0 4px 4px", boxShadow: "0px 5px 6px -4px rgba(2, 34, 19, 0.02)"}}>
                    {inputArea}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
})

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(ReportsInputIndex)