import React, {Component} from 'react'
import NavbarMobile from "./NavbarMobile";
import NavbarBrowser from "./NavbarBrowser";
import {hidePricing} from "../Constants/hidePricing";

class Navbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hidePricing: hidePricing()
        }
    }

    render() {
        return (
            <div style={{ zIndex: "999" }} id="navbar">
                <div id="landingPageBrowser">
                    <NavbarBrowser hidePricing={this.state.hidePricing}/>
                </div>
                <div id="landingPageMobile">
                    <NavbarMobile hidePricing={this.state.hidePricing}/>
                </div>
            </div>
        )
    }
}

export default Navbar