import {getAdminEmailFromTeamPK} from "./getAdminEmailFromTeamPK";

export const showUpgradeModal = (toggleModal, modifyModalInfo, userPlan, featureName, featureValue, userTeamPK, selectedTeamPK, allTeamsInfo) => {
    const isUserTeamSelected = userTeamPK === selectedTeamPK;

    let adminEmail;
    if (!isUserTeamSelected){
        adminEmail = getAdminEmailFromTeamPK(allTeamsInfo, selectedTeamPK)

        if (adminEmail === null){
            adminEmail = localStorage.getItem("email");
        }
    } else {
        adminEmail = localStorage.getItem("email");
    }

    modifyModalInfo({
        isUserTeamSelected: isUserTeamSelected,
        adminEmail: adminEmail,
        userPlan: userPlan,
        featureName: featureName,
        featureValue: featureValue
    })

    toggleModal("upgradeModal")
}