import React, {Component} from 'react'
import styles from "../Constants/styles";

class TroubleshootStepBox extends Component {
    render() {
        return (
            <div style={{ marginBottom: '12px'}}>
                <div style={{fontSize: '14px', color: styles.mainText, marginBottom: '4px', fontWeight: '700'}}>
                    {this.props.header}
                </div>
                <div style={typeof this.props.desc === 'string' ? {fontSize: '12px', color: styles.subText} : null}>
                    {this.props.desc}
                </div>
            </div>
        )
    }
}

export default TroubleshootStepBox