import React, {Component} from 'react'
import {connect} from 'react-redux'
import AddGoogleAccount from "./AddGoogleAccount"
import {areGoogleAccountsConnected} from "../../../../Constants/areGoogleAccountsConnected";
import styles from "../../../../Constants/styles";
import SelectGoogleAccountIndex from "./SelectGoogleAccountIndex";

class GoogleSheetsIndex extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        const userHasGoogleAccounts = areGoogleAccountsConnected(this.props.userIntegrations);

        return (
            <div>
                {
                    this.props.isGoogleUser && !userHasGoogleAccounts ?
                        <div style={{ color: styles.orange}}>We know you signed up with a Google Account but you have to add it again to give permissions for us to access your Google Sheets</div> : null
                }
                {
                    userHasGoogleAccounts ?
                        <SelectGoogleAccountIndex
                            userIntegrations={this.props.userIntegrations}
                            google_sheets_info={this.props.google_sheets_info}
                            changeState={this.props.changeState}
                        /> : null
                }
                <AddGoogleAccount />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userIntegrations: state.mainState.userIntegrations,
    isAuth: state.mainState.isAuth,
    isGoogleUser: state.mainState.isGoogleUser
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(GoogleSheetsIndex)