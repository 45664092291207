import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../../../../Constants/styles";
import API_Root from "../../../../Constants/API_Root";
import loadScript from 'load-script';
import {toggleModal} from "../../../../Actions/ToggleModal";
import {modifyModalInfo} from "../../../../Actions/ModifyModalInfo";
import {modifyUserIntegrations} from "../../../../Actions/ModifyUserIntegrations";
import {readFiles} from "../../../../Constants/readFiles";
import {modifyAiFiles} from "../../../../Actions/ModifyAiFiles";
import {modifySidebarSelection} from "../../../../Actions/ModifySidebarSelection";
import LoaderApp from "../../../../Loader";

const GOOGLE_PICKER_URL = 'https://apis.google.com/js/api.js';

class OpenGooglePicker extends Component {
    constructor(props){
        super(props);

        this.state = {
            clientId: '307583181930-rsajrsor5s7p6964flj1ea6rkcgji29d.apps.googleusercontent.com',
            developerKey: 'AIzaSyA9vSMxH1KT-cqNQCkz6Li-4w4T8sV4uWc',
            scope:['https://www.googleapis.com/auth/drive.file', 'https://www.googleapis.com/auth/spreadsheets'],
            onChange: data => this.onChange(data),
            onAuthFailed: data => console.log('on auth failed:', data),
            multiselect: true,
            navHidden: false,
            authImmediate: false,
            mimeTypes: ['application/vnd.google-apps.spreadsheet'],
            query: '',
            viewId:'DOCS',
            origin: API_Root.includes("api") ? 'https://lovespreadsheets.com' : 'http://localhost:2000',
            email: '',
            loading: false,
            pickerEnabled: false
        }
    }

    componentDidMount(){
        loadScript(GOOGLE_PICKER_URL, this.onApiLoad)
    }

    onApiLoad = () => {
        window.gapi.load('picker', this.setState({ pickerEnabled: true }));
        console.log("picker loaded")
    }

    startGoogleSheetPickProcess = () => {
        this.setState({
            loading: true
        })

        const syncError = (errorMessage="There was an error with your account selection. Check your Profile to make sure all your accounts are valid") => {
            this.setState({
                loading: false
            }, () => alert(errorMessage))
        }

        fetch(API_Root + "api/get-google-account-access-token/" + localStorage.getItem("email").toString() + "/" + localStorage.getItem("token").toString() + "/" + this.props.accountEmail.toString() + "/")
            .then(res => {
                if (res.status === 200){
                    res.json().then(data => {
                        this.createPicker(data.accessToken)
                    }).catch(err => syncError())
                } else {
                    syncError()
                }
            }).catch(err => syncError())
    }


    createPicker = (accessToken) => {
        console.log(accessToken)
        let view;

        if (this.props.mode === "folder"){
            view = new window.google.picker.DocsView(window.google.picker.ViewId.FOLDERS).setIncludeFolders(true);

            view.setSelectFolderEnabled(true);
            view.setMimeTypes('application/vnd.google-apps.folder')

        } else {
            console.log("picker file view set")
            view = new window.google.picker.DocsView();

            view.setMimeTypes(this.state.mimeTypes.join(','))
        }

        const picker = new window.google.picker.PickerBuilder()
            .addView(view)
            .setOAuthToken(accessToken)
            .setDeveloperKey(this.state.developerKey)
            .setCallback(this.state.onChange);

        if (this.props.mode === "file"){
            if (this.state.navHidden) {
                picker.enableFeature(window.google.picker.Feature.NAV_HIDDEN)
            }
        }

        try{
            picker.build().setVisible(true);
        } catch (e) {
            console.log("error building picker " + e.toString())
        }

    }

    onChange = (data) => {
        if (data.action === "picked"){
            if (data.docs){
                if (data.docs.length > 0){
                    const selectedDocInfo = data.docs[0];

                    if (this.props.mode === "file"){
                        this.getSheetNames(selectedDocInfo)
                    } else {
                        this.updateSelectedDocsInState(selectedDocInfo)
                    }
                } else {
                    this.stopLoading()
                }
            } else {
                this.stopLoading()
            }
        } else {
            this.stopLoading()
        }
    }

    stopLoading = () => this.setState({ loading: false })

    getSheetNames = (selectedDocInfo) => {
        const email = localStorage.getItem("email").toString();
        const token = localStorage.getItem("token").toString();
        const accountEmail = this.props.accountEmail.toString();
        const spreadsheetId = selectedDocInfo.id;


        fetch(API_Root + "api/get-sheets-of-google-sheets-file/" + email + "/" + token + "/" + accountEmail + "/" + spreadsheetId + "/")
            .then(res => {
                if (res.status === 200){
                    res.json().then(data => {
                        console.log(selectedDocInfo)
                        console.log(data)

                        let sheetNames = null;
                        if ("sheetNames" in data){
                            sheetNames = data.sheetNames
                        }

                        selectedDocInfo['sheetNames'] = sheetNames

                        this.updateSelectedDocsInState(selectedDocInfo)
                    }).catch(err => this.stopLoading())
                } else {
                    this.stopLoading()
                }
            }).catch(err => this.stopLoading())
    }

    updateSelectedDocsInState = (selectedDocInfo) => {
        try{
            const copiedGoogleSheetsInfo = JSON.parse(JSON.stringify(this.props.google_sheets_info))
            const accountInfo = copiedGoogleSheetsInfo[this.props.accountEmail]
            accountInfo['selectedDocInfo'] = selectedDocInfo

            copiedGoogleSheetsInfo[accountInfo] = accountInfo
            this.props.changeState({ google_sheets_info: copiedGoogleSheetsInfo })
            this.stopLoading()
        } catch (e) {
            alert(e)
            this.stopLoading()
        }
    }

    render(){
        return (
            <div
                className="linkStyle"
                onClick={this.startGoogleSheetPickProcess}
            >
                {
                    this.state.loading ? <LoaderApp height={25} width={25}/> :
                    this.props.mode === "folder" ?
                        "Select another folder from your Drive" :
                        this.props.fileText
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    isAuth: state.mainState.isAuth,
    userIntegrations: state.mainState.userIntegrations,
    aiFiles: state.mainState.aiFiles
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo,
    modifyUserIntegrations: modifyUserIntegrations,
    modifyAiFiles: modifyAiFiles,
    modifySidebarSelection: modifySidebarSelection
}

export default connect(mapStateToProps, mapActionsToProps)(OpenGooglePicker)