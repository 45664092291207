import React, {Component} from 'react'
import styles from "../Constants/styles";

class AlphaTag extends Component {
    render() {
        return (
            <span style={{ fontSize: "12px", backgroundColor: styles.red, color: "white", padding: "0 2px 0 2px", fontWeight: "700"}}>ALPHA</span>
        )
    }
}

export default AlphaTag