import React, {Component} from 'react'
import {connect} from 'react-redux'

class QuestionDemoStep extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{ paddingBottom: "0px" }}>
                <div style={{ fontSize: "16px" }}><b>For specific tasks, you can switch to BI mode</b></div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(QuestionDemoStep)