import React, {Component} from 'react'
import {connect} from 'react-redux'
import BannerAlert from "../SharedComponents/BannerAlert";
import {getEmailFromUsername} from "../Constants/getEmailFromUsername";
import styles from "../Constants/styles";
import Button from "../SharedComponents/Button"
import API_Root from "../Constants/API_Root";
import { toggleToast } from "../Actions/ToggleToast";
import {modifySelectedTeamPK} from "../Actions/ModifySelectedTeamPK";
import {modifyAllTeamsInfo} from "../Actions/ModifyAllTeamsInfo";
import {addParamToUrl} from "../Constants/addParamToUrl";

class TeamInvitesRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fetching: false
        }
    }

    acceptOrDeclineInvite = (isAccept) => {
        this.setState({ fetching: true })

        const error = () => {
            this.setState({
                fetching: false
            }, () => this.props.toggleToast({ show: true, type: "error", message: "There was an error " + (isAccept ? "accepting" : "declining") + " your invite. Please try again. Contact support if error persists." }))
        }

        fetch(API_Root + "api/accept-or-decline-lovespreadsheets-team/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                id: this.props.info.id,
                isAccept: isAccept,
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                isAdminAction: false
            })
        }).then(res => {
            if (res.status === 200){
                const toastMsg = isAccept ? "Invite accepted :)" : "Invite declined :("
                const toastType = isAccept ? "success" : "error"

                this.setState({
                    fetching: false
                }, () => this.props.toggleToast({ show: true, type: toastType, message: toastMsg }))

                this.acceptOrDeclineInRedux(isAccept)
            } else {
                error()
            }
        }).catch(err => error())
    }

    getAcceptOrDeclineBtn = (isAccept) => {
        const color = isAccept ? styles.mainGreen : styles.red;
        const text = isAccept ? "Accept" : "Decline"
        const icon = isAccept ? "fa-solid fa-check" : "fa-solid fa-ban"

        const style = {
            border: "1px solid " + color,
            color: color,
            borderRadius: "4px",
            padding: "8px 16px",
            cursor: "pointer",
            backgroundColor: "white",
            width: "100%"
        }

        return <Button loading={this.state.fetching}
                       onClick={e => this.acceptOrDeclineInvite(isAccept)}
                        content={<span><i className={icon} /> <span style={{ paddingLeft: "4px"}}>{text}</span></span>}
                       style={style} />
    }

    acceptOrDeclineInRedux = (isAccept) => {
        const copiedTeamInfo = JSON.parse(JSON.stringify(this.props.allTeamsInfo))

        if (isAccept){
            let url = window.location.href;
            const teamPK = this.props.info.love_team__pk.toString();

            window.location.href = addParamToUrl(url, "teamPK", teamPK)
        } else {
            copiedTeamInfo.splice(this.props.idx, 1)

            this.props.modifyAllTeamsInfo(copiedTeamInfo)
        }


    }

    render() {
        return (
            <div style={{ padding: "16px"}}>
                <BannerAlert
                    icon="fa-solid fa-users"
                    header={"You have been invited to join Team " + this.props.info.love_team__name}
                    type="display"
                    content={getEmailFromUsername(this.props.info.love_team__admin_username) + " invited you"}
                    action={<div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: "8px"}}>
                        <div>
                            {this.getAcceptOrDeclineBtn(true)}
                        </div>
                        <div>
                            {this.getAcceptOrDeclineBtn(false)}
                        </div>
                    </div>}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    allTeamsInfo: state.mainState.allTeamsInfo
})

const mapActionsToProps = {
    toggleToast: toggleToast,
    modifySelectedTeamPK: modifySelectedTeamPK,
    modifyAllTeamsInfo: modifyAllTeamsInfo
}

export default connect(mapStateToProps, mapActionsToProps)(TeamInvitesRow)