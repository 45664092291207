import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../../../../Constants/styles";
import WebsiteColumnNames from "./WebsiteColumnNames";

const menuItems = [
    {value: 'id', label: 'ID'},
    {value: 'class', label: 'Class'},
    {value: 'name', label: 'Name'},
    {value: 'xpath', label: 'XPath'}
]

class WebsiteInputIndex extends Component {
    constructor(props) {
        super(props);

        this.state = this.initWebsiteState();
    }

    getDefaultState = () => ({
        'elementType': 'id',
        'elementLabel': '',
        'columnInfo': [{name: '', desc: '', use: false}]
    })

    initWebsiteState = () => {
        return this.props.initState(this.getDefaultState())
    }

    handleChange = (e) => {
        const newState = { [e.target.name]: e.target.value };

        this.setState(newState)
        this.props.keepParamsUpdated(newState)
    }

    changeElementType = (val) => {
        const newState = { elementType: val }

        this.setState(newState)
        this.props.keepParamsUpdated(newState)
    }

    getElementTypeLabel = () => {
        let label = 'Element'

        for (let i=0; i<menuItems.length; i++){
            if (menuItems[i].value === this.state.elementType){
                label = menuItems[i].label
            }
        }

        return label
    }

    getCheckbox = (value, label) => {
        return <div style={{ marginRight: '16px' }}>
            <input type="radio"
                   name={"elementType" + this.props.reportSelectedIdx.toString()}
                   id={"elementType" + this.props.reportSelectedIdx.toString() + value.toString()}
                   value={value}
                   checked={this.state.elementType === value}
                   onClick={e => this.changeElementType(value)}
                   style={{ cursor: 'pointer'}}
            />
            <label htmlFor={"elementType" + this.props.reportSelectedIdx.toString() + value.toString()}
                   style={{...this.props.labelHeaderStyle, ...{paddingLeft: '4px',  cursor: 'pointer'}}}>
                {label}
            </label>
        </div>
    }

    changeKeyValPairs = (changeType, pairIdx, stateKey, changeInfo) => {
        const currPairs = JSON.parse(JSON.stringify(this.state[stateKey]));

        if (changeType === 'edit') {
            const selectedPair = currPairs[pairIdx];

            for (let infoType in changeInfo){
                if (infoType === 'name'){
                    if (selectedPair['name'].trim() === "" && changeInfo['name'].trim() !== ""){
                        selectedPair['use'] = true
                    }
                }

                selectedPair[infoType] = changeInfo[infoType]
            }

            currPairs[pairIdx] = selectedPair

            if (pairIdx === currPairs.length - 1){
                currPairs.push({name: '', desc: '', use: false})
            }

        } else if (changeType === 'delete'){
            currPairs.splice(pairIdx, 1)
        }

        if (currPairs.length === 0){
            currPairs.push({name: '', desc: '', use: false})
        }

        this.setState({
            [stateKey]: currPairs
        })

        this.props.keepParamsUpdated({
            [stateKey]: currPairs
        })
    }

    render() {
        return (
            <div>
                <div style={{ marginBottom: "16px"}}>
                    <div style={this.props.labelHeaderStyle}>
                        Element Type
                    </div>
                    <div>
                        <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto auto 1fr' }}>
                            {this.getCheckbox("id", "Id")}
                            {this.getCheckbox("class", "Class")}
                            {this.getCheckbox("name", "Name")}
                            {this.getCheckbox("xpath", "XPath")}
                            <div> </div>
                        </div>
                    </div>
                </div>
                <div style={{ marginBottom: "24px"}}>
                    <div style={this.props.labelHeaderStyle}>
                        {this.getElementTypeLabel()} Label
                    </div>
                    <div>
                        <input placeholder='my-countries-table'
                               className="inputGray"
                               name='elementLabel'
                               onChange={this.handleChange}
                               value={this.state['elementLabel']}
                               style={{ width: '100%' }}
                        />
                    </div>
                </div>
                {/*<div style={{ marginBottom: "24px"}}>*/}
                    {/*<div style={this.props.labelHeaderStyle}>*/}
                        {/*Output Column Details*/}
                    {/*</div>*/}
                    {/*<WebsiteColumnNames*/}
                        {/*keyValuePairs={this.state.columnInfo}*/}
                        {/*changeKeyValPairs={this.changeKeyValPairs}*/}
                        {/*name={'columnInfo'}*/}
                        {/*labelHeaderStyle={this.props.labelHeaderStyle}*/}
                    {/*/>*/}
                {/*</div>*/}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    reportSelectedIdx: state.mainState.reportSelectedIdx
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(WebsiteInputIndex)