import React, {Component} from 'react'
import styles from "../../Constants/styles";
import Developer from "../../Illustrations/developer.svg"
import Analyst from "../../Illustrations/analyst.svg"

class TeamHelpBody extends Component {
    render() {
        const boxStyle = { backgroundColor: "white", padding: "24px", border: "1px solid #F0F0F0", borderRadius: "4px", boxShadow: "0px 5px 6px -4px rgba(2, 34, 19, 0.02)", textAlign: "center"}
        const boxLabel = {
            color: styles.subText,
            fontSize: "18px",
            fontWeight: "700",
            marginBottom: "12px"
        }

        const subHeadingStyle = { marginTop: "24px" }
        const imgStyle = {
            // maxHeight: "160px",
            // maxWidth: "160px",
            // minHeight: "1"
            height: "160px",
            marginTop: "24px"
        }
        return (
            <div>
                <div className="row">
                    <div className="col-sm-6">
                        <div style={boxStyle}>
                            <div style={boxLabel}>
                                Invite a developer...
                            </div>
                            <div>
                                <img src={Developer} style={imgStyle} alt="programmer"/>
                            </div>
                            <div style={subHeadingStyle}>
                                ...to help you connect & manage data sources
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div style={boxStyle}>
                            <div style={boxLabel}>
                                Invite an analyst...
                            </div>
                            <div>
                                <img src={Analyst} style={imgStyle} alt="analyst"/>
                            </div>
                            <div style={subHeadingStyle}>
                                ...to help you ask the <b>right</b> questions
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TeamHelpBody