import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleModal} from "../Actions/ToggleModal";

class SignUpLogInLink extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <p><span className="linkStyle" onClick={e => this.props.toggleModal("signUpModal")}>Sign up</span> or <span className="linkStyle" onClick={e => this.props.toggleModal("loginModal")}>log in</span> {this.props.msg}</p>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(SignUpLogInLink)