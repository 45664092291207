import React, {Component} from 'react'

class BookACall extends Component {
    constructor(props) {
        super(props);

        // this.calendlyLink = React.createRef();
    }

    componentDidMount(){
        this.calendlyLink.click()
    }

    render() {
        return (
            <div>
                <a href="https://calendly.com/love-spreadsheets/25min" style={{ display: "none" }} ref={calendlyLink => this.calendlyLink = calendlyLink}> </a>
            </div>
        )
    }
}

export default BookACall