import {getFileURL} from "./getFileURL";

export const modifyFileInfoForCallback = (copiedAllFileInfo, id, pk, sheetRead) => {
    if (id in copiedAllFileInfo){
        const keysToDelete = ['teams_hash_list', 'queryFK', 'pkID', 'private', 'accessKey', 'secretKey', 'inputType', 'colLength', 'rowLength', 'isLargeFile',
            'jsonRowSample', 'matrixSample', 'jsonColumnSample', 'fixColumnNames', "description", "columnInformation", "reportInfo",
            "webhookURL", "webhookInfo", "fileURL", "teams_pk_list", "isTeam", "importSheetsFK", "importSheetsInfo", "importSheetsHash", "columnsMap", "fileHash", "useFileHash"];

        const fileInfo = copiedAllFileInfo[id];

        // Add sheet to the file info
        let sheet = null;
        if (id in sheetRead){
            sheet = sheetRead[id]
        }

        fileInfo['sheetRead'] = sheet

        // rename colLength and rowLength to colCount & rowCount
        const currColLength = fileInfo['colLength'];
        const currRowLength = fileInfo['rowLength'];

        fileInfo['colLen'] = currColLength
        fileInfo['rowLen'] = currRowLength

        // rename samples
        const currSamples = fileInfo['matrixSample'];

        let sample = [];

        if ("data" in currSamples){
            sample = currSamples['data']
        }

        fileInfo['sampleData'] = sample

        // Rename column names
        fileInfo['duplicateColumnNames'] = fileInfo['fixColumnNames']

        // Rename import sheets key
        fileInfo['importKey'] = fileInfo['importSheetsHash']

        // Rename file hash
        fileInfo['fileID'] = fileInfo['fileHash']

        // API get URL
        const apiUrl = getFileURL(copiedAllFileInfo, pk, id, "jsonRow", "readAction", currRowLength, currRowLength).replace(pk, fileInfo['fileHash'])
        console.log(apiUrl)
        fileInfo['apiUrl'] = apiUrl

        // Add sheetNames as blank if it doesn't exist
        if (!('sheetNames' in fileInfo)){
            fileInfo['sheetNames'] = []
        }

        // Delete all un-necessary information from fileInfo
        for (let j=0; j<keysToDelete.length; j++){
            const deleteKey = keysToDelete[j];

            if (deleteKey in fileInfo){
                delete fileInfo[deleteKey]
            }
        }

        return fileInfo
    } else {
        return null
    }

}