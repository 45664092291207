import React, {Component} from 'react'
import ModalApp from '../Modal'
import GuidesModalBody from "./GuidesModalBody"

class GuidesModal extends Component {
    render() {
        return (
            <ModalApp name="guidesModal"
                      header={null}
                      body={<GuidesModalBody/>}
            />
        )
    }
}

export default GuidesModal