export const getSelectedFilesAndCols = (allFiles) => {
    //        fileID
    const fileIDs = allFiles.map(x => x.fileID);

    const columns = {}

    for (let i=0; i<allFiles.length; i++){
        columns[i] = allFiles[i].columnNames.map((x, idx) => idx)
    }

    return {files: fileIDs, columns: columns}
}