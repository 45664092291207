import React, {Component} from 'react'
import Select from 'react-select'

const freqOptions = ["first", "second", "third", "fourth", "last"]
const dayOptions = ["day", "weekday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]

class MonthlyCustomSelection extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount(){
        if (!("custom" in this.props.report_info)){
            const reportInfo = JSON.parse(JSON.stringify(this.props.report_info))
            reportInfo['custom'] = {freq: "first", day: "day"}

            this.props.changeState({report_info: reportInfo})
        }
    }

    getValue = (valType) => {
        if ("custom" in this.props.report_info){
            if (valType in this.props.report_info.custom){
                const val = this.props.report_info.custom[valType]
                return {value: val, label: val}
            }
        }

        return null
    }

    changeDay = (dayVal) => {
        const reportInfo = JSON.parse(JSON.stringify(this.props.report_info))

        let custom;
        if ("custom" in reportInfo){
            custom = reportInfo.custom
            custom['day'] = dayVal.value

        } else {
            custom = {"day": dayVal.value}
        }

        reportInfo.custom = custom

        this.props.changeState({report_info: reportInfo})
    }

    changeFreq = (freqVal) => {
        const reportInfo = JSON.parse(JSON.stringify(this.props.report_info))

        let custom;
        if ("custom" in reportInfo){
            custom = reportInfo.custom
            custom['freq'] = freqVal.value

        } else {
            custom = {"freq": freqVal.value}
        }

        reportInfo.custom = custom

        this.props.changeState({report_info: reportInfo})
    }

    render() {
        return (
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr"}}>
                <div>
                    <Select
                        onChange={this.changeFreq}
                        value={this.getValue("freq")}
                        options={freqOptions.map(x => ({value: x, label: x}))}
                        isDisabled={!this.props.isCustomChecked}
                    />
                </div>
                <div>
                    <Select
                        onChange={this.changeDay}
                        value={this.getValue("day")}
                        options={dayOptions.map(x => ({value: x, label: x}))}
                        isDisabled={!this.props.isCustomChecked}
                    />
                </div>
            </div>
        )
    }
}

export default MonthlyCustomSelection