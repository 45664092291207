import React, {Component} from 'react'
import {connect} from 'react-redux'
import OpenGooglePicker from './OpenGooglePicker';
import styles from "../../../../Constants/styles";

class CreateNewFileFolderSelection extends Component {
    constructor(props) {
        super(props);
    }

    getFolderName = () => {
        try{
            if (this.props.google_sheets_info[this.props.accountEmail].selectedDocInfo.type === "folder"){
                return this.props.google_sheets_info[this.props.accountEmail].selectedDocInfo.name
            } else {
                return "main"
            }
        } catch (e) { return "main" }
    }

    removeFolder = () => {
        try {
            const copiedGoogleSheetsInfo = JSON.parse(JSON.stringify(this.props.google_sheets_info));
            const accountInfo = copiedGoogleSheetsInfo[this.props.accountEmail]
            delete accountInfo.selectedDocInfo;

            copiedGoogleSheetsInfo[this.props.accountEmail] = accountInfo

            this.props.changeState({ google_sheets_info: copiedGoogleSheetsInfo })
        } catch (e) {

        }

    }

    render() {
        return (
            <div>
                <div style={{ fontSize: "14px", color: styles.subText}}>
                    Your new file(s) will be created in the <b>{this.getFolderName()}</b> folder of your Google Drive.
                    {this.getFolderName() !== "main" ? <span className="linkStyle" onClick={this.removeFolder}>Go back to main folder</span> : null}
                </div>
                <OpenGooglePicker
                    accountInfo={this.props.accountInfo}
                    accountEmail={this.props.accountEmail}
                    google_sheets_info={this.props.google_sheets_info}
                    changeState={this.props.changeState}
                    mode="folder"
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(CreateNewFileFolderSelection)