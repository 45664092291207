import React, {Component} from 'react'
import { images } from '../Constants/images'
import SignUpForm from "./SignUpForm"

class SignUpIndex extends Component {
    componentDidMount(){
        document.title = "Sign Up - Love Spreadsheets"
    }

    render() {
        return (
            <div className="row" style={{backgroundColor: '#DCDCDC', minHeight: '100vh', paddingBottom: "120px"}}>
                <div className="col-sm-3" />
                <div className="col-sm-6">
                    <br/><br/><br/>
                    <a href="/">
                        <img style={{marginTop: '5px', width: "30%"}} src={images.logoNav} alt="Logo" />
                    </a>
                    <div style={{ marginTop: "8px" }}>
                        <SignUpForm />
                    </div>
                    <br/>
                    <p style={{textAlign: 'center'}}>Already have an account? <a href="/login">Log In</a></p>
                </div>
                <div className="col-sm-3" />
            </div>
        )
    }
}

export default SignUpIndex