import React, {Component} from 'react'
import { connect } from 'react-redux'
import {toggleModal} from "../Actions/ToggleModal"
import BannerAlert from "../SharedComponents/BannerAlert"
import {modifyModalInfo} from "../Actions/ModifyModalInfo";

class TestDatabaseMessage extends Component {
    openTutorialModal = (modalName) => {
        this.props.modifyModalInfo({
            guideType: 'connectDatabase'
        })

        this.props.toggleModal(modalName)
    }

    refresh = () => {
        this.props.toggleModal(null);
        window.location.reload();
    }

    render() {
        if (this.props.connectionValid !== null){

            const header = this.props.connectionValid ? "Congratulations! Your connection was successful :)" : "There was an error connecting to your database"
            const content = this.props.connectionValid ?
                <span><span className="linkStyle" onClick={this.refresh}>Refresh the website</span> to start reporting from your database</span>
                : <div>{this.props.connectionValidMessage}<br/><br/>
                    {
                        'edit' in this.props ?
                            <span>Read our troubleshooting steps and try them out to see if they would help</span>
                            :
                            <span>Read our <span className="linkStyle" onClick={e => this.openTutorialModal("guidesModal")}>Troubleshoot Database Connection</span> guide for help if you keep failing to connect</span>
                    }
                </div>

            return (
                <div>
                    <BannerAlert type={this.props.connectionValid ? "success" : "error"}
                                 icon={null}
                                 header={header}
                                 content={content}
                    />
                </div>
            )
        } else {
            return (
                null
            )
        }

    }
}

const mapStateToProps = {
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo
}

export default connect(null, mapStateToProps)(TestDatabaseMessage)