import React from 'react'
import { Route, Switch } from "react-router";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsAndConditions from "./TermsAndConditions";
import EmailIndex from './EmailIndex';
import BlogIndex from './Blog/BlogIndex'
import AboutIndex from "./About/AboutIndex";
import ContactIndex from "./Contact/ContactIndex";
import EmailUnsubscribeIndex from "./EmailUnsubscribeIndex";
import LoginIndex from "./Auth/LoginIndex";
import ResetPasswordIndex from "./Auth/ResetPasswordIndex";
import NotFound from "./SharedComponents/NotFound";
import SignUpIndex from "./Auth/SignUpIndex"
import AppsIndex from "./Apps/AppsIndex";
import SQLIndex from "./SQL/SQLIndex";
import BookACall from "./SharedComponents/BookACall";
import ProfileAuthIndex from "./Profile/ProfileAuthIndex";
import OAuthTest from "./SharedComponents/OAuthTest"
import StruxiDataCleaning from "./StruxiDataCleaning";
import IntegrateIndex from "./SharedComponents/Integrate/IntegrateIndex"
import CareersIndex from "./SharedComponents/Careers/CareersIndex"
import PricingIndex from "./SharedComponents/Pricing/PricingIndex"
import ContinueToPricing from "./SharedComponents/ContinueToPricing";
import DownloadEmailReportIndex from "./SharedComponents/DownloadEmailReportIndex";
import TestAPISpreadsheetsForm from "./SharedComponents/TestAPISpreadsheetsForm"
import LandingPageAuthIndex from "./Landing/LandingPageAuthIndex"

export default ({ childProps }) =>
    <Switch>
        <Route exact path='/' component={LandingPageAuthIndex} />
        <Route path='/download-email-report/:id' component={DownloadEmailReportIndex} />
        <Route path='/test-api-spreadsheets-form' component={TestAPISpreadsheetsForm} />
        <Route exact path='/integrate' component={IntegrateIndex} />
        <Route exact path='/oauth-test' component={OAuthTest} />
        <Route exact path="/sql" component={SQLIndex} />
        <Route exact path="/pricing" component={PricingIndex} />
        <Route path="/continue-to-pricing/:id" component={ContinueToPricing} />
        <Route path="/profile" component={ProfileAuthIndex} />
        <Route path="/login" component={LoginIndex} />
        <Route path="/signup" component={SignUpIndex} />
        <Route path="/reset-password" component={ResetPasswordIndex} />
        <Route path="/terms" component={TermsAndConditions} />
        <Route path="/privacy" component={PrivacyPolicy} />
        <Route path="/email" component={EmailIndex} />
        <Route path="/blog" component={BlogIndex} />
        <Route path="/blog/:id" component={BlogIndex} />
        <Route path="/about" component={AboutIndex} />
        <Route path="/contact" component={ContactIndex} />
        <Route exact path="/unsubscribe" component={EmailUnsubscribeIndex} />
        <Route exact path="/book-a-call" component={BookACall} />
        <Route exact path="/struxi-data-cleaning" component={StruxiDataCleaning} />
        <Route exact path="/careers" component={CareersIndex} />
        {/*<Route exact path="/promo-code-sign-up" component={PromoCodeSignUp} />*/}
        <Route component={NotFound}/>
    </Switch>;
