import React, {Component} from 'react'

class ProductQualityFeature extends Component {
    render() {
        return (
            <div id={this.props.id}>
                <div className="productQualityFeatureDiv">
                <h4>
                    <i className={"fa " + this.props.iconName} aria-hidden="true" />
                    <span style={{marginLeft: '7px'}}>{this.props.title}</span>
                </h4>
                    <p>{this.props.desc}</p>
                </div>
            </div>
        )
    }
}

export default ProductQualityFeature