import React, {Component} from 'react'
import API_Root from './Constants/API_Root'
import Loader from "./Loader";
import EmailProductSelection from "./EmailProductSelection";

class EmailIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fromEmail: '',
            toEmailSelection: false,
            toEmail: '',
            message : '',
            subject: '',
            s3URL: '',
            sending: false,
            productSelection: {
                merge: false,
                api: false,
                clean: false,
                viz: false,
                love: false,
                ml: false,
                woyera: false
            }
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
    }

    handleInputChange(e){
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    changeProductSelection = (productName) => {
        const productSelection = JSON.parse(JSON.stringify(this.state.productSelection))
        const currSelection = !(productSelection[productName]);

        productSelection[productName] = currSelection

        this.setState({
            productSelection: productSelection
        })

    }

    sendEmail(){
        this.setState({ sending: true })

        const stopSend = () => this.setState({ sending: false })

        fetch(API_Root + 'api/send-admin-email/', {
            method: "POST",
            body: JSON.stringify({
                fromEmail: this.state.fromEmail,
                toEmail: this.state.toEmail,
                message: this.state.message,
                subject: this.state.subject,
                s3URL: this.state.s3URL,
                toEmailSelection: this.state.toEmailSelection,
                productSelection: this.state.productSelection
            }),
            headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}
        }).then(
            (res) => {
                if (res.status === 200){
                    stopSend()
                    alert("Email Sent!");
                }
                else{
                    stopSend()
                    alert("There was an error!")
                }
            }).catch(err =>
            {
                stopSend()
                alert("There was an error!")
            })
    }

    toggle = () => {
        const currEmailSelection = this.state.toEmailSelection;

        this.setState({ toEmailSelection: !currEmailSelection })
    }

    isBtnDisabled = () => {
        if (this.state.toEmailSelection){
            return this.state.toEmail.trim() === ""
        } else {
            let disabled = true;

            for (let key in this.state.productSelection){
                if (this.state.productSelection[key]){
                    disabled = false;
                    break;
                }
            }

            return disabled
        }
    }

    render() {
        let selectionArray = [];

        for (let prod in this.state.productSelection){
            selectionArray.push(
                <EmailProductSelection key={prod}
                                       product={prod}
                                       checkedValue={this.state.productSelection[prod]}
                                       changeProductSelection={this.changeProductSelection}/>
            )
        }

        return (
            <div>
                <input name="fromEmail" type="text" placeholder="From Email" value={this.state.fromEmail} onChange={this.handleInputChange} className="emailInput"/>
                <br/>
                <div style={{padding: "10px"}}><input type="checkbox" id="enterEmailsToSend" value={this.state.toEmailSelection} onChange={this.toggle} /><label htmlFor="enterEmailsToSend">Enter emails to send?</label></div>
                {
                    this.state.toEmailSelection ?
                        <input name="toEmail" type="text" placeholder="To Email" value={this.state.toEmail} onChange={this.handleInputChange} className="emailInput"/> :
                        <div style={{padding: "10px"}}>
                            {selectionArray}
                        </div>
                }
                <input name="subject" type="text" placeholder="Subject" value={this.state.subject} onChange={this.handleInputChange} className="emailInput"/>
                <br/>
                <input name="s3URL" type="text" placeholder="LoveSpreadsheets/newsletter.html" value={this.state.s3URL} onChange={this.handleInputChange} className="emailInput"/>
                <br/>
                <textarea name="message" placeholder="Message" value={this.state.message} onChange={this.handleInputChange} className="emailInput"/>
                <br/>
                <button onClick={this.sendEmail}
                        disabled={this.isBtnDisabled()}
                        className="emailInput"
                        style={{color: 'white', backgroundColor: 'red', cursor: 'pointer'}}>
                    {
                        !this.state.sending ? "Send Email" : <Loader/>
                    }
                </button>
            </div>
        )
    }
}

export default EmailIndex