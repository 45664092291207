import React, {Component} from 'react'
import { ThreeDots } from 'react-loader-spinner'
import styles from "../Constants/styles";

class Button extends Component {
    click = () => {
        if ('onClick' in this.props){
            this.props.onClick()
        }
    }

    keyPress = (e) => {
        if ('onKeyPress' in this.props){
            this.props.onKeyPress(e)
        }
    }

    render() {
        const style = {backgroundColor: "gray", color: "white", cursor: "pointer"};

        if ('style' in this.props){
            for (let key in this.props.style){
                style[key] = this.props.style[key]
            }
        }

        let iconPosition = 'left';

        if ('iconPosition' in this.props){
            iconPosition = this.props.iconPosition
        }

        let icon = null;

        if ('icon' in this.props){
            icon = <i className={this.props.icon} style={{color: style.color}}/>
        }

        let label = "";

        if ('label' in this.props){
            label = this.props.label
        }

        let disabled = false;

        if ('disabled' in this.props){
            disabled = this.props.disabled
        }

        if (disabled){
            style['opacity'] = 0.2
            style['cursor'] = 'not-allowed'
        }

        let loading = false;

        if ('loading' in this.props){
            loading = this.props.loading
        }

        return (
            <button onClick={this.click} className={'small' in this.props ? "actionButtonSmall" : "actionButton"} style={style} onKeyPress={this.keyPress} disabled={disabled}>
                {
                    loading ?
                        <div>
                            <ThreeDots
                                height="20"
                                width="20"
                                radius="9"
                                color={styles.mainText}
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        </div> :
                        'content' in this.props ? <div>{this.props.content}</div> :
                            icon === null ?
                            <div style={{ textAlign: "center"}}>
                                {label}
                            </div> :
                        <div style={{display: "grid", gridTemplateColumns: "auto 1fr", columnGap: "8px"}}>
                            <div>
                                {
                                    iconPosition === "left" ? icon : label
                                }
                            </div>
                            <div style={{textAlign: iconPosition === "right" ? "right" : "left"}}>
                                {
                                    iconPosition === "right" ? icon : label
                                }
                            </div>
                        </div>
                }
            </button>
        )
    }
}

export default Button