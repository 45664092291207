import FullLogoSmall from "../Illustrations/FullLogoSmall.png";
import FullLogoWhiteSmall from "../Illustrations/FullLogoWhiteSmall.png";
import Logo32px from "../Illustrations/LogoIcon-32x32.png"
import GoogleBigQueryLogo from "../Illustrations/GoogleBigQueryLogo.svg"
import SnowflakeLogo from "../Illustrations/Snowflake_Logo.svg"
import MongoDBLogo from "../Illustrations/MongoDB_Logo.svg"
import SQLServerLogo from "../Illustrations/Microsoft_SQL_Server_Logo.svg"
import MariaDBLogo from "../Illustrations/Mariadb-seal-browntext.svg"
import RedshiftLogo from "../Illustrations/aws-redshift-logo.svg"
import OutreachLogo from "../Illustrations/OutreachLogo-01.svg"
import MailchimpLogo from "../Illustrations/MailchimpLogo-01.svg"
import JiraLogo from "../Illustrations/JiraLogo-01.svg"
import FacebookAdsLogo from "../Illustrations/FacebookAdsLogo-01.svg"
import HubspotLogo from "../Illustrations/HubspotLogo.svg"
import FacebookLogo from "../Illustrations/FacebookLogo.svg"
import AirtableLogo from "../Illustrations/AirtableLogo.svg"
import ShopifyLogo from "../Illustrations/ShopifyLogo.svg"
import QuickbooksLogo from "../Illustrations/QuickbooksLogo.svg"
import GoogleAnalyticsLogo from "../Illustrations/GoogleAnalyticsLogo.svg"
import StripeLogo from "../Illustrations/StripeLogo.svg"
import CodeIcon from "../Illustrations/CodeIcon.svg"
import BrowserIcon from "../Illustrations/BrowserIcon.svg"
import WoyeraFullLogoSmall from "../Illustrations/WoyeraFullLogoSmall.png"
import OracleLogo from "../Illustrations/Oracle-01.svg"
import SaasLogo from "../Illustrations/server-solid.svg"

export const images = {
    // logoNav: "https://project-static-assets.s3.amazonaws.com/LoveSpreadsheets/Logo.png",
    // logoWhite: "https://project-static-assets.s3.amazonaws.com/LoveSpreadsheets/LogoWhite.svg",
    logoNav: 'https://s3.amazonaws.com/project-static-assets/DBSpreadsheets/Logo.png',
    logo32px: 'https://project-static-assets.s3.amazonaws.com/DBSpreadsheets/LogoIcon.png',
    logoWhite: 'https://s3.amazonaws.com/project-static-assets/DBSpreadsheets/LogoWhite.png',
    image404: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Longhaired_Dachshund_portrait.jpg/1006px-Longhaired_Dachshund_portrait.jpg',
    mysqlLogo: 'https://upload.wikimedia.org/wikipedia/commons/b/b2/Database-mysql.svg',
    postgresLogo: 'https://upload.wikimedia.org/wikipedia/commons/2/29/Postgresql_elephant.svg',
    mariadbLogo: MariaDBLogo,
    sqlserverLogo: SQLServerLogo,
    mongodbLogo: MongoDBLogo,
    snowflakeLogo: SnowflakeLogo,
    googleBigQueryLogo: GoogleBigQueryLogo,
    redshiftLogo: RedshiftLogo,
    salesforceLogo: 'https://upload.wikimedia.org/wikipedia/commons/f/f9/Salesforce.com_logo.svg',
    hubspotLogo: HubspotLogo,
    quickbooksLogo: QuickbooksLogo,
    googleAnalyticsLogo: GoogleAnalyticsLogo,
    stripeLogo: StripeLogo,
    outreachLogo: OutreachLogo,
    pipedriveLogo: 'https://upload.wikimedia.org/wikipedia/commons/4/4a/Pipedrive_logo.svg',
    shopifyLogo: ShopifyLogo,
    mailchimpLogo: MailchimpLogo,
    jiraLogo: JiraLogo,
    airtableLogo: AirtableLogo,
    facebookAdsLogo: FacebookLogo,
    apiLogo: CodeIcon,
    websiteLogo: BrowserIcon,
    woyeraLogo: WoyeraFullLogoSmall,
    oracleLogo: OracleLogo,
    saasToolsLogo: SaasLogo
}