import isObjectEmpty from "./isObjectEmpty";

export const getSelectedDbTablesAndColumns = (dbSelectedIdx, allDatabases) => {
    let selectedTablesAndColumns;

    try {
        selectedTablesAndColumns = allDatabases[dbSelectedIdx].tablesAndColumnInfo
    } catch (e){
        selectedTablesAndColumns = null
    }

    if (selectedTablesAndColumns === undefined || selectedTablesAndColumns === null){
        selectedTablesAndColumns = null
    }

    if (selectedTablesAndColumns !== null){
        if (isObjectEmpty(selectedTablesAndColumns)){
            selectedTablesAndColumns = null
        }
    }

    return selectedTablesAndColumns
}