import React, {Component} from 'react'
import {connect} from 'react-redux'

class ConnectWebsiteIndex extends Component {
    constructor(props) {
        super(props);
    }

    onChange = (e) => {
        const copiedParams = JSON.parse(JSON.stringify(this.props.connectionParams));
        copiedParams[e.target.name] = e.target.value

        this.props.changeState({connectionParams: copiedParams})
    }

    render() {
        return (
            <div>
                <div style={this.props.displayHeaderStyle}>Website URL<span style={{color: 'red'}}>*</span></div>
                <input style={{width: '100%'}}
                       className="inputGray"
                       value={this.props.getValuesFromConnectionParams(this.props.connectionParams,'websiteUrl', 'str')}
                       type='text'
                       placeholder='https://wikitravel.org/en/Kyoto'
                       name="websiteUrl"
                       onChange={this.onChange}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ConnectWebsiteIndex)