import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifyAllDBReports} from "../../Actions/ModifyAllDBReports";
import Loader from "../../Loader"
import styles from "../../Constants/styles";
import ReportListItem from "./ReportListItem"
import AddReportBtn from "./AddReportBtn"
import {toggleModal} from "../../Actions/ToggleModal";
import {modifySidebarSelection} from "../../Actions/ModifySidebarSelection";
import SignUpLoginLink from "../../SharedComponents/SignUpLogInLink"

class ReportsList extends Component {
    render() {
        let body = null;
        if (!this.props.isAuth){
            body = <SignUpLoginLink msg='to create reports' />
            // body = <p style={{ color: styles.subText }}>Sign up or login connect a database to create reports</p>
        } else {
            if (this.props.fetchingAllAiResults){
                body = <Loader/>
            } else {
                if (this.props.allDatabases.length === 0){
                    body = <p style={{ color: styles.subText }}>You do not have have any data sources connected. <span className="linkStyle" onClick={e => this.props.modifySidebarSelection("connectDatabase")}>Connect one</span></p>
                } else {
                    if (this.props.allDBReports.length === 0){
                        body = null
                    } else {
                        body = this.props.allDBReports.map((x, idx) =>
                            <div style={{ marginTop: "16px"}}><ReportListItem info={x} idx={idx} key={idx}/></div>)
                    }
                }
            }

        }

        return (
            <div>
                <div style={{ fontSize: "16px", fontWeight: "700", color: styles.mainText, marginBottom: "12px"}}>
                    <i className="fa-solid fa-file-lines"/> <span style={{ paddingLeft: '4px'}}>Your Reports</span>
                </div>
                {
                    this.props.allDatabases.length === 0 ? null : <div>
                        <AddReportBtn />
                    </div>
                }
                {body}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    fetchingAllAiResults: state.mainState.fetchingAllAiResults,
    allDBReports: state.mainState.allDBReports,
    allDatabases: state.mainState.allDatabases,
    isAuth: state.mainState.isAuth
})

const mapActionsToProps = {
    modifyAllDBReports: modifyAllDBReports,
    toggleModal: toggleModal,
    modifySidebarSelection: modifySidebarSelection
}

export default connect(mapStateToProps, mapActionsToProps)(ReportsList)