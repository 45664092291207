import React, {Component} from 'react'
import {connect} from 'react-redux'
import {isMobile} from "react-device-detect";
import MobileLandingPage from "../Apps/MobileLandingPage"
import AppsIndex from "../Apps/AppsIndex"
import LandingPageBrowserIndex from "./LandingPageBrowserIndex"
import MobileSendLinkToYourself from "../Apps/MobileSendLinkToYourself";

class LandingPageAuthIndex extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if(isMobile){
            if (this.props.isAuth){
                return (
                    <div style={{ minHeight: "100vh"}}>
                        <MobileSendLinkToYourself/>
                    </div>
                )
            } else {
                return (
                    <div style={{ minHeight: "100vh"}}>
                        {/*Check us out on desktop*/}
                        <MobileLandingPage/>
                    </div>
                )
            }
        }

        if (this.props.isAuth){
            return(
                <AppsIndex />
            )
        }


        return (
            <LandingPageBrowserIndex />
        )
    }
}

const mapStateToProps = (state) => ({
    isAuth: state.mainState.isAuth
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(LandingPageAuthIndex)