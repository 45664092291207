import React, {Component} from 'react'

class LabelTag extends Component {
    render() {
        let labelStyle = {backgroundColor: this.props.bgColor, padding: "2px 6px 2px 6px", borderRadius: "2px", fontSize: "12px", color: this.props.color, fontWeight: "700", fontFamily: "Lato"}

        return (
            <span style={labelStyle}>{this.props.label}</span>
        )
    }
}

export default LabelTag