import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleChatMode} from "../Actions/ToggleChatMode";
import AlphaTag from "./AlphaTag";
import InfoPopup from "./InfoPopup";
import styles from "../Constants/styles";
import {appsInfo} from "../Constants/appsInfo";

class ChatToggleButton extends Component {
    constructor(props) {
        super(props);
    }

    toggleChat = () => {
        this.props.toggleChatMode(!this.props.chatMode)
    }

    getIcon = (appName) => {
        const appInfo = appsInfo[appName]

        const iconStyle = {fontSize: "16px", color: appInfo.color, marginBottom: "-4px"}

        return <span className="material-icons-outlined" style={iconStyle}>{appInfo.icon}</span>

    }

    render() {
        return (
            <div style={{ display: "grid", gridTemplateColumns: "1fr auto 1fr"}}>
                <div style={{ textAlign: "right", paddingRight: "12px"}}>
                    <InfoPopup position="bottom" popupComponent={<div>
                        <b>Use BI Mode for specific data tasks using natural language</b>
                        <div style={{ fontSize: "12px", marginTop: "8px", color: styles.subText}}>
                            <div style={{ marginBottom: "4px"}}>
                                <b>{this.getIcon("filter")} Filter</b>
                                <br/>
                                Get all movies that starred Chris Pratt and had run time more than 120 minutes
                            </div>
                            <div style={{ marginBottom: "4px"}}>
                                <b>{this.getIcon("merge")} Merge</b>
                                <br/>
                                Combine both files on the Title column
                            </div>
                            <div style={{ marginBottom: "4px"}}>
                                <b>{this.getIcon("clean")} Clean</b>
                                <br/>
                                Come up with 2-4 keywords for the Movie descriptions
                            </div>
                            <div style={{ marginBottom: "4px"}}>
                                <b>{this.getIcon("visualize")} Visualize</b>
                                <br/>
                                Create a scatter plot of movies vs. their runtime
                            </div>
                            <div style={{ marginBottom: "4px"}}>
                                <b>{this.getIcon("sql")} SQL</b>
                                <br/>
                                Write SQL code to run on your files
                            </div>
                        </div>
                    </div>}/> BI Mode
                </div>
                <div>
                    <label className="switch">
                        <input type="checkbox" checked={this.props.chatMode} onChange={this.toggleChat}/>
                        <span className="slider round"></span>
                    </label>
                </div>
                <div style={{ textAlign: "left", paddingLeft: "12px"}}>
                    Chat Mode <InfoPopup position="bottom" popupComponent={<div>
                    <b>Use Chat Mode for general data analysis on only 1 file</b>
                    <div style={{ fontSize: "12px", marginTop: "8px", color: styles.subText}}>
                        <div style={{ marginBottom: "4px"}}>Some examples</div>
                        <div style={{ marginBottom: "4px"}}>- run an analysis to see whether movie revenue is correlated with movie ratings</div>
                        <div style={{ marginBottom: "4px"}}>- describe the columns in the data set</div>
                        <div style={{ marginBottom: "4px"}}>- count the number of movies that have descriptions longer than 40 characters</div>
                    </div>
                </div>}/>
                    {/*<AlphaTag/>*/}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    chatMode: state.mainState.chatMode
})

const mapActionsToProps = {
    toggleChatMode: toggleChatMode
}

export default connect(mapStateToProps, mapActionsToProps)(ChatToggleButton)