import React, {Component} from 'react'
import {connect} from 'react-redux'

class DataSourceDisplayMessage extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        // {"pk":4,"dataSource":"api","name":"API Spreadsheets","description":"asdas","authInfo":{"desc":"asdas","name":"API Spreadsheets","docsUrl":"","isAiMode":false},"tablesAndColumnInfo":{},"extraInfo":{},"createdDt":"August 20, 2023 13:31:43","updatedDt":"August 20, 2023 13:31:43","canConnect":false}
        let dataSource;

        try {
            dataSource = this.props.dbInfo['dataSource']
        } catch (e) {
            dataSource = null
        }

        if (dataSource === "website"){
            let url = this.props.dbInfo['authInfo']['websiteUrl'];

            return (
                <div>
                    <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
                </div>
            )
        }

        return (
            null
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(DataSourceDisplayMessage)