import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../../Constants/styles";
import {modifyDBSelectedIdx} from "../../Actions/ModifyDBSelectedIdx";
import {modifyReportSelectedIdx} from "../../Actions/ModifyReportSelectedIdx";

class ReportListItem extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const isSelected = this.props.reportSelectedIdx === this.props.idx;
        let report_name = this.props.info.report_name;

        if (report_name.trim() === ""){
            report_name = this.props.info.created_dt;
        }

        return (
            <div style={{
                padding: this.props.isMobile ? "6px 12px 6px 12px" : "12px 12px 12px 12px",
                border: "1px solid #F0F0F0",
                borderRadius: "4px",
                color: isSelected ? "white" : styles.subText,
                fontWeight: isSelected ? "700" : "400",
                backgroundColor: isSelected ? styles.blue : "white",
                cursor: "pointer"}} onClick={e => this.props.modifyReportSelectedIdx(this.props.idx)}>
                <div style={{ display: "grid", gridTemplateColumns: "1fr 12px", columnGap: "8px"}}>
                    <div className="ellipses">
                        {report_name}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    reportSelectedIdx: state.mainState.reportSelectedIdx
})

const mapActionsToProps = {
    modifyDBSelectedIdx: modifyDBSelectedIdx,
    modifyReportSelectedIdx: modifyReportSelectedIdx
}

export default connect(mapStateToProps, mapActionsToProps)(ReportListItem)