import React, {Component} from 'react'

class AboutHero extends Component {
    render() {
        return (
            <div style={{ textAlign: "center", fontSize: "48px", fontWeight: "700"}}>
                About Us
            </div>
        )
    }
}

export default AboutHero