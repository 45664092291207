export const jobPostDetails = [
    {
        title: "Front-end Engineer (Remote/NYC/Bangkok, Full Time)",
        desc: "Make our UI more slick, performant, and robust. You will be working on our front-end application at www.lovespreadsheets.com. Written in React, you will have extensive freedom in owning various components, and making the appropriate engineering decisions.",
        roles: ["Write Javascript, HTML, CSS code in React to build & style UI components",
            "Interact with back-end services through REST APIs",
            "Install and manage external libraries as needed",
            "Collaborate with design, back-end, and business teams and resources"],
        about: "",
        experience: ["1-3 years of experience writing HTML, CSS, Javascript code",
            "Expert fluency in HTML, CSS, Javascript",
            "Experience working with front-end frameworks like React, Vue, Angular",
            "React & Redux experience preferred",
            "Redux experience preferred",
            "English fluency required"
        ]
    },
    {
        title: "Marketing Analyst (Bangkok, Full Time/Contract)",
        desc: "Spread the message to all data, business, and engineering people that there is a new AI solution to do Data Analysis. You will be responsible for brainstorming creative new ways to relay our messaging. As well as using traditional approaches like content creation, and social media",
        roles: ["Create content in written and video form regarding Data & AI", "Own our email list and email cadence", "Post on social media regarding product & company updates, as well as micro-content", "Brainstorm creative ideas to relay our message"],
        about: "",
        experience: ["1-3 years of experience working in Marketing for a Software company", "Native or expert English fluency", "Creative writing experience required", "Video production experience preferred", "Strong writing skills required"]
    },
    // {
    //     title: "Back-end Engineer (Remote, Contract)",
    //     desc: "",
    //     roles: [""],
    //     about: "",
    //     experience: [""]
    // }
]