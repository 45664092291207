import React, {Component} from 'react'
import {connect} from 'react-redux'
import {getSelectedDbTablesAndColumns} from "../../../../Constants/getSelectedDbTablesAndColumns";
import API_Root from "../../../../Constants/API_Root";
import {toggleModal} from "../../../../Actions/ToggleModal";
import {toggleToast} from "../../../../Actions/ToggleToast";
import {modifyAllDatabases} from "../../../../Actions/ModifyAllDatabases";
import Select from 'react-select'

class DatabaseNoCodeIndex extends Component {
    constructor(props) {
        super(props);

        this.state = this.initDBState();
    }

    getDefaultState = () => ({
        'tableSelected': null,
        'columnsSelected': []
    })

    initDBState = () => {
        return this.props.initState(this.getDefaultState())
    }

    getDbInfo = () => (this.props.allDatabases[this.props.dbSelectedIdx])

    refreshTablesAndColumns = () => {
        this.props.toggleModal("loadingModalRefreshTables");

        const error = () => {
            this.props.toggleModal(null);

            this.props.toggleToast({
                show: true,
                type: "error",
                message:"There was an error getting your tables and columns. Please contact our support"
            })
        }

        const dbInfo = this.getDbInfo()

        fetch(API_Root + "api/refresh-database-tables-and-columns/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                token: localStorage.getItem("token"),
                email: localStorage.getItem("email"),
                pk: dbInfo.pk
            })
        }).then(res => {
            if (res.status === 200){
                res.json().then(data => {
                    const copiedAllDBs = JSON.parse(JSON.stringify(this.props.allDatabases));
                    const dbInfo = copiedAllDBs[this.props.dbSelectedIdx];

                    dbInfo.tablesAndColumnInfo = data

                    copiedAllDBs[this.props.dbSelectedIdx] = dbInfo

                    this.props.modifyAllDatabases(copiedAllDBs)

                    this.props.toggleModal(null)

                    this.props.toggleToast({
                        show: true,
                        type: "success",
                        message:"Tables and columns for your database read successfully"
                    })
                }).catch(err => error())
            } else {
                error()
            }
        }).catch(err => error())
    }

    changeTable = (tableObj) => {
        const newState = { tableSelected: tableObj.value, columnsSelected: [] }

        this.setState(newState)
        this.props.keepParamsUpdated(newState)
    }

    toggleColumnSelected = (colName) => {
        const currColumns = JSON.parse(JSON.stringify(this.state.columnsSelected));

        if (currColumns.includes(colName)){
            currColumns.splice(currColumns.indexOf(colName), 1)
        } else {
            currColumns.push(colName)
        }

        const newState = { columnsSelected: currColumns }

        this.setState(newState)
        this.props.keepParamsUpdated(newState)
    }

    getColumns = (dbTablesAndCols) => {
        const allColumns = dbTablesAndCols[this.state.tableSelected];

        let allColumnsCheckboxes = [];

        for (let i=0; i<allColumns.length; i++){
            const colName = allColumns[i]['Field'];
            const id = this.state.tableSelected + "-" + colName + "-col-checkbox";

            allColumnsCheckboxes.push(<div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', columnGap: "8px"}} key={colName}>
                <div>
                    <input type="checkbox"
                           id={id}
                           checked={this.state.columnsSelected.includes(colName)}
                           onClick={e => this.toggleColumnSelected(colName)}
                           style={{ cursor: 'pointer' }}
                   />
                </div>
                <div>
                    <label htmlFor={id} style={{ cursor: 'pointer' }}>
                        {colName}
                    </label>
                </div>
            </div>)
        }

        return allColumnsCheckboxes
    }

    render() {
        const dbTablesAndCols = getSelectedDbTablesAndColumns(this.props.dbSelectedIdx, this.props.allDatabases)

        if (dbTablesAndCols === null){
            return (
                <div>
                    Couldn't read your tables and columns so the AI will not work. <span className="linkStyle"  onClick={this.refreshTablesAndColumns}>Try again to read your tables and columns</span>
                </div>
            )
        } else {
            let tableOptions = [];

            for (let tableName in dbTablesAndCols){
                tableOptions.push({value: tableName, label: tableName})
            }

            return (
                <div>
                    <div style={{ marginBottom: "24px"}}>
                        <div style={this.props.labelHeaderStyle}>
                            Select Database Table
                        </div>
                        <Select
                            onChange={this.changeTable}
                            value={{value: this.state.tableSelected, label: this.state.tableSelected}}
                            options={tableOptions}
                        />
                    </div>
                    {
                        this.state.tableSelected === null ?
                            null :
                            <div style={{ marginBottom: "24px"}}>
                                <div style={this.props.labelHeaderStyle}>
                                    Select Columns
                                </div>
                                <div>
                                    {this.getColumns(dbTablesAndCols)}
                                </div>
                            </div>
                    }
                </div>
            )
        }

    }
}

const mapStateToProps = (state) => ({
    allDatabases: state.mainState.allDatabases,
    dbSelectedIdx: state.mainState.dbSelectedIdx
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    toggleToast: toggleToast,
    modifyAllDatabases: modifyAllDatabases
}

export default connect(mapStateToProps, mapActionsToProps)(DatabaseNoCodeIndex)