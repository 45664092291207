import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../../Constants/styles";
import {modifySidebarSelection} from "../../Actions/ModifySidebarSelection";

class ChooseDBOrReport extends Component {
    constructor(props) {
        super(props);
    }

    getClickableComponent = (componentType) => {
        const segmentStyle = { backgroundColor: "white",
            padding: "12px",
            border: "1px solid #F0F0F0",
            borderRadius: "4px",
            boxShadow: "0px 5px 6px -4px rgba(2, 34, 19, 0.02)",
            textAlign: "center"
        }

        const icons = {
            databaseList: "fa-solid fa-database",
            file: "fa-solid fa-file-lines",
            connectDatabase: "fa-solid fa-plug"
        }

        const title = {
            databaseList: "Choose a Database to get data",
            file: "Choose a Report to view data",
            connectDatabase: "Connect a Database to get data"
        }

        const btnText = {
            databaseList: "Choose a Database",
            file: "Choose a Report",
            connectDatabase: "Connect a Database"
        }

        let btn;
        if (this.props.sidebarSelection !== componentType){
            btn = <button style={{
                backgroundColor: styles.blue,
                cursor: "pointer",
                color: "white",
                border: "none",
                borderRadius: "4px",
                fontSize: "16px",
                width: "100%",
                outline: "none",
                padding: "6px 16px" }}
                onClick={e => this.props.modifySidebarSelection(componentType)}
            >
                {btnText[componentType]}
            </button>
        } else {
            btn = <div style={{
                border: "none",
                padding: "6px 16px",
                fontSize: "16px",
                width: '100%',
                color: styles.subText
            }}>
                <i className="fa-solid fa-arrow-left" /> {btnText[componentType]} from the sidebar
            </div>
        }

        return <div style={segmentStyle}>
            <div style={{ fontSize: "20px", fontWeight: "700", color: styles.mainText}}>
                {title[componentType]}
            </div>
            <div style={{ marginTop: "16px", fontSize: "24px"}}>
                <i className={icons[componentType]} />
            </div>
            <div style={{ marginTop: "16px"}}>
                {btn}
            </div>
        </div>
    }

    render() {
        let dbPicker;

        if (this.props.allDatabases.length === 0){
            dbPicker = this.getClickableComponent("connectDatabase")
        } else {
            dbPicker = this.getClickableComponent("databaseList")
        }

        const overallStyle = {padding: "12px 24px 8px"}

        if (this.props.allDBReports.length > 0){
            return (
                <div style={overallStyle}>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr auto 1fr", columnGap: "16px"}}>
                        <div>
                            {dbPicker}
                        </div>
                        <div style={{ paddingTop: "24px"}}>
                            <div style={{ fontSize: "20px", fontWeight: "700", color: styles.mainText}}>
                                OR
                            </div>
                        </div>
                        <div>
                            {this.getClickableComponent("file")}
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={overallStyle}>
                    <div style={{ maxWidth: "50%"}}>
                        {dbPicker}
                    </div>
                </div>
            )
        }

    }
}

const mapStateToProps = (state) => ({
    allDBReports: state.mainState.allDBReports,
    allDatabases: state.mainState.allDatabases,
    sidebarSelection: state.mainState.sidebarSelection
})

const mapActionsToProps = {
    modifySidebarSelection: modifySidebarSelection
}

export default connect(mapStateToProps, mapActionsToProps)(ChooseDBOrReport)