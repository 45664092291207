import React, { Component } from 'react';
import API_ROOT from "../Constants/API_Root";
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import {toggleModal} from "../Actions/ToggleModal";
import isObjectEmpty from "../Constants/isObjectEmpty";
import {toggleIsAuth} from "../Actions/ToggleIsAuth";
import Button from "../SharedComponents/Button";
import BannerAlert from "../SharedComponents/BannerAlert";
import styles from "../Constants/styles";
import GoogleSignIn from "./GoogleSignIn"
import {getModalInfoValue} from "../Constants/getModalInfoValue";

class LoginForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            email: this.initEmail(),
            password: '',
            error: null,
            loading: false,
            url: "/"
        }

        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.login = this.login.bind(this);
        this.forgetPassword = this.forgetPassword.bind(this);
        this.dismissMessage = this.dismissMessage.bind(this);
    }

    initEmail = () => {
        const url = window.location.href;

        if (url.includes("&email=")){
            return url.substring(url.indexOf("&email=") + "&email=".length, url.length).trim()
        } else { return '' }
    }


    handleKeyPress(e){
        if (e.charCode === 13){
            if (!(this.state.password.trim() === "" || this.state.email.trim() === "")){
                e.preventDefault()
                this.login(e)
            }
        }
    }

    handleInputChange(e){
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    changeState = (newState) => this.setState(newState)

    login(e, emailP=null, passwordOneP=null, googleInfo=null){
        this.setState({
            loading: true
        })

        let password = this.state.password
        let email = emailP === null ? this.state.email : emailP

        const API = API_ROOT + "api/login/"

        const errorMessageObject = {
            message: <p>There was a problem with your login. Check your email and password and try again!<br/><span className="linkStyle" onClick={e => this.props.toggleModal("signUpModal")}>Sign up</span> if you don't have an account</p>
        };

        const error = () => this.setState({ loading: false, error: errorMessageObject })

        fetch(API, {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: email,
                password: password,
                googleInfo: googleInfo
            })
        }).then((res) => {
            if (res.status === 200){
                res.json().then((data) => {
                    localStorage.setItem("email", email.toLowerCase().trim());
                    localStorage.setItem("token", data.token);

                    this.setState({
                        loading: false,
                        error: null
                    }, () => {
                        this.props.toggleIsAuth(true);
                        this.props.toggleModal(null)

                        const nextURL = getModalInfoValue(this.props.modalInfo, 'nextLink', '/')
                        window.location.href = nextURL
                        // this.setState({
                        //     url: nextURL
                        // }, () => {
                        //     this.goHomeLink.click();
                        //     window.location.reload()
                        // })
                    })
                }).catch((err) => error());
            } else{
                error()
            }
        }).catch(err => error())
    };

    forgetPassword(e){
        this.props.toggleModal(null);
        this.props.toggleModal("forgetPasswordModal")
    }

    dismissMessage(){
        this.setState({
            error: null
        })
    }

    render(){
        const {
            email,
            password,
            error,
        } = this.state;

        const isInvalid =
            password === '' ||
            email === '';

        let errorMessage = null;

        if (error !== null){
            if ("message" in error){
                errorMessage = <BannerAlert
                    icon='bx bx-error'
                    close={this.dismissMessage}
                    content=""
                    action="close"
                    header={error.message}
                    type="error"
                />
            }
        }

        let headerText = "Log In";
        let btnText = "Log In"
        let extraInfo = null;

        if ('modal' in this.props){
            if (this.props.modalInfo !== null) {
                if (typeof this.props.modalInfo === "object") {
                    if (!(isObjectEmpty(this.props.modalInfo))) {
                        if ("source" in this.props.modalInfo){
                            if (this.props.modalInfo.source === "cancelButton"){
                                headerText = "Re-authenticate"
                                btnText = "Re-authenticate Account"
                                extraInfo = <p style={{fontSize: '110%', color: 'red'}}>You can cancel your account after re-authentication</p>
                            }
                        }
                    }
                }
            }
        }

        return(
            <div style={{ background: "white", padding: "16px" }} onKeyPress={this.handleKeyPress}>
                <a href={this.state.url} ref={goHomeLink => this.goHomeLink = goHomeLink} style={{ display: "none" }}> </a>
                <div style={{ fontSize: "24px", fontWeight: "700"}}>
                    {headerText}
                </div>
                <br/>
                <div style={{ fontSize: "16px", fontWeight: "700"}}>
                    Enter your email
                </div>
                <input
                       id="loginEmail"
                       placeholder="your.email@domain.com"
                       className="inputGray"
                       type="text" name="email" value={email}
                       onChange={this.handleInputChange}
                       style={{width: "100%"}}
                />
                <br/><br/>
                <div style={{ fontSize: "16px", fontWeight: "700"}}>
                    Enter your password
                </div>
                <input
                       id="loginPassword"
                       placeholder="********"
                       className="inputGray"
                       type="password" name="password" value={password}
                       onChange={this.handleInputChange}
                       style={{width: '100%'}}
                />
                <br/><br/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Button loading={this.state.loading}
                            style={{width: "100%", textAlign: "center", backgroundColor: "white", color: styles.mainGreen, border: "1px solid " + styles.mainGreen}}
                            disabled={isInvalid}
                            label={btnText}
                            id="loginButton"
                            onKeyPress={this.handleKeyPress}
                            onClick={this.login} />
                </div>
                {/*<hr/>*/}
                {/*<GoogleSignIn signUp={this.login} changeState={this.changeState} login/>*/}
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    {extraInfo}
                    <p className="linkStyle" onClick={this.forgetPassword}>Forgot password?</p>
                </div>
                {errorMessage}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo
})

const mapActionsToProps = {
    navigateTo: push,
    toggleModal: toggleModal,
    toggleIsAuth: toggleIsAuth
}

export default connect(mapStateToProps, mapActionsToProps)(LoginForm);
