import React, {Component} from 'react'
import {scheduleCallText} from "../../Constants/scheduleCallText";
import styles from "../../Constants/styles";
import {integrateFAQs} from "./integrateFAQs";
import FAQAccordion from "../../FAQs/FAQAccordion";

class IntegrateBody extends Component {
    render() {
        const iconStyle = { fontSize: "12px", paddingRight: "4px", color: styles.mainGreen}
        const divStyle = { marginTop: "4px" }
        const isMobile = 'isMobile' in this.props;
        const headerStyle = { fontSize: "24px", fontWeight: "700"}

        return (
            <div>
                <div>
                    <div style={headerStyle}>How we integrate other data sources</div>
                    <div style={divStyle}><i className="fa-solid fa-circle-check" style={iconStyle}></i> {scheduleCallText("Schedule a call")} with us to discuss your data sources and requirements</div>
                    <div style={divStyle}><i className="fa-solid fa-circle-check" style={iconStyle}></i> We create a plan on how to best link your data sources with our web app</div>
                    <div style={divStyle}><i className="fa-solid fa-circle-check" style={iconStyle}></i> Approve the plan and we build the integration for you</div>
                </div>
                <div style={{ marginTop: isMobile ? "24px" : "48px"}}>
                    <div style={headerStyle}>FAQs</div>
                    {integrateFAQs.map((x, idx) => <div style={{ marginBottom: "16px" }} key={idx}>
                        <FAQAccordion question={x.question} answer={x.answer} open/>
                    </div>)}
                </div>
            </div>
        )
    }
}

export default IntegrateBody