import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../../../Constants/styles";
import Select from 'react-select'

class AuthItemsMenu extends Component {
    constructor(props) {
        super(props);
    }

    changeSelectedAuthCategory = (authCategoryObj) => {
        this.props.changeState({
            selectedAuthCategory: authCategoryObj.value
        })
    }

    getAuthCategoryOptions = () => {
        return this.props.authCategories.map(x => ({value: x, label: x}))
    }

    render() {
        return (
            <div>
                <Select
                    onChange={this.changeSelectedAuthCategory}
                    value={{'value': this.props.selectedAuthCategory, 'label': this.props.selectedAuthCategory}}
                    options={this.getAuthCategoryOptions()}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(AuthItemsMenu)