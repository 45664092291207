import React, {Component} from 'react'
import {images} from "../Constants/images";
import {modifyAppName} from "../Actions/ModifyAppName";
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

class NavbarLogo extends Component {
    goHome = (e) => {
        this.props.modifyAppName("home")
        this.props.navigateTo("/")
    }

    render() {
        const isSidebar = 'isSidebar' in this.props ? this.props.isSidebar : false

        return (
            <a style={{ textDecoration: "none" }} className="navbarLogo" href="/">
                <img src={isSidebar ? images.logoWhite : images.logoNav} alt="Logo" style={{
                    height: "62px",
                    /*Full width: 1884px*/
                    width: "auto",
                    padding: "12px 0 12px 16px",
                    cursor: "pointer"
                }}/>
            </a>
        )
    }
}

const mapActionsToProps = {
    modifyAppName: modifyAppName,
    navigateTo: push
}

export default connect(null, mapActionsToProps)(NavbarLogo)