import React, {Component} from 'react';
import BlogHero from './BlogHero';
import { isMobile } from 'react-device-detect'
import FadeIn from 'react-fade-in';
import UseCaseCard from "./UseCaseCard";
import PowerApps from "../Illustrations/PowerApps.svg"
import webform from "../Illustrations/webform.svg"
import Aws from "../Illustrations/Aws.svg"
import Webdev from "../Illustrations/Webdev.svg"
import IntegrateSources from "../Illustrations/IntegrateSources.svg"
import Code from "../Illustrations/Code.svg"
import Dashboard from "../Illustrations/Dashboard.svg"
import Charts from "../Illustrations/Charts.svg"
import Forms from "../Illustrations/Forms.svg"
import clean from "../Illustrations/clean.svg"
import geocode1 from "../Illustrations/geocode1.svg"
import ultimate from "../Illustrations/ultimate.svg"
import Merge from "../Illustrations/Merge.svg"
import combine from "../Illustrations/combine.svg"
import csv from "../Illustrations/csv.svg"
import guide from "../Illustrations/guide.svg"
import SQL from "../Illustrations/SQL.svg"
import UltimateDateTimeGuide from "../Illustrations/UltimateDateTimeGuide.svg"
import joke from "../Illustrations/joke.svg"
import data from "../Illustrations/data.svg"
import join from "../Illustrations/join.svg"
import spreadsheets from "../Illustrations/spreadsheets.svg"
import { push } from 'react-router-redux'
import { connect } from 'react-redux'

class BlogIndex extends Component {
    constructor(props){
        super(props);

        this.blogLink.click()
    }

    render() {
        return (
            <div id="guidesIndex">
                <a href="https://lovespreadsheets.medium.com/" style={{ display: "none" }} ref={blogLink => this.blogLink = blogLink}> </a>
                <BlogHero/>
                <br/><br/>
                <div className="tutorialsPreviewDiv">
                    <div className="row">
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Use all spreadsheet data in your apps"
                                    url="blog/react-site-with-no-backend"
                                    img={PowerApps}
                                    landing={false}
                                    tutorialInfo={{heading: "Power Apps", writtenBy: "Astha Sharma", date: "2020-06-09"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Save data from web forms to spreadsheets"
                                    url="blog/save-web-form-data"
                                    img={Forms}
                                    landing={false}
                                    tutorialInfo={{heading: "Web Forms", writtenBy: "Adhaar Sharma", date: "2020-06-10"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Create a live dashboard with Google Sheets"
                                    url="blog/dashboard-with-google-sheets"
                                    img={Charts}
                                    landing={false}
                                    tutorialInfo={{heading: "Visualize", writtenBy: "Adhaar Sharma", date: "2020-05-31"}}
                                />
                            </FadeIn>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Collaborate with your fellow Data Analysts using spreadsheets"
                                    url="blog/share-spreadsheet-data"
                                    img={Dashboard}
                                    landing={false}
                                    tutorialInfo={{heading: "Share", writtenBy: "Adhaar Sharma", date: "2020-06-19"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Learn about an API by experimenting with Your spreadsheet data"
                                    url="blog/what-is-an-api"
                                    img={Code}
                                    landing={false}
                                    tutorialInfo={{heading: "Learn", writtenBy: "Clarissa Ng", date: "2020-06-06"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Integrate your Google Sheets, Dropbox and Local Spreadsheets"
                                    url="blog/integrate-google-sheets-dropbox-local-files"
                                    img={IntegrateSources}
                                    landing={false}
                                    tutorialInfo={{heading: "Integrate", writtenBy: "Adhaar Sharma", date: "2020-06-03"}}
                                />
                            </FadeIn>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Make a custom website using Google Sheets and HTML"
                                    url="blog/custom-html-sheets"
                                    img={Webdev}
                                    landing={false}
                                    tutorialInfo={{heading: "Custom Website", writtenBy: "Astha Sharma", date: "2021-02-11"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Deploy your websites using AWS and Google Domains"
                                    url="blog/deploy-website-using-aws-google-domain"
                                    img={Aws}
                                    landing={false}
                                    tutorialInfo={{heading: "Deploying", writtenBy: "Astha Sharma", date: "2021-02-11"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="The Ultimate Guide to creating & styling HTML Forms"
                                    url="blog/ultimate-guide-to-html-forms"
                                    img={webform}
                                    landing={false}
                                    tutorialInfo={{heading: "HTML Forms", writtenBy: "Astha Sharma", date: "2021-02-19"}}
                                />
                            </FadeIn>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Quickstart guide of Clean Spreadsheets"
                                    url="blog/clean-spreadsheets-quickstart"
                                    img={clean}
                                    landing={false}
                                    tutorialInfo={{heading: "Clean Spreadsheets", writtenBy: "Adhaar Sharma", date: "2020-08-19"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="The Ultimate Guide to Geocoding Tools"
                                    url="blog/ultimate-guide-to-geocoding-tools"
                                    img={ultimate}
                                    landing={false}
                                    tutorialInfo={{heading: "Geocode Tools", writtenBy: "Clarissa Ng", date: "2020-08-19"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Get Started With Geocoding"
                                    url="blog/geocode-quickstart"
                                    img={geocode1}
                                    landing={false}
                                    tutorialInfo={{heading: "Geocode Quickstart", writtenBy: "Astha Sharma", date: "2020-08-20"}}
                                />
                            </FadeIn>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Ultimate Guide to Cleaning Date & Time"
                                    url="blog/ultimate-guide-to-clean-date-time"
                                    img={UltimateDateTimeGuide}
                                    landing={false}
                                    tutorialInfo={{heading: "Clean Date & Time", writtenBy: "Adhaar Sharma", date: "2020-10-09"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Easily combine multiple excel sheets"
                                    url="blog/merge-excel-sheets"
                                    img={Merge}
                                    landing={false}
                                    tutorialInfo={{heading: "Merge Excel Sheets", writtenBy: "Adhaar Sharma", date: "2020-06-09"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Easily combine multiple excel files"
                                    url="blog/combine-excel-files"
                                    img={combine}
                                    landing={false}
                                    tutorialInfo={{heading: "Combine Excel Files", writtenBy: "Adhaar Sharma", date: "2020-06-09"}}
                                />
                            </FadeIn>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Ultimate Guide to Cleaning Date & Time"
                                    url="blog/merge-csv-files"
                                    img={csv}
                                    landing={false}
                                    tutorialInfo={{heading: "Merge CSV Files", writtenBy: "Adhaar Sharma", date: "2020-10-09"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Every possible way to merge multiple spreadsheets"
                                    url="blog/ultimate-guide-to-merging-spreadsheets"
                                    img={guide}
                                    landing={false}
                                    tutorialInfo={{heading: "Ultimate Guide to Merging", writtenBy: "Clarissa Ng", date: "2020-06-26"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="An introduction to SQL Joins"
                                    url="blog/SQL-Join"
                                    img={SQL}
                                    landing={false}
                                    tutorialInfo={{heading: "SQL Joins", writtenBy: "Clarissa Ng", date: "2020-08-11"}}
                                />
                            </FadeIn>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="31 Best Spreadsheet Memes"
                                    url="blog/31-best-spreadsheet-memes"
                                    img={joke}
                                    landing={false}
                                    tutorialInfo={{heading: "Memes", writtenBy: "Astha Sharma", date: "2021-03-02"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title=""
                                    url="blog/customer-data-infographic"
                                    img={data}
                                    landing={false}
                                    tutorialInfo={{heading: "Customer Data Infographic", writtenBy: "Astha Sharma", date: "2021-03-26"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title=""
                                    url="blog/SQL-Join-infographic"
                                    img={join}
                                    landing={false}
                                    tutorialInfo={{heading: "SQL Join Infographic", writtenBy: "Astha Sharma", date: "2021-04-11"}}
                                />
                            </FadeIn>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Essential Excel Functions Everyone Should Know"
                                    url="blog/13-essential-excel-functions-everyone-should-know"
                                    img={spreadsheets}
                                    landing={false}
                                    tutorialInfo={{heading: "13 Essential Excel Functions", writtenBy: "Astha Sharma", date: "2021-11-02"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Why You Should Clean Your Spreadsheet Data"
                                    url="blog/when-why-clean-your-spreadsheet-data"
                                    img={clean}
                                    landing={false}
                                    tutorialInfo={{heading: "6 Reasons to Clean Your Data", writtenBy: "Astha Sharma", date: "2021-07-19"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            {/*<FadeIn>*/}
                            {/*    <UseCaseCard*/}
                            {/*        title=""*/}
                            {/*        url="blog/SQL-Join-infographic"*/}
                            {/*        img={join}*/}
                            {/*        landing={false}*/}
                            {/*        tutorialInfo={{heading: "SQL Join Infographic", writtenBy: "Astha Sharma", date: "2021-04-11"}}*/}
                            {/*    />*/}
                            {/*</FadeIn>*/}
                        </div>
                    </div>



                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({

})

const mapActionsToProps = {
    navigateTo: push
}

export default connect(mapStateToProps, mapActionsToProps)(BlogIndex)