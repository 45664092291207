import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../Constants/styles";
import EditableText from "../SharedComponents/EditableText"

class TeamName extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{ fontSize: "16px", fontWeight: "700", color: styles.subText}}>
                <EditableText
                    text={this.props.teamName.trim() !== "" ? this.props.teamName : "NO TEAM NAME"}
                    textType="teamName"
                    idx={this.props.teamSelectedIdx}
                    pk={this.props.selectedTeamPK}
                    input
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    selectedTeamPK: state.mainState.selectedTeamPK,
    teamSelectedIdx: state.mainState.teamSelectedIdx
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(TeamName)