import React, {Component} from 'react'
import MenuSegment from "../SharedComponents/MenuSegment"
import {faqTexts} from "./faqTexts";
import FAQAccordion from "./FAQAccordion";
import CircleIcon from "../SharedComponents/CircleIcon";
import styles from "../Constants/styles";
import IconTitle from "../SharedComponents/IconTitle";

const menuItems = [
    {name: "general", label: "General", icon: "fas fa-question"},
    {name: "upload", label: "Upload Files", icon: "fas fa-upload"},
    {name: "ask", label: "Asking Questions", icon: "fas fa-brain"},
    {name: "profile", label: "Lifetime Plan", icon: "fas fa-credit-card"},
    {name: "integrate", label: "Integrate Data Sources", icon: "fas fa-database"},
]

class FAQsModalBody extends Component {
    constructor(props) {
        super(props);

        this.state = {
            faqTopic: "general"
        }
    }

    changeMenuItem = (e, menuItem) => this.setState({ faqTopic: menuItem })

    render() {

        return (
            <div>
                <div>
                    <IconTitle
                        icon={<CircleIcon
                            icon="fa-solid fa-question"
                            circleColor={styles.red}
                            color="white"
                            solid
                        />}
                        label="Frequently Asked Questions"
                    />
                </div>
                <div className="row"  style={{ padding: "32px 16px 32px 0"}}>
                    <div className="col-sm-3">
                        <div className="menuDiv">
                            <div style={{ fontFamily: "Lato", fontWeight: "700", fontSize: "12px"}}>
                                Categories
                            </div>
                            {menuItems.map((x, idx) => <MenuSegment icon={x.icon} name={x.name} menuItem={this.state.faqTopic} label={x.label} onClick={this.changeMenuItem} key={idx}/>)}
                        </div>
                    </div>
                    <div className="col-sm-9">
                        <div>
                            {faqTexts[this.state.faqTopic].map((x, idx) => <div style={{ marginBottom: "16px" }} key={idx}>
                                <FAQAccordion question={x.question} answer={x.answer}/>
                            </div>)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FAQsModalBody