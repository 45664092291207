import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../../../../Constants/styles";
import API_Root from "../../../../Constants/API_Root";
import loadScript from 'load-script';
import {toggleModal} from "../../../../Actions/ToggleModal";
import {modifyModalInfo} from "../../../../Actions/ModifyModalInfo";
import {modifyUserIntegrations} from "../../../../Actions/ModifyUserIntegrations";

// const GOOGLE_SDK_URL = 'https://apis.google.com/js/api.js';
const GOOGLE_SDK_URL = 'https://accounts.google.com/gsi/client';

class AddGoogleAccount extends Component {
    constructor(props){
        super(props);

        this.state = {
            clientId: '307583181930-rsajrsor5s7p6964flj1ea6rkcgji29d.apps.googleusercontent.com',
            developerKey: 'AIzaSyA9vSMxH1KT-cqNQCkz6Li-4w4T8sV4uWc',
            scope:['https://www.googleapis.com/auth/drive.file', 'https://www.googleapis.com/auth/spreadsheets'],
            onChange: data => this.onChange(data),
            onAuthFailed: data => console.log('on auth failed:', data),
            multiselect: true,
            navHidden: false,
            authImmediate: false,
            mimeTypes: ['application/vnd.google-apps.spreadsheet'],
            query: '',
            viewId:'DOCS',
            origin: API_Root.includes("api") ? 'https://lovespreadsheets.com' : 'http://localhost:2000',
            email: ''
        }
    }

    componentDidMount(){
        loadScript(GOOGLE_SDK_URL, () => {
            console.log("loaded")
        })
    }

    addGoogleAccount = () => {
        const scope = this.state.scope;
        const error = (err) => alert(err)

        const res = window.google.accounts.oauth2.initCodeClient({
            client_id: this.state.clientId,
            scope: scope.join(" ") + " profile email",
            error_callback: (err) => {
                error(err)
            },
            callback: (authresult) => {
                if (authresult.code){
                    const code = authresult.code
                    fetch(API_Root + "api/api-store-google-sheets-auth/",
                        {
                            method: 'POST',
                            headers: {
                                Accept: 'application/json, text/plain, */*',
                            },
                            body: JSON.stringify({
                                email: localStorage.getItem("email"),
                                token: localStorage.getItem("token"),
                                code: code
                            })
                        }).then(res => {
                            if (res.status === 200){
                                res.json().then(data => {
                                    this.setState({
                                        email: data.email
                                    }, () => {
                                        this.props.modifyUserIntegrations(data.integrations);
                                    })
                                }).catch(err => error(err))
                            }  else {
                                error(res.status)
                            }
                    }).catch(err => error(err))
                } else {
                    error("No code")
                }
            }
        })

        res.requestCode()
    }

    render(){
        return (
            <div
                style={{
                    backgroundColor: styles.mainGreen,
                    color: "white",
                    width: "100%",
                    borderRadius: "4px",
                    padding: "8px 16px",
                    textAlign: "center", cursor: "pointer"}}
                onClick={this.addGoogleAccount}
            >
                <i className="fa-brands fa-google"></i> <span style={{ paddingLeft: "8px"}}>Choose Google Account</span>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    isAuth: state.mainState.isAuth,
    userIntegrations: state.mainState.userIntegrations,
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo,
    modifyUserIntegrations: modifyUserIntegrations,
}

export default connect(mapStateToProps, mapActionsToProps)(AddGoogleAccount)