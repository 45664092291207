import React, {Component} from 'react'
import styles from "../../Constants/styles";

class PerkTexts extends Component {
    render() {
        return (
            <div style={{ paddingBottom: "16px"}}>
                <div>
                    <span><i className={this.props.info.icon} style={{ color: this.props.iconColor }}/></span><span style={{ fontWeight: "700", fontSize: "16px", color: styles.mainText, paddingLeft: "6px" }}> {this.props.info.header}</span>
                </div>
                <div style={{ paddingTop: "4px", fontSize: "14px", color: styles.subText }}>
                    {this.props.info.desc}
                </div>
            </div>
        )
    }
}

export default PerkTexts