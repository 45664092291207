export const MODIFY_AI_FILES = 'MODIFY_AI_FILES';

export function modifyAiFiles(aiFiles, updateAllFiles=true){
    return{
        type: MODIFY_AI_FILES,
        mainState: {
            aiFiles: aiFiles,
            updateAllFiles: updateAllFiles
        }
    }
}