import React, {Component} from 'react'
import {connect} from 'react-redux'
import API_Root from "../../Constants/API_Root";
import Button from "../../SharedComponents/Button";
import styles from "../../Constants/styles";
import {toggleToast} from "../../Actions/ToggleToast";
import {getPricingInfoFromUserPlan} from "../../Constants/getPricingInfoFromUserPlan";

class DownloadResultsBtn extends Component {
    constructor(props) {
        super(props);

        this.state = {
            text: '',
            inputValue: '',
            fetching: false,
            isError: false,
            status: -1,
        }
    }

    getReport = (e) => {
        this.setState({
            fetching: true,
            text: '',
            isError: false,
            status: -1,
        })

        const websocketRoute = API_Root.substring(0, 5) === "https" ? API_Root.replace("https", "wss") : API_Root.replace("http", "ws");
        const socket = new WebSocket(websocketRoute + "ws/api/download-love-db-spreadsheets/");

        socket.onopen = (event) => {
            socket.send(JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                reportPK: this.props.reportInfo['pk'],
            }))
        }

        socket.onmessage = (event) => {
            const reply = JSON.parse(event.data);
            console.log(reply)
            // const downloadURL = reply['downloadURL'];
            let fetching = true;

            if (reply['isError'] || (!reply['isError'] && reply['status'] === 100)){
                fetching = false
            }

            this.setState({
                fetching: fetching,
                text: reply['text'],
                isError: reply['isError'],
                status: reply['status']
            })


            if (!reply['isError'] && reply['status'] === 100){
                this.downloadLink.click()

                let rowsLeftBehind;
                try{
                    rowsLeftBehind = reply['rowsLeftBehind']

                    if (rowsLeftBehind === null){
                        rowsLeftBehind = 0
                    }
                } catch (e) {
                    rowsLeftBehind = 0
                }

                if (rowsLeftBehind > 0){
                    const pricingInfo = getPricingInfoFromUserPlan(this.props.userPlan)
                    let rowsLimit = 0;
                    try{
                        rowsLimit = pricingInfo['rowsPerReport']
                    } catch (e) {
                        rowsLimit = 0
                    }

                    if (rowsLimit !== 0){
                        this.props.toggleToast({ show: true, type: "warn", message: "Your plan only supports " + rowsLimit.toString() + " rows per report. " + rowsLeftBehind.toString() + " rows were NOT included in this report. Please upgrade to allow all rows to be included" })
                    }

                }
            }

            if (reply['isError'] || (!reply['isError'] && reply['status'] === 100)){
                socket.close()
            }
        }
    }


    render() {
        const downloadURL = this.state.text;

        return (
            <div>
                <a style={{display: 'none'}} ref={downloadLink => this.downloadLink = downloadLink}
                   href={downloadURL}>Download hidden</a>
                <Button style={{
                    backgroundColor: styles.blue,
                    cursor: "pointer",
                    color: "white",
                    border: "1px solid " + styles.blue,
                    borderRadius: "4px",
                    fontSize: "16px",
                    outline: "none",
                    padding: "6px 16px" }}
                        small
                        label={<span><i className="fa-solid fa-table"></i> <span style={{ paddingLeft: "4px" }}>Download as Excel</span></span>}
                        loading={this.state.fetching}
                        onClick={this.getReport}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userPlan: state.mainState.userPlan
})

const mapActionsToProps = {
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(DownloadResultsBtn)