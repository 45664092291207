import React, {Component} from 'react'
import {connect} from 'react-redux'
import API_Root from "../Constants/API_Root";
import TestDatabaseMessage from "./TestDatabaseMessage";
import ConnectDatabase from "./ConnectDatabase";
import {modifyAllDatabases} from "../Actions/ModifyAllDatabases";

class EditDatabaseParameters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editDesc: "",
            isEditingDesc: false,
            saving: false,
            errorTest: null,
            dbAuthInfo: null
        }
    }

    // saveDesc = (e) => {
    //     this.setState({ saving: true });
    //
    //     const databasePK = getDatabasePKFromURL(window.location.href);
    //     const urlEnd = "api/edit-db-info/" + databasePK.toString() + "/"
    //
    //     const error = () => this.setState({ isEditingDesc: false, editDesc: "", saving: false }, () => alert("There was an error saving. Please try again!"))
    //
    //     fetch(API_Root + urlEnd, {
    //         method: 'POST',
    //         headers: {
    //             Accept: 'application/json, text/plain, */*',
    //         },
    //         body: JSON.stringify({
    //             email: localStorage.getItem("email"),
    //             token: localStorage.getItem("token"),
    //             editType: "description",
    //             value: { description: this.state.editDesc }
    //         })
    //     }).then((res) => {
    //         if (res.status === 200){
    //             const allDBInfo = JSON.parse(JSON.stringify(this.props.allDBInfo))
    //             allDBInfo['dbInfo']['description'] = this.state.editDesc
    //             this.props.modifyAllDBInfo(allDBInfo)
    //             this.setState({ isEditingDesc: false, editDesc: "", saving: false })
    //         } else {
    //             error()
    //         }
    //     }).catch(err => error())
    // }

    // undoDesc = (e) => {
    //     this.setState({ editDesc: this.props.allDBInfo['dbInfo']['description'], isEditingDesc: false })
    // }

    testDBConnection = (dbAuthInfo) => {
        // const databasePK = dbAuthInfo['pk'];
        dbAuthInfo['databasetype'] = this.props.dbInfo.authInfo['type']
        dbAuthInfo['pk'] = this.props.dbInfo.pk;

        console.log(dbAuthInfo)
        this.setState({ saving: true, dbAuthInfo: dbAuthInfo });

        const error = (errorObject={connectionValid: false, connectionValidMessage: "There was an error connecting to your database. Please try again!"}) => this.setState({ saving: false, errorTest: errorObject })

        fetch(API_Root + "api/test-db-connection/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify(dbAuthInfo)
        }).then((res) => res.json()).then((data) =>
            {
                error({connectionValid: data.connectionValid, connectionValidMessage: data.connectionValidMessage})

                if (data.connectionValid){
                    const copiedDbInfo = JSON.parse(JSON.stringify(this.props.dbInfo));
                    copiedDbInfo['authInfo'] = {user: this.state.dbAuthInfo['user'], host: this.state.dbAuthInfo['host'], database: this.state.dbAuthInfo['database'], port: this.state.dbAuthInfo['port'], type: this.state.dbAuthInfo['databasetype']}

                    const allDbInfo = JSON.parse(JSON.stringify(this.props.allDatabases));
                    allDbInfo[this.props.dbSelectedIdx] = copiedDbInfo

                    this.props.modifyAllDatabases(allDbInfo)

                }
            }
        ).catch(err => error())
    }

    // saveDBAuthInfo = (e) => {
    //     this.setState({ saving: true });
    //
    //     const databasePK = getDatabasePKFromURL(window.location.href);
    //     const urlEnd = "api/edit-db-info/" + databasePK.toString() + "/"
    //
    //     const error = () => this.setState({ saving: false }, () => alert("There was an error saving. Please try again!"))
    //
    //     fetch(API_Root + urlEnd, {
    //         method: 'POST',
    //         headers: {
    //             Accept: 'application/json, text/plain, */*',
    //         },
    //         body: JSON.stringify({
    //             email: localStorage.getItem("email"),
    //             token: localStorage.getItem("token"),
    //             editType: "authInfo",
    //             value: this.state.dbAuthInfo
    //         })
    //     }).then((res) => {
    //         if (res.status === 200){
    //             const allDBInfo = JSON.parse(JSON.stringify(this.props.allDBInfo))
    //             allDBInfo['dbInfo']['authInfo'] = {user: this.state.dbAuthInfo['user'], host: this.state.dbAuthInfo['host'], database: this.state.dbAuthInfo['database'], port: this.state.dbAuthInfo['port'], type: this.state.dbAuthInfo['databasetype']}
    //             this.props.modifyAllDBInfo(allDBInfo)
    //             this.setState({ saving: false })
    //         } else {
    //             error()
    //         }
    //     }).catch(err => error())
    // }

    render() {
        return (
            <div>
                <div>
                    <ConnectDatabase edit dbInfo={this.props.dbInfo.authInfo} testDBConnection={this.testDBConnection} saving={this.state.saving}/>
                    {
                        this.state.errorTest !== null ?
                            <div>
                                <TestDatabaseMessage
                                    connectionValid={this.state.errorTest.connectionValid}
                                    connectionValidMessage={this.state.errorTest.connectionValidMessage}
                                    edit
                                />
                                {/*<Button style={{marginTop: '10px'}} fluid size="huge" color="purple" onClick={this.saveDBAuthInfo} loading={this.state.saving}><Icon name="save alternate outline" /> Save Updated Details</Button>*/}
                                {/*{*/}
                                    {/*this.state.errorTest.connectionValid ? null*/}
                                        {/*: null*/}
                                {/*}*/}
                            </div>: null
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    allDBInfo: state.mainState.allDBInfo,
    dbSelectedIdx: state.mainState.dbSelectedIdx,
    allDatabases: state.mainState.allDatabases
})

const mapActionsToProps = {
    modifyAllDatabases: modifyAllDatabases
}

export default connect(mapStateToProps, mapActionsToProps)(EditDatabaseParameters)