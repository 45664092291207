import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleModal} from "../Actions/ToggleModal";
import {toggleIsMobileSidebarOpen} from "../Actions/ToggleIsMobileSidebarOpen";

class ModalLink extends Component {
    constructor(props) {
        super(props);
    }

    openModal = () => {
        this.props.toggleIsMobileSidebarOpen(false);
        this.props.toggleModal(this.props.modalName)
    }

    render() {
        return (
            <span onClick={this.openModal}>{this.props.linkText}</span>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {
    toggleModal: toggleModal,
    toggleIsMobileSidebarOpen: toggleIsMobileSidebarOpen
}

export default connect(mapStateToProps, mapActionsToProps)(ModalLink)