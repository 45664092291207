import React, {Component} from 'react'
import loadScript from 'load-script';
import GoogleBtnDarkNormal from "../Illustrations/GoogleBtnDarkNormal.svg"
import API_Root from "../Constants/API_Root";
import { connect } from 'react-redux'
import {toggleModal} from "../Actions/ToggleModal";

const GOOGLE_SDK_URL = 'https://accounts.google.com/gsi/client';

let scriptLoadingStarted = false;

class GoogleSignIn extends Component {
    constructor(props) {
        super(props);

        this.state = {
            clientId: '307583181930-rsajrsor5s7p6964flj1ea6rkcgji29d.apps.googleusercontent.com',
            developerKey: 'AIzaSyA9vSMxH1KT-cqNQCkz6Li-4w4T8sV4uWc',
            // scope:['https://www.googleapis.com/auth/drive.file', 'https://www.googleapis.com/auth/spreadsheets'],

        }
    }

    componentDidMount(){
        scriptLoadingStarted = true;

        loadScript(GOOGLE_SDK_URL, () => {
            console.log("loaded")
        })
    }

    errorFunc = (err) => {
        let msgs;

        if ('login' in this.props){
            msgs = ["log in", 'signUpModal', 'sign up', 'do not have an account']
        } else {
            msgs = ["sign up", 'loginModal', 'log in', 'already have an account']
        }

        this.props.changeState({
            error: { message: <p>There was an error with Google Sign in. Please {msgs[0]} using an email and password or <span className="linkStyle" onClick={e => this.props.toggleModal(msgs[1])}>{msgs[2]}</span> if you {msgs[3]}</p>},
            loading: false
        })
        console.log(err)
    }

    loginGoogle = (e) => {
        this.props.changeState({ loading: true })

        const res = window.google.accounts.oauth2.initTokenClient({
            client_id: this.state.clientId,
            scope: "profile email",
            error_callback: this.errorFunc,
            callback: (authresult) => {
                let accessToken = authresult.access_token;
                fetch("https://www.googleapis.com/oauth2/v1/userinfo?access_token=" + accessToken.toString())
                    .then(res => {
                        if (res.status === 200){
                            res.json().then(data => {
                                this.props.signUp(e, data.email, "", data)
                            }).catch(err => this.errorFunc(err))
                        } else {
                            this.errorFunc(res.status.toString())
                        }
                    }).catch(err => this.errorFunc(err))
            }
        })

        res.requestAccessToken()
    }

    render() {
        return (
            <div style={{ height: '50', width: '240', backgroundColor: '#4285F4', color: "white", display: "grid", gridTemplateColumns: "auto 1fr" }}
                 className="googleSignInBtn"
                 onClick={this.loginGoogle}>
                <div>
                    <img src={GoogleBtnDarkNormal} />
                </div>
                <div className="googleSignInText">
                    {'login' in this.props ? "Sign in with Google" : "Sign up with Google"}
                </div>
            </div>
        )
    }
}

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(null, mapActionsToProps)(GoogleSignIn)