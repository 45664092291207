import React, {Component} from 'react'
import GoogleSheetsSendData from "../../Illustrations/GoogleSheetsSendData-01.svg"
import ModalHeaders from "./ModalHeaders"
import EmailReportIcon from "../../Illustrations/EmailReportIcon-01.svg"
import styles from "../../Constants/styles";
import DownloadExcel from "../../Illustrations/DownloadExcel-01.svg"

class GetDataGoogleSheets extends Component {
    render() {
        const boxStyle = { backgroundColor: "white", padding: "24px", border: "1px solid #F0F0F0", borderRadius: "4px", boxShadow: "0px 5px 6px -4px rgba(2, 34, 19, 0.02)", textAlign: "center"}
        const boxLabel = {
            color: styles.subText,
            fontSize: "18px",
            fontWeight: "700",
            marginBottom: "12px"
        }

        return (
            <div>
                <div style={{ marginBottom: "48px"}}>
                    <img src={GoogleSheetsSendData} alt="send data automatically to Google Sheets"/>
                </div>
                {/*<ModalHeaders*/}
                    {/*header={"2 more ways to get your data"}*/}
                {/*/>*/}
                {/*<div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: "32px"}}>*/}
                    {/*<div style={boxStyle}>*/}
                        {/*<div style={boxLabel}>*/}
                            {/*Get Excel file in email attachment*/}
                        {/*</div>*/}
                        {/*<div>*/}
                            {/*<img src={EmailReportIcon} style={{ maxWidth: "160px"}} alt="email reporting"/>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                    {/*<div style={boxStyle}>*/}
                        {/*<div style={boxLabel}>*/}
                            {/*Download Excel file from dashboard*/}
                        {/*</div>*/}
                        {/*<div>*/}
                            {/*<img src={DownloadExcel} style={{ maxWidth: "160px"}} alt="email reporting"/>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</div>*/}
            </div>
        )
    }
}

export default GetDataGoogleSheets