import React, {Component} from 'react'
import {connect} from 'react-redux'
import FileOptionsToggle from "./FileOptionsToggle"
import UploadFiles from "../Upload/UploadFiles"
import GoogleSheetsConnect from "../Upload/GoogleSheets/GoogleSheetsConnect";
import GooglePrivacyPolicyFooter from "../GooglePrivacyPolicyFooter"
import HelpSidebar from "./HelpSidebar"
import ConnectDatabaseIndex from "../../Database/ConnectDatabaseIndex"
import DatabaseList from "../../Database/DatabaseList"
import ReportsList from "../../Database/Reports/ReportsList"
import TeamIndex from "../../Team/TeamIndex"
import RequestDataConnectionIndex from "./RequestData/RequestDataConnectionIndex";
import ConnectDataSourcesIndex from "../../ConnectDataSources/ConnectDataSourcesIndex";

class FileOptionsSidebarIndex extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const sidebarDisplay = {
            file: <ReportsList />,
            help: <HelpSidebar />,
            connectDataSources: <ConnectDataSourcesIndex />,
            databaseList: <DatabaseList />,
            team: <TeamIndex />,
            requestDataSources: <RequestDataConnectionIndex userPlan={this.props.userPlan} isAuth={this.props.isAuth}/>
        }

        return (
            <div style={{ backgroundColor: "white", border: "1px solid #F0F0F0", borderTop: "none", minHeight: "100vh", display: "grid", gridTemplateColumns: "40px 1fr"}}>
                <div>
                    <FileOptionsToggle />
                </div>
                <div style={{ padding: "12px", height: '100%'}}>
                    {
                        sidebarDisplay[this.props.sidebarSelection]
                    }
                    {/*<GooglePrivacyPolicyFooter />*/}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    appName: state.mainState.appName,
    sidebarSelection: state.mainState.sidebarSelection,
    chatMode: state.mainState.chatMode,
    userPlan: state.mainState.userPlan,
    isAuth: state.mainState.isAuth
})

const mapActionsToProps = {

}

export default connect(mapStateToProps, mapActionsToProps)(FileOptionsSidebarIndex)