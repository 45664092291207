import React, {Component} from 'react'
import {connect} from 'react-redux'
import Button from "../../../SharedComponents/Button"
import styles from "../../../Constants/styles";
import API_Root from "../../../Constants/API_Root";
import {modifyAllDBReports} from "../../../Actions/ModifyAllDBReports";
import isEmailValid from "../../../Constants/isEmailValid";

class SaveReportInfoBtn extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saving: false
        }
    }

    getReportObj = () => (this.props.allDBReports[this.props.reportSelectedIdx])

    checkIfParamSelectedInReportInfo = (param, report_info) => {
        if (param in report_info) {
            if (Array.isArray(report_info[param])) {
                if (report_info[param].length > 0) {
                    return true
                }
            }
        }

        return false
    }

    getErrorMsg = () => {
        if (!['daily', 'daily_weekday', 'weekly', 'monthly', 'yearly'].includes(this.props.newReportInfo.report_frequency)){
            return "Please select a frequency"
        }

        if (!['15-minute', '30-minute', '60-minute', 'custom'].includes(this.props.newReportInfo.interval)){
            return "Please select time(s)"
        }

        const report_info = this.props.newReportInfo.report_info;

        if (this.props.newReportInfo.report_frequency === "weekly"){
            if (!this.checkIfParamSelectedInReportInfo("days",report_info)){
                return "Please select day(s)"
            }
        } else if (this.props.newReportInfo.report_frequency === "monthly" || this.props.newReportInfo.report_frequency === "yearly"){
            if (this.props.newReportInfo.report_frequency === "yearly"){
                if (!this.checkIfParamSelectedInReportInfo("months",report_info)){
                    return "Please select months(s)"
                }
            }

            if (!("reportingMode" in report_info)){
                return "Please select when to send report"
            } else {
                if (report_info.reportingMode === "dates"){
                    if (!this.checkIfParamSelectedInReportInfo("dates", report_info)){
                        return "Please select date(s)"
                    }
                } else if (report_info.reportingMode === "custom"){
                    if (!("custom" in report_info)){
                        return "Please select frequency to send report"
                    } else {
                        if (!("freq" in report_info.custom) || !("day" in report_info.custom)){
                            return "Please select frequency to send report"
                        }
                    }
                }
            }
        }

        if (this.props.newReportInfo.send_email_report){
            if (!("emails" in this.props.newReportInfo.email_report_info)){
                return "Please enter at least 1 email to send to"
            } else {
                const emails = this.props.newReportInfo.email_report_info['emails'];

                for (let i=0; i<emails.length; i++){
                    if (!isEmailValid(emails[i])){
                        return "Please make sure all emails are valid"
                    }
                }
            }
        }

        if (this.props.newReportInfo.send_google_sheets){
            if (!("accountEmails" in this.props.newReportInfo.google_sheets_info)){
                return "Please select Google Account(s)"
            } else {
                if (this.props.newReportInfo.google_sheets_info.accountEmails.length === 0){
                    return "Please select Google Account(s)"
                } else {
                    for (let i=0; i<this.props.newReportInfo.google_sheets_info.accountEmails.length; i++){
                        const accountEmail = this.props.newReportInfo.google_sheets_info.accountEmails[i];

                        if (accountEmail in this.props.newReportInfo.google_sheets_info){
                            const accountInfo = this.props.newReportInfo.google_sheets_info[accountEmail];

                            if (!("sendType" in accountInfo)){
                                return "Please select how to receive your data in Google Sheets"
                            } else {
                                if (accountInfo.sendType === "add"){
                                    if (!("selectedDocInfo" in accountInfo)){
                                        return "Please select a Google Sheets file to add data to"
                                    } else {
                                        if (!("createOrAddToSheet" in accountInfo.selectedDocInfo)){
                                            return "Please select how to add data to your Google Sheets file"
                                        } else {
                                            if (accountInfo.selectedDocInfo.createOrAddToSheet === "add"){
                                                if (!("selectedSheetNames" in accountInfo.selectedDocInfo)){
                                                    return "Please select sheet(s) in Google Sheets file to add data to"
                                                } else {
                                                    if (Array.isArray(accountInfo.selectedDocInfo.selectedSheetNames)){
                                                        if (accountInfo.selectedDocInfo.selectedSheetNames.length === 0){
                                                            return "Please select sheet(s) in Google Sheets file to add data to"
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return null
    }

    updateInRedux = () => {
        try{
            const allDBReports = JSON.parse(JSON.stringify(this.props.allDBReports));
            const reportObj = allDBReports[this.props.reportSelectedIdx];

            for (let key in this.props.newReportInfo){
                reportObj[key] = this.props.newReportInfo[key];
            }

            allDBReports[this.props.reportSelectedIdx] = reportObj

            this.props.modifyAllDBReports(allDBReports)
        } catch (e) {
            window.location.reload()
        }

    }

    save = () => {
        this.setState({
            saving: true
        })

        const error = (err) => this.setState({ saving: false }, () => alert(err))

        fetch(API_Root + "api/save-lovespreadsheets-report-settings/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                reportPK: this.getReportObj()['pk'],
                report_frequency: this.props.newReportInfo['report_frequency'],
                report_info: this.props.newReportInfo['report_info'],
                report_times: this.props.newReportInfo['report_times'],
                send_email_report: this.props.newReportInfo['send_email_report'],
                send_google_sheets: this.props.newReportInfo['send_google_sheets'],
                email_report_info: this.props.newReportInfo['email_report_info'],
                google_sheets_info: this.props.newReportInfo['google_sheets_info'],
                interval: this.props.newReportInfo['interval']
            })
        }).then(res => {
            if (res.status === 200){
                this.setState({
                    saving: false
                }, () => this.updateInRedux())
            } else {
                error(res.status.toString())
            }
        }).catch(err => error(err))
    }

    render() {
        const errorMsg = this.getErrorMsg();

        return (
            <div>
                <Button style={{
                    backgroundColor: "white",
                    cursor: "pointer",
                    color: styles.mainGreen,
                    border: "1px solid " + styles.mainGreen,
                    borderRadius: "4px",
                    padding: "8px 12px",
                    fontWeight: "700"
                }}
                        loading={this.state.saving}
                        disabled={errorMsg !== null}
                        label={<span><i className="fa-regular fa-floppy-disk"></i> <span style={{ paddingLeft: "4px"}}>Save Report Settings</span></span>}
                        onClick={this.save}
                        small
                />
                {errorMsg !== null ?
                <div style={{ color: styles.red }}>
                    {errorMsg}
                </div> : null}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    reportSelectedIdx: state.mainState.reportSelectedIdx,
    allDBReports: state.mainState.allDBReports
})

const mapActionsToProps = {
    modifyAllDBReports: modifyAllDBReports
}

export default connect(mapStateToProps, mapActionsToProps)(SaveReportInfoBtn)