import React, {Component} from 'react'
import styles from "../../../Constants/styles";

class KeyValueInputIndex extends Component {
    constructor(props) {
        super(props);
    }

    getKeyValueInputDiv = (keyValuePairObj, keyValuePairIdx) => {
        return <div className="row" style={{ marginBottom: "4px"}}>
            <div className="col-sm-1">
                <div style={{ paddingTop: "4px" }}>
                    <input type="checkbox"
                           checked={keyValuePairObj.use}
                           onChange={e => this.props.changeKeyValPairs("edit", keyValuePairIdx, this.props.name, {use: !keyValuePairObj.use})}
                           style={{ cursor: 'pointer' }}
                       />
                </div>
            </div>
            <div className="col-sm-5">
                <input type='text'
                       value={keyValuePairObj.key}
                       onChange={e => this.props.changeKeyValPairs("edit", keyValuePairIdx, this.props.name, {key: e.target.value})}
                       className="inputGray"
                       style={{ width: '95%' }}
                />
            </div>
            <div className="col-sm-5">
                <input type='text'
                       value={keyValuePairObj.value}
                       onChange={e => this.props.changeKeyValPairs("edit", keyValuePairIdx, this.props.name, {value: e.target.value})}
                       className="inputGray"
                       style={{ width: '95%' }}
                />
            </div>
            <div className="col-sm-1">
                {
                    keyValuePairIdx === this.props.keyValuePairs.length - 1 ?
                        null :
                        <span onClick={e => this.props.changeKeyValPairs("delete", keyValuePairIdx, this.props.name, {})}>
                            <i className="fa-solid fa-xmark" id="hover-black" style={{ cursor: 'pointer', color: styles.subText }}/>
                        </span>
                }
            </div>
        </div>
    }

    render() {
        if (this.props.keyValuePairs.length <= 0){
            return null
        }

        return (
            <div>
                <div className="row">
                    <div className="col-sm-1">

                    </div>
                    <div className="col-sm-5">
                        <div style={this.props.labelHeaderStyle}>
                            Key
                        </div>
                    </div>
                    <div className="col-sm-5">
                        <div style={this.props.labelHeaderStyle}>
                            Value
                        </div>
                    </div>
                    <div className="col-sm-1">

                    </div>
                </div>
                {
                    this.props.keyValuePairs.map((x, idx) => <div key={idx}>{this.getKeyValueInputDiv(x, idx)}</div>)
                }
            </div>
        )
    }
}

export default KeyValueInputIndex