import React, {Component} from 'react'
import styles from "../../../../Constants/styles";

class SecurityBox extends Component {
    render() {
        return (
            <div style={{textAlign: "center"}}>
                <div style={{ color: styles.subText, fontWeight: "700", marginBottom: "12px" }}>
                    {this.props.header}
                </div>
                <div style={{ marginBottom: "12px"}}>
                    <img style={{ height: '120px' }} src={this.props.img} alt={this.props.header}/>
                </div>
                <div style={{fontFamily: "Lato", fontSize: "14px", color: styles.secondaryText}}>
                    {this.props.desc}
                </div>
            </div>
        )
    }
}

export default SecurityBox