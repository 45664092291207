import React, {Component} from 'react'
import {connect} from 'react-redux'
import Select from 'react-select'
import CountryTelephoneCode from "../../../Constants/CountryTelephoneCodes";
import {images} from "../../../Constants/images";

const dataLabels = {
    mysql: "MySQL",
    postgres: "Postgres",
    mariadb: "MariaDB",
    sqlserver: "SQL Server",
    mongodb: "MongoDB",
    snowflake: "Snowflake",
    googleBigQuery: "Big Query",
    redshift: "AWS Redshift",
    "oracle": "Oracle DB",
    // salesforce: "Salesforce",
    // hubspot: 'Hubspot',
    // quickbooks: 'Quickbooks',
    // googleAnalytics: "Google Analytics",
    // "stripe": "Stripe",
    // "outreach": "Outreach",
    // "pipedrive": "Pipedrive",
    // "shopify": "Shopify",
    // "mailchimp": "Mailchimp",
    // "jira": "Jira",
    // "airtable": "Airtable",
    // "facebookAds": "Facebook Ads",
    'api': 'API',
    'website': 'Web Scraping',
    "saasTools": "SaaS Tools"
}

class DataSourcesDropdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSource: 'dataSource' in this.props ?
                {
                    value: this.props.dataSource,
                    label: this.getLabelForDataSource(this.props.dataSource)
                } :
            {
                value: 'mongodb',
                label: this.getLabelForDataSource('mongodb')
            }
        }
    }

    getLabelForDataSource = (dataSource) => {
        return <span>
            <img className="avatar"
                    style={{ height: "20px", width: "20px"}}
                    src={images[dataSource + 'Logo']}
                    alt={dataSource + "Logo"} />
            <span style={{ paddingLeft: "8px"}}>{dataLabels[dataSource]}</span>
            </span>
    }

    getDataSourceOptions = () => {
        let options = [];
        console.log(this.props.notInclude)

        for (let dataSource in dataLabels){
            let add = true;
            if ('notInclude' in this.props){

                if (this.props.notInclude.includes(dataSource)){
                    console.log(dataSource)
                    add = false
                }
            }

            if ('include' in this.props){
                if (!this.props.include.includes(dataSource)){
                    add = false
                }
            }

            if (add){
                options.push({
                    value: dataSource,
                    label: this.getLabelForDataSource(dataSource)
                })
            }

        }

        return options
    }

    onChange = (newDataSource) => {
        this.setState({ dataSource: newDataSource
        }, () => {
            if ('changeDataSource' in this.props){
                this.props.changeDataSource({
                    value: newDataSource.value,
                    label: dataLabels[newDataSource.value]
                })
            }
        })
    }

    render() {
        return (
            <Select
                onChange={this.onChange}
                options={this.getDataSourceOptions()}
                value={this.state.dataSource}
                isSearchable={true}
                isDisabled={'disabled' in this.props ? this.props.disabled : false}
            />
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(DataSourcesDropdown)