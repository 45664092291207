import React, {Component} from 'react'
import {connect} from 'react-redux'
import InfoPopup from "../../SharedComponents/InfoPopup"
import ToggleCheckbox from "../../SharedComponents/ToggleCheckbox";

class ConnectStripeIndex extends Component {
    constructor(props) {
        super(props);
    }

    onChange = (e) => {
        const copiedParams = JSON.parse(JSON.stringify(this.props.connectionParams));
        copiedParams[e.target.name] = e.target.value

        this.props.changeState({connectionParams: copiedParams})
    }

    toggle = () => {
        const copiedParams = JSON.parse(JSON.stringify(this.props.connectionParams));
        const currValue = copiedParams.isTestMode

        copiedParams.isTestMode = !currValue

        this.props.changeState({connectionParams: copiedParams})
    }

    render() {
        const isTestMode = this.props.getValuesFromConnectionParams(this.props.connectionParams, 'isTestMode', 'bool');

        return (
            <div>
                <div style={{ marginBottom: "16px" }}>
                    <ToggleCheckbox
                        right="Stripe test mode?"
                        checked={isTestMode}
                        toggle={this.toggle}
                        labelStyle={this.props.displayHeaderStyle}
                    />
                </div>
                <div style={this.props.displayHeaderStyle}>{isTestMode ? "Test" : "Live"} API Key<span style={{color: 'red'}}>*</span></div>
                <input style={{width: '100%'}}
                       className="inputGray"
                       value={this.props.getValuesFromConnectionParams(this.props.connectionParams, isTestMode ? 'testApiKey' : 'prodApiKey', 'str')}
                       type='text'
                       placeholder='sk-your-test-api-key'
                       name={isTestMode ? 'testApiKey' : 'prodApiKey'}
                       onChange={this.onChange}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ConnectStripeIndex)