import React, {Component} from 'react'
import {connect} from 'react-redux'
import Button from "../SharedComponents/Button"
import {toggleModal} from "../Actions/ToggleModal";
import {modifyModalInfo} from "../Actions/ModifyModalInfo";
import {showUpgradeModal} from "../Constants/showUpgradeModal";
import {doesUserNeedToUpgrade} from "../Constants/doesUserNeedToUpgrade";
import ConnectStripeIndex from "./Stripe/ConnectStripeIndex"
import ConnectAPIIndex from "./API/ConnectAPIIndex"
import API_Root from "../Constants/API_Root";
import BannerAlert from "../SharedComponents/BannerAlert";
import {modifySidebarSelection} from "../Actions/ModifySidebarSelection";
import styles from "../Constants/styles";
import ConnectWebsiteIndex from "./Website/ConnectWebsiteIndex"

class ConnectDataSourcesForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fetching: false,
            connectionParams: this.initConnectionParams(),
            canConnect: null,
            connectionValidMsg: ''

        }
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if (this.props.dataSource !== prevProps.dataSource){
            this.setState({
                fetching: false,
                connectionParams: this.initConnectionParams(),
                canConnect: null,
                connectionValidMsg: ''
            })
        }
    }

    initConnectionParams = () => {
        let params = { name: '', desc: '' }

        switch (this.props.dataSource){
            case 'stripe':
                params['testApiKey'] = ''
                params['prodApiKey'] = ''
                params['isTestMode'] = true
                break
            case 'api':
                params['docsUrl'] = ''
                params['isAiMode'] = false
                break
            case 'website':
                params['websiteUrl'] = ''
                break
            default:
                break
        }

        return params
    }

    getValuesFromConnectionParams = (connectionParamsObj, key, paramType) => {
        if (key in connectionParamsObj){
            return connectionParamsObj[key]
        } else {
            if (paramType === "bool"){
                return false
            } else {
                return ""
            }
        }
    }

    changeState = (newState) => this.setState(newState)

    getFormBody = (displayHeaderStyle) => {
        return {
            stripe: <ConnectStripeIndex connectionParams={this.state.connectionParams}
                                        changeState={this.changeState}
                                        displayHeaderStyle={displayHeaderStyle}
                                        getValuesFromConnectionParams={this.getValuesFromConnectionParams}
            />,
            api: <ConnectAPIIndex connectionParams={this.state.connectionParams}
                                  changeState={this.changeState}
                                  displayHeaderStyle={displayHeaderStyle}
                                  getValuesFromConnectionParams={this.getValuesFromConnectionParams}
            />,
            website: <ConnectWebsiteIndex
                connectionParams={this.state.connectionParams}
                changeState={this.changeState}
                displayHeaderStyle={displayHeaderStyle}
                getValuesFromConnectionParams={this.getValuesFromConnectionParams}
            />
        }[this.props.dataSource]
    }

    onConnect = () => {
        this.setState({ fetching: true })

        const error = (errorMsg='There was a general error connecting to your ' + this.props.dataSource.label + " account") => {
            this.setState({
                fetching: false,
                canConnect: false,
                connectionValidMsg: errorMsg
            })
        }

        const dbLengths = this.props.allDatabases.length;

        if (doesUserNeedToUpgrade(this.props.userPlan, "databases", dbLengths)){
            showUpgradeModal(this.props.toggleModal, this.props.modifyModalInfo, this.props.userPlan, "databases", dbLengths, this.props.userTeamPK, this.props.selectedTeamPK, this.props.allTeamsInfo)
        } else {
            const connectionParams = JSON.parse(JSON.stringify(this.state.connectionParams));

            fetch(API_Root + "api/connect-to-love-data-sources/", {
                method: 'POST',
                headers: {
                    Accept: 'application/json, text/plain, */*',
                },
                body: JSON.stringify({
                    email: localStorage.getItem("email"),
                    token: localStorage.getItem("token"),
                    connectionParams: connectionParams,
                    dataSource: this.props.dataSource,
                    teamPK: this.props.selectedTeamPK
                })
            }).then(res => {
                if (res.status === 200){
                    res.json().then(data => {
                        this.setState({
                            fetching: false,
                            canConnect: data.canConnect,
                            connectionValidMsg: data.connectionValidMessage
                        })

                        if (this.props.dataSource === "api" && connectionParams.isAiMode){
                            if (data.canConnect){
                                this.props.modifyModalInfo({
                                    'name': connectionParams.name,
                                    'apiAuthInfo': data.connectionValidMessage
                                })

                                this.props.toggleModal("connectAPIAuthInfoModal")
                            }
                        } else {
                            if (data.canConnect){
                                window.location.reload()
                            }
                        }
                    }).catch(err => error())
                } else {
                    error()
                }
            }).catch(err => error())
        }
    }

    getErrorMsg = () => {
        let errorMsg = "";

        if (!this.props.isAuth){
            return "Sign up to connect " + this.props.dataSourceLabel + " account"
        }

        if (this.state.connectionParams.name.trim() === ''){
            errorMsg = "Enter name for account (could be anything unique)."
        }

        switch (this.props.dataSource){
            case 'stripe':
                let apiKey;

                if (this.getValuesFromConnectionParams(this.state.connectionParams, 'isTestMode', 'bool')){
                    apiKey = this.getValuesFromConnectionParams(this.state.connectionParams, 'testApiKey', 'str').trim()
                } else {
                    apiKey = this.getValuesFromConnectionParams(this.state.connectionParams, 'prodApiKey', 'str').trim()
                }

                if (apiKey === ""){
                    errorMsg += 'Enter API Key.'
                }

                break
            case 'website':
                const website = this.getValuesFromConnectionParams(this.state.connectionParams, 'websiteUrl', 'str').trim()

                if (website === ""){
                    errorMsg += 'Enter a website to get data from.'
                }

                break
            default:
                break
        }

        return errorMsg
    }

    onChange = (e) => {
        const copiedParams = JSON.parse(JSON.stringify(this.state.connectionParams));
        copiedParams[e.target.name] = e.target.value

        this.setState({connectionParams: copiedParams})
    }

    render() {
        const errorMsg = this.getErrorMsg();

        const btnDisabled = errorMsg.trim() !== "";
        const displayHeaderStyle = {fontFamily: "Lato", fontSize: "14px", fontWeight: "400", color: "#5E645E", marginBottom: "8px"}

        return (
            <div>
                <div style={{ marginBottom: '16px'}}>
                    <div style={displayHeaderStyle}>Account Name<span style={{color: 'red'}}>*</span></div>
                    <input style={{width: '100%'}}
                           className="inputGray"
                           value={this.state.connectionParams.name}
                           type='text'
                           placeholder='Love Spreadsheets'
                           name='name'
                           onChange={this.onChange}/>
                </div>
                <div style={{ marginBottom: '16px'}}>
                    <div style={displayHeaderStyle}>Description</div>
                    <input style={{width: '100%'}}
                           className="inputGray"
                           value={this.state.connectionParams.desc}
                           type='text'
                           placeholder='Main account for team'
                           name='desc'
                           onChange={this.onChange}/>
                </div>
                <div>
                    {this.getFormBody(displayHeaderStyle)}
                </div>
                <div style={{ marginTop: "16px"}}>
                    <Button disabled={btnDisabled}
                            style={{backgroundColor: "#6FBB98", color: "white", cursor: btnDisabled ? "not-allowed" : "pointer", border: "none", borderRadius: "4px"}}
                            onClick={this.onConnect}
                            loading={this.state.fetching}
                            icon="plug"
                            label={"Connect " + this.props.dataSourceLabel + " account"}
                    />
                </div>
                {
                    btnDisabled ?
                        <div style={{ color: styles.red}}>{errorMsg}</div> : null
                }
                {
                    this.state.canConnect === false ?
                        <div style={{ marginTop: "16px"}}>
                            <BannerAlert
                                type="error"
                                header={this.state.connectionValidMsg}
                                content={<span>Try again. Contact our <span className="linkStyle" onClick={e => this.props.modifySidebarSelection("help")}>support</span> if you keep running into trouble</span>}
                            />
                        </div> :
                        null
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    allDatabases: state.mainState.allDatabases,
    userPlan: state.mainState.userPlan,
    userTeamPK: state.mainState.userTeamPK,
    selectedTeamPK: state.mainState.selectedTeamPK,
    allTeamsInfo: state.mainState.allTeamsInfo,
    isAuth: state.mainState.isAuth
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo,
    modifySidebarSelection: modifySidebarSelection
}

export default connect(mapStateToProps, mapActionsToProps)(ConnectDataSourcesForm)