import React, {Component} from 'react'
import DatabaseBox from "./DatabaseBox";
import DatabaseLogos from "./DatabaseLogos";
import ModalHeaders from "../../ModalHeaders";
import SecurityRow from "./SecurityRow";

class ConnectDatabaseBody extends Component {
    render() {
        return (
            <div>
                <div style={{ marginBottom: "48px"}}>
                    <DatabaseLogos isMobile={this.props.isMobile}/>
                </div>
                {/*<ModalHeaders header="Connection Security" />*/}
                {/*<div style={{ marginBottom: "48px"}}>*/}
                    {/*<SecurityRow/>*/}
                {/*</div>*/}
            </div>
        )
    }
}

export default ConnectDatabaseBody