import React, {Component} from 'react'
import {connect} from 'react-redux'
import {getLastParamValue} from "../Constants/getLastParamValue";
import {toggleModal} from "../Actions/ToggleModal";
import API_Root from "../Constants/API_Root";

class DownloadEmailReportIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            reportId: this.getReportId(),
            urlToClick: "/"
        }

        this.props.toggleModal("loadingModalDownloadEmailReport")
    }

    errorDownloading = () => {
        this.props.toggleModal(null)
        alert("There was an error downloading your report. Please try again.")

        // this.setState({
        //     urlToClick: "/"
        // }, () => this.downloadReportLink.click())
    }

    getReportId = () => {
        try{
            const id  = getLastParamValue(window.location.href);

            if (id === undefined || id === null){
                this.errorDownloading()
            }
            return id
        } catch (e) {
            this.errorDownloading()
        }
    }

    componentDidMount(){
        fetch(API_Root + "api/download-email-report-for-love-spreadsheets/" + this.state.reportId + "/")
            .then(res => {
                res.json().then(data => {
                    this.setState({
                        urlToClick: data.downloadUrl
                    }, () => {
                        this.downloadReportLink.click();
                        this.props.toggleModal(null);

                        // this.setState({
                        //     urlToClick: "/"
                        // }, () => this.downloadReportLink.click())
                    })
                }).catch(err => this.errorDownloading())
            }).catch(err => this.errorDownloading())
    }

    render() {
        return (
            <div>
                <a href={this.state.urlToClick} ref={downloadReportLink => this.downloadReportLink = downloadReportLink} style={{ display: "none" }}> </a>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(DownloadEmailReportIndex)