import React, {Component} from 'react'
import {connect} from 'react-redux'
import Select from 'react-select'

class DatabaseNoCode extends Component {
    constructor(props) {
        super(props);

        this.state = {
            'tableSelected': "customers",
            'columnsSelected': ["id", "email", "full_name"]
        }
    }

    changeTable = (tableObj) => {
        const newState = { tableSelected: tableObj.value, columnsSelected: [] }

        this.setState(newState)
    }

    toggleColumnSelected = (colName) => {
        const currColumns = JSON.parse(JSON.stringify(this.state.columnsSelected));

        if (currColumns.includes(colName)){
            currColumns.splice(currColumns.indexOf(colName), 1)
        } else {
            currColumns.push(colName)
        }

        const newState = { columnsSelected: currColumns }

        this.setState(newState)
    }

    getColumns = (dbTablesAndCols) => {
        const allColumns = dbTablesAndCols[this.state.tableSelected];

        let allColumnsCheckboxes = [];

        for (let i=0; i<allColumns.length; i++){
            const colName = allColumns[i]['Field'];
            const id = this.state.tableSelected + "-" + colName + "-col-checkbox";

            allColumnsCheckboxes.push(<div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', columnGap: "8px"}} key={colName}>
                <div>
                    <input type="checkbox"
                           id={id}
                           checked={this.state.columnsSelected.includes(colName)}
                           onClick={e => this.toggleColumnSelected(colName)}
                           style={{ cursor: 'pointer' }}
                    />
                </div>
                <div>
                    <label htmlFor={id} style={{ cursor: 'pointer' }}>
                        {colName}
                    </label>
                </div>
            </div>)
        }

        return allColumnsCheckboxes
    }

    render() {
        const dbTablesAndCols = {
            "customers": [{"Field": "id"}, {"Field": "email"}, {"Field": "full_name"}, {"Field": "date_joined"}],
            "orders": [{"Field": "id"}, {"Field": "customer_id"}, {"Field": "item"}, {"Field": "price"}],
        }

        let tableOptions = [];

        for (let tableName in dbTablesAndCols){
            tableOptions.push({value: tableName, label: tableName})
        }

        return (
            <div>
                <div style={{ marginBottom: "24px"}}>
                    <div style={this.props.labelHeaderStyle}>
                        Select Database Table
                    </div>
                    <Select
                        onChange={this.changeTable}
                        value={{value: this.state.tableSelected, label: this.state.tableSelected}}
                        options={tableOptions}
                    />
                </div>
                {
                    this.state.tableSelected === null ?
                        null :
                        <div style={{ marginBottom: "24px"}}>
                            <div style={this.props.labelHeaderStyle}>
                                Select Columns
                            </div>
                            <div>
                                {this.getColumns(dbTablesAndCols)}
                            </div>
                        </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
})

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(DatabaseNoCode)