import React, {Component} from 'react'
import ProductMenuRow from "./ProductMenuRow";
import DropdownMenuItem from "./DropdownMenuItem";
import styles from "../Constants/styles";

class ResourcesMenu extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const isMobile = this.props.isMobile;

        return (
            <div>
                <div style={isMobile ? { marginBottom: "8px" } : null}>
                    <DropdownMenuItem
                        icon={<i className="fa-solid fa-database"></i>}
                        isMobile={isMobile}
                        name="Integrate"
                        website="/integrate"
                    />
                </div>
                <div style={isMobile ? { marginBottom: "8px" } : null}>
                    <DropdownMenuItem
                        icon={<i className="fa-solid fa-envelope" style={{ color: isMobile ? "white" : "black", backgroundColor: isMobile ? styles.red : "white" }}> </i>}
                        isMobile={isMobile}
                        name="Contact Us"
                        website="/contact"
                    />
                </div>
                <div style={isMobile ? { marginBottom: "8px" } : null}>
                    <DropdownMenuItem
                        icon={<i className="fa-solid fa-square-rss"></i>}
                        isMobile={isMobile}
                        name="Blog"
                        website="https://lovespreadsheets.medium.com/"
                        external
                    />
                </div>
                <div style={{ fontSize: "20px", fontWeight: "700", marginBottom: "16px", marginTop: "16px", paddingTop: "16px", borderTop: "1px solid #F0F0F0" }}>
                    Other Products
                </div>
                <div>
                    <ProductMenuRow
                        isMobile={isMobile}
                        name="API Spreadsheets"
                        icon="https://project-static-assets.s3.amazonaws.com/APISpreadsheets/LogoIcon.png"
                        desc=""
                        website="https://www.apispreadsheets.com/"
                    />
                </div>
            </div>
        )
    }
}

export default ResourcesMenu