import React, {Component} from 'react'
import PerkTexts from "./PerkTexts"

const iconColors = [
    '#6fbb98',
    '#78b782',
    '#86b16b',
    '#96aa54',
    '#a9a141',
    '#bd9633',
    '#d08830',
    '#e27837'
]

const perks = [
    {header: "Office space in NYC or Bangkok", icon: "fas fa-building", desc: "We provide fun & collaborative office spaces in both NYC and Bangkok for you to work from"},
    {header: "Hybrid remote work", icon: "fas fa-laptop", desc: "Depending on your project & role, you may be able to work remotely up to 90% of the time"},
    {header: "Competitive salary", icon: "fas fa-dollar-sign", desc: "We pay industry level and often, higher salaries"},
    {header: "Equity in company", icon: "fas fa-percentage", desc: "At our current stage, every employee will get a stake in the company"},
    {header: "Unlimited vacation days", icon: "fas fa-umbrella-beach", desc: "We trust you to take time off as necessary. Optimal work sometimes comes after a break"},
    {header: "Lunch & dinner allowance", icon: "fas fa-utensils", desc: "When you work in our office, you won't have to worry about meals or snacks"},
    {header: "AI development training ", icon: "fas fa-graduation-cap", desc: "We strongly believe in every employee knowing how AI & Software development works. Even in marketing."},
    {header: "Unlimited growth opportunities", icon: "fas fa-chart-line", desc: "You will have unlimited opportunity to grow within your role. Or even create a new role."},
]

class PerksIndex extends Component {
    render() {
        return (
            <div style={{ border: "1px solid #F0F0F0", borderRadius: "4px", padding: "16px" }}>
                <div style={{ fontSize: "28px", fontWeight: "700", textAlign: "center"}}>
                    What you get
                </div>
                <div style={{ paddingTop: "24px"}}>
                    {perks.map((x, idx) => <PerkTexts info={x} key={idx} iconColor={iconColors[idx]}/>)}
                </div>
            </div>
        )
    }
}

export default PerksIndex