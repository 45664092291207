import React, {Component} from 'react'

class PlanCTA extends Component {
    render() {
        return (
            <span style={{fontSize: "14px", fontWeight: "700"}}>
                <span className="linkStyle"
                   style={this.props.planIdx === 2 || this.props.planIdx === 3 ? {color: "white"} : null}>
                    {this.props.getSignUpText(this.props.planIdx)}
                   <span style={{paddingLeft: "2px", paddingTop: "2px"}}> > </span>
                </span>
            </span>
        )
    }
}

export default PlanCTA