import React, {Component} from 'react'
import API_Root from "../../../Constants/API_Root";

class TestGoogleSheetsReport extends Component {
    constructor(props) {
        super(props);
    }

    testGoogleSheetsReport = () => {
        fetch(API_Root + "api/test-google-sheets-report-for-love-spreadsheets/", {
            method: 'POST',
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                reportPK: this.props.reportPK
            }),
            headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}
        }).then(res => alert("This is an async process. Please wait and check")).catch(err => alert(err))
    }

    render() {
        return (
            <div className="linkStyle" onClick={this.testGoogleSheetsReport}>
                Test Google Sheets Report
            </div>
        )
    }
}

export default TestGoogleSheetsReport