export const isLastConvoData = (projectConversations) => {
    let isData = false;
    let fileHash = null;

    if (projectConversations.length > 0){
        const lastConvo = projectConversations[projectConversations.length-1];
        if ('results' in lastConvo){
            if (Array.isArray(lastConvo.results)){
                if (lastConvo.results.length > 0){
                    for (let i=0; i<lastConvo.results.length; i++){
                        const resultObj = lastConvo.results[i]
                        if ('are_results_data' in resultObj){
                            isData = resultObj['are_results_data']
                            fileHash = resultObj['file_hash']
                            break;
                        }
                    }
                }
            }
        }
    }

    return {isData: isData, fileHash: fileHash}
}