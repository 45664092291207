import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleModal} from "../../Actions/ToggleModal";
import {modifyModalInfo} from "../../Actions/ModifyModalInfo";
import API_Root from "../../Constants/API_Root";
import {pricing} from "./pricing";
import {modifyCheckoutUrl} from "../../Actions/ModifyCheckoutUrl";
import { push } from 'react-router-redux'
import {toggleToast} from "../../Actions/ToggleToast";

class PaymentLink extends Component {
    constructor(props) {
        super(props);

        this.state = {
            checkoutUrl: "",
        }
    }

    getMode = () => {
        if ('pricingMode' in this.props){
            return this.props.pricingMode
        } else {
            if (this.props.pricingType === "lifetime"){
                return 'payment'
            } else {
                return 'subscription'
            }
        }
    }

    startPayment = () => {
        if (!this.props.isAuth){
            this.props.modifyModalInfo({"signUpMsg": "Sign Up to upgrade", "nextLink": "/continue-to-pricing/pay?planIdx=" + this.props.planIdx.toString() + "&" + this.getMode()})
            this.props.toggleModal("signUpModal")
        } else {
            this.props.toggleModal("loadingModalPreparingPayment")

            const error = () => {
                this.setState({
                    checkoutUrl: "mailto:info@lovespreadsheets.com?Subject=Error%20upgrading%20to%20" + pricing[this.props.planIdx].name + "%20plan"
                }, () => this.checkoutLink.click())
            }

            fetch(API_Root + "api/create-stripe-checkout-session/", {
                method: 'POST',
                body: JSON.stringify({
                    email: localStorage.getItem("email"),
                    planIdx: this.props.planIdx,
                    mode: this.getMode(),
                    successUrl: window.location.origin + "/?upgradedPlanIdx=" + this.props.planIdx.toString() + "&email=" + localStorage.getItem("email"),
                    cancelUrl: window.location.href
                })
            }).then(res => {
                if (res.status === 200){
                    res.json().then(data => {
                        const checkoutUrl = data.checkoutUrl;
                        try{
                            this.props.toggleModal(null)
                            this.props.modifyCheckoutUrl(checkoutUrl)
                            window.location.href = checkoutUrl
                        } catch (e) {
                            error()
                        }
                        // this.setState({
                        //     checkoutUrl: checkoutUrl
                        // }, () => {
                        //     this.checkoutLink.click()
                        // })
                    }).catch(err => error())
                } else {
                    error()
                }
            }).catch(err => error())
            // console.log("go to stripe to get checkout sessions")
        }


    }

    render() {
        return (
            <div>
                <a href={this.props.checkoutUrl} ref={checkoutLink => this.checkoutLink = checkoutLink} style={{ visibility: 'hidden' }}> </a>
                <span onClick={this.startPayment}>{this.props.component}</span>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    isAuth: state.mainState.isAuth,
    checkoutUrl: state.mainState.checkoutUrl,
    pricingType: state.mainState.pricingType
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo,
    modifyCheckoutUrl: modifyCheckoutUrl,
    navigateTo: push,
}

export default connect(mapStateToProps, mapActionsToProps)(PaymentLink)