import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../Constants/styles";
import {modifyModalInfo} from "../Actions/ModifyModalInfo";
import {toggleModal} from "../Actions/ToggleModal";
import {calendlyLink} from "../Constants/calendlyLink";

class CTARow extends Component {
    constructor(props) {
        super(props);
    }

    signUp = () => {
        this.props.modifyModalInfo({
            signUpMsg: "Sign up to get started"
        })

        this.props.toggleModal("signUpModal")
    }

    render() {
        return (
            <div>
                <a href={calendlyLink} ref={scheduleCallLink => this.scheduleCallLink = scheduleCallLink} target="_blank" rel="noopener noreferrer" style={{ visibility: 'hidden' }}> </a>
                <div className="row">
                    <div className="col-sm-6">
                        <div style={{ textAlign: this.props.isMobile ? 'center' : 'right'}}>
                            <button style={{
                                border: "none",
                                backgroundColor: styles.blue,
                                color: "white",
                                padding: "12px 16px 12px 16px",
                                fontSize: this.props.isMobile ? "18px" : "24px",
                                fontWeight: "700",
                                width: this.props.isMobile ? "75%" : "auto",
                                borderRadius: "4px"}}
                                    onClick={this.signUp}
                            >
                                Sign up for free
                            </button>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div style={{ textAlign: this.props.isMobile ? 'center' : 'left', marginTop: this.props.isMobile ? "12px" : "0"}}>
                            <button style={{
                                border: "1px solid " + styles.blue,
                                backgroundColor: "white",
                                color: styles.blue,
                                padding: "12px 16px 12px 16px",
                                fontSize: this.props.isMobile ? "18px" : "24px",
                                fontWeight: "700",
                                width: this.props.isMobile ? "75%" : "auto",
                                borderRadius: "4px"}}
                                    onClick={e => this.scheduleCallLink.click()}
                            >
                                Schedule a demo
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo
}

export default connect(mapStateToProps, mapActionsToProps)(CTARow)