import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../Constants/styles";
import DataSourcesDropdown from "../Apps/FileOptionsSidebar/RequestData/DataSourcesDropdown"
import ConnectDatabaseIndex from "../Database/ConnectDatabaseIndex";
import ConnectDataSourcesForm from "./ConnectDataSourcesForm";
import SignUpLoginLink from "../SharedComponents/SignUpLogInLink"
import DataSourcesHelpToolbar from "./Help/DataSourcesHelpToolbar"

class ConnectDataSourcesIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSource: {
                value: 'mysql',
                label: 'MySQL'
            }
        }
    }

    changeDataSource = (newSource) => {
        this.setState({
            dataSource: newSource
        })
    }

    render() {
        return (
            <div>
                <div style={{ fontSize: "16px", fontWeight: "700", color: styles.mainText}}>
                    <i className="fa-solid fa-plug"/> <span style={{ paddingLeft: '4px'}}> Connect your data sources</span>
                </div>
                {
                    this.props.isAuth ?
                        <div>
                            <DataSourcesHelpToolbar dataSource={this.state.dataSource.value}/>
                        </div>
                        :
                        <div style={{ marginTop: "12px" }}>
                            <SignUpLoginLink msg={"to connect data sources"}/>
                        </div>
                }
                <div style={{ marginTop: "12px"}}>
                    <div style={{fontFamily: "Lato", fontSize: "14px", fontWeight: "400", color: "#5E645E", marginBottom: "4px"}}>
                        Select Data Source
                    </div>
                </div>
                <DataSourcesDropdown changeDataSource={this.changeDataSource} include={['mysql', 'postgres', 'mariadb', 'sqlserver', 'snowflake', 'oracle', 'api']} dataSource='mysql'/>
                <div style={{ marginTop: "16px"}}>
                    {
                        this.props.isAuth ?
                            ['mysql', 'postgres', 'mariadb', 'sqlserver', 'oracle', 'snowflake', 'mongodb'].includes(this.state.dataSource.value) ?
                                <ConnectDatabaseIndex type={this.state.dataSource.value.toUpperCase()}/> :
                                <ConnectDataSourcesForm dataSource={this.state.dataSource.value} dataSourceLabel={this.state.dataSource.label}/>
                            : null
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    isAuth: state.mainState.isAuth
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ConnectDataSourcesIndex)