import React, {Component} from 'react'
import ModalApp from '../SharedComponents/Modal'
import DemoModalBody from "./DemoModalBody"

class DemoModal extends Component {
    render() {
        return (
            <ModalApp name="demoModal"
                      header={null}
                      size="medium"
                      body={<DemoModalBody step={0}/>}
                      noClose
            />
        )
    }
}

export default DemoModal