import React, {Component} from 'react'
import Modal from "../SharedComponents/Modal"
import LoginForm from "../Auth/LoginForm"
import {images} from "../Constants/images";

class LoginModal extends Component {
    render() {
        const segmentStyle = { backgroundColor: "white", padding: "12px", border: "1px solid #F0F0F0", borderRadius: "4px", boxShadow: "0px 5px 6px -4px rgba(2, 34, 19, 0.02)"}

        return (
            <Modal name="loginModal"
                   header={null}
                   body={
                       <div style={{ paddingBottom: "48px" }}>
                           <div className="row">
                               <div className="col-sm-2" />
                               <div className="col-sm-8">
                                   {/*<a href="/">*/}
                                       <img style={{marginTop: '5px', width: "30%"}} src={images.logoNav} alt="Logo" />
                                   {/*</a>*/}
                                   <div style={{...{ marginTop: "8px" }, ...segmentStyle}}>
                                       <LoginForm modal />
                                   </div>
                               </div>
                               <div className="col-sm-2" />
                           </div>
                       </div>}
                   size="medium"
            />
        )
    }
}


export default LoginModal