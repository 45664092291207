export const languages = [
    { language: "Albanian", language_local: "Shqip" },
    { language: "Arabic", language_local: "العربية" },
    { language: "Armenian", language_local: "Հայերեն" },
    { language: "Awadhi", language_local: "अवधी" },
    { language: "Azerbaijani", language_local: "Azərbaycanca" },
    { language: "Bashkir", language_local: "Башҡорт" },
    { language: "Basque", language_local: "Euskara" },
    { language: "Belarusian", language_local: "Беларуская" },
    { language: "Bengali", language_local: "বাংলা" },
    { language: "Bhojpuri", language_local: "भोजपुरी" },
    { language: "Bosnian", language_local: "Bosanski" },
    { language: "Brazilian Portuguese", language_local: "português brasileiro" },
    { language: "Bulgarian", language_local: "български" },
    { language: "Cantonese (Yue)", language_local: "粵語" },
    { language: "Catalan", language_local: "Català" },
    { language: "Chhattisgarhi", language_local: "छत्तीसगढ़ी" },
    { language: "Chinese", language_local: "中文" },
    { language: "Croatian", language_local: "Hrvatski" },
    { language: "Czech", language_local: "Čeština" },
    { language: "Danish", language_local: "Dansk" },
    { language: "Dogri", language_local: "डोगरी" },
    { language: "Dutch", language_local: "Nederlands" },
    { language: "English", language_local: "English" },
    { language: "Estonian", language_local: "Eesti" },
    { language: "Faroese", language_local: "Føroyskt" },
    { language: "Finnish", language_local: "Suomi" },
    { language: "French", language_local: "Français" },
    { language: "Galician", language_local: "Galego" },
    { language: "Georgian", language_local: "ქართული" },
    { language: "German", language_local: "Deutsch" },
    { language: "Greek", language_local: "Ελληνικά" },
    { language: "Gujarati", language_local: "ગુજરાતી" },
    { language: "Haryanvi", language_local: "हरियाणवी" },
    { language: "Hindi", language_local: "हिंदी" },
    { language: "Hungarian", language_local: "Magyar" },
    { language: "Indonesian", language_local: "Bahasa Indonesia" },
    { language: "Irish", language_local: "Gaeilge" },
    { language: "Italian", language_local: "Italiano" },
    { language: "Japanese", language_local: "日本語" },
    { language: "Javanese", language_local: "Basa Jawa" },
    { language: "Kannada", language_local: "ಕನ್ನಡ" },
    { language: "Kashmiri", language_local: "कश्मीरी" },
    { language: "Kazakh", language_local: "Қазақша" },
    { language: "Konkani", language_local: "कोंकणी" },
    { language: "Korean", language_local: "한국어" },
    { language: "Kyrgyz", language_local: "Кыргызча" },
    { language: "Latvian", language_local: "Latviešu" },
    { language: "Lithuanian", language_local: "Lietuvių" },
    { language: "Macedonian", language_local: "Македонски" },
    { language: "Maithili", language_local: "मैथिली" },
    { language: "Malay", language_local: "Bahasa Melayu" },
    { language: "Maltese", language_local: "Malti" },
    { language: "Mandarin", language_local: "普通话" },
    { language: "Mandarin Chinese", language_local: "中文" },
    { language: "Marathi", language_local: "मराठी" },
    { language: "Marwari", language_local: "मारवाड़ी" },
    { language: "Min Nan", language_local: "閩南語" },
    { language: "Moldovan", language_local: "Moldovenească" },
    { language: "Mongolian", language_local: "Монгол" },
    { language: "Montenegrin", language_local: "Crnogorski" },
    { language: "Nepali", language_local: "नेपाली" },
    { language: "Norwegian", language_local: "Norsk" },
    { language: "Oriya", language_local: "ଓଡ଼ିଆ" },
    { language: "Pashto", language_local: "پښتو" },
    { language: "Persian (Farsi)", language_local: "فارسی" },
    { language: "Polish", language_local: "Polski" },
    { language: "Portuguese", language_local: "Português" },
    { language: "Punjabi", language_local: "ਪੰਜਾਬੀ" },
    { language: "Rajasthani", language_local: "राजस्थानी" },
    { language: "Romanian", language_local: "Română" },
    { language: "Russian", language_local: "Русский" },
    { language: "Sanskrit", language_local: "संस्कृतम्" },
    { language: "Santali", language_local: "संताली" },
    { language: "Serbian", language_local: "Српски" },
    { language: "Sindhi", language_local: "سنڌي" },
    { language: "Sinhala", language_local: "සිංහල" },
    { language: "Slovak", language_local: "Slovenčina" },
    { language: "Slovene", language_local: "Slovenščina" },
    { language: "Slovenian", language_local: "Slovenščina" },
    { language: "Spanish", language_local: "español"},
    { language: "Swahili", language_local: "Kiswahili"},
    { language: "Swedish", language_local: "Svenska"},
    { language: "Tajik", language_local: "тоҷикӣ"},
    { language: "Tamil", language_local: "தமிழ்"},
    { language: "Tatar", language_local: "تاتارچا"},
    { language: "Telugu", language_local: "తెలుగు"},
    { language: "Thai", language_local: "ภาษาไทย"},
    { language: "Turkish", language_local: "Türkçe"},
    { language: "Turkmen", language_local: "Türkmençe"},
    { language: "Ukrainian", language_local: "Українська" },
    { language: "Urdu", language_local: "اردو" },
    { language: "Uzbek", language_local: "Ўзбек" },
    { language: "Vietnamese", language_local: "Việt Nam" },
    { language: "Welsh", language_local: "Cymraeg" },
    { language: "Wu", language_local: "吴语" },
];
