import React, {Component} from 'react'
import {connect} from 'react-redux'
import ModalApp from '../../SharedComponents/Modal'
import ConnectHelpModalBody from "./ConnectHelpModalBody"

class ConnectHelpModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ModalApp name="connectHelpModal"
                      header={null}
                      body={<ConnectHelpModalBody />}
            />
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ConnectHelpModal)