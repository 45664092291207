import React, {Component} from 'react'
import AuthComponent from "../SharedComponents/AuthComponent"
import LogoutButton from '../Auth/LogoutButton'
import { connect } from 'react-redux'
import styles from "../Constants/styles";
import LoginButton from "../Auth/LoginButton";
import NavbarLogo from "./NavbarLogo";
import ProductMenu from "./ResourcesMenu";
import ServicesMenu from "./ServicesMenu";
import ModalLink from "./ModalLink";
import {toggleModal} from "../Actions/ToggleModal";
import UpgradeBtn from "./UpgradeBtn"
import DropdownMenu from "./DropdownMenu"
import {toggleRunProductDemo} from "../Actions/ToggleRunProductDemo";
import {modifyDemoTourStep} from "../Actions/ModifyDemoTourStep";
import ChatToggleButton from "../SharedComponents/ChatToggleButton"
import UserPlanTag from "./UserPlanTag";
import SwitchTeamDropdown from "../Team/SwitchTeamDropdown"
import {isHomePage} from "../Constants/isHomePage";

class NavbarBrowser extends Component {
    displayNavbar = () => {
        const url = window.location.href;

        if (url.includes("/login") || url.includes("/reset-password") || url.includes("/signup")){
            return false
        } else {
            return true
        }
    }

    runDemo = () => {
        this.props.modifyDemoTourStep(0);
        this.props.toggleRunProductDemo(true);
    }

    render() {
        const upgradeBtn = <div style={{ marginTop: "-8px"}}>
            <UpgradeBtn />
        </div>

        if (this.displayNavbar()){
            if (!this.props.isAuth){
                return (
                    <div style={{ display: "grid", gridTemplateColumns: "50% 50%", width: "100%", backgroundColor: "white", borderBottom: "1px solid #F0F0F0"}}>
                        <div id="navbarIcon">
                            <NavbarLogo isAuth={this.props.isAuth}/>
                        </div>
                        <div style={{ textAlign: "right", paddingTop: "20px", paddingRight: "24px"}}>
                            <div style={{ display: "grid", gridTemplateColumns: "1fr auto auto auto auto", columnGap: "32px"}}>
                                <div>
                                </div>
                                <div>
                                    {/*<a href="/contact" className="navLink">Contact Us</a>*/}
                                </div>
                                <div>
                                    {
                                        this.props.hidePricing ? null : <a href="/pricing" className="navLink">Pricing</a>
                                    }
                                </div>
                                {/*<div>*/}
                                    {/*<a href="/about" className="navLink">About</a>*/}
                                {/*</div>*/}
                                <div>
                                    <span className="navLink" style={{color: styles.blue}}>
                                        <ModalLink
                                            linkText="Log in"
                                            modalName="loginModal"/>
                                    </span>
                                </div>
                                <div>
                                    <div style={{ textAlign: "right"}}>
                                        <LoginButton/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            return (
                <div style={{ display: "grid", gridTemplateColumns: "37% 25% 38%", width: "100%", backgroundColor: "white", borderBottom: "1px solid #F0F0F0"}}>
                    <div id="navbarIcon">
                        <NavbarLogo isAuth={this.props.isAuth}/>
                    </div>
                    <div>
                        {
                            this.props.allTeamsInfo.length > 1 && isHomePage() ?
                                <div style={{ display: "grid", gridTemplateColumns: "1fr auto", columnGap: "8px", paddingTop: "12px"}}>
                                    <div style={{ fontSize: "16px", fontWeight: "700", color: styles.mainText, textAlign: "right", paddingTop: "8px"}}>
                                        <i className="fa-solid fa-users"/> <span style={{ paddingLeft: '4px'}}> Current Team</span>
                                    </div>
                                    <div style={{ fontSize: "16px"}}>
                                        <SwitchTeamDropdown />
                                    </div>
                                </div> : null
                        }
                    </div>
                    <div style={{ textAlign: "right", paddingTop: "20px", paddingRight: "24px"}}>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr auto auto auto", columnGap: "32px"}}>
                            <div>
                                {/*<span className="navLink"><ModalLink linkText="FAQs" modalName="faqsModal" /></span>*/}
                            </div>
                            <div>
                                {
                                    this.props.userPlan === null ?
                                        <a href="/pricing" className="navLink">Pricing</a>

                                        :
                                        <div style={{ marginTop: "-2px"}}>
                                            <UserPlanTag userPlan={this.props.userPlan}/>
                                        </div>
                                }
                            </div>
                            <div>
                                <AuthComponent
                                    auth={<a href="/profile" className="navLink">Profile</a>}
                                    nonAuth={
                                        <span className="navLink" style={{color: styles.blue}}>
                                            <ModalLink
                                                linkText="Log in"
                                                modalName="loginModal"/>
                                        </span>
                                    }
                                />
                            </div>
                            <div>
                                <AuthComponent
                                    auth={<LogoutButton />}
                                    nonAuth={window.location.pathname === "/" ? null :<div style={{ textAlign: "right"}}>
                                        {/*actually signing up or if homepage don't show*/}
                                        <LoginButton/>
                                    </div>}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return null
        }
    }
}

const mapStateToProps = (state) => ({
    isAuth: state.mainState.isAuth,
    userPlan: state.mainState.userPlan,
    allTeamsInfo: state.mainState.allTeamsInfo
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    toggleRunProductDemo: toggleRunProductDemo,
    modifyDemoTourStep: modifyDemoTourStep
}

export default connect(mapStateToProps, mapActionsToProps)(NavbarBrowser)