import React, {Component} from 'react'
import styles from "../../Constants/styles";
import HelpToolbar from "../../SharedComponents/Help/HelpToolbar"

class HelpSidebar extends Component {
    render() {
        return (
            <div>
                <div style={{ fontSize: "16px", fontWeight: "700", color: styles.mainText, marginBottom: "12px"}}>
                    <i className="fa-solid fa-circle-info"/> <span style={{ paddingLeft: '4px'}}>Get help from us</span>
                </div>
                <HelpToolbar />
            </div>
        )
    }
}

export default HelpSidebar