import React, {Component} from 'react'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

class InfoPopup extends Component {
    render() {
        return (
            <Popup trigger={open => (
                    <span>
                        <i className="fa-solid fa-circle-info"></i>
                    </span>
                    )}
                   position={this.props.position}
                   on={['hover', 'focus']}
                   closeOnDocumentClick
            >
                <div style={{ padding: "8px"}}>
                    {'info' in this.props ? this.props.info : 'popupComponent' in this.props ? this.props.popupComponent : null}
                </div>
            </Popup>

        )
    }
}

export default InfoPopup