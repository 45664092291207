import React, {Component} from 'react'
import MonthlySelection from "./MonthlySelection";
import styles from "../../../Constants/styles";
import capitalizeHyphenated from "../../../Constants/capitalizeHyphenated";

const monthLabels = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december"
]

class YearlySelection extends Component {
    isMonthSelected = (month) => {
        if ("months" in this.props.report_info){
            if (Array.isArray(this.props.report_info.months)){
                return this.props.report_info.months.includes(month)
            }
        }

        return false
    }

    toggleMonth = (month) => {
        const reportInfo = JSON.parse(JSON.stringify(this.props.report_info));

        if (this.isMonthSelected(month)){
            let months = reportInfo.months;

            months.splice(months.indexOf(month), 1)

            if (months.length > 0){
                reportInfo.months = months
            } else {
                delete reportInfo.months
            }
        } else {
            if ("months" in reportInfo){
                if (Array.isArray(reportInfo.months)){
                    let months = reportInfo.months;
                    months.push(month)
                }
            } else {
                reportInfo.months = [month]
            }
        }

        this.props.changeState({ report_info: reportInfo })
    }

    getMonthBox = (month, label) => {
        const isMonthSelected = this.isMonthSelected(month);

        return <div style={{
            backgroundColor: isMonthSelected ? styles.blue : "white",
            color: isMonthSelected ? "white" : styles.subText,
            border: "1px solid " + (isMonthSelected ? styles.blue : "#F0F0F0"),
            padding: "4px 8px",
            cursor: "pointer"
        }} onClick={e => this.toggleMonth(month)}>
            {capitalizeHyphenated(label.substring(0, 3))}
        </div>
    }

    render() {
        const gridStyle = { display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr"}

        return (
            <div>
                <div style={{fontSize: "16px", color: styles.subText, fontWeight: "700"}}>
                    Select month(s)
                </div>
                <div>
                    <div style={gridStyle}>
                        {monthLabels.slice(0,4).map((x, idx) => <div key={idx}>
                            {this.getMonthBox(idx+1, x)}
                        </div>)}
                    </div>
                    <div style={gridStyle}>
                        {monthLabels.slice(4, 8).map((x, idx) => <div key={idx}>
                            {this.getMonthBox(idx+5, x)}
                        </div>)}
                    </div>
                    <div style={gridStyle}>
                        {monthLabels.slice(8, 12).map((x, idx) => <div key={idx}>
                            {this.getMonthBox(idx+9, x)}
                        </div>)}
                    </div>
                </div>
                <MonthlySelection
                    userReportFrequency={this.props.userReportFrequency}
                    report_info={this.props.report_info}
                    changeState={this.props.changeState}
                />
            </div>
        )
    }
}

export default YearlySelection