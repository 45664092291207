import React, {Component} from 'react'
import {connect} from 'react-redux'
import {getQueryParamValue} from "./Constants/getQueryParamValue";

let client_id = "rBDv~6IBp5ITJDjBo5m38021m5i4kh1.xoviOp1NfxzD"
let client_secret = "nh,0l,lRxr-fj6z}/xop+@_vr[pH}HZz&'ulIFh7_7$"
let redirectURL = encodeURIComponent("https://www.lovespreadsheets.com/struxi-data-cleaning")
// let redirectURL = "https://webhook.site/73bb44a1-1a4b-4481-84c7-4cdfbc1f822e"

class StruxiDataCleaning extends Component {
    getURL = () => {
        const url = "https://api.outreach.io/oauth/authorize?client_id=" + client_id + "&redirect_uri=" + redirectURL + "&response_type=code&scope=prospects.all"

        return url
    }

    componentDidMount(){
        const url = window.location.href;

        if (url.includes("?code=")){
            const code = getQueryParamValue("code", url)
            console.log(code)

            localStorage.setItem("outreach_code", code)

            this.refreshLink.click()

        } else {
            if (localStorage.getItem("outreach_code") !== null){
                const code = localStorage.getItem("outreach_code")
                console.log(code)
                const formData = {
                    "client_id": client_id,
                    "client_secret": client_secret,
                    "redirect_uri": redirectURL,
                    "grant_type": "authorization_code",
                    "code": code
                }

                fetch("https://api.outreach.io/oauth/token", {
                    method: "POST",
                    headers: {'Content-Type': 'application/json', 'Accept': 'application/json'},
                    body: JSON.stringify(formData)
                }).then(res => {
                    console.log(res.status);

                    res.json().then(data => {
                        console.log(data)
                    }).catch(err => console.log(err))

                }).catch(err => console.log(err))
            }
        }
    }

    render() {
        return (
            <div>
                <a href="/struxi-data-cleaning" style={{ display: "none" }} ref={refreshLink => this.refreshLink = refreshLink}> </a>
                <a href={this.getURL()}>Get Code</a>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(StruxiDataCleaning)