import React, {Component} from 'react'
import styles from "../../Constants/styles";
import {languages} from "./languages";

class LanguageModalBody extends Component {
    constructor(props){
        super(props);

        this.state = {
            searchTerm: ''
        }
    }

    handleInputChange = (e) => this.setState({ searchTerm: e.target.value })

    render() {
        let languagesDisplay = [];

        for (let i=0; i<languages.length; i++){
            const languageObj = languages[i];
            const searchTerm = this.state.searchTerm.toLowerCase().trim();
            if (searchTerm.length > 0){
                if (languageObj.language.toLowerCase().includes(searchTerm) || languageObj.language_local.toLowerCase().includes(searchTerm)){
                    languagesDisplay.push(languageObj)
                }
            } else {
                languagesDisplay.push(languageObj)
            }

        }

        return (
            <div>
                <div style={{ fontSize: "20px", fontWeight: "700", color: styles.mainText}}>
                    You can ask in any of the 95+ languages listed below
                </div>
                <div style={{ fontSize: "12px", color: styles.subText}}>
                    If you don't see your language, try it out any way! It may work
                </div>
                <div style={{ width: "50%", marginTop: "12px"}}>
                    <div className="search-container">
                        <form className="form-nosubmit-search">
                            <input className="nosubmit-search" type="search" placeholder="Search your language..." value={this.state.searchTerm} onChange={this.handleInputChange}/>
                        </form>
                    </div>
                </div>
                <div style={{ marginTop: "12px"}}>
                    {
                        languagesDisplay.length > 0 ?
                            languagesDisplay.map((x, idx) => <div key={idx}>
                                {x.language + " (" + x.language_local + ")"}
                            </div>) :
                        <div style={{ color: styles.orange }}>
                            Language in search not tested BUT try it out any way. It may work!
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default LanguageModalBody