import React, {Component} from 'react'
import PromoCountdown from "./PromoCountdown";

let utc3 = new Date(Date.UTC(2023, 10, 28, 20, 0, 0, 0));

class PromoTimer extends Component {
    constructor(props){
        super(props);

        this.state = {
            timeLeft: this.initTimeLeft(false)
        }

        this.initTimeLeft = this.initTimeLeft.bind(this);
    }

    initTimeLeft(setState){
        let timeLeft = {day: null, hour: null, minute: null, second: null};
        const currDate = new Date();

        if (currDate <= utc3){
            const diffDays = (utc3 - currDate) / (1000 * 60 * 60 * 24);
            timeLeft.day = Math.floor(diffDays)
            const diffHours = (diffDays % 1) * 24;
            timeLeft.hour = Math.floor(diffHours)
            const diffMinutes = (diffHours % 1) * 60;
            timeLeft.minute = Math.floor(diffMinutes);
            const diffSeconds = (diffMinutes % 1) * 60;
            timeLeft.second = Math.floor(diffSeconds);
        }

        if (setState){
            this.setState({ timeLeft: timeLeft })
        }
        else{
            return timeLeft
        }
    }

    componentWillMount(){
        setInterval(() => this.initTimeLeft(true), 1000);
    }

    render() {
        return (
            <span>
                <PromoCountdown timeLeft={this.state.timeLeft}/>
            </span>
        )
    }
}

export default PromoTimer