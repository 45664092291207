import React, {Component} from 'react'
import ModalApp from '../../../../SharedComponents/Modal'
import GetSupportModalBody from './GetSupportModalBody'

class GetSupportModal extends Component {
    render() {
        return (
            <div>
                <ModalApp name="getSupportModal"
                          header={<span style={{ fontSize: "20px", fontWeight: "700"}}><i className="fa-solid fa-paper-plane"></i> <span style={{ paddingLeft: "4px"}}> Submit a support ticket. 12 hour reply. Guaranteed.</span></span>}
                          body={<GetSupportModalBody />}
                          size="medium"
                />
            </div>
        )
    }
}

export default GetSupportModal