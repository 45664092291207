import React, {Component} from 'react'
import {connect} from 'react-redux'
import DataSourcesDropdown from "./DataSourcesDropdown"
import InfoPopup from "../../../SharedComponents/InfoPopup"
import isEmailValid from "../../../Constants/isEmailValid";
import styles from "../../../Constants/styles";
import Button from "../../../SharedComponents/Button"
import API_Root from "../../../Constants/API_Root";
import {toggleToast} from "../../../Actions/ToggleToast";
import BannerAlert from "../../../SharedComponents/BannerAlert";

class RequestDataForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSource: {
                value: 'salesforce',
                label: 'Salesforce'
            },
            email: localStorage.getItem("email"),
            msg: "",
            sending: false,
            sendingComplete: false
        }
    }

    changeDataSource = (newSource) => {
        this.setState({
            dataSource: newSource
        })
    }

    onChange = (e) => this.setState({ [e.target.name]: e.target.value })

    sendConnectionRequest = () => {
        this.setState({
            sending: true
        })

        const error = () => {
            this.setState({
                sending: false
            }, () => this.props.toggleToast({
                show: true,
                message: "There was an error submitting your request. Please email us at info@lovespreadsheets.com",
                type: "error"
            }))
        }

        fetch(API_Root + 'api/send-admin-email/', {
            method: "POST",
            body: JSON.stringify({
                fromEmail: "info@lovespreadsheets.com",
                toEmail: "admin@lovespreadsheets.com",
                message: this.state.msg + "\n\nContact email: " + this.state.email ,
                subject: localStorage.getItem("email") + " requests " + this.state.dataSource.label,
                s3URL: "",
                toEmailSelection: true
            }),
            headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}
        }).then(
            (res) => {
                if (res.status === 200){
                    this.setState({
                        sending: false,
                        sendingComplete: true
                    })
                } else{
                    error()
                }
            }).catch(err => {
                error()
        })
    }

    render() {
        const displayHeader = {fontFamily: "Lato", fontSize: "14px", fontWeight: "400", color: "#5E645E", marginBottom: "8px"}

        return (
            <div>
                <DataSourcesDropdown changeDataSource={this.changeDataSource} notInclude={['mysql', 'postgres', 'mariadb', 'sqlserver', 'snowflake', 'oracle', 'mongodb', 'api']} />
                <div style={{ marginTop: "16px"}}>
                    <div style={displayHeader}>Preferred email <span style={{color: 'orange', fontSize: "12px"}}>required, if it exists</span> <InfoPopup info={<span>We will contact you on this email for any info we need to connect your {this.state.dataSource.label} account</span>}/></div>
                    <input
                        style={{width: '100%'}}
                        className="inputGray"
                        type='text'
                        value={this.state.email}
                        placeholder='abc@xyz.com'
                        name='email'
                        onChange={this.onChange}/>
                    {
                        !isEmailValid(this.state.email) ?
                            <div style={{ color: styles.red }}>
                                Please enter a valid email
                            </div> : null
                    }
                </div>
                <div style={{ marginTop: "16px"}}>
                    <div style={displayHeader}>Anything we should know</div>
                    <textarea
                        style={{width: '100%'}}
                        rows={3}
                        className="inputGray"
                        value={this.state.msg}
                        placeholder='I have 2 accounts but I am not the admin'
                        name='msg'
                        onChange={this.onChange}/>
                </div>
                <div style={{ marginTop: "16px"}}>
                    <Button disabled={!isEmailValid(this.state.email)}
                            style={{backgroundColor: "#6FBB98", color: "white", cursor: !isEmailValid(this.state.email) ? "not-allowed" : "pointer", border: "none", borderRadius: "4px"}}
                            onClick={this.sendConnectionRequest}
                            loading={this.state.sending}
                            label="Request connection"
                    />
                </div>
                {
                    !this.state.sendingComplete ? null :
                        <div style={{ marginTop: "16px"}}>
                            <BannerAlert
                                type="success"
                                header={"Your request to add a " + this.state.dataSource.label + " connection was successful!"}
                                content={<div>
                                    <div style={{ fontWeight: "700"}}>
                                        Next Steps
                                    </div>
                                    <div>
                                        1. We will email you at {this.state.email} with any additional information required
                                        <br/><br/>
                                        2. Once we receive additional information, we will provide you with the estimated time of completion
                                        <br/><br/>
                                        3. You will be able to get data from your {this.state.dataSource.label} account using natural language
                                        <br/><br/>
                                    </div>
                                </div>}
                            />
                        </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(RequestDataForm)