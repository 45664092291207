import {pricing} from "../SharedComponents/Pricing/pricing";
import {getPricingIdxFromUserPlan} from "./getPricingIdxFromUserPlan";

export const getPricingInfoFromUserPlan = (userPlan) => {
    const pricingIdx = getPricingIdxFromUserPlan(userPlan);

    if (pricingIdx === null){
        return null
    } else {
        return pricing[pricingIdx]
    }

    return null
}