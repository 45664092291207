import React, {Component} from 'react'

class EmailToSubjectMsgInput extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <span style={{fontFamily: "Lato", fontWeight: "400", fontSize: "14px", color: "#5E645E"}}>{this.props.label}</span>
                {
                    this.props.type === "msg" ?
                        <textarea rows={5}
                                  className="inputGray"
                                  value={this.props.val}
                                  onChange={e => this.props.updateVal(this.props.type, e)}
                                  style={{width: "100%"}}
                                  placeholder={this.props.placeholder}
                        /> : <input className="inputGray"
                                    value={this.props.val}
                                    onChange={e => this.props.updateVal(this.props.type, e)}
                                    style={{width: "100%"}}
                                    placeholder={this.props.placeholder}/>
                }
            </div>
        )
    }
}

export default EmailToSubjectMsgInput