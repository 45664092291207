import React, {Component} from 'react'
import styles from "../../Constants/styles";

class JobOpeningsAccordionBody extends Component {
    render() {
        const titleStyle = {fontWeight: "700", fontSize: "16px", color: styles.mainText}
        const descStyle = {fontSize: "14px", color: styles.subText}
        const blockStyle = {paddingBottom: "24px"}

        return (
            <div style={{ padding: "24px" }}>
                <div style={blockStyle}>
                    <div style={titleStyle}>Job Description</div>
                    <div style={descStyle}>{this.props.info.desc}</div>
                </div>
                <div style={blockStyle}>
                    <div style={titleStyle}>Responsibilities</div>
                    <div style={descStyle}>
                        <ul>
                            {this.props.info.roles.map((x, idx) => <li key={idx}>{x}</li>)}
                        </ul>
                    </div>
                </div>
                <div style={blockStyle}>
                    <div style={titleStyle}>Your Experience</div>
                    <div style={descStyle}>
                        <ul>
                            {this.props.info.experience.map((x, idx) => <li key={idx}>{x}</li>)}
                        </ul>
                    </div>
                </div>
                <div style={blockStyle}>
                    <div style={titleStyle}>Apply</div>
                    <div style={descStyle}>
                        Email your resume, a 1 paragraph cover letter with examples of past projects to admin@lovespreadsheets.com with the subject line the same as the job title
                    </div>
                </div>
            </div>
        )
    }
}

export default JobOpeningsAccordionBody