import React, {Component} from 'react'
import { connect } from 'react-redux'
import {toggleIsMobileSidebarOpen} from "../Actions/ToggleIsMobileSidebarOpen";
import MobileNavbarHeader from "./MobileNavbarHeader";
import MobileSidebar from "./MobileSidebar";

class NavbarMobile extends Component {
    render() {
        if (!this.props.isMobileSidebarOpen){
            return (
                <div style={{ backgroundColor: "white",
                    // position: "fixed",
                    width: "100%",
                    borderBottom: "1px solid #F0F0F0" }}>
                    <MobileNavbarHeader isSidebar={false}/>
                </div>
            )
        } else {
            return (
                // null
                <div>
                    <MobileSidebar hidePricing={this.props.hidePricing}/>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    isMobileSidebarOpen: state.mainState.isMobileSidebarOpen,
    isAuth: state.mainState.isAuth
})

export const mapActionsToProps = {
    toggleIsMobileSidebarOpen: toggleIsMobileSidebarOpen
}

export default connect(mapStateToProps, mapActionsToProps)(NavbarMobile)