import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifyAiInput} from "../Actions/ModifyAiInput";
import {modifyAiFiles} from "../Actions/ModifyAiFiles";
import {toggleUseTestData} from "../Actions/ToggleUseTestData";
import {modifyAiFileSelected} from "../Actions/ModifyAiFileSelected";
import {toggleIsAiResultSelected} from "../Actions/ToggleIsAiResultSelected";
import {localTestFileData} from "../Apps/Upload/localTestFileData";
import {testFileData} from "../Apps/Upload/testFileData";
import styles from "../Constants/styles";

class ViewFilesDemoStep extends Component {
    render() {
        return (
            <div style={{ paddingBottom: "0px" }}>
                <div style={{ fontSize: "16px" }}><b>You can ask any question on 1 file</b></div>
                <div style={{ fontSize: "12px", marginTop: "8px", color: styles.subText}}>
                    <div style={{ marginBottom: "4px"}}>Some examples</div>
                    <div style={{ marginBottom: "4px"}}>- run an analysis to see whether movie revenue is correlated with movie ratings</div>
                    <div style={{ marginBottom: "4px"}}>- describe the columns in the data set</div>
                    <div style={{ marginBottom: "4px"}}>- count the number of movies that have descriptions longer than 40 characters</div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    aiFiles: state.mainState.aiFiles,
    aiFileSelected: state.mainState.aiFileSelected
})

const mapActionsToProps = {
    toggleUseTestData: toggleUseTestData,
    modifyAiInput: modifyAiInput,
    modifyAiFiles: modifyAiFiles,
    modifyAiFileSelected: modifyAiFileSelected,
    toggleIsAiResultSelected: toggleIsAiResultSelected
}

export default connect(mapStateToProps, mapActionsToProps)(ViewFilesDemoStep)