import styles from "./styles";

export const appsInfo = {
    filter: {
        icon: 'filter_alt',
        color: styles.red,
        heading: "General question"
    },
    merge: {
        icon: "file_copy",
        color: "#800080",
        heading: "Merge multiples files and sheets"
    },
    split: {
        icon: "content_cut",
        color: "#800080",
        heading: "Split file into smaller files"
    },
    clean: {
        icon: "cleaning_services",
        color: "#0081ff",
        heading: "Clean messy data in columns"
    },
    transform: {
        icon: "pivot_table_chart",
        color: "#0081ff",
        heading: "Transform your data in different formats"
    },
    visualize: {
        icon: "stacked_bar_chart",
        color: "#e27837",
        heading: "Visualize your data in graphs & charts"
    },
    analyze: {
        icon: "query_stats",
        color: "#e27837",
        heading: "Analyze your data using data science algorithms"
    },
    parse: {
        icon: "picture_as_pdf",
        color: "#6FBB98",
        heading: "Convert unstructured data to excel"
    },
    python: {
        icon: 'code',
        color: 'black',
        heading: 'Python code'
    },
    sql: {
        icon: 'storage',
        color: '#808080',
        heading: 'SQL code'
    }
}