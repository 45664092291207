import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../Constants/styles";
import Button from "../SharedComponents/Button"
import API_Root from "../Constants/API_Root";

class DeleteDatabaseModalBody extends Component {
    constructor(props) {
        super(props);

        this.state = {
            matchName: '',
            deleting: false
        }
    }

    getDBInfo = () => {
        const info = this.props.allDatabases[this.props.dbSelectedIdx];

        return {
            pk: info.pk,
            name: info.name,
            dataSource: info.dataSource
        }
    }

    changeName = (e) => this.setState({ matchName: e.target.value })

    deleteDatabase = () => {
        this.setState({ deleting: true })

        const error = () => {
            this.setState({
                deleting: false
            }, () => alert("There was an error deleting your database. Contact our support."))
        }

        const dbPK = this.getDBInfo()['pk']
        const dataSource = this.getDBInfo()['dataSource'];

        fetch(API_Root + "api/delete-love-data-sources/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                token: localStorage.getItem("token"),
                email: localStorage.getItem("email"),
                pk: dbPK,
                dataSource: dataSource
            })
        }).then(res => {
            if (res.status === 200){
                window.location.reload()
            } else {
                error()
            }
        }).catch(err => error())
    }

    render() {
        const dbInfo = this.getDBInfo();
        const isDisabled = dbInfo.name !== this.state.matchName;

        return (
            <div>
                <div style={{ fontSize: "20px", fontWeight: "700", color: styles.mainText, marginBottom: "12px"}}>
                    <i className="fa-solid fa-trash-can"></i> <span style={{ paddingLeft: "4px"}}>Delete Data Source</span>
                </div>
                <div style={{ fontSize: "16px", color: styles.subText, marginBottom: "4px" }}>
                    Type the data source name to delete
                </div>
                <div>
                    <input
                        className="inputGray"
                        value={this.state.matchName}
                        placeholder={dbInfo.name}
                        onChange={this.changeName}
                        style={{ width: "100%"}}
                    />
                </div>
                <div style={{ marginTop: "12px"}}>
                    <Button disabled={isDisabled}
                            style={{backgroundColor: "red", color: "white", cursor: isDisabled ? "not-allowed" : "pointer", border: "none", borderRadius: "4px", textAlign: "left", padding: "12px 16px 10px 16px"}}
                            onClick={this.deleteDatabase}
                            loading={this.state.deleting}
                            icon="fa-solid fa-trash-can"
                            label="Delete Data Source"
                            small
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    allDatabases: state.mainState.allDatabases,
    dbSelectedIdx: state.mainState.dbSelectedIdx
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(DeleteDatabaseModalBody)