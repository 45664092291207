import React, {Component} from 'react'
import {connect} from 'react-redux'
import {ToastContainer, toast} from 'react-toastify'
import { toggleToast } from "../Actions/ToggleToast";

class CustomToastContainer extends Component {
    render() {
        if (this.props.toast.show) {
            const message = this.props.toast.message;
            const toastType = this.props.toast.type;

            if (toastType === "success") {
                toast.success(message)
            }
            else if (toastType === "info") {
                toast.info(message)
            }
            else if (toastType === "warn") {
                toast.warn(message)
            }
            else if (toastType === "error") {
                toast.error(message)
            }

            this.props.toggleToast({show: false, message: "", type: ""})
        }


        return (
            <ToastContainer
                position="top-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover/>
        )
    }
}

const mapStateToProps = (state) => ({
    toast: state.mainState.toast
})

const mapActionsToProps = {
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(CustomToastContainer)