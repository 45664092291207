import React, {Component} from 'react';

class BlogHero extends Component {
    render() {
        return (
            <div id="blogHero">
                <h1>Blog</h1>
            </div>
        );
    }
}


export default BlogHero