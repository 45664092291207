import React, {Component} from 'react'

class OurValuesCard extends Component {
    render() {
        const divStyle = {...{ textAlign: "center"}, ...this.props.imgDivStyle};

        return (
            <div style={divStyle}>
                <div>
                    <img src={this.props.img} style={this.props.imgStyle} alt={this.props.title}/>
                </div>
                <div style={{ textAlign: "center", paddingTop: "24px" }}>
                    <div style={{ fontSize: "24px"}}>
                        {this.props.header}
                    </div>
                    <div style={this.props.descStyle}>
                        {this.props.desc}
                    </div>
                </div>
            </div>
        )
    }
}

export default OurValuesCard