import React, {Component} from 'react'
import Brain from "./Brain.svg"
import Collaborate from "./Collaborate.svg"
import ProblemSolving from "./ProblemSolving.svg"
import OurValuesCard from "./OurValuesCard";

class OurValues extends Component {
    render() {
        const isMobile = 'isMobile' in this.props;
        const imgDivStyle = isMobile ? { paddingTop: "36px" } : null;
        const imgStyle = isMobile ? { maxWidth: "72px" } : { maxWidth: "120px" };
        const descStyle = isMobile ? { paddingTop: "8px" } : { padding: "8px 12px" }

        return (
            <div>
                <div className="row" style={this.props.divStyle}>
                    <div className="col-sm-2" />
                    <div className="col-sm-8">
                        <div style={{ fontSize: "28px", fontWeight: "700", textAlign: "center"}}>
                            Our Values
                        </div>
                    </div>
                    <div className="col-sm-2" />
                </div>
                <div className="row" style={this.props.divStyle}>
                    <div className="col-sm-4">
                        <OurValuesCard img={Brain}
                                       header="Always look to the future"
                                       desc="We design and build things for the next evolution of data analysis software"
                                       imgDivStyle={imgDivStyle}
                                       imgStyle={imgStyle}
                                       descStyle={descStyle}
                        />
                    </div>
                    <div className="col-sm-4">
                        <OurValuesCard img={ProblemSolving}
                                       header="Every problem has a solution"
                                       desc="Identifying problems without a solution makes one a critic. We are builders"
                                       imgDivStyle={imgDivStyle}
                                       imgStyle={imgStyle}
                                       descStyle={descStyle}
                        />
                    </div>
                    <div className="col-sm-4">
                        <OurValuesCard img={Collaborate}
                                       header="Bring opinions, not egos"
                                       desc="We strongly believe there is always a kind way to bring together diverse opinions"
                                       imgDivStyle={imgDivStyle}
                                       imgStyle={imgStyle}
                                       descStyle={descStyle}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default OurValues