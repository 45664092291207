import React, {Component} from 'react'
import {connect} from 'react-redux'
import emailIsValid from "../../../../Constants/isEmailValid";
import bytesToMB from '../../../../Constants/bytesToMB'
import API_Root from '../../../../Constants/API_Root'
import BannerAlert from "../../../../SharedComponents/BannerAlert";
import Button from "../../../../SharedComponents/Button";
import styles from "../../../../Constants/styles";

class GetSupportModalBody extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            email: localStorage.getItem("email"),
            subject: '',
            message: '',
            supportFiles: null,
            sending: false,
            responseMessage: null,
            fileMessage: null
        }

        this.dismissMessage = this.dismissMessage.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.emailValid = this.emailValid.bind(this);
        this.sendSupport = this.sendSupport.bind(this);
        this.dismissFileMessage = this.dismissFileMessage.bind(this);
        this.clearFiles = this.clearFiles.bind(this);
    }

    sendSupport(e){
        const errorMessage = <BannerAlert
            onDismiss={this.dismissMessage}
            content={null}
            header={<span>There was an error storing your support request. Please <a href="mailto:support@apispreadsheets.com?Subject=Support">email us at support@apispreadsheets.com</a> directly.</span>}
            action="close"
            type="error"
        />

        this.setState({
            sending: true
        })

        let formData = new FormData();

        // Add Files to Form Data
        if (this.state.supportFiles !== null){
            for (let i=0; i<this.state.supportFiles.length; i++){
                formData.append("file" + i.toString(), this.state.supportFiles[i]);
            }
        }

        // Add form info
        formData.append("email", this.state.email)
        formData.append("subject", this.state.subject);
        formData.append("message", this.state.message + "\n" + String(this.props.modalInfo.conversationId));
        formData.append("name", this.state.name);
        formData.append("userEmail", localStorage.getItem("email"));

        fetch(API_Root + 'api/store-love-spreadsheets-support/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: formData
        }).then((res) => {
            if (res.status === 201){
                this.setState({
                    sending: false,
                    responseMessage: <BannerAlert
                        onDismiss={this.dismissMessage}
                        header="Thanks for sending your support request. We will be reaching out to you within 12 hours. Guaranteed."
                        type="success"
                        action="close"
                    />
                })
            }
            else{
                this.setState({
                    sending: false,
                    responseMessage: errorMessage
                })
            }
        }).catch(err => {
            this.setState({
                sending: false,
                responseMessage: errorMessage
            })
        })
    }

    clearFiles(e){
        this.setState({
            supportFiles: null,
            fileMessage: null
        })
    }


    handleFileUpload(e){
        const files = e.target.files;

        if (files.length > 0){
            let fileSize = 0;

            for (let i=0; i<files.length; i++){
                fileSize += bytesToMB(files[i].size);
            }

            if (this.state.supportFiles !== null){
                for (let j=0; j<this.state.supportFiles.length; j++){
                    fileSize += bytesToMB(this.state.supportFiles[j].size);
                }
            }

            if (fileSize <= 5){
                this.setState({
                    supportFiles: files,
                })
            }
            else{
                const fileMessage = <BannerAlert
                    onDismiss={this.dismissFileMessage}
                    content={null}
                    header="You cannot upload more than 5MB Total"
                    type="error"
                    action="close"
                />;

                this.setState({
                    fileMessage: fileMessage
                })
            }


        }
    }

    handleInputChange(e){
        const name = e.target.name;
        const value = e.target.value;

        if (name === "subject"){
            if (value.length <= 75){
                this.setState({
                    [name]: value
                })
            }
        }
        else if (name === "message"){
            if (value.length <= 5000){
                this.setState({
                    [name]: value
                })
            }
        }
        else{
            this.setState({
                [name]: value
            })
        }
    }

    dismissMessage(e){
        this.setState({
            responseMessage: null
        })
    }

    dismissFileMessage(e){
        this.setState({
            fileMessage: null
        })
    }

    emailValid(){
        if (this.state.email !== null){
            if (this.state.email.trim() !== ""){
                return emailIsValid(this.state.email)
            }
            else{
                return true
            }
        }
        else{
            return true
        }

    }

    render() {
        const inputFileStyle = {
            width: '0.1px',
            height: '0.1px',
            opacity: '0',
            overflow: 'hidden',
            position: 'absolute',
            zIndex: '-1'
        };

        const emailError = !this.emailValid() ? <small className="inputError">Enter a valid email</small> : null;
        const subjectCharLeft = 75 - this.state.subject.length;
        const subjectCharMessage = subjectCharLeft.toString() + " character" + (subjectCharLeft !== 1 ? "s" : '') + " left";

        const detailCharLeft = 5000 - this.state.message.length;
        const detailCharMessage = detailCharLeft.toString() + " character" + (subjectCharLeft !== 1 ? "s" : '') + " left";

        let filesUploadedMessage = null;

        if (this.state.supportFiles !== null){
            let fileNames = [];

            for (let i=0; i<this.state.supportFiles.length; i++){
                fileNames.push(this.state.supportFiles[i].name);
            }

            filesUploadedMessage =<BannerAlert
                type="info"
                header={<span><b>Files Selected</b><button id="supportFormClearAllButton" onClick={this.clearFiles}>Clear All</button></span>}
                content={<ul>{fileNames.map((x, idx) => <li key={idx}>{x}</li>)}</ul>}
            />;
        }

        const inputStyle = {width: "100%"}
        const divStyle = { marginBottom: "12px"};

        return (
            <div id="supportForm" style={{ marginTop: "12px" }}>
                {this.state.responseMessage}
                <div style={divStyle}>
                    <span className="supportFormLabel">Your Name</span>
                    <input style={inputStyle}
                           name='name'
                           onChange={this.handleInputChange}
                           value={this.state.name}
                           className="inputGray"
                    />
                </div>
                <div style={divStyle}>
                    <span><span className="supportFormLabel">Preferred Email</span><small className="requiredLabel">Required</small></span>
                    <input type="email"
                            name="email"
                            onChange={this.handleInputChange}
                            value={this.state.email} className="inputGray" style={inputStyle} />
                    {emailError}
                </div>
                <div style={divStyle}>
                    <span><span className="supportFormLabel">Briefly Describe Your Issue</span><small className="requiredLabel">Required</small><small id="subjectCharLeft"><i>{"   " + subjectCharMessage}</i></small></span>
                    <input
                           name="subject"
                           onChange={this.handleInputChange}
                           value={this.state.subject}
                           className="inputGray" style={inputStyle} />
                </div>
                <div style={divStyle}>
                    <span><span className="supportFormLabel">Provide As Much Detail As Possible</span><small id="subjectCharLeft"><i>{"  " + detailCharMessage}</i></small></span>
                    <div>
                        <textarea
                            onChange={this.handleInputChange}
                            name="message"
                            value={this.state.message}
                            className="inputGray"
                            style={inputStyle}
                            rows={3} />
                    </div>
                </div>
                <input
                    type="file"
                    onChange={this.handleFileUpload}
                    style={inputFileStyle}
                    multiple={true}
                    name="inputFileUpload"
                    id="inputFileUpload"
                    accept=".txt, .csv, .xlsx, .png, .jpeg, .jpg, .doc, .docx, .pdf"
                />
                <div style={{ display: "grid", gridTemplateColumns: "auto 1fr", columnGap: "8px"}}>
                    <div>
                        <label htmlFor="inputFileUpload">
                            <div className="buttonRaw" id="supportUploadFiles">
                                <span className="material-icons-outlined">file_upload</span>
                            </div>
                        </label>
                    </div>
                    <div>
                        <span>
                            <span className="supportFormLabel">Upload Supporting Files <small>(.txt, .csv, .xlsx, .png, .jpeg, .doc, .docx)</small></span>
                            <small id="subjectCharLeft">    5 MB Max</small>
                        </span>
                    </div>
                </div>
                <br/>
                {filesUploadedMessage}
                {this.state.fileMessage}
                <Button label="Get Help"
                        style={{width: "100%", textAlign: "center", backgroundColor: "white", color: styles.mainGreen, border: "1px solid " + styles.mainGreen}}
                        onClick={this.sendSupport}
                        loading={this.state.sending}
                        disabled={!this.emailValid() || this.state.subject.trim().length === 0}/>
                <br/><br/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    // conversationId
    modalInfo: state.mainState.modalInfo
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(GetSupportModalBody)