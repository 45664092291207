import React, {Component} from 'react'
import styles from "../../Constants/styles";

class ModalHeaders extends Component {
    render() {
        return (
            <div style={{
                color: 'subHeader' in this.props ? styles.subText : styles.mainText,
                fontSize: 'subHeader' in this.props ? "20px" : "24px",
                fontWeight: "700", marginBottom: "24px"}}>
                {this.props.header}
            </div>
        )
    }
}

export default ModalHeaders