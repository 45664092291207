import React, {Component} from 'react'

class IconLabel extends Component {
    render() {
        let style = { display: "grid", gridTemplateColumns: "auto 1fr", fontSize: "20px", columnGap:  "8px"};

        if ('style' in this.props){
            style = {...style, ...this.props.style}
        }

        const fontNum = parseInt(style['fontSize'].replace("px", ""))

        return (
            <div style={style}>
                <div style={{ fontSize: (fontNum + 4).toString() + "px" }}>
                    <i className={this.props.icon} />
                </div>
                <div style={{ textAlign: "left"}}>
                    {this.props.label}{'required' in this.props ? <span style={{ color: "red "}}>*</span> : null}
                </div>
            </div>
        )
    }
}

export default IconLabel