import React, {Component} from 'react'
import {connect} from 'react-redux'
import Select from 'react-select'
import {modifyDBSelectedIdx} from "../Actions/ModifyDBSelectedIdx";
import styles from "../Constants/styles";
import {toggleModal} from "../Actions/ToggleModal";
import {getSelectedDbTablesAndColumns} from "../Constants/getSelectedDbTablesAndColumns";
import {modifyModalInfo} from "../Actions/ModifyModalInfo";
import {toggleToast} from "../Actions/ToggleToast";
import API_Root from "../Constants/API_Root";
import {modifyAllDatabases} from "../Actions/ModifyAllDatabases";
import {images} from "../Constants/images";
import DataSourceDisplayMessage from "./DataSourceDisplayMessage"

class DatabaseToolbar extends Component {
    constructor(props) {
        super(props);
    }

    onChange = (dbInfo) => {
        if (dbInfo !== null){
            this.props.modifyDBSelectedIdx(dbInfo.value)
        }
    }

    getLabelForDropdown = (dbInfo) => {
        let dataSource;
        if (dbInfo.dataSource === "database"){
            dataSource = dbInfo.authInfo.type.toLowerCase();
        } else {
            dataSource = dbInfo.dataSource
        }

        return <span>
            <img className="avatar"
                    style={{ height: "20px", width: "20px"}}
                    src={images[dataSource + 'Logo']}
                    alt={dataSource + "Logo"} />
            <span style={{ paddingLeft: "8px"}}>{(dbInfo.name)}</span>
            </span>
    }

    hasReportResultsBeenObtained = () => {
        if (this.props.reportSelectedIdx !== null){
            const reportInfo = this.props.allDBReports[this.props.reportSelectedIdx];
            const results = reportInfo.results_100_rows;

            try {
                if ("testRecords" in results && 'testColumns' in results){
                    return true
                }
            } catch (e) {
                return false
            }
        }

        return false
    }

    getDbSelectedIdx = () => {
        if (this.props.dbSelectedIdx === null){
            return null
        } else {
            if (this.hasReportResultsBeenObtained()) {
                const reportInfo = this.props.allDBReports[this.props.reportSelectedIdx];

                const reportInputFK = reportInfo.input_fk;
                let dbIdx = this.props.dbSelectedIdx;

                for (let i = 0; i < this.props.allDatabases.length; i++) {
                    if (this.props.allDatabases[i].pk === reportInputFK) {
                        dbIdx = i;
                        break
                    }
                }

                return dbIdx
            } else {
                return this.props.dbSelectedIdx
            }
        }
    }

    selectedValue = () => {
        const dbIdx = this.getDbSelectedIdx()

        if (dbIdx === null){
            return null
        } else {
            return {value: dbIdx, label: this.getLabelForDropdown(this.props.allDatabases[dbIdx])}
        }
    }

    deleteDatabase = () => {
        this.props.toggleModal("deleteDatabaseModal")
    }

    getDbInfo = () => (this.props.allDatabases[this.getDbSelectedIdx()])

    editDBSettings = () => {
        this.props.modifyModalInfo({
            dbInfo: this.getDbInfo()
        })

        this.props.toggleModal("editDatabaseSettingsModal")
    }

    refreshTablesAndColumns = () => {
        this.props.toggleModal("loadingModalRefreshTables");

        const error = () => {
            this.props.toggleModal(null);
            this.props.toggleToast({
                show: true,
                type: "error",
                message:"There was an error getting your tables and columns. Please contact our support"
            })
        }

        const dbInfo = this.getDbInfo()

        fetch(API_Root + "api/refresh-database-tables-and-columns/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                token: localStorage.getItem("token"),
                email: localStorage.getItem("email"),
                pk: dbInfo.pk
            })
        }).then(res => {
            if (res.status === 200){
                res.json().then(data => {
                    const copiedAllDBs = JSON.parse(JSON.stringify(this.props.allDatabases));
                    const dbInfo = copiedAllDBs[this.getDbSelectedIdx()];

                    dbInfo.tablesAndColumnInfo = data

                    copiedAllDBs[this.getDbSelectedIdx()] = dbInfo

                    this.props.modifyAllDatabases(copiedAllDBs)

                    this.props.toggleModal(null)

                    this.props.toggleToast({
                        show: true,
                        type: "success",
                        message:"Tables and columns for your database read successfully"
                    })
                }).catch(err => error())
            } else {
                error()
            }
        }).catch(err => error())

    }

    viewTablesAndColumns = (tablesAndCols) => {
        this.props.modifyModalInfo({
            tablesAndColumnInfo: tablesAndCols
        })

        this.props.toggleModal("viewTablesAndColumnModal")
    }

    render() {
        const dbInfo = this.getDbInfo();
        const dbTablesAndCols = getSelectedDbTablesAndColumns(this.getDbSelectedIdx(), this.props.allDatabases)
        const dataSource = dbInfo.dataSource;

        let canConnect = dbInfo.canConnect

        if (dbInfo.dataSource === 'api'){
            canConnect = true
        }

        return (
            <div style={{ padding: "12px 24px"}}>
                <div style={{ fontSize: "16px", fontWeight: "700"}}>
                    Data source selected
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: "1fr auto", columnGap: "16px"}}>
                    <div>
                        <div style={{ fontSize: "16px", color: styles.subText}}>
                            {
                                this.getDbSelectedIdx() === null ? null :
                                    <Select
                                        onChange={this.onChange}
                                        value={this.selectedValue()}
                                        options={this.props.allDatabases.map((x, idx) => ({ value: idx, label: this.getLabelForDropdown(x)}))}
                                        isDisabled={this.hasReportResultsBeenObtained()}
                                    />
                            }
                        </div>
                    </div>
                    <div style={{ paddingTop: "6px"}}>
                    {
                        this.props.selectedTeamRole === "analyst" || this.props.selectedTeamRole === null ? null :
                            <i className="fa-solid fa-trash-can" style={{ color: styles.red, fontSize: "14px", cursor: "pointer" }} onClick={this.deleteDatabase}></i>
                    }
                    </div>
                </div>
                <div>
                    {
                        !canConnect ?
                        <div>
                            The data source cannot connect. <span className="linkStyle" onClick={this.editDBSettings}>Troubleshoot your database connection</span>
                        </div> :
                            dataSource === "database" ?
                                dbTablesAndCols === null ?
                                <div>
                                    Couldn't read your tables and columns so the AI will not work. <span className="linkStyle"  onClick={this.refreshTablesAndColumns}>Try again to read your tables and columns</span>
                                </div> :
                                <div>
                                    <span className="linkStyle" onClick={e => this.viewTablesAndColumns(dbTablesAndCols)}>View database tables and columns</span>
                                </div>
                            : <DataSourceDisplayMessage
                                    dataSource={dataSource}
                                    dbIdx={this.getDbSelectedIdx()}
                                    dbInfo={dbInfo}
                                />
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    allDatabases: state.mainState.allDatabases,
    dbSelectedIdx: state.mainState.dbSelectedIdx,
    selectedTeamRole: state.mainState.selectedTeamRole,
    reportSelectedIdx: state.mainState.reportSelectedIdx,
    allDBReports: state.mainState.allDBReports
})

const mapActionsToProps = {
    modifyDBSelectedIdx: modifyDBSelectedIdx,
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo,
    toggleToast: toggleToast,
    modifyAllDatabases: modifyAllDatabases
}

export default connect(mapStateToProps, mapActionsToProps)(DatabaseToolbar)