import React, {Component} from 'react'
import {connect} from "react-redux";
import {toggleModal} from "../../Actions/ToggleModal";
import PaymentLink from "./PaymentLink"

class StartPlanButton extends Component {
    openSignUpModal = () => {
        this.props.toggleModal("signUpModal")
    }

    contactUs = () => {
        window.location.href = this.props.url
    }

    render() {
        const btnBody = <div
            style={{
                border: "none",
                backgroundColor: this.props.backgroundColor,
                color: this.props.color,
                cursor: "pointer",
                borderRadius: "4px",
                padding: "8px 32px", textAlign: 'center'
            }}
        >
            {this.props.text}
        </div>

        if (this.props.planIdx === 0){
            return (
                <div onClick={e => this.props.continueForPlan(this.props.planIdx)}>
                    {btnBody}
                </div>
            )
        } else if (this.props.planIdx === 4){
            return <div onClick={this.contactUs}>
                {btnBody}
            </div>
        }

        return (
            <div>
                <PaymentLink
                    planIdx={this.props.planIdx}
                    component={btnBody}
                />
            </div>
        )
    }
}

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(null, mapActionsToProps)(StartPlanButton)