import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../../Constants/styles";
import {modifyAllDBReports} from "../../Actions/ModifyAllDBReports";
import Button from "../../SharedComponents/Button";
import API_Root from "../../Constants/API_Root";
import {modifyReportSelectedIdx} from "../../Actions/ModifyReportSelectedIdx";
import {showUpgradeModal} from "../../Constants/showUpgradeModal";
import {doesUserNeedToUpgrade} from "../../Constants/doesUserNeedToUpgrade";
import {toggleModal} from "../../Actions/ToggleModal";
import {modifyModalInfo} from "../../Actions/ModifyModalInfo";

class AddReportBtn extends Component {
    constructor(props) {
        super(props);

        this.state = {
            adding: false
        }
    }

    addReport = () => {
        const reportLengths = this.props.allDBReports.length;

        if (doesUserNeedToUpgrade(this.props.userPlan, "reports", reportLengths)) {
            showUpgradeModal(this.props.toggleModal, this.props.modifyModalInfo, this.props.userPlan, "reports", reportLengths, this.props.userTeamPK, this.props.selectedTeamPK, this.props.allTeamsInfo)
        } else {
            const error = () => {
                this.setState({
                    adding: false
                }, () => alert("There was an error adding a new report. Try again or contact us at admin@lovespreadsheets.com if the error persists"))
            }

            this.setState({ adding: true })

            fetch(API_Root + "api/add-new-report-for-love-spreadsheets/", {
                method: 'POST',
                body: JSON.stringify({
                    email: localStorage.getItem("email"),
                    token: localStorage.getItem("token"),
                    pk: this.props.allDatabases[this.props.dbSelectedIdx].pk,
                    inputType: "database",
                    teamPK: this.props.selectedTeamPK
                })
            }).then(res => {
                if (res.status === 200){
                    res.json().then(data => {
                        const allDBReportsCopied = JSON.parse(JSON.stringify(this.props.allDBReports))
                        allDBReportsCopied.unshift(data)

                        this.setState({
                            adding: false
                        }, () => {
                            this.props.modifyAllDBReports(allDBReportsCopied);
                            this.props.modifyReportSelectedIdx(0);
                        })
                    }).catch(err => error())
                } else {
                    error()
                }
            }).catch(err => error())
        }
    }

    render() {
        return (
            <div>
                <Button
                        style={{border: "1px solid " + styles.blue, color: styles.blue, backgroundColor: "white", paddingTop: "8px", paddingRight: "16px", width: "100%", paddingBottom: "10px" }}
                        onClick={this.addReport}
                        loading={this.state.adding}
                        icon="fa-solid fa-plus"
                        label="Create new report"
                        small
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    allDBReports: state.mainState.allDBReports,
    dbSelectedIdx: state.mainState.dbSelectedIdx,
    allDatabases: state.mainState.allDatabases,
    selectedTeamPK: state.mainState.selectedTeamPK,
    userPlan: state.mainState.userPlan,
    userTeamPK: state.mainState.userTeamPK,
    allTeamsInfo: state.mainState.allTeamsInfo
})

const mapActionsToProps = {
    modifyAllDBReports: modifyAllDBReports,
    modifyReportSelectedIdx: modifyReportSelectedIdx,
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo,

}

export default connect(mapStateToProps, mapActionsToProps)(AddReportBtn)