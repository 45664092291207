import React, {Component} from 'react'
import API_ROOT from "../Constants/API_Root";
import {connect} from "react-redux";
import {push} from "react-router-redux";
import {toggleModal} from "../Actions/ToggleModal";
import ResetPasswordMessage from './ResetPasswordMessage'
import {getQueryParamValue} from "../Constants/getQueryParamValue";
import Button from "../SharedComponents/Button";
import BannerAlert from "../SharedComponents/BannerAlert";

class ResetPasswordIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            code: getQueryParamValue("code", window.location.href),
            email: getQueryParamValue("email", window.location.href),
            passwordOne: '',
            error: null,
            message: null,
            loading: false,
            isCodeValid: true
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.dismissMessage = this.dismissMessage.bind(this);
    }

    componentDidMount(){
        fetch(API_ROOT + 'api/check-user-auth/', {
            method: "POST",
            body: JSON.stringify({
                email: this.state.email,
                token: this.state.code
            }),
            headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}
        }).then((res) => {
            if (res.status !== 200){
                this.setState({ isCodeValid: false })
            }
        }).catch(err => this.setState({ isCodeValid: false }))
    }

    handleInputChange(e){
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    changePassword(e){
        const {code, email, passwordOne} = this.state;

        const completionMessage = (success) => this.setState({
            message: <ResetPasswordMessage dismissMessage={this.dismissMessage}
                                           success={success}/>
        });

        const API = API_ROOT + "api/reset-password/";

        this.setState({ loading: true })

        fetch(API, {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                password: passwordOne,
                email: email
            })
        }).then(res => {
            if (res.status === 200){
                this.setState({ loading: false}, () => completionMessage(true))
            } else {
                this.setState({ loading: false}, () => completionMessage(false))
            }
        }).catch(err => this.setState({ loading: false}, () => completionMessage(false)));

    }

    dismissMessage(){
        this.setState({
            message: null
        })
    }

    render() {
        let body = null;

        if (this.state.isCodeValid){
            const {
                passwordOne,
                error,
                email
            } = this.state;


            const isInvalid =
                passwordOne === '' || passwordOne.length < 8 || error;

            const passwordLengthError = passwordOne.length < 8 ? <div><small style={{color: 'red'}}>Password must be at least 8 characters</small></div> : null;

            body = <div>
                <div style={{ fontSize: "24px", fontWeight: "700"}}>{"Changing Password for " + email}</div>
                <br/>
                <div style={{ fontSize: "16px", fontWeight: "700"}}>
                    Enter new password
                </div>
                <input
                    value={passwordOne}
                    onChange={this.handleInputChange}
                    type="password"
                    name="passwordOne"
                    placeholder="********"
                    className="inputGray"
                    style={{width: '100%', border: passwordOne.length < 8 ? "1px solid red": ""}}
                />
                {passwordLengthError}
                <br/><br/>
                <Button style={{backgroundColor: 'orange'}}
                        disabled={isInvalid}
                        label="Reset Password"
                        loading={this.state.loading}
                        onClick={this.changePassword} />
                {this.state.message}
                <br/><br/>
            </div>
        } else {
            body = <BannerAlert
                type="error"
                header="This URL is invalid. Please check your email again"
                icon="bx bx-error"
                action="close"
                onDismiss={this.dismissMessage}
                content="Email us at info@lovespreadsheets.com if this error persists"
            />
        }

        return (
            <div style={{backgroundColor: '#DCDCDC', minHeight: '100vh'}}>
                <div className="row">
                    <div className="col-sm-3" />
                    <div className="col-sm-6">
                        <br/><br/><br/><br/>
                        <div style={{ backgroundColor: "white", padding: "32px" }}>
                            {body}
                        </div>
                    </div>
                    <div className="col-sm-3" />
                </div>
            </div>
        )
    }
}

const mapActionsToProps = {
    navigateTo: push,
    toggleModal: toggleModal
}

export default connect(null,mapActionsToProps)(ResetPasswordIndex);