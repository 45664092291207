import React, {Component} from 'react'

class ProductMenuRow extends Component {
    render() {
        const divId = this.props.isMobile ? "" : "productMenuRow"

        return (
            <a href={this.props.website} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                <div style={{ padding: "12px 16px 12px 12px", backgroundColor: this.props.isMobile ? "white": "", borderRadius: this.props.isMobile ? "8px" : ""}} id={divId}>
                    <div style={{ display: "grid", gridTemplateColumns: "50px 1fr",  columnGap: "16px"}}>
                        <div style={{ paddingTop: this.props.isMobile ? "4px" : "0" }}>
                            <img src={this.props.icon} style={{ height: "50px", width: "50px" }} alt={this.props.name}/>
                        </div>
                        <div>
                            <div style={{ fontSize: this.props.isMobile ? "14px" : "16px", fontWeight: "700", color: "#161E16", marginBottom: this.props.isMobile ? "0" : "8px" }}>
                                {this.props.name}
                            </div>
                            <div style={{ fontSize: this.props.isMobile ? "12px" : "14px", color: "#5E645E", fontWeight: "400" }}>
                                {this.props.desc}
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        )
    }
}

export default ProductMenuRow