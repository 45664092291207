import React, {Component} from 'react'
import ModalApp from '../../../SharedComponents/Modal'
import SetupTimeModalBody from "./SetupTimeModalBody"

class SetupTimeModal extends Component {
    render() {
        return (
            <ModalApp name="setupTimeModal"
                      header={null}
                      body={<SetupTimeModalBody />}
            />
        )
    }
}

export default SetupTimeModal