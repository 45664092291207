import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../../Constants/styles";

class UploadFilesDemoStep extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{ paddingBottom: "0px" }}>
                <div style={{ fontSize: "16px" }}><b>Upload as many .xlsx, .csv or Google Sheet files</b></div>
                <div style={{ fontSize: "12px", marginTop: "8px", color: styles.subText}}>
                    <div style={{ marginBottom: "4px"}}>Currently, only 1st sheet is read</div>
                    <div>You can also copy and paste unstructured text (like JSON) and convert it into a spreadsheet</div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(UploadFilesDemoStep)