import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../Constants/styles";
import IconTitle from "../SharedComponents/IconTitle"
import CircleIcon from "../SharedComponents/CircleIcon"
import {toggleRunProductDemo} from "../Actions/ToggleRunProductDemo";
import {toggleModal} from "../Actions/ToggleModal";
import MainDemoScreen from "./Steps/MainDemoScreen"
import UploadFilesDemoStep from "./Steps/UploadFilesDemoStep"
import ViewFilesDemoStep from "./ViewFilesDemoStep"
import QuestionDemoStep from "./Steps/QuestionDemoStep"
import AskDemoStep from "./Steps/AskDemoStep"
import FinalResultsDemoStep from "./Steps/FinalResultsDemoStep"

class DemoModalBody extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let body;
        let iconName;

        try{
            body = [<UploadFilesDemoStep />, <ViewFilesDemoStep />, <QuestionDemoStep />, <AskDemoStep />, <FinalResultsDemoStep />][this.props.step]
            iconName = ["fa-solid fa-upload", "fa-solid fa-brain", "fa-solid fa-chart-mixed", "fa-solid fa-comment", "fa fa-table"][this.props.step]
        } catch (e) {
            body = null
            iconName = null
        }

        let stepDisplay = null;
        let icon = null;

        // if (this.props.step > 0){
            if (iconName !== null && iconName !== ""){
                icon = <div>
                    <i className={iconName} style={{ color: styles.subText, fontSize: "14px" }}/>
                </div>
            }

            stepDisplay = <div style={{ fontWeight: "700", marginBottom: "12px"}}>
                {icon}
                <span style={{ fontSize: "14px"}}><div>Step {this.props.step + 1}</div></span>
            </div>
        // }

        return (
            <div style={{ paddingTop: "12px", paddingBottom: "12px", textAlign: "left"}}>
                {stepDisplay}
                {/*{icon}*/}
                {body}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleRunProductDemo: toggleRunProductDemo,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(DemoModalBody)