import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifySelectedTeamInfo} from "../Actions/ModifySelectedTeamInfo";
import styles from "../Constants/styles";
import Select from 'react-select'
import capitalizeHyphenated from "../Constants/capitalizeHyphenated";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import InfoPopup from "../SharedComponents/InfoPopup";
import isEmailValid from "../Constants/isEmailValid";
import API_Root from "../Constants/API_Root";
import {toggleToast} from "../Actions/ToggleToast";

class TeamUserRow extends Component {
    constructor(props) {
        super(props);

        this.state = this.props.userInfo;
    }

    handleEmailChange = (e) => {
        this.setState({ user_email: e.target.value })
    }

    changeUserInfo = (new_user_info) => {
        const copiedTeamInfo = JSON.parse(JSON.stringify(this.props.selectedTeamInfo));
        const allUsers = copiedTeamInfo.team_users;

        const userInfo = allUsers[this.props.idx];

        for (let key in new_user_info){
            userInfo[key] = new_user_info[key]
        }

        allUsers[this.props.idx] = userInfo

        copiedTeamInfo.team_users = allUsers

        this.setState(new_user_info, () => this.props.modifySelectedTeamInfo(copiedTeamInfo))
    }

    hasUserJoinedTeam = () => (this.state.has_joined)

    hasUserBeenInvited = () => (this.state.id !== null)

    hasUserBeenEdited = () => (JSON.stringify(this.props.userInfo) !== JSON.stringify(this.state))

    getDisplayIdentifier = () => {
        const style = { fontSize: "14px", fontWeight: '400'}
        let text = ""

        if (this.state.role === "admin"){
            style['color'] = styles.subText;
            text = "Admin email is default"

            return <div style={style}>
                {text}
            </div>
        }

        if (!this.hasUserJoinedTeam() || !this.hasUserBeenInvited() || this.hasUserBeenEdited()){
            if (!this.hasUserBeenInvited()){
                style['color'] = styles.red
                text = "Not yet invited"
            } else {
                // being edited
                if (this.hasUserBeenEdited()){
                    style['color'] = styles.red
                    text = "Not yet saved"
                } else {
                    if (!this.hasUserJoinedTeam()){
                        style['color'] = styles.orange
                        text = "Invite not yet accepted"
                    }
                }
            }

            return <div style={style}>
                {text}
            </div>
        } else {
            return null
        }
    }

    changeRole = (roleObj) => {
        this.setState({
            role: roleObj.value
        })
    }

    errorFetch = (err) => this.props.toggleToast({show: true, type: "error", message: err})

    inviteUser = () => {
        const errorMsg = "There was an error inviting team member. Try again"
        this.props.toggleToast({show: true, type: "info", message:"Sending member invitation..."})
        // email, role, teamPK
        fetch(API_Root + "api/invite-user-to-lovespreadsheets-team/", {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                teamPK: this.props.selectedTeamPK,
                user_email: this.state.user_email,
                role: this.state.role
            }),
            headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}
        }).then(res => {
            if (res.status === 200){
                res.json().then(data => {
                    this.changeUserInfo({ id: data.teamUserID, user_email: this.state.user_email, role: this.state.role })
                    this.props.toggleToast({show: true, type: "success", message:"Member invitation sent!"})
                }).catch(err => this.errorFetch(errorMsg))
            } else {
                this.errorFetch(errorMsg)
            }
        }).catch(err => this.errorFetch(errorMsg))
    }

    cancelInvite = () => this.cancelOrDeleteUser(false)

    deleteUser = () => this.cancelOrDeleteUser(true)

    cancelOrDeleteUser = (isDeleteUser) => {
        const errorMsg = "There was an error " + (isDeleteUser ? "removing" : "canceling the invite of the") + " team member. Try again"

        this.props.toggleToast({show: true, type: "info", message:isDeleteUser ? "Removing team member..." : "Canceling team member invite..."})

        fetch(API_Root + "api/accept-or-decline-lovespreadsheets-team/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                id: this.props.userInfo.id,
                isAccept: false,
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                isAdminAction: true
            })
        }).then(res => {
            if (res.status === 200){
                const selectedTeamInfo = JSON.parse(JSON.stringify(this.props.selectedTeamInfo));
                const teamUsers = selectedTeamInfo.team_users;

                teamUsers.splice(this.props.idx, 1)

                selectedTeamInfo.team_users = teamUsers
                this.props.modifySelectedTeamInfo(selectedTeamInfo)

                this.setState(this.props.userInfo)
                this.props.toggleToast({show: true, type: "success", message:isDeleteUser ? "Team member deleted" : "Team member invite canceled"})
            } else {
                this.errorFetch(errorMsg)
            }
        }).catch(err => this.errorFetch(errorMsg))
    }

    saveUser = () => {
        const errorMsg = "There was an error updating your team member's information. Try again"

        this.props.toggleToast({show: true, type: "info", message: "Updating your team member"})

        const editValues = {user_email: this.state.user_email, role: this.state.role};

        fetch(API_Root + "api/accept-or-decline-lovespreadsheets-team/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                id: this.props.userInfo.id,
                isAccept: true,
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                isAdminAction: true,
                isEdit: true,
                isEditValues: editValues
            })
        }).then(res => {
            if (res.status === 200){
                this.changeUserInfo(editValues)

                this.props.toggleToast({show: true, type: "success", message: "User info successfully updated!"})
            } else {
                this.errorFetch(errorMsg)
            }
        }).catch(err => this.errorFetch(errorMsg))


    }

    undo = () => {
        const copiedInfo = JSON.parse(JSON.stringify(this.props.userInfo))

        this.setState(copiedInfo)
    }

    getBtn = (iconName, func, btnStyle, popupName) => {
        return <Popup trigger={open => (
            <i className={iconName} onClick={func} style={btnStyle}></i>
        )}
                      position="top"
                      on={['hover', 'focus']}
                      closeOnDocumentClick
        >
            {popupName}
        </Popup>
    }

    getFirstBtn = () => {
        if (this.state.role === "admin"){
            if (this.props.isUserAdmin){
                return <InfoPopup position="top" info="Admin role cannot be changed"/>
            } else {
                return null
            }
        }

        const btnStyle = {fontSize: "20px", cursor: "pointer", paddingTop: "4px"}
        const canTakeAction = isEmailValid(this.state.user_email) && this.isRoleValid() && this.props.isUserAdmin;

        if (!this.hasUserBeenInvited()){
            btnStyle['color'] = styles.mainGreen;

            if (canTakeAction){
                return this.getBtn("fa-solid fa-paper-plane", this.inviteUser, btnStyle, "Invite member")
            } else {
                return null
            }
        } else {
            if (!this.hasUserJoinedTeam()){
                btnStyle['color'] = styles.red;

                if (canTakeAction){
                    return this.getBtn("fa-solid fa-user-xmark", this.cancelInvite, btnStyle, "Cancel invite")
                } else {
                    return null
                }
            } else {
                if (this.hasUserBeenEdited()){
                    btnStyle['color'] = styles.mainGreen;

                    if (canTakeAction){
                        return this.getBtn("fa-solid fa-floppy-disk", this.saveUser, btnStyle, "Save member")
                    } else {
                        return null
                    }
                } else {
                    btnStyle['color'] = styles.red;

                    if (canTakeAction){
                        return this.getBtn("fa-solid fa-trash-can", this.deleteUser, btnStyle, "Delete member")
                    } else {
                        if (localStorage.getItem("email") === this.state.user_email){
                            return this.getBtn("fa-solid fa-trash-can", null, btnStyle, "Ask your admin to be removed or edited")
                        }

                    }

                    return null
                }
            }
        }

        return null
    }

    getSecondBtn = () => {
        if (this.state.role === "admin"){
            return null
        }

        const btnStyle = {fontSize: "20px", cursor: "pointer", paddingTop: "4px"}


        if (this.hasUserBeenEdited()){
            btnStyle['color'] = styles.blue;
            btnStyle['paddingTop'] = '8px'
            btnStyle['paddingLeft'] = '8px'

            return this.getBtn("fa-solid fa-rotate-left", this.undo, btnStyle, "Undo")
        }

        return null
    }

    isRoleValid = () => (['developer', 'analyst', 'admin'].includes(this.state.role))

    render() {
        const isAdmin = this.state.role === "admin";

        return (
            <div style={{ border: "1px solid #F0F0F0", borderRadius: "4px", padding: "8px"}}>
                <div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr auto", columnGap: "8px"}}>
                        <div>
                            <div style={{ fontSize: "14px", fontWeight: "400", color: "#5E645E", marginBottom: "4px"}}>Email</div>
                        </div>
                        <div>
                            {this.getDisplayIdentifier()}
                        </div>
                    </div>
                    <div>
                        <input style={{ width: "100%" }}
                               className="inputGray"
                               value={this.state.user_email}
                               readOnly={isAdmin || !this.props.isUserAdmin}
                               onChange={this.handleEmailChange}
                               placeholder="user@company.com"
                        />
                        {
                            isEmailValid(this.state.user_email) ? null : <small style={{ color: styles.red}}>Enter a valid email</small>
                        }
                    </div>
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "1fr auto", columnGap: "4px", marginTop: "4px" }}>
                    <div>
                        <div style={{ fontSize: "14px", fontWeight: "400", color: "#5E645E", marginBottom: "4px"}}>Role</div>
                        <Select
                            isDisabled={isAdmin || !this.props.isUserAdmin}
                            isSearchable={true}
                            onChange={this.changeRole}
                            value={{value: this.state.role, label: capitalizeHyphenated(this.state.role)}}
                            options={["developer", "analyst"].map((x, idx) => ({value: x, label: capitalizeHyphenated(x)}))}
                        />
                        {
                            this.isRoleValid() ? null : <small style={{ color: styles.red }}>Select a role</small>
                        }
                    </div>
                    <div>
                        <div style={{ fontSize: "14px", fontWeight: "400", color: "white", marginBottom: "4px"}}>Actions</div>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: '4px'}}>
                            <div style={{ padding: "4px"}}>
                                {
                                    this.getFirstBtn()
                                }
                            </div>
                            <div>
                                {
                                    this.getSecondBtn()
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    selectedTeamInfo: state.mainState.selectedTeamInfo,
    selectedTeamPK: state.mainState.selectedTeamPK
})

const mapActionsToProps = {
    modifySelectedTeamInfo: modifySelectedTeamInfo,
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(TeamUserRow)