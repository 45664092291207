import React, {Component} from 'react'
import {connect} from 'react-redux'
import PaymentForm from '../Payment/PaymentForm'
import CompletedCelebration from './CompletedCelebration'
import UpgradeModalHeader from "./UpgradeModalHeader"
import {getPricingInfoFromUserPlan} from "../../Constants/getPricingInfoFromUserPlan";
import UpgradePricingBoxesIndex from "./UpgradePricingBoxesIndex";
import {getPricingIdxFromUserPlan} from "../../Constants/getPricingIdxFromUserPlan";
import UpgradeTeamDisplay from "./UpgradeTeamDisplay";

class UpgradeModalBody extends Component {
    isUserTeamSelected = () => (this.props.modalInfo.isUserTeamSelected);

    getUserPlan = () => (this.props.modalInfo.userPlan)

    getPricingInfo = () => (getPricingInfoFromUserPlan(this.getUserPlan()))
    // {"isUserTeamSelected":true,"adminEmail":"testpricing1@test.com","userPlan":null,"featureName":"team","featureValue":1}

    getFeatureName = () => (this.props.modalInfo.featureName)

    getPlanValue = () => (this.getPricingInfo()[this.getFeatureName()])

    getPricingIdx = () => (getPricingIdxFromUserPlan(this.getUserPlan()))

    render() {
        let body = null;

        if (this.isUserTeamSelected()){
            body = <UpgradePricingBoxesIndex
                pricingIdx={this.getPricingIdx()}
            />
        } else {
            body = <UpgradeTeamDisplay
                featureName={this.getFeatureName()}
                adminEmail={this.props.modalInfo.adminEmail}
            />
        }

        return (
            <div>
                <div className="row">
                    <div className="col-sm-1" />
                    <div className="col-sm-10">
                        <UpgradeModalHeader
                            featureName={this.getFeatureName()}
                            planValue={this.getPlanValue()}
                            userPlanName={this.getPricingInfo()['name']}
                            isUserTeamSelected={this.isUserTeamSelected()}
                        />
                        {body}
                    </div>
                    <div className="col-sm-1" />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo
})

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(UpgradeModalBody)