import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifyAllDBReports} from "../../../Actions/ModifyAllDBReports";
import Tag from "../../../SharedComponents/Tag";
import styles from "../../../Constants/styles";
import ChangeReportFrequency from "./ChangeReportFrequency";
import TimeSelection from "./TimeSelection";
import WeeklyDaysSelection from "./WeeklyDaysSelection";
import MonthlySelection from "./MonthlySelection";
import YearlySelection from "./YearlySelection";
import SaveReportInfoBtn from "./SaveReportInfoBtn";
import GoogleSheetsIndex from "./GoogleSheets/GoogleSheetsIndex";
import EmailReportIndex from "./EmailReport/EmailReportIndex";
import {getUTCStringFromOffset} from "../../../Constants/getUTCStringFromOffset";
import TimezoneDropdown from "./TimezoneDropdown";
import TestGoogleSheetsReport from "./TestGoogleSheetsReport";
import TestEmailReport from "./TestEmailReport";
import {pricing} from "../../../SharedComponents/Pricing/pricing";
import {getPricingInfoFromUserPlan} from "../../../Constants/getPricingInfoFromUserPlan";
import SetupTimeRestrictionBanner from "./SetupTimeRestrictionBanner"

class SetupTimeModalBody extends Component {
    constructor(props) {
        super(props);

        let reportObj = this.getReportObj();

        this.state = {
            report_frequency: reportObj['report_frequency'],
            report_info: reportObj['report_info'],
            report_times: reportObj['report_times'],
            send_email_report: reportObj['send_email_report'],
            send_google_sheets: reportObj['send_google_sheets'],
            email_report_info: reportObj['email_report_info'],
            google_sheets_info: reportObj['google_sheets_info'],
            interval: reportObj['interval'],
            report_timezone: reportObj['report_timezone'] === "" || reportObj['report_timezone'] === null ? getUTCStringFromOffset(new Date()) : reportObj['report_timezone']
        }
    }

    getUserReportFrequency = () => {
        const pricingInfo = getPricingInfoFromUserPlan(this.props.userPlan);

        if (pricingInfo !== null){
            return pricingInfo.reportFrequency
        } else {
            return null
        }
    }

    getReportObj = () => (this.props.allDBReports[this.props.reportSelectedIdx])

    isSaved = () => {
        const reportObj = this.getReportObj();
        let keys = Object.keys(this.state);

        for (let i=0; i<keys.length; i++){
            if (JSON.stringify(reportObj[keys[i]]) !== JSON.stringify(this.state[keys[i]])){
                return false
            }
        }

        return true
    }

    changeState = (newState) => this.setState(newState)

    getFrequencyBody = () => {
        const bodyMap = {
            daily: null,
            daily_weekday: null,
            weekly: <WeeklyDaysSelection
                report_info={this.state.report_info}
                changeState={this.changeState}
            />,
            monthly: <MonthlySelection
                userReportFrequency={this.getUserReportFrequency()}
                report_info={this.state.report_info}
                changeState={this.changeState}
            />,
            yearly: <YearlySelection
                userReportFrequency={this.getUserReportFrequency()}
                report_info={this.state.report_info}
                changeState={this.changeState}
            />
        }

        if (this.state.report_frequency in bodyMap){
            return bodyMap[this.state.report_frequency]
        }

        return null
    }

    toggleSend = (sendType) => {
        const currVal = this.state[sendType]

        this.setState({ [sendType]: !currVal })
    }

    render() {
        const subHeaderStyle = {fontSize: "16px", color: styles.subText, fontWeight: "700"}
        const segmentStyle = { backgroundColor: "white", padding: "12px", border: "1px solid #F0F0F0", borderRadius: "4px", boxShadow: "0px 5px 6px -4px rgba(2, 34, 19, 0.02)"}

        return (
            <div>
                <div style={{ display: "grid", gridTemplateColumns: "1fr auto", columnGap: "16px", marginBottom: "16px"}}>
                    <div>
                        <div style={{ fontSize: "20px", fontWeight: "700"}}>
                            <span><i className="fa-regular fa-clock"></i> <span style={{ paddingLeft: "4px" }}>Set up auto report</span></span>
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
                <div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr auto 1fr", columnGap: "8px"}}>
                        <div>
                            <div style={subHeaderStyle}>
                                Frequency
                            </div>
                            <div>
                                <ChangeReportFrequency
                                    userReportFrequency={this.getUserReportFrequency()}
                                    report_frequency={this.state.report_frequency}
                                    changeState={this.changeState}
                                />
                            </div>
                            <div>
                                {this.getFrequencyBody()}
                            </div>
                        </div>
                        <div>
                            <div style={subHeaderStyle}>
                                Time
                            </div>
                            <div>
                                <TimeSelection
                                    userReportFrequency={this.getUserReportFrequency()}
                                    report_times={this.state.report_times}
                                    interval={this.state.interval}
                                    changeState={this.changeState}
                                />
                            </div>
                        </div>
                        <div>
                            <div style={subHeaderStyle}>
                                Timezone
                            </div>
                            <div>
                                <TimezoneDropdown
                                    report_timezone={this.state.report_timezone}
                                    changeState={this.changeState}
                                />
                            </div>
                        </div>
                        <div style={{ textAlign: "right" }}>
                            {
                                this.isSaved() ? null :
                                    <div>
                                        <div>
                                            <Tag label="Not saved" color="white" bgColor={styles.red} />
                                        </div>
                                        <SaveReportInfoBtn
                                            newReportInfo={this.state}/>
                                    </div>

                            }
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '24px'}}>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: "8px"}}>
                        <div style={segmentStyle}>
                            <div style={{ display: "grid", gridTemplateColumns: "auto 1fr", columnGap: "8px"}}>
                                <div style={subHeaderStyle}>
                                    <i className="fa-solid fa-envelope-open-text"></i> <span style={{ marginLeft: "8px" }}>Send Excel file in Email</span>
                                </div>
                                <div style={{ textAlign: "right"}}>
                                    <label className="switch">
                                        <input type="checkbox" checked={this.state.send_email_report} onChange={e => this.toggleSend("send_email_report")}/>
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            {
                                this.state.send_email_report ?
                                    <div style={{ marginTop: "24px"}}>
                                        <EmailReportIndex
                                            email_report_info={this.state.email_report_info}
                                            changeState={this.changeState}
                                        />
                                        <div style={{ marginTop: "12px"}}>
                                            <TestEmailReport
                                                reportPK={this.getReportObj()['pk']}
                                            />
                                        </div>
                                    </div> : null
                            }
                        </div>
                        <div style={segmentStyle}>
                            <div style={{ display: "grid", gridTemplateColumns: "auto 1fr", columnGap: "8px"}}>
                                <div style={subHeaderStyle}>
                                    <i className="fa-brands fa-google"></i> <span style={{ marginLeft: "8px" }}>Send to Google Sheets</span>
                                </div>
                                <div style={{ textAlign: "right"}}>
                                    <label className="switch">
                                        <input type="checkbox" checked={this.state.send_google_sheets} onChange={e => this.toggleSend("send_google_sheets")}/>
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            {
                                this.state.send_google_sheets ?
                                    <div style={{ marginTop: "24px"}}>
                                        <GoogleSheetsIndex
                                            google_sheets_info={this.state.google_sheets_info}
                                            changeState={this.changeState}
                                        />
                                        <div style={{ marginTop: "12px"}}>
                                            <TestGoogleSheetsReport
                                                reportPK={this.getReportObj()['pk']}
                                            />
                                        </div>
                                    </div> : null
                            }

                        </div>
                    </div>
                    {
                        ['15-minutes', null].includes(this.getUserReportFrequency()) ? null
                            :
                        <div style={{ marginTop: "24px", marginBottom: "24px"}}>
                            <SetupTimeRestrictionBanner
                                userPlan={this.props.userPlan}
                                userReportFrequency={this.getUserReportFrequency()}
                            />
                        </div>

                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    reportSelectedIdx: state.mainState.reportSelectedIdx,
    allDBReports: state.mainState.allDBReports,
    userPlan: state.mainState.userPlan
})

const mapActionsToProps = {
    modifyAllDBReports: modifyAllDBReports
}

export default connect(mapStateToProps, mapActionsToProps)(SetupTimeModalBody)