import React, {Component} from 'react'
import {connect} from 'react-redux'

class MenuSegment extends Component {
    selectMenuSegment = (e) => {
        this.props.onClick(e, this.props.name)
    }

    render() {
        let id = ""
        let cName = "menuSegment"

        if (this.props.name === this.props.menuItem){
            id = "menuSegmentSelected"
        }

        // make a color ID and class with the name or hex color
        if ("color" in this.props){
            id += " " + this.props.color
            cName += " " + this.props.color
        }

        return (
            <div className={cName} id={id} onClick={this.selectMenuSegment}>
                <div>
                    <i className={this.props.icon} />
                    <span style={{paddingLeft: "12px"}}>{this.props.label}</span>
                </div>
                <div>
                </div>
                <div>
                    {
                        "arrow" in this.props ?
                            <i className="fas fa-chevron-right" style={{ textAlign: "right" }} />
                            : null
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    reduxTier: state.mainState.tier
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(MenuSegment)