import React, {Component} from 'react'
import styles from "../../Constants/styles";

class ColumnInfoBox extends Component {
    render() {
        const labelStyle = { fontSize: "14px", fontWeight: "700", color: styles.subText }

        const displayStyle = { fontSize: "14px", fontWeight: this.props.label === "Field" ? "700": "500", color: styles.mainText }

        return (
            <div>
                <div style={labelStyle}>
                    {this.props.label}
                </div>
                <div style={displayStyle}>
                    {this.props.value}
                </div>
            </div>
        )
    }
}

export default ColumnInfoBox