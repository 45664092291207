import React, { Component } from 'react';
import BannerAlert from "../SharedComponents/BannerAlert";
import API_Root from "../Constants/API_Root";
import Button from "../SharedComponents/Button";

class PasswordForget extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            loading: false,
            message: null
        };

        this.dismissMessage = this.dismissMessage.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.forgetPassword = this.forgetPassword.bind(this);
    }

    handleInputChange(e){
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    forgetPassword(e){
        this.setState({
            loading: true
        });

        const { email } = this.state;

        const srcURL = window.location.href.includes("localhost") ? "http://localhost:2000/" : "https://www.lovespreadsheets.com/"

        const error = () => {
            this.setState({
                loading: false,
                message:
                    <BannerAlert
                                 type="error"
                                 header="There was an error. Please try again"
                                 icon={null}
                                 action="close"
                                 onDismiss={this.dismissMessage}
                                 content={null}
                    />
            })
        }

        fetch(API_Root + "api/send-reset-password-email/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                srcURL: srcURL,
                email: this.state.email
            })
        }).then(res => {
            if (res.status === 200) {
                this.setState({
                    email: '',
                    loading: false,
                    message:
                        <BannerAlert type="success"
                                     icon={null}
                                     action="close"
                                     onDismiss={this.dismissMessage}
                                     header={"Please check your email at " + email}
                                     content="Follow the instructions in your email. Don't forget to check Spam or Promotions tab"
                        />
                })
            } else {
                error()
            }
        }).catch(err => error());

    }

    dismissMessage(e){
        this.setState({ message: null })
    }

    render() {
        const {
            email
        } = this.state;

        const isInvalid = email === '';

        const isProfilePage = 'profilePage' in this.props;

        return (
            <div style={isProfilePage ? null : { padding: "16px", background: "white" }}>
                {isProfilePage ? null :
                    <div><div style={{ fontSize: "24px", fontWeight: "700"}}>Reset Password</div><br/></div>}
                    <div style={{ fontSize: "16px", fontWeight: "700"}}>
                        Enter your email
                    </div>
                    <input
                        value={this.state.email}
                        onChange={this.handleInputChange}
                        name="email"
                        className="inputGray"
                        placeholder="Enter Your Email"
                        style={{width: '100%'}}
                    />
                    <br/><br/>
                    <Button loading={this.state.loading}
                            style={{width: "100%", backgroundColor: "orange", color: "white"}}
                            label="Reset Password"
                            onClick={this.forgetPassword}
                            disabled={isInvalid} />
                    {this.state.message}
            </div>
        );
    }
}


export default PasswordForget;