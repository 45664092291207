import {getEmailFromUsername} from "./getEmailFromUsername";

export const getAdminEmailFromTeamPK = (allTeamsInfo, selectedTeamPK) => {
    for (let i=0; i<allTeamsInfo.length; i++){
        let teamInfo = allTeamsInfo[i];

        if (teamInfo.love_team__pk === selectedTeamPK){
            return getEmailFromUsername(teamInfo.love_team__admin_username)
        }
    }

    return null
}