import React, {Component} from 'react'
import {connect} from 'react-redux'
import Select from 'react-select'
import {modifySelectedTeamPK} from "../Actions/ModifySelectedTeamPK";
import {addParamToUrl} from "../Constants/addParamToUrl";

class SwitchTeamDropdown extends Component {
    constructor(props) {
        super(props);
    }

    switchSelectedTeam = (teamObj) => {
        const teamPK = teamObj.value;

        let url = window.location.href;

        window.location.href = addParamToUrl(url, "teamPK", teamPK)
    }

    getTeamName = (teamPK) => {
        let teamName = "";

        if (teamPK !== null){
            for (let i=0; i<this.props.allTeamsInfo.length; i++){
                const teamInfo = this.props.allTeamsInfo[i];

                if (teamInfo.love_team__pk === teamPK){
                    teamName = teamInfo.love_team__name;
                    break;
                }
            }
        }

        if (teamName === ""){
            teamName = "NO TEAM NAME"
        }

        return teamName
    }

    render() {
        return (
            <div>
                <Select
                    onChange={this.switchSelectedTeam}
                    value={{value: this.props.selectedTeamPK, label: this.getTeamName(this.props.selectedTeamPK)}}
                    options={this.props.allTeamsInfo.map((x, idx) => ({
                        value: x.love_team__pk,
                        label: x.love_team__name
                    }))}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    allTeamsInfo: state.mainState.allTeamsInfo,
    selectedTeamPK: state.mainState.selectedTeamPK
})

const mapActionsToProps = {
    modifySelectedTeamPK: modifySelectedTeamPK
}

export default connect(mapStateToProps, mapActionsToProps)(SwitchTeamDropdown)