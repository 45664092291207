import React, {Component} from 'react'
import LabelTag from "../../SharedComponents/LabelTag"

class ConnectDatabaseGuide extends Component {
    render() {
        const ourIP = <span><code>34.194.225.150</code> and <code>172.31.128.0</code> and <code>172.31.144.0</code></span>

        return (
            <div>
                <div className="docsSection">
                    <p className="docsHeader">You need to get the following information from your database to connect</p>
                    <p>1. <b>Hostname</b> is the URL or IP Address where the database is located. </p>
                    <p style={{marginBottom: "5px"}}> This is generally in the following form(s)</p>
                    <div className="row">
                        <div className="col-sm-6">
                            <pre style={{backgroundColor: "whitesmoke", padding: "10px"}}>
                                <code>
                                    testrds.xxxx.amazonaws.com
                                </code>
                            </pre>
                        </div>
                        <div className="col-sm-6">
                            <pre style={{backgroundColor: "whitesmoke", padding: "10px"}}>
                                <code>
                                    111.111.11.11
                                </code>
                            </pre>
                        </div>
                    </div>
                    <p> </p>
                    <p>2. <b>Database Name</b> is the name of the database. It can be found by either asking your database developer. Or your cloud provider if the database is in a cloud</p>
                    <p>3. <b>Username</b> is the username given to you by the person who set up the database</p>
                    <p>4. <b>Password</b> is the password given to you by the person who set up the database</p>
                    <p>One database can have multiple usernames and passwords for different people</p>
                    <p>5. <b>Port</b> is a number where the hostname URL can be connected to. Often the port number for <b>MySQL is 3306</b> and for <b>Postgres is 5432</b> but it can technically be any number from 0 to 65353.</p>
                    <p>Here are articles from the top three cloud providers on how to find all this information for MySQL and Postgres databases</p>
                    <div className="row">
                        <div className="col-sm-4">
                            <p><b><i className="fa-brands fa-aws"></i> <span style={{ paddingLeft: "4px"}}>AWS</span></b></p>
                            <a href="https://docs.aws.amazon.com/AmazonRDS/latest/UserGuide/USER_ConnectToInstance.html" target="_blank" rel="noopener noreferrer">Connecting to MySQL</a>
                            <br/>
                            <a href="https://docs.aws.amazon.com/AmazonRDS/latest/UserGuide/USER_ConnectToPostgreSQLInstance.html" target="_blank" rel="noopener noreferrer">Connecting to Postgres</a>
                            <br/>
                        </div>
                        <div className="col-sm-4">
                            <p><b><i className="fa-brands fa-microsoft"></i> <span style={{ paddingLeft: "8px" }}>Azure</span></b></p>
                            <a href="https://docs.microsoft.com/en-us/azure/mysql/flexible-server/connect-workbench#get-connection-information" target="_blank" rel="noopener noreferrer">Connecting to MySQL</a>
                            <br/>
                            <a href="https://docs.microsoft.com/en-us/azure/postgresql/flexible-server/connect-python#get-database-connection-information" target="_blank" rel="noopener noreferrer">Connecting to Postgres</a>
                            <br/>
                        </div>
                        <div className="col-sm-4">
                            <p><b><i className="fa-brands fa-google"></i> <span style={{ paddingLeft: "8px"}}>Google Cloud</span></b></p>
                            <a href="https://cloud.google.com/sql/docs/mysql/connect-overview" target="_blank" rel="noopener noreferrer">Connecting to MySQL</a>
                            <br/>
                            <a href="https://cloud.google.com/sql/docs/postgres/connect-overview" target="_blank" rel="noopener noreferrer">Connecting to Postgres</a>
                            <br/>
                        </div>
                    </div>
                </div>
                <hr/>
                <br/>
                <div className="docsSection">
                    <p className="docsHeader">You also need to make sure your Database can connect to Love Spreadsheets</p>
                    <p>You have two options:
                        <br/>1. You can expose your Database to ALL Public IPs: 0.0.0.0<br/>2. <LabelTag bgColor="orange" color="white" label={<span><i className="fa-solid fa-lock"></i> <span style={{ paddingLeft: "4px"}}>SECURE</span></span>} /> You can allow your Database connections ONLY from our IPs: {ourIP} </p>
                    <br/>
                    <p>In addition, you need to make sure your database is <b>Publicly Accessible</b>.<br/><br/><u><b>NOTE</b></u> making a database <b>Publicly Accessible</b> is different than exposing it to ALL Public IPs. <b>Publicly Accessible</b> makes sure the database can be accessed over a URL that you specify in your <b>hostname</b>  </p>
                </div>
                <hr/>
                <br/>
                <div className="docsSection">
                    <p className="docsHeader">Still can't connect?</p>
                    <p>1. Make sure all of the credential information above is accurate</p>
                    <p>2. The database is set up to receive connections from any IP: 0.0.0.0 or our private IPs: {ourIP}</p>
                    <p>3. Sometimes a /32 and/or a /20 needs to be added to our Private IP</p>
                    <p>4. The database is publicly accessible (this is different than exposing it to the entire public)</p>
                    <p>5. The username and password you are using (if any) allows the database to make READ requests</p>
                </div>
                {/*<div className="docsSection">*/}
                    {/*{*/}
                        {/*isImporter ? <div>*/}
                            {/*<p className="docsHeader">Select tables from your database if it successfully connected</p>*/}
                            {/*<p><span className="linkStyle" onClick={e => this.props.setBothMenuItems("database-selectTable")}>Read how to select tables from your database</span></p>*/}
                        {/*</div> : <div>*/}
                            {/*<p className="docsHeader">Create an API if your database successfully connected</p>*/}
                            {/*<p><span className="linkStyle" onClick={e => this.props.setBothMenuItems("database-createAPI")}>Read how to create an API from your database</span></p>*/}
                        {/*</div>*/}
                    {/*}*/}
                {/*</div>*/}
            </div>
        )
    }
}

export default ConnectDatabaseGuide