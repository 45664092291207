import React, { Component } from 'react';
import API_Root from "../Constants/API_Root";
import {connect} from 'react-redux'
import {modifyProjectConversations} from "../Actions/ModifyProjectConversations";
import {modifyAllProjects} from "../Actions/ModifyAllProjects";
import {modifyAllDBReports} from "../Actions/ModifyAllDBReports";
import {modifyAllTeamsInfo} from "../Actions/ModifyAllTeamsInfo";
import {modifySelectedTeamInfo} from "../Actions/ModifySelectedTeamInfo";

class EditableText extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isEditing: false,
            text: props.text
        };

        this.editableTextRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.idx !== this.props.idx) {
            this.setState({
                isEditing: false,
                text: this.props.text
            })
        }
    }


    storeUpdatedText = () => {
        if (this.props.text.trim() !== this.state.text){
            // someone can change the project title, the prompt, or the prompt's report
            fetch(API_Root + "api/save-updated-text-for-lovespreadsheets-reports/", {
                method: "POST",
                body: JSON.stringify({
                    text: this.state.text,
                    textType: this.props.textType,
                    pk: this.props.pk
                })
            }).then(res => {
                if (res.status === 200){
                    this.updateTextInRedux()
                } else {
                    console.log(res.status)
                }
            }).catch(err => console.log(err))
        }
    }

    updateTextInRedux = () => {
        if (this.props.textType === "report_name"){
            const allReports = JSON.parse(JSON.stringify(this.props.allDBReports));
            let currReport = allReports[this.props.idx];
            currReport.report_name = this.state.text;

            allReports[this.props.idx] = currReport

            this.props.modifyAllDBReports(allReports)

        } else if (this.props.textType === "teamName"){
            const allTeamsInfo = JSON.parse(JSON.stringify(this.props.allTeamsInfo))
            let currTeamInfo = allTeamsInfo[this.props.idx]
            currTeamInfo.name = this.state.text

            allTeamsInfo[this.props.idx] = currTeamInfo

            this.props.modifyAllTeamsInfo(allTeamsInfo)

            const selectedTeamInfo = JSON.parse(JSON.stringify(this.props.selectedTeamInfo));
            selectedTeamInfo.name = this.state.text

            this.props.modifySelectedTeamInfo(selectedTeamInfo)
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.handleOutsideClick, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleOutsideClick, true);
    }

    handleTextClick = () => {
        this.setState(
            {
                isEditing: true
            },
            () => {
                this.editableTextRef.current.focus();
            }
        );
    }

    handleTextChange = (event) => {
        this.setState({
            text: event.target.value
        });
    }

    handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            this.setState({
                isEditing: false
            }, () => this.storeUpdatedText());
        }
    }

    handleOutsideClick = (event) => {
        if (this.editableTextRef.current && !this.editableTextRef.current.contains(event.target)) {
            this.setState({
                isEditing: false
            }, () => this.storeUpdatedText());
        }
    };

    render() {
        const { isEditing, text } = this.state;

        if (isEditing) {
            return (
                <input
                    value={text}
                    onChange={this.handleTextChange}
                    onKeyDown={this.handleKeyDown}
                    style={{ width: "100%" }}
                    className="inputGray"
                    ref={this.editableTextRef}
                />
            )
        }


        return (
            <div>
                <span onClick={this.handleTextClick}>{text.trim() === "" ? this.props.text : text}</span>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    projectConversations: state.mainState.projectConversations,
    allProjects: state.mainState.allProjects,
    reportSelectedIdx: state.mainState.reportSelectedIdx,
    allDBReports: state.mainState.allDBReports,
    allTeamsInfo: state.mainState.allTeamsInfo,
    selectedTeamInfo: state.mainState.selectedTeamInfo,
})

const mapActionsToProps = {
    modifyProjectConversations: modifyProjectConversations,
    modifyAllProjects: modifyAllProjects,
    modifyAllDBReports: modifyAllDBReports,
    modifyAllTeamsInfo: modifyAllTeamsInfo,
    modifySelectedTeamInfo: modifySelectedTeamInfo
}

export default connect(mapStateToProps, mapActionsToProps)(EditableText);
