import React, {Component} from 'react'
import {connect} from 'react-redux'
import ModalApp from "../../SharedComponents/Modal"
import LandingPageDescModalBody from "./LandingPageDescModalBody"

class LandingPageDescModal extends Component {
    render() {
        if (this.props.modalInfo !== null){
            if (typeof this.props.modalInfo === 'object' &&
                !Array.isArray(this.props.modalInfo)){
                if ('landingPageDescBodyType' in this.props.modalInfo && 'header' in this.props.modalInfo){
                    return (
                        <ModalApp name="landingPageDescModal"
                                  size="medium"
                                  header={null}
                                  body={<LandingPageDescModalBody
                                      bodyType={this.props.modalInfo.landingPageDescBodyType}
                                      header={this.props.modalInfo.header}
                                  />}
                        />
                    )
                }
            }
        }

        return null
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(LandingPageDescModal)