import React, {Component} from 'react'
import {connect} from 'react-redux'
import AuthItemsMenu from "./AuthAPI/AuthItemsMenu"

class ConnectAPIAuthInfoModalBody extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedAuthCategory: "Select auth method"
        }
    }

    changeState = (newState) => this.setState(newState)

    getFromModalInfo = (key, defaultInfo) => {
        let info;

        try {
            info = this.props.modalInfo['apiAuthInfo'][key]
        } catch (e) {
            info = defaultInfo
        }

        if (info === undefined){
            info = defaultInfo
        }

        return info
    }

    render() {
        // console.log(this.props.modalInfo)
        // "HTTP Basic Authentication", "HTTP Bearer Authentication", "OAuth 2.0", "JWT Authentication", "No auth needed"

        const authCategories = this.getFromModalInfo('auth_categories', [])
        const authHelpText = this.getFromModalInfo('auth_help_text', [])

        return (
            <div>
                {
                    authCategories.length > 0 ?
                        <div>
                            <div>
                                <b>This API can be authenticated using the following methods</b>
                            </div>
                            <div style={{ marginTop: "12px"}}>
                                <AuthItemsMenu
                                    authCategories={authCategories}
                                    selectedAuthCategory={this.state.selectedAuthCategory}
                                    changeState={this.changeState}
                                />
                            </div>
                            <div style={{ marginTop: "12px"}}>

                            </div>
                        </div> : null
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ConnectAPIAuthInfoModalBody)