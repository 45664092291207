import React, {Component} from 'react'
import {connect} from "react-redux";
import { pricing } from "./pricing";
import capitalizeHyphenated from "../../Constants/capitalizeHyphenated";
import PlanPricingBoxFeature from "./PlanPricingBoxFeature";
import StartPlanButton from "./StartPlanButton";
import PlanName from "./PlanName";
import PlanDesc from "./PlanDesc";
import PlanPricing from "./PlanPricing";
import PlanCTA from "./PlanCTA";
import { isMobile } from 'react-device-detect'
import {toggleModal} from "../../Actions/ToggleModal";
import PaymentLink from "./PaymentLink"

const boxShadowValues = [
    "inset 1px 0px 0px #F0F0F0, inset 0px -1px 0px #F2F2F2",
    "inset 1px 0px 0px #F0F0F0, inset 0px -1px 0px #F2F2F2",
    "inset 1px 0px 0px rgba(240, 240, 240, 0.2), inset 0px -1px 0px rgba(242, 242, 242, 0.2)",
    "inset 1px 0px 0px rgba(240, 240, 240, 0.2), inset 0px -1px 0px rgba(242, 242, 242, 0.2)"
]

class PlanPricingBox extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount(){
        if ('changeHeaderBoxHeight' in this.props){
            const height = this.planPricingBoxHeaderDivRef.clientHeight;
            this.props.changeHeaderBoxHeight(height)
        }
        // this.setState({ height });
    }

    continueForPlan = (planIdx) => {
        if (planIdx === 0){
            this.props.toggleModal("signUpModal")
        } else {
            //
            console.log("go to stripe checkout")
        }
    }

    getSignUpText = (planIdx) => {
        if (planIdx === 0){ return "Sign Up Free"} else { return "Subscribe Now"}
    }

    render() {
        const isNotPricingPage = 'profilePage' in this.props || isMobile || 'paymentModal' in this.props;

        const planIdx = this.props.planIdx;

        let pricingInfo = pricing[this.props.planIdx];

        const featureSegments = !('planSelected' in this.props) ? this.props.featureList.map((x, idx) => <PlanPricingBoxFeature pricing={pricingInfo}
                                                                                                                                info={x}
                                                                                                                                key={idx}
                                                                                                                                lastItem={idx === this.props.featureList.length - 1}
                                                                                                                                planIdx={planIdx}
                                                                                                                                isProfilePage={'profilePage' in this.props}
                                                                                                                                isNotPricingPage={isNotPricingPage}/>) : null

        return (
            <div style={{backgroundColor: this.props.backgroundColor}}>
                <div style={{borderBottom: "1px solid #F0F0F0"}}> </div>
                <div style={{padding: "16px", boxShadow: boxShadowValues[planIdx], borderRight: isNotPricingPage ? "1px solid #F0F0F0" : null}}
                     id="planPricingBoxHeaderDiv"
                     ref={ (planPricingBoxHeaderDivRef) => { this.planPricingBoxHeaderDivRef = planPricingBoxHeaderDivRef } }>
                    {
                        isNotPricingPage ?
                            <div style={{ display: "grid", gridTemplateColumns: "50% 50%"}}>
                                <div style={{textAlign: "left"}}>
                                    <div style={{marginBottom: '4px'}}>
                                        <PlanName
                                            isNotPricingPage={isNotPricingPage}
                                            planIdx={planIdx}
                                            pricingName={pricingInfo.name}
                                        />
                                    </div>
                                    <div>
                                        <PlanDesc
                                            isNotPricingPage={isNotPricingPage}
                                            desc={pricingInfo.desc}
                                            planIdx={planIdx}
                                        />
                                    </div>
                                </div>
                                <div style={{textAlign: "right"}}>
                                    {'profilePage' in this.props ? null :
                                        <PlanPricing
                                            isNotPricingPage={isNotPricingPage}
                                            pricingText={"$" + (this.props.pricingType === "lifetime" ? pricing[planIdx].lifetimePrice.toString() : pricing[planIdx].price.toString())}
                                            planIdx={planIdx}
                                            yearlyPricing={false}
                                        />
                                    }
                                </div>
                            </div> :
                            <div>
                                <div style={{marginBottom: '4px'}}>
                                    <PlanName
                                        isNotPricingPage={isNotPricingPage}
                                        planIdx={planIdx}
                                        pricingName={pricingInfo.name}
                                    />
                                </div>
                                <div style={{marginBottom: '4px'}}>
                                    <PlanDesc
                                        isNotPricingPage={isNotPricingPage}
                                        desc={pricingInfo.desc}
                                        planIdx={planIdx}
                                    />
                                </div>
                                <div style={{marginBottom: "8px"}}>
                                    <PlanPricing
                                        isNotPricingPage={isNotPricingPage}
                                        pricingText={"$" + pricing[planIdx].price.toString()}
                                        planIdx={planIdx}
                                        yearlyPricing={false}
                                    />
                                </div>
                                <div style={{textAlign: "center"}}>
                                    {
                                        planIdx === 0 ?
                                            <PlanCTA
                                                onClick={this.continueForPlan}
                                                planIdx={planIdx}
                                                getSignUpText={this.getSignUpText}
                                            /> : <PaymentLink
                                                planIdx={planIdx}
                                                component={<PlanCTA
                                                    planIdx={planIdx}
                                                    getSignUpText={this.getSignUpText}
                                                />}
                                            />
                                    }

                                </div>
                            </div>
                    }
                </div>
                {featureSegments}
                {
                    'planSelected' in this.props ? null :
                        <PlanPricingBoxFeature planIdx={planIdx}
                                               isNotPricingPage={isNotPricingPage}
                                               pricing={pricingInfo}
                                               content={
                                                   <StartPlanButton
                                                       isNotPricingPage={isNotPricingPage}
                                                       planIdx={planIdx}
                                                       continueForPlan={this.continueForPlan}
                                                       backgroundColor={planIdx === 2 || planIdx === 3 ? "white" : "#5499F8"}
                                                       color={planIdx === 2 ? "#6FBB98" : planIdx === 3 ? "#5499F8" : "white"}
                                                       text={planIdx === 0 ? "Sign Up Free" : "Subscribe Now"}
                                               />}
                        />
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currency: state.mainState.currency,
    yearlyPricing: state.mainState.yearlyPricing,
    tier: state.mainState.tier,
    appsumoPlanName: state.mainState.appsumoPlanName,
    isGrandfather: state.mainState.isGrandfather,
    pricingType: state.mainState.pricingType
})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(PlanPricingBox)