import React, {Component} from 'react'
import {connect} from 'react-redux'
import HomeIndex from "../../Landing/HomeIndex"
import {isProjectSaved} from "../../Constants/isProjectSaved";
import ChooseDBOrReport from "../Conversations/ChooseDBOrReport"
import DatabaseToolbar from "../../Database/DatabaseToolbar"
import ReportsIndex from "../../Database/Reports/ReportsIndex"
import ChooseOrAddReport from "../../Database/Reports/AddReportBtn"
import Loader from "../../Loader"
import {scheduleCallText} from "../../Constants/scheduleCallText";
import {modifySidebarSelection} from "../../Actions/ModifySidebarSelection";
import styles from "../../Constants/styles";
import ConnectDatabase from '../../Illustrations/ConnectDatabase.svg'
import HelpToolbar from "../../SharedComponents/Help/HelpToolbar"
import ReportName from "./ReportName"
import DeleteReportButton from "./DeleteReportButton"

class ConversationsIndex extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const standardPadding = "12px 24px"
        const segmentStyle = { backgroundColor: "white", padding: "24px", border: "1px solid #F0F0F0", borderRadius: "4px", boxShadow: "0px 5px 6px -4px rgba(2, 34, 19, 0.02)"}

        return (
            <div>
                {
                    !this.props.isAuth ?
                        <HomeIndex segmentStyle={this.props.segmentStyle}/> :
                        this.props.fetchingAllAiResults ?
                        <div style={{ padding: standardPadding }}>
                            <Loader />
                        </div> :
                        <div style={{ padding: standardPadding }}>
                            {
                                this.props.allDatabases.length > 0 ?
                                    <div>
                                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: "16px"}}>
                                            <div style={{padding: "12px 24px"}}>
                                                <div style={segmentStyle}>
                                                    {
                                                        this.props.reportSelectedIdx === null ?
                                                            <div>
                                                                <span className="linkStyle"
                                                                      style={{ fontSize: "20px", fontWeight: '700' }}
                                                                      onClick={e => this.props.modifySidebarSelection("file")}>Choose or add a new report</span>
                                                            </div> :
                                                            <div>
                                                                <div style={{ fontSize: "16px", fontWeight: "700"}}>
                                                                    Report selected
                                                                </div>
                                                                <div style={{ display: "grid", gridTemplateColumns: "1fr auto", columnGap: "16px"}}>
                                                                    <div className="ellipses">
                                                                        <ReportName />
                                                                    </div>
                                                                    <div>
                                                                        <DeleteReportButton />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                            <div>
                                                <DatabaseToolbar standardPadding={standardPadding} />
                                            </div>
                                        </div>
                                        {
                                            this.props.reportSelectedIdx === null ?
                                                null :
                                                <ReportsIndex standardPadding={standardPadding} />
                                        }
                                    </div>
                                    :
                                    <div style={{ display: "grid", gridTemplateColumns: "1fr auto 1fr"}}>
                                        <div> </div>
                                        <div style={segmentStyle}>
                                            <div style={{ marginBottom: "12px", fontWeight: "700", fontSize: "20px", textAlign: "center"}}>
                                                <i className="fa-solid fa-plug"/> <span style={{ paddingLeft: '4px'}}> Connect your data source to start generating reports </span>
                                            </div>
                                            <div style={{ marginBottom: "24px", textAlign: "center" }}>
                                                <img src={ConnectDatabase} style={{ maxWidth: "200px"}}/>
                                            </div>
                                            <div style={{ textAlign: "center", color: styles.subText }}>
                                                <div style={{ fontWeight: '700'}}>
                                                    Don't know how to connect a data source?
                                                </div>
                                                <div>
                                                    We will do it for you for <b>FREE</b>
                                                </div>
                                            </div>
                                            <div style={{ marginTop: "12px"}}>
                                                <div className="row">
                                                    <div className="col-sm-2" />
                                                    <div className="col-sm-8">
                                                        <HelpToolbar notInclude={['connectGuide']}/>
                                                    </div>
                                                    <div className="col-sm-2" />
                                                </div>
                                            </div>
                                        </div>
                                        <div> </div>
                                    </div>

                            }
                        </div>

                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    fetchingAllAiResults: state.mainState.fetchingAllAiResults,
    projectSelectedIdx: state.mainState.projectSelectedIdx,
    isAuth: state.mainState.isAuth,
    dbSelectedIdx: state.mainState.dbSelectedIdx,
    reportSelectedIdx: state.mainState.reportSelectedIdx,
    allDBReports: state.mainState.allDBReports,
    allDatabases: state.mainState.allDatabases
})

const mapActionsToProps = {
    modifySidebarSelection: modifySidebarSelection
}

export default connect(mapStateToProps, mapActionsToProps)(ConversationsIndex)