import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleModal} from "../Actions/ToggleModal";
import styles from "../Constants/styles";

class UpgradeBtn extends Component {
    openUpgradeModal = () => {
        // this.props.toggleModal("upgradeModal")
        this.props.toggleModal("signUpModal")
    }

    render() {
        const upgradeText = "Sign Up"
        if ('isMobile' in this.props){
            return (
                <div style={{ marginTop: "40px"}}>
                    <button onClick={e => this.openUpgradeModal()}
                            id="navbarUpgradeBtn"
                            className="heroButton" style={{ backgroundColor: styles.mainGreen}}> {upgradeText}</button>
                </div>
            )
        } else {
            const style = {
                // padding: "6px 16px",
                background: styles.mainGreen,
                border: "1px solid " + styles.mainGreen,
                color: "#FFFFFF",
                borderRadius: "4px"
            }

            return (
                <button onClick={e => this.openUpgradeModal()}
                        id="navbarUpgradeBtn"
                        style={style}> {upgradeText}</button>
            )
        }

    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(UpgradeBtn)