import React, {Component} from 'react'
import AboutHero from "./AboutHero";
import ProductQualityFeature from "./ProductQualityFeature";
import { fadeInUp, fadeIn } from 'react-animations';
import Radium, {StyleRoot} from 'radium';
import Spreadsheet from "./Spreadsheet.svg"
import LeadershipRow from "./LeadershipRow";
import styles from "../Constants/styles";
import { isMobile } from 'react-device-detect'

class AboutIndex extends Component {
    render() {
        const styles = {
            fadeInUp: {
                animation: 'x 2s',
                animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
            },
            fadeIn: {
                animation: 'x 10s',
                animationName: Radium.keyframes(fadeIn, 'fadeIn')
            }
        };

        return (
            <div style={{ paddingBottom: "240px" }}>
                <div style={{paddingTop: "48px", paddingBottom: "12px" }}>
                    <AboutHero/>
                </div>
                <StyleRoot>
                    <div className="aboutDesc" style={{ textAlign: 'center' }}>
                        <p>We are a startup that wants YOU to Love Spreadsheets!</p>
                        <p>We strive to make this happen by doing your spreadsheet work for you. And by making easy to use spreadsheet productivity software.</p>
                    </div>
                </StyleRoot>
                <StyleRoot>
                    <div style={styles.fadeInUp}>
                        <div className="aboutHeader">Our Vision</div>
                        <div className="aboutDesc">
                            <p>We want to solve every spreadsheet user's pain in dealing with spreadsheets by doing your spreadsheet work quickly, accurately, and transparently.</p>
                            <p>So you can focus on your <i><b>actual</b></i> business</p>
                            <p>We want everyone in the world who deals with spreadsheets to think of our service and our products before starting with their spreadsheets</p>
                        </div>
                    </div>
                </StyleRoot>
                <StyleRoot>
                    <div style={styles.fadeInUp}>
                        <h1 className="aboutHeader">Our Team</h1>
                        <div className="aboutDesc">
                            <p>We are based in New Jersey, USA and Bangkok, Thailand. We are, or have been, programmers, data scientists, business analysts, designers, football fans, nature lovers and foodies.</p>
                            <p>But above all, we are spreadsheet lovers. We come from Excel heavy backgrounds and love building productivity tools and services that enhance your Excel experience.</p>
                        </div>
                    </div>
                </StyleRoot>
                <StyleRoot>
                    <div style={styles.fadeInUp}>
                        <h1 className="aboutHeader">Leadership</h1>
                        <div style={{ padding: isMobile ? "24px 40px 24px 40px" : "24px 120px 24px 120px" }}>
                            <div style={{ marginBottom: "24px" }}>
                                <LeadershipRow
                                    img="https://project-static-assets.s3.amazonaws.com/LoveSpreadsheets/TeamPics/AdhaarProCrop.jpg"
                                    name="Adhaar Sharma"
                                    title="CEO & Co-Founder"
                                    desc={[
                                        "Adhaar witnessed first hand the amount of time spreadsheet work takes during his time as a Consultant at Accenture & ZS Associates",
                                        "He would often dream of a service like Love Spreadsheets when he spent days fixing and formatting spreadsheets issues for his clients",
                                        "He holds an MS in Machine Learning from Georgia Institute of Technology and a BS in Chemical Engineering from Rutgers University",
                                        "When not thinking about spreadsheets, Adhaar loves to travel and eat!"
                                    ]}
                                />
                            </div>
                            <div style={{ marginBottom: "24px" }}>
                                <LeadershipRow
                                    img="https://project-static-assets.s3.amazonaws.com/LoveSpreadsheets/TeamPics/AsthaProCrop.jpg"
                                    name="Astha Sharma"
                                    title="COO & Co-Founder"
                                    desc={[
                                        "Astha crunched a ton of numbers in spreadsheets during her time as a UX researcher",
                                        "As a non-data and non-technical person, she was constantly frustrated by the lack of easy to use tools and services for an Excel beginner to analyze their data",
                                        "She holds a BS in Human Computer Interaction & Marketing from the New Jersey Institute of Technology",
                                        "Astha spends her free time hiking and trying out restaurants in New Jersey"
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                </StyleRoot>
                <StyleRoot>
                    <div style={styles.fadeInUp}>
                        <h1 className="aboutHeader">Investors</h1>
                        <div className="aboutDesc">
                            <p style={{ textAlign: "center" }}>We are backed by Technology Incubators & Individual Investors from Atlanta</p>
                            <div className="row">
                                <div className="col-sm-5">
                                    <a href="https://create-x.gatech.edu/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "inherit", textAlign: "center" }}>
                                        <img
                                            src="https://project-static-assets.s3.amazonaws.com/LoveSpreadsheets/TeamPics/CreateX.jpg"
                                            style={{ margin: "0 auto", display: "block" }}
                                            alt="Create X"
                                        />
                                        <div style={{ paddingTop: '16px'}}>
                                            <div style={{ fontWeight: "700", fontSize: "20px" }}>
                                                Create-X
                                            </div>
                                            <div style={{ fontWeight: "700", fontSize: "14px", color: styles.subText }}>
                                                Georgia Institute of Technology Incubator
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-sm-2" />
                                <div className="col-sm-5">
                                    <a href="https://en.wikipedia.org/wiki/Chris_Klaus" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "inherit", textAlign: "center" }}>
                                        <img
                                            src="https://project-static-assets.s3.amazonaws.com/LoveSpreadsheets/TeamPics/ChrisKlaus.jpg"
                                            style={{ margin: "0 auto", display: "block" }}
                                            alt="Chris Klaus"
                                        />
                                        <div style={{ paddingTop: '16px'}}>
                                            <div style={{ fontWeight: "700", fontSize: "20px" }}>
                                                Chris Klaus
                                            </div>
                                            <div style={{ fontWeight: "700", fontSize: "14px", color: styles.secondaryText }}>
                                                Atlanta area professional angel & seed investor
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </StyleRoot>
            </div>
        )
    }
}

export default AboutIndex