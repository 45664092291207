import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../Constants/styles";
import EditDatabaseParameters from "./EditDatabaseParameters"
import TroubleshootStepBox from "./TroubleshootStepBox";
import LabelTag from "../SharedComponents/LabelTag"

class EditDatabaseSettingsModalBody extends Component {
    constructor(props) {
        super(props);
    }

    getDbInfo = () => {
        let dbInfo;
        try{
            dbInfo = this.props.modalInfo.dbInfo
        } catch (e){
            dbInfo = null
        }

        if (dbInfo === undefined){
            dbInfo = null
        }

        return dbInfo
    }

    render() {
        const dbInfo = this.getDbInfo();

        return (
            <div>
                <div style={{ fontSize: '18px', fontWeight: "700", marginBottom: "12px" }}>
                    <i className="fa-solid fa-database"></i> <span style={{ paddingLeft: '4px'}}> Troubleshoot your database connection</span>
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: "8px"}}>
                    <div style={{ borderRight: "1px solid #F0F0F0", padding: "16px"}}>
                        <div style={{ fontSize: '16px', fontWeight: "700", marginBottom: "12px", color: styles.subText }}>
                            <i className="fa-solid fa-gear"></i><span style={{ paddingLeft: '4px'}}> Modify your connection parameters</span>
                        </div>
                        <div>
                            {
                                dbInfo === null ? <div style={{ color: styles.red }}>
                                    There was an error getting your database parameters. Refresh and try again. Contact support if error persists
                                </div> : <EditDatabaseParameters dbInfo={dbInfo} />
                            }
                        </div>
                    </div>
                    <div style={{ padding: "16px"}}>
                        <div style={{ fontSize: '16px', fontWeight: "700", marginBottom: "12px", color: styles.subText }}>
                            <i class="fa-solid fa-wrench"></i><span style={{ paddingLeft: '4px'}}> Or try these steps</span>
                        </div>
                        <div>
                            <div className="docsSection">
                                <TroubleshootStepBox
                                    header={<span>Check that your database is actually running on its hostname: {dbInfo.authInfo.host}</span>}
                                    desc="You can check that it's running by going to your database hosting service"
                                />
                                <TroubleshootStepBox
                                    header="Check your database is publicly accessible on its hostname"
                                    desc="This means that the databases can be accessed over the IP or URL specified in your the host. It is a different setting than exposing the database to all IPs"
                                />
                                <TroubleshootStepBox
                                    header="Make sure your database can connect to Love Spreadsheets"
                                    desc={<div style={{ fontSize: "12px", color: styles.subText}}>
                                        You have two options: <br/>
                                        1. Expose your Database to ALL Public IPs: 0.0.0.0 <br/>
                                        2. <LabelTag bgColor="orange" color="white" label={<span><i className="fa-solid fa-lock"></i> <span style={{ paddingLeft: "4px"}}>SECURE</span></span>} /> Expose your Database connections ONLY from ALL of our IPs: <span><code>34.194.225.150</code> and <code>172.31.128.0</code> and <code>172.31.144.0</code></span>
                                    </div>}
                                />
                                <TroubleshootStepBox
                                    header="Make sure database is exposed to ALL our Private IPs"
                                    desc="If you are exposing to our Private IPs, make sure to expose the databse to ALL of them. Since the server we use to connect can change depending on traffic."
                                />
                                <TroubleshootStepBox
                                    header="Try adding a /32 or a /20 to our Private IPs"
                                    desc={<span style={{ fontSize: '12px', color: styles.subText}}>If you are exposing the database to our Private IPs, sometimes a /32 or /20 may need to be added. For e.g., <code>34.194.225.150/32</code> or <code>34.194.225.150/30</code></span>}
                                />
                                <TroubleshootStepBox
                                    header="Verify your credential information is accurate"
                                    desc="This includes the database name, and the username and password (if needed)"
                                />
                                <TroubleshootStepBox
                                    header="Verify that the username (if needed) has READ access"
                                    desc="Depending on the permissions set up by your database admin, the user profile associated to the username and password may not have have READ access to the database. No other permissions are needed by the way"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(EditDatabaseSettingsModalBody)