import React, {Component} from 'react'
import styles from "../../../Constants/styles";

class MonthlyDateSelection extends Component {
    constructor(props) {
        super(props);
    }

    canSelectMoreDates = () => {
        if (this.props.userReportFrequency === "monthly"){
            try{
                if (Array.isArray(this.props.report_info.dates)){
                    if (this.props.report_info.dates.length >=1){
                        return false
                    }
                }
            } catch (e) {
                return true
            }
        }

        return true
    }

    isDateSelected = (date) => {
        if ("dates" in this.props.report_info){
            if (Array.isArray(this.props.report_info.dates)){
                return this.props.report_info.dates.includes(date)
            }
        }

        return false
    }

    toggleDate = (date) => {
            const reportInfo = JSON.parse(JSON.stringify(this.props.report_info));

            if (this.isDateSelected(date)){
                let dates = reportInfo.dates;

                dates.splice(dates.indexOf(date), 1)

                if (dates.length > 0){
                    reportInfo.dates = dates
                } else {
                    delete reportInfo.dates
                }
            } else {
                if (this.canSelectMoreDates()){
                    if ("dates" in reportInfo){
                        if (Array.isArray(reportInfo.dates)){
                            let dates = reportInfo.dates;
                            dates.push(date)
                        }
                    } else {
                        reportInfo.dates = [date]
                    }
                } else {
                    // replace the date with new one selected
                    reportInfo.dates = [date]
                }

            }

            this.props.changeState({ report_info: reportInfo })
    }

    getDateBox = (date) => {
        const isDateSelected = this.isDateSelected(date);

        return <div style={{
            backgroundColor: this.props.isDatesChecked ? isDateSelected ? styles.blue : "white" : "gray",
            color: this.props.isDatesChecked ? isDateSelected ? "white" : styles.subText : styles.subText,
            border: "1px solid #F0F0F0",
            padding: "2px 4px",
            textAlign: "center",
            cursor: this.props.isDatesChecked ? "pointer" : "not-allowed"
        }} onClick={this.props.isDatesChecked ? e => this.toggleDate(date) : null}>
            {date}
        </div>
    }

    render() {
        const gridStyle = {display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr"}
        return (
            <div>
                <div style={gridStyle}>
                    {[1, 2, 3, 4, 5, 6, 7].map(x => <div key={x}>
                        {this.getDateBox(x)}
                    </div>)}
                </div>
                <div style={gridStyle}>
                    {[8, 9, 10, 11, 12, 13, 14].map(x => <div key={x}>
                        {this.getDateBox(x)}
                    </div>)}
                </div>
                <div style={gridStyle}>
                    {[15, 16, 17, 18, 19, 20, 21].map(x => <div key={x}>
                        {this.getDateBox(x)}
                    </div>)}
                </div>
                <div style={gridStyle}>
                    {[22, 23, 24, 25, 26, 27, 28].map(x => <div key={x}>
                        {this.getDateBox(x)}
                    </div>)}
                </div>
                <div style={gridStyle}>
                    {[29, 30, 31].map(x => <div key={x}>
                        {this.getDateBox(x)}
                    </div>)}
                    <div> </div>
                    <div> </div>
                    <div> </div>
                    <div> </div>
                </div>
            </div>
        )
    }
}

export default MonthlyDateSelection