import React, {Component} from 'react'
import ContactUsForm from './ContactUsForm'
import styles from "../Constants/styles";
import {getSupportDays, getSupportTimes} from "../Constants/supportHours";
import capitalizeHyphenated from "../Constants/capitalizeHyphenated";
import contact from "./contact.svg"

class ContactIndex extends Component {
    getContactInfoRow = (label, value) => {
        return <div style={{ marginBottom: "32px"}}>
            <div className="row">
                <div className="col-sm-6">
                    <div style={{ fontSize: "18px", color: styles.secondaryText }}>
                        {label}
                    </div>
                </div>
                <div className="col-sm-6">
                    <div style={{ fontSize: "18px", color: styles.mainText, fontWeight: "700" }}>
                        {value}
                    </div>
                </div>
            </div>
        </div>
    }

    getSupportHoursFinal = () => {
        const supportDays = getSupportDays()
        const supportTimes = getSupportTimes()

        return capitalizeHyphenated(supportDays.startDay) + "-" + capitalizeHyphenated(supportDays.endDay) + " " + supportTimes.startTime + " to " + supportTimes.endTime
    }

    render() {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone.replace("_", " ");

        return (
            <div className="row">
                <div className='col-sm-6'>
                    <div style={{ padding: "16px 32px 16px 32px"}}>
                        <div style={{ fontSize: "32px", fontWeight: "700", marginBottom: "32px"}}>
                            Contact Us
                        </div>
                        <ContactUsForm />
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div style={{ boxShadow: "-4px 0px 8px -6px rgba(0,0,0,1)", minHeight: "100vh", padding: "32px 32px 32px 32px"}}>
                        {this.getContactInfoRow("Hours (GMT)", "Monday-Friday 7AM to 7PM")}
                        {this.getContactInfoRow("Hours (" + timezone + ")", this.getSupportHoursFinal())}
                        {this.getContactInfoRow("Contact Email", "info@lovespreadsheets.com")}
                        {this.getContactInfoRow("Schedule Call", <a href="https://calendly.com/love-spreadsheets/25min"
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    style={{ textDecoration: "none" }}>Click here to schedule</a>)}
                        <div style={{ marginTop: "48px" }}>
                            <img src={contact} style={{ height: "300px", width: "100%"}} alt="Contact us illustration"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ContactIndex