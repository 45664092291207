import React, {Component} from 'react'

class HelpBtn extends Component {
    render() {
        return (
            <div style={{ padding: "8px", borderRadius: "4px", display: "grid", gridTemplateColumns: "auto 1fr", columnGap: "16px", border: "1px solid " + this.props.color }}>
                <div>
                    <div style={{borderRadius: "40px", padding: "10px", background: "#F0F0F0", width: "40px", height: "40px", textAlign: "center"}}>
                        <i className={this.props.icon} style={{color: this.props.color, fontSize: "20px"}}/>
                    </div>
                </div>
                <div>
                    <div style={{color: this.props.color, fontFamily: 'Lato', fontWeight: "700", fontSize: "14px", marginBottom: "2px"}}>
                        {this.props.header}
                    </div>
                    <div style={{color: "#5E645E", fontFamily: 'Lato', fontWeight: "400", fontSize: "12px"}}>
                        {this.props.desc}
                    </div>
                </div>
            </div>
        )
    }
}

export default HelpBtn