import React, {Component} from 'react'
import ModalApp from '../SharedComponents/Modal'
import FAQsModalBody from "./FAQsModalBody"

class FAQsModal extends Component {
    render() {
        return (
            <ModalApp name="faqsModal"
                      header={null}
                      body={<FAQsModalBody/>}
            />
        )
    }
}

export default FAQsModal