import {getPricingInfoFromUserPlan} from "./getPricingInfoFromUserPlan";

export const doesUserNeedToUpgrade = (userPlan, featureName, featureValue) => {
    const pricingInfo = getPricingInfoFromUserPlan(userPlan)
    const pricingValue = pricingInfo[featureName];

    if (['reports', 'team', 'databases'].includes(featureName)){
        return featureValue >= pricingValue
    }

    return false
}