import React, {Component} from 'react'
import ProductMenuRow from "./ProductMenuRow";
import {servicesInfo} from "../Constants/servicesInfo";

class ServicesMenu extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const isMobile = 'isMobile' in this.props ? this.props.isMobile : false;

        return (
            <div>
                <div style={isMobile ? { marginBottom: "8px" } : null}>
                    <ProductMenuRow
                        isMobile={isMobile}
                        name={servicesInfo.dataViz.name}
                        icon={servicesInfo.dataViz.icon}
                        desc="Live dashboards on websites, spreadsheets, or BI tools"
                        website="/services/live-dashboards-on-website-from-excel-and-google-sheets.html"

                    />
                </div>
                <div style={isMobile ? { marginBottom: "8px" } : null}>
                    <ProductMenuRow
                        isMobile={isMobile}
                        name={servicesInfo.autoReporting.name}
                        icon={servicesInfo.autoReporting.icon}
                        desc="Auto create & email PDF, Spreadsheet, or other reports"
                        website="/services/send-emails-from-your-spreadsheet-data.html"

                    />
                </div>
                <div style={isMobile ? { marginBottom: "8px" } : null}>
                    <ProductMenuRow
                        isMobile={isMobile}
                        name={servicesInfo.dataTransfer.name}
                        icon={servicesInfo.dataTransfer.icon}
                        desc="Transfer data between files, systems & databases"
                        website="/services/automate-data-transfer-from-excel-and-google-sheets.html"

                    />
                </div>
                <div style={isMobile ? { marginBottom: "8px" } : null}>
                    <ProductMenuRow
                        isMobile={isMobile}
                        name={servicesInfo.dataCleaning.name}
                        icon={servicesInfo.dataCleaning.icon}
                        desc="Clean, de-dupe, consolidate, & transform your data"
                        website="https://www.cleanspreadsheets.com/"

                    />
                </div>
                <div style={isMobile ? { marginBottom: "8px" } : null}>
                    <ProductMenuRow
                        isMobile={isMobile}
                        name={servicesInfo.dataEntry.name}
                        icon={servicesInfo.dataEntry.icon}
                        desc="Scrape websites, collect form data, PDF or Images to Excel"
                        website="/services/automate-data-entry-for-excel-files-google-sheets.html"

                    />
                </div>
                <div style={isMobile ? { marginBottom: "8px" } : null}>
                    <ProductMenuRow
                        isMobile={isMobile}
                        name={servicesInfo.dataAnalysis.name}
                        icon={servicesInfo.dataAnalysis.icon}
                        desc="Model your data with regression, ANOVA, t-test etc."
                        website="https://www.mergespreadsheets.com/"
                    />
                </div>
            </div>
        )
    }
}

export default ServicesMenu