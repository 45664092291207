import React, {Component} from 'react'
import CompanyLinks from "./CompanyLinks";
import WoyeraInc from "./WoyeraInc";
import ProductsLinks from "./ProductsLinks";
import FooterIconAndTagline from "./FooterIconAndTagline";
import SupportLinks from "./SupportLinks";
import FooterTermsAndPrivacy from "./FooterTermsAndPrivacy";
import NewsletterSignUp from "../Landing/NewsletterSignUp"

class FullFooter extends Component {
    render() {
        const headerStyle = {fontFamily: "Lato", fontWeight: "700", fontSize: "16px", color: "#161E16", marginBottom: "12px"}

        return (
            <div style={{
                borderTop: "1px solid #F0F0F0",
                margin: "0 100px 0 100px",
                padding: "50px 10px 10px 10px",
                color: "#161E16"
            }}>
                <div style={{paddingBottom: "72px", borderBottom: "1px solid #F0F0F0"}}>
                    <div style={{display: "grid", gridTemplateColumns: "1.25fr 0.75fr 1fr 1fr", columnGap: "24px"}}>
                        <div>
                            {/*<NewsletterSignUp/>*/}
                            <FooterIconAndTagline/>
                        </div>
                        <div style={{paddingLeft: "32px"}}>
                            <CompanyLinks headerStyle={headerStyle}/>
                        </div>
                        <div style={{paddingLeft: "32px"}}>
                            <ProductsLinks headerStyle={headerStyle}/>
                        </div>
                        <div style={{paddingLeft: "32px"}}>
                            <SupportLinks headerStyle={headerStyle}/>
                        </div>
                    </div>
                </div>
                <div style={{paddingTop: "16px"}}>
                    <div style={{display: "grid", gridTemplateColumns: "1fr 1fr"}}>
                        <div>
                            <WoyeraInc/>
                        </div>
                        <div style={{textAlign: "right"}}>
                            <FooterTermsAndPrivacy/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FullFooter