import React, {Component} from 'react'
import ModalApp from '../SharedComponents/Modal'
import EditDatabaseSettingsModalBody from "./EditDatabaseSettingsModalBody"

class EditDatabaseSettingsModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ModalApp name="editDatabaseSettingsModal"
                      header={null}
                      body={<EditDatabaseSettingsModalBody />}
            />
        )
    }
}

export default EditDatabaseSettingsModal