import React, {Component} from 'react'
import Loader from './Loader'
import API_Root from "./Constants/API_Root";
import isEmailValid from "./Constants/isEmailValid";

class EmailUnsubscribeIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            unsubscribing: false,
            error: false,
            done: false,
            email: ""
        }
    }

    unsubscribe = () => {
        fetch(API_Root + "api/unsubscribe-from-product-emails/", {
            method: "POST",
            body: JSON.stringify({
                email: this.state.email,
            })
        }).then((res) => {
            if (res.status === 200){
                this.setState({ unsubscribing: false, error: false, done: true })
            }
            else{
                this.setState({ unsubscribing: false, error: true, done: false })
            }
        }).catch(err => this.setState({ unsubscribing: false, error: true, done: false }))
    }

    handleInputChange = (e) => {
        this.setState({
            email: e.target.value
        })
    }

    render() {
        if (this.state.unsubscribing){
            return (
                <div style={{minHeight: '100vh', textAlign: "center", marginTop: "100px"}}>
                    <h4 style={{marginBottom: "50px"}}>Unsubscribing { this.state.email } from Love Spreadsheet emails...</h4>
                    <div style={{width: "50px", height: "50px"}}>
                        <Loader />
                    </div>
                </div>
            )
        } else {
            let emojiStyle = null;
            if (this.state.error){
                return (
                    <div style={{minHeight: '100vh', textAlign: "center", marginTop: "100px"}}>
                        <h4 style={{marginBottom: "50px"}}><box-icon name='error' style={{color: "red"}} /> {this.state.email} could not be unsubscribed. Please email us at support@apispreadsheets.com to be unsubscribed.</h4>
                    </div>
                )
            } else {
                if (this.state.done){
                    return (
                        <div style={{minHeight: '100vh', textAlign: "center", marginTop: "100px"}}>
                            <h4 style={{marginBottom: "50px"}}>{this.state.email} successfully unsubscribed!
                                <span role="img" aria-label="smile" style={emojiStyle}>😀</span>
                                <span role="img" aria-label="confetti" style={emojiStyle}>🎉</span>
                                <span role="img" aria-label="heart" style={emojiStyle}>❤️</span>
                                <span role="img" aria-label="beer" style={emojiStyle}>🍻</span>
                                <span role="img" aria-label="poppers" style={emojiStyle}>🎊</span>
                            </h4>
                        </div>
                    )
                } else{
                    return (
                        <div style={{minHeight: '100vh', textAlign: "center", marginTop: "100px"}}>
                            <h4>Enter your email to unsubscribe</h4>
                            <div style={{margin: "0 auto"}}>
                                <input  className="input-field"
                                        type="text"
                                        style={{height: "50px"}}
                                        value={this.state.email}
                                        name="email"
                                        onChange={this.handleInputChange}
                                        placeholder="📧 Email"
                                />
                            </div>
                            {!isEmailValid(this.state.email.trim()) ? <div style={{color: "red"}}><small>Please enter a valid email</small></div> : null}
                            <button className="heroButton" onClick={this.unsubscribe}
                                    style={{marginTop:"40px", cursor: !isEmailValid(this.state.email.trim()) ? "not-allowed" : "pointer", opacity: !isEmailValid(this.state.email.trim()) ? "0.5" : "1"}}>
                                Unsubscribe
                            </button>
                        </div>
                    )
                }

            }
        }
    }
}

export default EmailUnsubscribeIndex