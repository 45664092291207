import React, {Component} from 'react'
import FooterItem from "./FooterItem"

class CompanyLinks extends Component {
    render() {
        return (
            <div>
                <div style={this.props.headerStyle}>Company</div>
                <FooterItem to="https://www.lovespreadsheets.com/" name="Love Spreadsheets" external />
                <FooterItem to="https://www.lovespreadsheets.com/contact" name="Contact Us" external />
                <FooterItem to="https://www.lovespreadsheets.com/careers" name="Careers" external />
                <FooterItem to="https://lovespreadsheets.medium.com/" name="Blog" external/>
            </div>
        )
    }
}

export default CompanyLinks