import React, {Component} from 'react'
import {connect} from 'react-redux'
import ReportsInputIndex from "./ReportsInputIndex"
import Spreadsheet from "react-spreadsheet";
import styles from "../../Constants/styles";
import DownloadResultsBtn from "./DownloadResultsBtn"
import Button from "../../SharedComponents/Button"
import {toggleModal} from "../../Actions/ToggleModal";

class ReportsIndex extends Component {
    getReportInfo = () => (this.props.allDBReports[this.props.reportSelectedIdx])

    formatDataToDisplay = () => {
        let cols = this.getReportInfo()['results_100_rows']['testColumns'];
        let records = this.getReportInfo()['results_100_rows']['testRecords'];

        try{
            let data = [];

            for (let i=0; i<records.length; i++){
                const rowObj = records[i];
                let row = [];

                for (let j=0; j<cols.length; j++){
                    row.push({value: JSON.stringify(rowObj[cols[j]])})
                }

                data.push(row)
            }


            return data
        } catch (e){
            return [[]]
        }
    }

    render() {
        // input_fk(pin): 89
        // input_type(pin): "database"
        // prompt(pin): ""
        // query(pin): ""
        // results_100_rows(pin):
        // file_link(pin): ""
        // send_email_report(pin): false
        // send_google_sheets(pin): false
        // email_report_info(pin): google_sheets_info(pin): additional_info(pin):
        // created_dt(pin): "2023-05-25T17:07:14.142630+00:00"
        // updated_dt(pin): "2023-05-25T17:07:14.142649+00:00"

        const segmentStyle = { backgroundColor: "white", marginTop: "24px", padding: "12px", border: "1px solid #F0F0F0", borderRadius: "4px", boxShadow: "0px 5px 6px -4px rgba(2, 34, 19, 0.02)"}

        return (
            <div style={{ padding: this.props.standardPadding}}>
                <ReportsInputIndex />
                {
                    'testColumns' in this.getReportInfo()['results_100_rows'] && 'testRecords' in this.getReportInfo()['results_100_rows'] ?
                        <div style={segmentStyle}>
                            <div style={{ marginBottom: "12px" }}>
                                <div style={{ display: "grid", gridTemplateColumns: "1fr auto", columnGap: "16px" }}>
                                    <div>
                                        <div style={{ color: styles.mainText, fontSize: "16px", fontWeight: "700" }}>Results</div>
                                        <div style={{ color: styles.subText, fontSize: "12px" }}>Showing up to first 50 rows</div>
                                    </div>
                                    <div>
                                        <div style={{ display: "grid", gridTemplateColumns: "auto auto", columnGap: "8px"}}>
                                            <div>
                                                <Button style={{
                                                    backgroundColor: "white",
                                                    cursor: "pointer",
                                                    color: styles.blue,
                                                    border: "1px solid " + styles.blue,
                                                    borderRadius: "4px",
                                                    fontSize: "16px",
                                                    outline: "none",
                                                    padding: "6px 16px" }}
                                                        small
                                                        label={<span><i className="fa-regular fa-clock"></i> <span style={{ paddingLeft: "4px" }}>Set up auto report</span></span>}
                                                        onClick={e => this.props.toggleModal("setupTimeModal")}
                                                />
                                            </div>
                                            <div>
                                                <DownloadResultsBtn reportInfo={this.getReportInfo()}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="uploadFilesSampleTableContainer">
                                <div id="uploadFilesSampleTable">
                                    <Spreadsheet data={this.formatDataToDisplay()}
                                                 columnLabels={this.getReportInfo()['results_100_rows'].testColumns}
                                                 className="sampleDataTable"/>
                                </div>
                            </div>
                        </div> : null
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    testResultData: state.mainState.testResultData,
    reportSelectedIdx: state.mainState.reportSelectedIdx,
    allDBReports: state.mainState.allDBReports,
})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(ReportsIndex)