import React, {Component} from 'react'
import Modal from '../SharedComponents/Modal'
import PasswordForget from "./PasswordForget";

class ForgetPasswordModal extends Component {
    render() {
        const body =
            <div className="row">
                <div className="col-sm-2" />
                <div className="col-sm-8">
                    <br/><br/>
                    <PasswordForget/>
                    <br/><br/><br/>
                </div>
                <div className="col-sm-2" />
            </div>;

        return (
            <Modal name="forgetPasswordModal"
                      header={null}
                      body={body}
                      size="medium"
                   bodyColor="#DCDCDC"
            />
        )
    }
}


export default ForgetPasswordModal