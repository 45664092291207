import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../Constants/styles";
import TeamName from "./TeamName";
import TeamUserRow from "./TeamUserRow"
import {modifySelectedTeamInfo} from "../Actions/ModifySelectedTeamInfo";
import {isTeamUserAdmin} from "../Constants/isTeamUserAdmin";
import {doesUserNeedToUpgrade} from "../Constants/doesUserNeedToUpgrade";
import {toggleModal} from "../Actions/ToggleModal";
import {modifyModalInfo} from "../Actions/ModifyModalInfo";
import {showUpgradeModal} from "../Constants/showUpgradeModal";

class TeamDisplay extends Component {
    constructor(props) {
        super(props);
    }

    addMember = () => {
        const copiedTeamInfo = JSON.parse(JSON.stringify(this.props.selectedTeamInfo));
        const allUsers = copiedTeamInfo.team_users;

        if (doesUserNeedToUpgrade(this.props.userPlan, "team", allUsers.length)){
            showUpgradeModal(this.props.toggleModal, this.props.modifyModalInfo, this.props.userPlan, "team", allUsers.length, this.props.userTeamPK, this.props.selectedTeamPK, this.props.allTeamsInfo)
        } else {
            allUsers.push({
                id: null,
                user_id: null,
                love_team_id: this.props.selectedTeamPK,
                user_email: "",
                role: "",
                has_joined: false,
                info: {},
                created_dt: new Date().toUTCString(),
                updated_dt: new Date().toUTCString()
            })

            copiedTeamInfo.team_users = allUsers

            this.props.modifySelectedTeamInfo(copiedTeamInfo)
        }
    }

    render() {
        if (this.props.selectedTeamInfo !== null){
            const isUserAdmin = isTeamUserAdmin(this.props.selectedTeamRole);

            return (
                <div>
                    <div style={{marginBottom: "12px"}}>
                        <TeamName teamName={this.props.selectedTeamInfo.name}/>
                    </div>
                    {
                        this.props.selectedTeamInfo.team_users.length > 0 ?
                            this.props.selectedTeamInfo.team_users.map((x, idx) =>
                            <div key={idx} style={{ marginBottom: "8px" }}>
                                <TeamUserRow idx={idx} userInfo={x} isUserAdmin={isUserAdmin}/>
                            </div>) : null
                    }
                    {
                       isUserAdmin ?
                           <div>
                               <button style={{
                                   border: "none",
                                   backgroundColor: styles.mainGreen,
                                   borderRadius: "24px",
                                   padding: "4px 8px",
                                   color: "white",
                                   fontSize: "12px"
                               }}
                                       onClick={this.addMember}
                               >
                                   <i className="fa fa-plus" aria-hidden="true" /> Add member
                               </button>
                           </div>  : null
                    }
                </div>
            )
        } else {
            return null
        }
    }
}

const mapStateToProps = (state) => ({
    selectedTeamInfo: state.mainState.selectedTeamInfo,
    selectedTeamPK: state.mainState.selectedTeamPK,
    selectedTeamRole: state.mainState.selectedTeamRole,
    userPlan: state.mainState.userPlan,
    userTeamPK: state.mainState.userTeamPK,
    allTeamsInfo: state.mainState.allTeamsInfo
})

const mapActionsToProps = {
    modifySelectedTeamInfo: modifySelectedTeamInfo,
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo
}

export default connect(mapStateToProps, mapActionsToProps)(TeamDisplay)