import React, {Component} from 'react'
import styles from "../../../Constants/styles";
import Select from 'react-select'
import CustomTimeRow from "./CustomTimeRow";

class AddCustomTimes extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount(){
        if (this.props.report_times.length === 0){
            this.addPlaceholderTime("12:00")
        }
    }

    addPlaceholderTime = (time) => {
        const currTimes = JSON.parse(JSON.stringify(this.props.report_times))

        currTimes.push({
            time: time,
            additional_info: {}
        })

        // add null when clicking new button
        this.props.changeState({
            report_times: currTimes
        })
    }

    changeTime = (timeObj, idx) => {
        const report_times = JSON.parse(JSON.stringify(this.props.report_times));

        const currTime = report_times[idx];
        currTime.time = timeObj.value

        report_times[idx] = currTime
        this.props.changeState({ report_times: report_times })
    }

    deleteCustomTimeRow = (idx) => {
        const report_times = JSON.parse(JSON.stringify(this.props.report_times));

        report_times.splice(idx, 1)

        this.props.changeState({ report_times: report_times })
    }

    getAllTimes = () => (this.props.report_times.map(x => x.time))

    render() {
        return (
            <div>
                {
                    this.props.report_times.length > 0 ?
                        this.props.report_times.map((x, idx) =>
                            <CustomTimeRow
                                userReportFrequency={this.props.userReportFrequency}
                                allTimes={this.getAllTimes()}
                                timeInfo={x}
                                idx={idx}
                                key={idx}
                                deleteCustomTimeRow={this.deleteCustomTimeRow}
                                changeTime={this.changeTime}
                            />
                        ) : null
                }
                {
                    !['monthly', 'daily'].includes(this.props.userReportFrequency) ?
                        <button style={{
                            border: "none",
                            backgroundColor: styles.mainGreen,
                            borderRadius: "24px",
                            padding: "4px 8px",
                            color: "white",
                            fontSize: "12px"
                        }}
                                onClick={e => this.addPlaceholderTime(null)}
                        >
                            <i className="fa fa-plus" aria-hidden="true" /> Add more time(s)
                        </button> : null
                }

            </div>
        )
    }
}

export default AddCustomTimes