const styles = {
    dropdown: {background: "#F0F0F0", border: "none", outline: "none", fontFamily: "Lato", fontSize: '14px', color: "#161E16"},
    btnGreen: {backgroundColor: "#6FBB98", color: "white"},
    btnGreenBasic: {backgroundColor: "#FFFFFF", border: "1px solid #6FBB98", color: "#6FBB98"},
    btnBlue: {backgroundColor: "#5499F8", color: "white"},
    btnGray: {backgroundColor: "#9696A0", color: "white"},
    mainGreen: "#6FBB98",
    subGreen: "#D1E4CE",
    bgGreen: "#F4FBF3",
    blue: "#5499F8",
    orange: "#E27837",
    subOrange: "#FFF7F3",
    red: "#EE685F",
    midRed: "#f4a49f",
    subRed: "#FDEFEF",
    mainText: "#161E16",
    subText: "#5E645E",
    secondaryText: "#9696A0"
}

export default styles