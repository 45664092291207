export const changeToFilesTab = (props, goToProjectFiles=false) => {
    props.modifySidebarSelection("file");

    if (goToProjectFiles){
        try{
            props.toggleFileSidebarSelection('projectFiles')
        } catch (e) {

        }
    }
}

// export const changeToFilesTab = () => (console.log("change"))