import {TOGGLE_MODAL} from "../Actions/ToggleModal";
import {TOGGLE_IS_AUTH} from "../Actions/ToggleIsAuth";
import {TOGGLE_PRICING_POPUP} from "../Actions/TogglePricingPopup";
import {MODIFY_MODAL_INFO} from "../Actions/ModifyModalInfo";
import {MODIFY_WORK_TYPE} from "../Actions/ModifyWorkType";
import { TOGGLE_IS_MOBILE_SIDEBAR_OPEN } from "../Actions/ToggleIsMobileSidebarOpen";
import {MODIFY_ALL_ORDERS} from "../Actions/ModifyAllOrders";
import {MODIFY_AI_INPUT} from "../Actions/ModifyAiInput";
import {MODIFY_AI_FILES} from "../Actions/ModifyAiFiles";
import {MODIFY_AI_FILE_SELECTED} from "../Actions/ModifyAiFileSelected";
import {MODIFY_AI_RESULTS} from "../Actions/ModifyAIResults";
import {MODIFY_AI_RESULTS_PK} from "../Actions/Upload/ModifyAiResultsPK";
import {TOGGLE_IS_AI_RESULT_SELECTED} from "../Actions/ToggleIsAiResultSelected";
import {MODIFY_AI_RESULT_SELECTED} from "../Actions/ModifyAiResultSelected";
import {TOGGLE_AI_RESULTS_RUN} from "../Actions/ToggleAiResultsRun";
import {MODIFY_ALL_AI_RESULTS} from "../Actions/ModifyAllAiResults";
import {TOGGLE_FETCHING_ALL_AI_RESULTS} from "../Actions/ToggleFetchingAllAiResults";
import {INIT_NEW_PROJECT} from "../Actions/initNewProject";
import {MODIFY_USER_PLAN} from "../Actions/ModifyUserPlan";
import {MODIFY_APP_NAME} from "../Actions/ModifyAppName";
import {TOGGLE_USE_TEST_DATA} from "../Actions/ToggleUseTestData";
import {RESET_ALL_STATES} from "../Actions/ResetAllStates";
import {TOGGLE_RUN_PRODUCT_DEMO} from "../Actions/ToggleRunProductDemo";
import {MODIFY_AI_FILES_SELECTED_TO_RUN} from "../Actions/ModifyAiFilesSelectedToRun";
import {TOGGLE_RUN_RESULTS_ON_MULTIPLE_FILES} from "../Actions/ToggleRunResultsOnMultipleFiles";
import {MODIFY_DEMO_TOUR_STEP} from "../Actions/ModifyDemoTourStep";
import {MODIFY_AI_CLEAN_COLUMNS_SELECTED} from "../Actions/ModifyAiCleanColumnsSelected";
import {MODIFY_SIDEBAR_SELECTION} from "../Actions/ModifySidebarSelection";
import {MODIFY_ALL_PROJECTS} from "../Actions/ModifyAllProjects";
import {MODIFY_PROJECT_SELECTED_IDX} from "../Actions/ModifyProjectSelectedIdx";
import {MODIFY_PROJECT_CONVERSATIONS} from "../Actions/ModifyProjectConversations";
import {TOGGLE_FETCHING_RESULTS} from "../Actions/ToggleFetchingResults";
import {MODIFY_CONVERSATION_COUNT} from "../Actions/ModifyConversationCount";
import {MODIFY_USER_INTEGRATIONS} from "../Actions/ModifyUserIntegrations";
import {TOGGLE_CHAT_MODE} from "../Actions/ToggleChatMode";
import {isLastConvoData} from "../Constants/isLastConvoData";
import {getSelectedFilesAndCols} from "../Constants/getSelectedFilesAndCols";
import {MODIFY_CHAT_FILE_SELECTED} from "../Actions/ModifyChatFileSelected";
import {MODIFY_ALL_USER_FILES} from "../Actions/ModifyAllUserFiles";
import {isTestFile} from "../Constants/isTestFile";
import {TOGGLE_FILE_SIDEBAR_SELECTION} from "../Actions/ToggleFileSidebarSelection";
import {MODIFY_ALL_DATABASES} from "../Actions/ModifyAllDatabases";
import {MODIFY_DB_SELECTED_IDX} from "../Actions/ModifyDBSelectedIdx";
import {MODIFY_REPORT_SELECTED_IDX} from "../Actions/ModifyReportSelectedIdx";
import {MODIFY_ALL_DB_REPORTS} from "../Actions/ModifyAllDBReports";
import {MODIFY_TEST_RESULT_DATA} from "../Actions/ModifyTestResultData";
import {INIT_GOOGLE_USER_INFO} from "../Actions/Upload/InitGoogleUserInfo";
import {INIT_IS_GOOGLE_USER} from "../Actions/InitIsGoogleUser";
import {TOGGLE_TOAST} from "../Actions/ToggleToast";
import {MODIFY_ALL_TEAMS_INFO} from "../Actions/ModifyAllTeamsInfo";
import {MODIFY_SELECTED_TEAM_PK} from "../Actions/ModifySelectedTeamPK";
import {MODIFY_SELECTED_TEAM_INFO} from "../Actions/ModifySelectedTeamInfo";
import {MODIFY_TEAM_SELECTED_IDX} from "../Actions/ModifyTeamSelectedIdx";
import {MODIFY_SELECTED_TEAM_ROLE} from "../Actions/ModifySelectedTeamRole";
import {MODIFY_USER_TEAM_PK} from "../Actions/ModifyUserTeamPK";
import {MODIFY_CHECKOUT_URL} from "../Actions/ModifyCheckoutUrl";
import {MODIFY_PRICING_TYPE} from "../Actions/ModifyPricingType";
import {MODIFY_MODAL_IMAGE_URL} from "../Actions/ModifyModalImageURL";

function mainState(
    state = {
        modalName: null,
        isAuth: false,
        pricingPopup: true,
        modalInfo: {},
        workType: "automate",
        isMobileSidebarOpen: false,
        allOrders: null,
        aiInput: "",
        aiFiles: [],
        aiFileSelected: null,
        aiResults: null,
        aiResultsPK: null,
        isAiResultSelected: false,
        aiResultSelected: null,
        userPlan: null,
        aiResultsRun: false,
        allAiResults: [],
        fetchingAllAiResults: false,
        appName: "filter",
        useTestData: false,
        runProductDemo: false,
        runResultsOnMultipleFiles: true,
        aiFilesSelectedToRun: [],
        demoTourStep: 0,
        aiCleanColumnsSelected: {},
        sidebarSelection: "connectDataSources",
        allProjects: [],
        projectSelectedIdx: null,
        projectConversations: [],
        fetchingResults: false,
        conversationCount: 0,
        userIntegrations: {},
        chatMode: true,
        chatFileSelected: 0,
        allUserFiles: [],
        fileSidebarSelection: "allFiles",
        allDatabases: [],
        dbSelectedIdx: null,
        reportSelectedIdx: null,
        allDBReports: [],
        testResultData: {},
        googleUserInfo: {},
        isGoogleUser: false,
        toast: {show: false, message: "", type: ""},
        allTeamsInfo: [],
        selectedTeamPK: null,
        selectedTeamInfo: null,
        teamSelectedIdx: null,
        selectedTeamRole: null,
        userTeamPK: null,
        checkoutUrl: "",
        pricingType: "monthly",
        modalImageURL: ''
    }, action
) {
    switch (action.type){
        case MODIFY_MODAL_IMAGE_URL:
            return Object.assign({}, state, {
                modalImageURL: action.mainState.modalImageURL
            })
        case MODIFY_PRICING_TYPE:
            return Object.assign({}, state, {
                pricingType: action.mainState.pricingType
            })
        case MODIFY_CHECKOUT_URL:
            return Object.assign({}, state, {
                checkoutUrl: action.mainState.checkoutUrl
            });
        case MODIFY_USER_TEAM_PK:
            return Object.assign({}, state, {
                userTeamPK: action.mainState.userTeamPK
            });
        case MODIFY_SELECTED_TEAM_ROLE:
            return Object.assign({}, state, {
                selectedTeamRole: action.mainState.selectedTeamRole
            });
        case MODIFY_TEAM_SELECTED_IDX:
            return Object.assign({}, state, {
                teamSelectedIdx: action.mainState.teamSelectedIdx
            });
        case MODIFY_SELECTED_TEAM_INFO:
            return Object.assign({}, state, {
                selectedTeamInfo: action.mainState.selectedTeamInfo
            });
        case MODIFY_SELECTED_TEAM_PK:
            return Object.assign({}, state, {
                selectedTeamPK: action.mainState.selectedTeamPK
            });
        case MODIFY_ALL_TEAMS_INFO:
            return Object.assign({}, state, {
                allTeamsInfo: action.mainState.allTeamsInfo
            });
        case TOGGLE_TOAST:
            return Object.assign({}, state, {
                toast: action.mainState.toast
            });
        case INIT_GOOGLE_USER_INFO:
            return Object.assign({}, state, {
                googleUserInfo: action.mainState.googleUserInfo
            });
        case INIT_IS_GOOGLE_USER:
            return Object.assign({}, state, {
                isGoogleUser: action.mainState.isGoogleUser
            });
        case MODIFY_TEST_RESULT_DATA:
            return Object.assign({}, state, {
                testResultData: action.mainState.testResultData
            });
        case MODIFY_ALL_DB_REPORTS:
            return Object.assign({}, state, {
                allDBReports: action.mainState.allDBReports
            });
        case MODIFY_REPORT_SELECTED_IDX:
            return Object.assign({}, state, {
                reportSelectedIdx: action.mainState.reportSelectedIdx
            });
        case MODIFY_DB_SELECTED_IDX:
            return Object.assign({}, state, {
                dbSelectedIdx: action.mainState.dbSelectedIdx
            });
        case MODIFY_ALL_DATABASES:
            return Object.assign({}, state, {
                allDatabases: action.mainState.allDatabases
            });
        case TOGGLE_FILE_SIDEBAR_SELECTION:
            return Object.assign({}, state, {
                fileSidebarSelection: action.mainState.fileSidebarSelection
            });
        case MODIFY_ALL_USER_FILES:
            return Object.assign({}, state, {
                allUserFiles: action.mainState.allUserFiles
            });
        case MODIFY_CHAT_FILE_SELECTED:
            return Object.assign({}, state, {
                chatFileSelected: action.mainState.chatFileSelected
            });
        case TOGGLE_CHAT_MODE:
            return Object.assign({}, state, {
                chatMode: action.mainState.chatMode
            });
        case MODIFY_USER_INTEGRATIONS:
            return Object.assign({}, state, {
                userIntegrations: action.mainState.userIntegrations
            });
        case MODIFY_CONVERSATION_COUNT:
            return Object.assign({}, state, {
                conversationCount: action.mainState.conversationCount
            });
        case TOGGLE_FETCHING_RESULTS:
            return Object.assign({}, state, {
                fetchingResults: action.mainState.fetchingResults
            });
        case MODIFY_PROJECT_CONVERSATIONS:
            return Object.assign({}, state, {
                projectConversations: action.mainState.projectConversations
            });
        case MODIFY_PROJECT_SELECTED_IDX:
            return Object.assign({}, state, {
                projectSelectedIdx: action.mainState.projectSelectedIdx
            });
        case MODIFY_ALL_PROJECTS:
            return Object.assign({}, state, {
                allProjects: action.mainState.allProjects
            });
        case MODIFY_SIDEBAR_SELECTION:
            return Object.assign({}, state, {
                sidebarSelection: action.mainState.sidebarSelection
            });
        case MODIFY_AI_CLEAN_COLUMNS_SELECTED:
            return Object.assign({}, state, {
                aiCleanColumnsSelected: action.mainState.aiCleanColumnsSelected
            });
        case MODIFY_DEMO_TOUR_STEP:
            return Object.assign({}, state, {
                demoTourStep: action.mainState.demoTourStep
            });
        case TOGGLE_RUN_RESULTS_ON_MULTIPLE_FILES:
            return Object.assign({}, state, {
                runResultsOnMultipleFiles: action.mainState.runResultsOnMultipleFiles
            });
        case MODIFY_AI_FILES_SELECTED_TO_RUN:
            return Object.assign({}, state, {
                aiFilesSelectedToRun: action.mainState.aiFilesSelectedToRun
            });
        case TOGGLE_RUN_PRODUCT_DEMO:
            return Object.assign({}, state, {
                runProductDemo: action.mainState.runProductDemo
            });
        case TOGGLE_USE_TEST_DATA:
            return Object.assign({}, state, {
                useTestData: action.mainState.useTestData
            });
        case MODIFY_APP_NAME:
            return Object.assign({}, state, {
                appName: action.mainState.appName
            });
        case MODIFY_USER_PLAN:
            return Object.assign({}, state, {
                userPlan: action.mainState.userPlan
            });
        case TOGGLE_FETCHING_ALL_AI_RESULTS:
            return Object.assign({}, state, {
                fetchingAllAiResults: action.mainState.fetchingAllAiResults
            });
        case MODIFY_ALL_AI_RESULTS:
            return Object.assign({}, state, {
                allAiResults: action.mainState.allAiResults
            });
        case TOGGLE_AI_RESULTS_RUN:
            return Object.assign({}, state, {
                aiResultsRun: action.mainState.aiResultsRun
            });
        case MODIFY_WORK_TYPE:
            return Object.assign({}, state, {
                workType: action.mainState.workType
            });
        case TOGGLE_MODAL:
            return Object.assign({}, state, {
                modalName: action.mainState.modalName
            });
        case TOGGLE_IS_AUTH:
            return Object.assign({}, state, {
                isAuth: action.mainState.isAuth
            });
        case TOGGLE_PRICING_POPUP:
            return Object.assign({}, state, {
                pricingPopup: action.mainState.pricingPopup
            });
        case MODIFY_MODAL_INFO:
            return Object.assign({}, state, {
                modalInfo: action.mainState.modalInfo
            });
        case TOGGLE_IS_MOBILE_SIDEBAR_OPEN:
            return Object.assign({}, state, {
                isMobileSidebarOpen: action.mainState.isMobileSidebarOpen
            })
        case MODIFY_ALL_ORDERS:
            return Object.assign({}, state, {
                allOrders: action.mainState.allOrders
            })
        case MODIFY_AI_INPUT:
            return Object.assign({}, state, {
                aiInput: action.mainState.aiInput
            })
        case MODIFY_AI_FILES:
            // let isLastConvoWithData = isLastConvoData(state.projectConversations).isData
            const currAllUserFiles = JSON.parse(JSON.stringify(state.allUserFiles));

            if (action.mainState.updateAllFiles){
                const currAllUserFilesId = currAllUserFiles.map((x, idx) => x.fileID)

                const aiFilesId = action.mainState.aiFiles.map((x, idx) => x.fileID)

                for (let i=0; i<aiFilesId.length; i++){
                    if (!(currAllUserFilesId.includes(aiFilesId[i]))){
                        if (!isTestFile(action.mainState.aiFiles[i])){
                            currAllUserFiles.unshift(action.mainState.aiFiles[i])
                        }
                    }
                }
            }

            if (state.aiFilesSelectedToRun.length === 0){
                if (action.mainState.aiFiles.length > 0){
                    // if (!action.mainState.aiFiles[action.mainState.aiFiles.length - 1].is_result_file){
                        const filesAndColumns = getSelectedFilesAndCols(action.mainState.aiFiles);

                        return Object.assign({}, state, {
                            aiFiles: action.mainState.aiFiles,
                            aiFilesSelectedToRun: filesAndColumns.files,
                            aiCleanColumnsSelected: filesAndColumns.columns,
                            allUserFiles: currAllUserFiles
                        })
                    // }
                }
            }

            return Object.assign({}, state, {
                aiFiles: action.mainState.aiFiles,
                allUserFiles: currAllUserFiles
            })
        case MODIFY_AI_FILE_SELECTED:
            return Object.assign({}, state, {
                aiFileSelected: action.mainState.aiFileSelected
            })
        case MODIFY_AI_RESULTS:
            return Object.assign({}, state, {
                aiResults: action.mainState.aiResults
            })
        case MODIFY_AI_RESULTS_PK:
            return Object.assign({}, state, {
                aiResultsPK: action.mainState.aiResultsPK
            })
        case TOGGLE_IS_AI_RESULT_SELECTED:
            return Object.assign({}, state, {
                isAiResultSelected: action.mainState.isAiResultSelected
            })
        case MODIFY_AI_RESULT_SELECTED:
            return Object.assign({}, state, {
                aiResultSelected: action.mainState.aiResultSelected
            })
        case INIT_NEW_PROJECT:
            return Object.assign({}, state, {
                aiFiles: [],
                aiFileSelected: null,
                aiInput: "",
                aiResults: null,
                aiResultsPK: null,
                aiResultsRun: false,
                isAiResultSelected: false,
                aiResultSelected: null
            })
        case RESET_ALL_STATES:
            return Object.assign({}, state, {
                modalName: null,
                isAuth: false,
                pricingPopup: true,
                modalInfo: {},
                workType: "automate",
                isMobileSidebarOpen: false,
                allOrders: null,
                aiInput: "",
                aiFiles: [],
                aiFileSelected: null,
                aiResults: null,
                aiResultsPK: null,
                isAiResultSelected: false,
                aiResultSelected: null,
                userPlan: null,
                aiResultsRun: false,
                allAiResults: [],
                fetchingAllAiResults: false,
                appName: "filter",
                useTestData: false,
                runProductDemo: false,
                runResultsOnMultipleFiles: true,
                aiFilesSelectedToRun: [],
                demoTourStep: 0,
                aiCleanColumnsSelected: {},
                sidebarSelection: "connectDataSources",
                allProjects: [],
                projectSelectedIdx: null,
                projectConversations: [],
                fetchingResults: false,
                conversationCount: 0,
                userIntegrations: {},
                chatMode: true,
                chatFileSelected: 0,
                allUserFiles: [],
                fileSidebarSelection: "allFiles",
                allDatabases: [],
                dbSelectedIdx: null,
                reportSelectedIdx: null,
                allDBReports: [],
                testResultData: {},
                googleUserInfo: {},
                isGoogleUser: false,
                toast: {show: false, message: "", type: ""},
                allTeamsInfo: [],
                selectedTeamPK: null,
                selectedTeamInfo: null,
                teamSelectedIdx: null,
                selectedTeamRole: null,
                userTeamPK: null,
                checkoutUrl: "",
                pricingType: "monthly",
                modalImageURL: ''
            })
        default:
            return state
    }
}

export default mainState;