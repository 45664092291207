import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifySelectedTeamInfo} from "../Actions/ModifySelectedTeamInfo";
import API_Root from "../Constants/API_Root";
import LoaderApp from "../Loader";
import styles from "../Constants/styles";
import TeamDisplay from "./TeamDisplay"
import {toggleModal} from "../Actions/ToggleModal";
import SignUpLoginLink from "../SharedComponents/SignUpLogInLink"

class TeamIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fetching: false,
            msg: null
        }
    }

    componentDidMount(){
        if (this.props.selectedTeamInfo === null && this.props.isAuth){
            this.getTeamInfo()
        }
    }

    getTeamInfo = () => {
        const error = () => {
            this.setState({
                fetching: false,
                msg: "There was an error getting your team information. Try again or contact support if error persists"
            })
        }

        this.setState({
            fetching: true
        })

        fetch(API_Root + "api/get-lovespreadsheets-team-info/", {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                teamPK: this.props.selectedTeamPK
            }),
            headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}
        }).then(res => {
            if (res.status === 200){
                res.json().then(data => {
                    this.props.modifySelectedTeamInfo(data.teamInfo)

                    this.setState({
                        fetching: false,
                        msg: null
                    })

                }).catch(err => error())
            } else {
                error()
            }
        }).catch(err => error())
    }

    render() {
        let body = null;

        if (!this.props.isAuth){
            body = <SignUpLoginLink msg={"to invite team members"}/>
        } else {
            if (this.state.fetching){
                body = <LoaderApp/>
            } else {
                if (this.state.msg !== null){
                    body = <p style={{ color: 'red'}}>There was an error getting your team information. <span>Please try again</span></p>
                } else {
                    body = <TeamDisplay />
                }
            }
        }

        return (
            <div>
                <div style={{ fontSize: "16px", fontWeight: "700", color: styles.mainText, marginBottom: "12px"}}>
                    <i className="fa-solid fa-users"/> <span style={{ paddingLeft: '4px'}}>Your Team</span>
                </div>
                {body}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    selectedTeamPK: state.mainState.selectedTeamPK,
    selectedTeamInfo: state.mainState.selectedTeamInfo,
    isAuth: state.mainState.isAuth
})

const mapActionsToProps = {
    modifySelectedTeamInfo: modifySelectedTeamInfo,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(TeamIndex)