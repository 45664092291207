import React, {Component} from 'react'
import {connect} from 'react-redux'
import Select from 'react-select'
import APINoCodeMenu from "./APINoCodeMenu";
import KeyValueInputIndex from "../NoCode/KeyValueInputIndex"
import APINoCodeBodyIndex from "../NoCode/APINoCodeBodyIndex"
import APINoCodeAuthIndex from "../NoCode/APINoCodeAuthIndex"

class APINoCodeInputIndex extends Component {
    constructor(props) {
        super(props);

        this.state = this.initAPIState();

    }

    getDefaultState = () => ({
        requestType: "GET",
        requestUrl: '',
        requestAuthType: 'none',
        requestAuthBearerToken: '',
        requestAuthBasicParams: {username: '', password: ''},
        requestAuthOAuth2: null,
        requestBodyType: 'none',
        requestBodyJson: '',
        requestBodyFormData: [{key: '', value: '', use: false}],
        requestBodyFormUrlEncoded: [{key: '', value: '', use: false}],
        requestUrlParams: [{key: '', value: '', use: false}],
        activeMenuItem: 'urlParams',
        requestHeaders: [{key: '', value: '', use: false}]
    })

    initAPIState = () => {
        return this.props.initState(this.getDefaultState())
    }

    changeState = (newState) => {
        this.setState(newState)
        this.props.keepParamsUpdated(newState)
    }

    handleTextChange = (e) => {
        const newState = { [e.target.name]: e.target.value };

        this.setState(newState)
        this.props.keepParamsUpdated(newState)
    }

    requestTypeChange = (requestObj) => {
        const newState = {
            requestType: requestObj.value
        }

        this.setState(newState)
        this.props.keepParamsUpdated(newState)
    }

    handleUrlChange = (e) => {
        // get params and replace params in params state

        const url = e.target.value.trim();

        let urlParams = this.getParamsFromUrl(url);
        // const currParams = JSON.parse(JSON.stringify(this.state.requestUrlParams))

        // if (urlParams.length > 0){
        //     for (let i=0; i<urlParams.length; i++){
        //         const paramObj = urlParams[i];
        //         currParams.push(paramObj)
        //     }
        // }

        // if (urlParams.length === 0){
        urlParams.push({key: '', value: '', use: false})
        // }

        this.setState({
            requestUrl: url,
            requestUrlParams: urlParams
        })

        this.props.keepParamsUpdated({
            requestUrl: url,
            requestUrlParams: urlParams
        })
    }

    getParamsFromUrl = (url) => {
        let params = [];

        try{
            let paramString = url.toString().split('?')[1];
            let queryString = new URLSearchParams(paramString);

            for (let pair of queryString.entries()) {
                params.push({ key: pair[0], value: pair[1], use: true })
            }
        } catch (e) {
            console.log(e)
        }

        return params

    }

    handleURLChangeFromParamsChange = (allParams) => {
        // get params and add them to url
        let paramsToAdd = [];
        if (allParams.length > 0){
            for (let i=0; i<allParams.length; i++){
                if (allParams[i].use && allParams[i].key.trim().length > 0){
                    paramsToAdd.push(allParams[i].key + '=' + allParams[i].value)
                }
            }
        }

        let urlNoParams = this.state.requestUrl.split("?")[0];

        if (paramsToAdd.length > 0){
            urlNoParams += '?' + paramsToAdd.join('&')
        }

        this.setState({
            requestUrl: urlNoParams
        })

        this.props.keepParamsUpdated({
            requestUrl: urlNoParams
        })

    }

    changeKeyValPairs = (changeType, pairIdx, stateKey, changeInfo) => {
        const currPairs = JSON.parse(JSON.stringify(this.state[stateKey]));

        if (changeType === 'edit') {
            const selectedPair = currPairs[pairIdx];

            for (let infoType in changeInfo){
                if (infoType === 'key'){
                    if (selectedPair['key'].trim() === "" && changeInfo['key'].trim() !== ""){
                        selectedPair['use'] = true
                    }
                }

                selectedPair[infoType] = changeInfo[infoType]
            }

            currPairs[pairIdx] = selectedPair

            if (pairIdx === currPairs.length - 1){
                currPairs.push({key: '', value: '', use: false})
            }

        } else if (changeType === 'delete'){
            currPairs.splice(pairIdx, 1)
        }

        if (currPairs.length === 0){
            currPairs.push({key: '', value: '', use: false})
        }

        this.setState({
            [stateKey]: currPairs
        })

        this.props.keepParamsUpdated({
            [stateKey]: currPairs
        })

        if (stateKey === 'requestUrlParams'){
            this.handleURLChangeFromParamsChange(currPairs)
        }
    }

    getMenuFormBody = (activeMenuItem) => {
        // 1. if someone starts typing in the last box then add a new item
        // 2. Change, remove, delete value based on idx & bodyType
        // 3. If urlParams, then also add them to url

        const bodyMap = {
            urlParams: <KeyValueInputIndex
                keyValuePairs={this.state.requestUrlParams}
                changeKeyValPairs={this.changeKeyValPairs}
                name={'requestUrlParams'}
                labelHeaderStyle={this.props.labelHeaderStyle}
            />,
            auth: <div>
                <APINoCodeAuthIndex
                    labelHeaderStyle={this.props.labelHeaderStyle}
                    changeState={this.changeState}
                    requestAuthType={this.state.requestAuthType}
                    requestAuthBearerToken={this.state.requestAuthBearerToken}
                    requestAuthBasicParams={this.state.requestAuthBasicParams}
                />
            </div>,
            body: <div>
                <APINoCodeBodyIndex
                    labelHeaderStyle={this.props.labelHeaderStyle}
                    changeState={this.changeState}
                    requestBodyJson={this.state.requestBodyJson}
                    requestBodyType={this.state.requestBodyType}
                    requestBodyFormData={this.state.requestBodyFormData}
                    requestBodyFormUrlEncoded={this.state.requestBodyFormUrlEncoded}
                    changeKeyValPairs={this.changeKeyValPairs}
                />
            </div>,
            headers: <KeyValueInputIndex
                keyValuePairs={this.state.requestHeaders}
                changeKeyValPairs={this.changeKeyValPairs}
                name={'requestHeaders'}
                labelHeaderStyle={this.props.labelHeaderStyle}
            />
        }

        if (activeMenuItem in bodyMap){
            return bodyMap[activeMenuItem]
        }

        return null
    }

    render() {
        return (
            <div>
                <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', columnGap: '16px' }}>
                    <div>
                        <div style={this.props.labelHeaderStyle}>
                            Request Type
                        </div>
                        <div>
                            <Select
                                onChange={this.requestTypeChange}
                                value={{value: this.state.requestType, label: this.state.requestType}}
                                options={[{value: 'GET', label: 'GET'}, {value: 'POST', label: 'POST'}]}
                            />
                        </div>
                    </div>
                    <div>
                        <div style={this.props.labelHeaderStyle}>
                            Request URL
                        </div>
                        <div>
                            <input placeholder='https://api.spreadsheets.com/4232'
                                   className="inputGray"
                                   name='requestUrl'
                                   onChange={this.handleUrlChange}
                                   value={this.state['requestUrl']}
                                   style={{ width: '100%' }}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: "24px" }}>
                    <APINoCodeMenu activeMenuItem={this.state.activeMenuItem} changeState={this.changeState}/>
                </div>
                <div style={{ marginTop: '16px' }}>
                    {this.getMenuFormBody(this.state.activeMenuItem)}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(APINoCodeInputIndex)