import React, {Component} from 'react'
import {connect} from 'react-redux'
import OpenGooglePicker from "./OpenGooglePicker";
import styles from "../../../../Constants/styles";

class ChooseCurrentFileSelection extends Component {
    constructor(props) {
        super(props);
    }

    getFileName = () => {
        try{
            if (this.props.google_sheets_info[this.props.accountEmail].selectedDocInfo.type === "document") {
                return this.props.google_sheets_info[this.props.accountEmail].selectedDocInfo.name
            } else {
                return null
            }
        } catch (e) { return null }
    }

    getSheetNames = () => {
        let sheetNames;
        try {
            sheetNames = this.props.google_sheets_info[this.props.accountEmail].selectedDocInfo.sheetNames

            if (sheetNames === undefined){
                sheetNames = null
            }
        } catch (e) {
            sheetNames = null
        }

        return sheetNames
    }

    getCreateOrAddValue = () => {
        let val;
        try {
            val = this.props.google_sheets_info[this.props.accountEmail].selectedDocInfo.createOrAddToSheet

            if (val === undefined){
                val = null
            }
        } catch (e) {
            val = null
        }

        return val
    }

    toggleCreateOrAdd = (val) => {
        try {
            const copiedGoogleSheetsInfo = JSON.parse(JSON.stringify(this.props.google_sheets_info));
            const accountInfo = copiedGoogleSheetsInfo[this.props.accountEmail]

            let selectedDocInfo;
            if ("selectedDocInfo" in accountInfo){
                selectedDocInfo = accountInfo.selectedDocInfo
            }  else {
                selectedDocInfo = {}
            }

            selectedDocInfo['createOrAddToSheet'] = val

            accountInfo['selectedDocInfo'] = selectedDocInfo
            copiedGoogleSheetsInfo[this.props.accountEmail] = accountInfo

            this.props.changeState({ google_sheets_info: copiedGoogleSheetsInfo })
        } catch (e) {
            console.log(e)
        }
    }

    isSheetNameChecked = (sheetName) => {
        let isChecked;

        try {
            isChecked = this.props.google_sheets_info[this.props.accountEmail].selectedDocInfo.selectedSheetNames.includes(sheetName);

            if (isChecked === undefined){
                isChecked = false
            }
        } catch (e) {
            isChecked = false
        }

        return isChecked
    }

    toggleSheetName = (sheetName) => {
        try {
            const copiedGoogleSheetsInfo = JSON.parse(JSON.stringify(this.props.google_sheets_info));
            const accountInfo = copiedGoogleSheetsInfo[this.props.accountEmail]

            let selectedDocInfo;
            if ("selectedDocInfo" in accountInfo){
                selectedDocInfo = accountInfo.selectedDocInfo
            }  else {
                selectedDocInfo = {}
            }

            let currSelectedSheets
            if ("selectedSheetNames" in selectedDocInfo){
                currSelectedSheets = selectedDocInfo.selectedSheetNames;

                if (currSelectedSheets.includes(sheetName)){
                    currSelectedSheets.splice(currSelectedSheets.indexOf(sheetName), 1)
                } else {
                    currSelectedSheets.push(sheetName)
                }
            } else {
                currSelectedSheets = [sheetName]
            }

            selectedDocInfo.selectedSheetNames = currSelectedSheets
            accountInfo['selectedDocInfo'] = selectedDocInfo

            copiedGoogleSheetsInfo[this.props.accountEmail] = accountInfo

            this.props.changeState({ google_sheets_info: copiedGoogleSheetsInfo })
        } catch (e) {
            console.log(e)
        }

    }

    render() {
        const fileName = this.getFileName();
        const createOrAddValue = this.getCreateOrAddValue();
        const sheetNames = this.getSheetNames();

        return (
            <div>
                {
                    fileName !== null ?
                        <div>
                            <div style={{ fontSize: "14px", color: styles.subText}}>
                                File Selected: <b>{fileName}</b>
                            </div>
                            <div style={{ display: 'grid', gridTemplateColumns: "1fr 1fr", columnGap: "8px"}}>
                                <div>
                                    <input type="radio"
                                           style={{ cursor: "pointer", fontSize: "14px" }}
                                           id={'createNewSheetInGoogleSheetFile' + this.props.accountEmail}
                                           name={"createOrAddToSheet" + this.props.accountEmail}
                                           value="create"
                                           checked={createOrAddValue === "create"}
                                           onChange={e => this.toggleCreateOrAdd("create")}
                                    />
                                    <label htmlFor={'createNewSheetInGoogleSheetFile' + this.props.accountEmail}
                                           style={{ cursor: "pointer", paddingLeft: "8px", fontSize: "14px", color: styles.subText}}>
                                        Create a new sheet in file
                                    </label>
                                </div>
                                <div>
                                    <input type="radio"
                                           style={{ cursor: "pointer", fontSize: "14px" }}
                                           id={'addToSheetInGoogleSheetFile' + this.props.accountEmail}
                                           name={"createOrAddToSheet" + this.props.accountEmail}
                                           value="add"
                                           checked={createOrAddValue === "add"}
                                           onChange={e => this.toggleCreateOrAdd("add")}
                                    />
                                    <label htmlFor={'addToSheetInGoogleSheetFile' + this.props.accountEmail}
                                           style={{ cursor: "pointer", paddingLeft: "8px", fontSize: "14px", color: styles.subText}}>
                                        Add to sheet in file
                                    </label>
                                    {
                                        createOrAddValue === "add" && sheetNames !== null ?
                                        <div>
                                            {
                                                sheetNames.map((x, idx) =>
                                                <div key={idx}>
                                                    <input type="checkbox"
                                                           style={{ cursor: "pointer", fontSize: "14px" }}
                                                           id={'addToSheetInGoogleSheetFileSheetNames' + idx.toString() + this.props.accountEmail}
                                                           name={'addToSheetInGoogleSheetFileSheetNames' + idx.toString() + this.props.accountEmail}
                                                           checked={this.isSheetNameChecked(x)}
                                                           onChange={e => this.toggleSheetName(x)}
                                                    />
                                                    <label htmlFor={'addToSheetInGoogleSheetFileSheetNames' + idx.toString() + this.props.accountEmail}
                                                           style={{ cursor: "pointer", paddingLeft: "8px", fontSize: "14px", color: styles.subText}}>
                                                        {x}
                                                    </label>
                                                </div>)
                                            }
                                        </div> : null
                                    }
                                </div>
                            </div>
                        </div>: null
                }
                <OpenGooglePicker
                    accountInfo={this.props.accountInfo}
                    accountEmail={this.props.accountEmail}
                    google_sheets_info={this.props.google_sheets_info}
                    changeState={this.props.changeState}
                    mode="file"
                    fileText={fileName === null ? "Select a file to add to" : "Select another file"}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ChooseCurrentFileSelection)