import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifyPricingType} from "../../Actions/ModifyPricingType";

class TogglePricingType extends Component {
    componentDidMount(){
        if (window.location.href.includes("?lifetime=true")){
            this.props.modifyPricingType("lifetime")
        }
    }

    toggle = (e) => {
        const newPricingType = this.props.pricingType === "lifetime" ? "monthly" : "lifetime";

        this.props.modifyPricingType(newPricingType)
    }

    render() {
        return (
            <div style={{ display: "grid", gridTemplateColumns: "1fr auto 1fr"}}>
                <div style={{ textAlign: "right", paddingRight: "12px"}}>
                    Monthly
                </div>
                <div>
                    <label className="switch">
                        <input type="checkbox" checked={this.props.pricingType === "lifetime"} onChange={this.toggle}/>
                        <span className="slider round"></span>
                    </label>
                </div>
                <div style={{ textAlign: "left", paddingLeft: "12px"}}>
                    Lifetime
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    pricingType: state.mainState.pricingType
})

const mapActionsToProps = {
    modifyPricingType: modifyPricingType
}

export default connect(mapStateToProps, mapActionsToProps)(TogglePricingType)