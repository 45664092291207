export const getModalInfoValue = (modalInfo, key, defaultVal=null) => {
    let value;

    try{
        value = modalInfo[key]
    } catch (e) {
        value = null
    }

    if (value === null || value === undefined){
        value = defaultVal
    }

    return value
}