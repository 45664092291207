import React, {Component} from 'react'
import {connect} from 'react-redux'
import ConnectDatabaseGuide from "./ConnectDatabaseGuide";

class GuidesModalBody extends Component {
    constructor(props) {
        super(props);
    }

    getGuideType = () => {
        try {
            return this.props.modalInfo['guideType']
        } catch (e) {
            return null
        }
    }

    getGuideBody = (guideType) => {
        const guideMap = {
            "connectDatabase": <ConnectDatabaseGuide/>
        }

        if (guideType in guideMap){
            return guideMap[guideType]
        } else {
            return null
        }
    }

    render() {
        const guideType = this.getGuideType();

        if (guideType === undefined || guideType === null){
            return null
        } else {
            return (
                <div>
                    {this.getGuideBody(guideType)}
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(GuidesModalBody)