import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../../Constants/styles";

class ConnectHelpModalBody extends Component {
    constructor(props) {
        super(props);

        this.state = {
            toolbarSelection: ""
        }
    }

    getDataSourceInfo = () => {
        if (this.props.modalInfo !== null){
            if ('dataSource' in this.props.modalInfo && 'dataSourceLabel' in this.props.modalInfo){
                return this.props.modalInfo
            }
        }

        return null
    }

    render() {
        const dataSourceInfo = this.getDataSourceInfo();

        return (
            <div>
                <div style={{ fontSize: '24px', fontWeight: "700", color: styles.mainText }}>
                    Learn how to connect {dataSourceInfo.dataSourceLabel}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ConnectHelpModalBody)