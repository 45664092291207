import DataVisualizationIcon from "../Illustrations/DataVisualizationIcon.svg"
import AutoReportingIcon from "../Illustrations/AutoReportingIcon.svg"
import DataAnalysisIcon from "../Illustrations/DataAnalysisIcon.svg"
import DataEntryIcon from "../Illustrations/DataEntryIcon.svg"
import DataTransferIcon from "../Illustrations/DataTransferIcon.svg"
import DataCleaningIcon from "../Illustrations/DataCleaningIcon.svg"

export const servicesInfo = {
    dataViz: {
        icon: DataVisualizationIcon,
        name: "Data Visualization",
        boxIconName: "bx bx-line-chart"
    },
    autoReporting: {
        icon: AutoReportingIcon,
        name: "Auto Reporting",
        boxIconName: "bx bxs-report"
    },
    dataAnalysis: {
        icon: DataAnalysisIcon,
        name: "Data Analysis",
        boxIconName: "bx bx-analyse"
    },
    dataEntry: {
        icon: DataEntryIcon,
        name: "Data Entry",
        boxIconName: "bx bxs-keyboard"
    },
    dataTransfer: {
        icon: DataTransferIcon,
        name: "Data Transfer",
        boxIconName: "bx bx-transfer-alt"
    },
    dataCleaning: {
        icon: DataCleaningIcon,
        name: "Data Cleaning",
        boxIconName: "bx bx-filter-alt"
    }
}