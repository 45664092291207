import React, {Component} from 'react'
import {connect} from 'react-redux'
import {getQueryParamValue} from "../Constants/getQueryParamValue";
import styles from "../Constants/styles";
import IconLabelRight from "../SharedComponents/IconLabelRight"
import PaymentLink from "../SharedComponents/Pricing/PaymentLink"
import {modifyPricingType} from "../Actions/ModifyPricingType";

class ContinueToPricing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            planIdx: parseInt(getQueryParamValue("planIdx", window.location.href))
        }
    }

    getMode = () => {
        if (window.location.href.includes("payment")){
            return 'payment'
        } else {
            return 'subscription'
        }
    }

    render() {
        return (
            <div style={{ minHeight: "100vh", textAlign: "center", paddingTop: "80px"}}>
                <div style={{ fontWeight: "700", fontSize: "24px"}}>
                    Yay! You are now logged in
                </div>
                <div style={{marginTop: "24px"}}>
                    <PaymentLink
                        planIdx={this.state.planIdx}
                        pricingMode={this.getMode()}
                        component={<button style={{
                            border: "none",
                            backgroundColor: styles.blue,
                            color: "white",
                            padding: "12px 16px 8px 12px",
                            borderRadius: "4px"}}
                        >
                            <IconLabelRight
                                style={{fontSize: "16px", fontWeight: '700'}}
                                icon="bx bx-right-arrow-alt"
                                label="Continue to payment for upgrading"
                            />
                        </button>}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    modifyPricingType: modifyPricingType
}

export default connect(mapStateToProps, mapActionsToProps)(ContinueToPricing)