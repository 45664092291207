import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../../../Constants/styles";
import capitalizeHyphenated from "../../../Constants/capitalizeHyphenated";

const dayLabels = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]

class WeeklyDaysSelection extends Component {
    constructor(props) {
        super(props);
    }

    isDaySelected = (day) => {
        if ("days" in this.props.report_info){
            if (Array.isArray(this.props.report_info.days)){
                return this.props.report_info.days.includes(day)
            }
        }

        return false
    }

    toggleDay = (day) => {
        const reportInfo = JSON.parse(JSON.stringify(this.props.report_info));

        if (this.isDaySelected(day)){
            let days = reportInfo.days;

            days.splice(days.indexOf(day), 1)

            if (days.length > 0){
                reportInfo.days = days
            } else {
                delete reportInfo.days
            }
        } else {
            if ("days" in reportInfo){
                if (Array.isArray(reportInfo.days)){
                    let days = reportInfo.days;
                    days.push(day)
                }
            } else {
                reportInfo.days = [day]
            }
        }

        this.props.changeState({ report_info: reportInfo })

    }

    getDayBox = (day, label) => {
        const isDaySelected = this.isDaySelected(day);

        return <div style={{
            backgroundColor: isDaySelected ? styles.blue : "white",
            color: isDaySelected ? "white" : styles.subText,
            border: "1px solid " + (isDaySelected ? styles.blue : "#F0F0F0"),
            padding: "4px 8px",
            cursor: "pointer"
        }} onClick={e => this.toggleDay(day)}>
            {capitalizeHyphenated(label)}
        </div>
    }

    render() {
        return (
            <div style={{ marginTop: "16px"}}>
                <div style={{fontSize: "16px", color: styles.subText, fontWeight: "700"}}>
                    Select day(s)
                </div>
                <div>
                    {dayLabels.map((x, idx) => <div key={idx} style={{ marginTop: "4px"}}>
                        {this.getDayBox(idx+1, x)}
                    </div>)}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(WeeklyDaysSelection)