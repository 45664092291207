import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleIsMobileSidebarOpen} from "../Actions/ToggleIsMobileSidebarOpen";
import { fadeInUp, fadeIn, fadeInRight } from 'react-animations';
import Radium, {StyleRoot} from 'radium';
import MobileNavbarHeader from "./MobileNavbarHeader";
import ProductMenu from "./ResourcesMenu";
import ServicesMenu from "./ServicesMenu";
import AuthComponent from "../SharedComponents/AuthComponent"
import LogoutButton from '../Auth/LogoutButton'
import ModalLink from "./ModalLink"
import UpgradeBtn from "./UpgradeBtn"
import DropdownMenu from "./DropdownMenu"
import ResourcesMenu from "./ResourcesMenu"
import {toggleRunProductDemo} from "../Actions/ToggleRunProductDemo";
import LoginButton from "../Auth/LoginButton";

class MobileSidebar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            productMenuOpen : false,
            servicesMenuOpen : false
        }
    }

    componentDidMount(){
        window.scrollTo({
            top: 1,
            behavior: "smooth"
        });
    }

    toggleSidebar = (mainState) => dispatch => {
        dispatch({
            type: 'TOGGLE_IS_MOBILE_SIDEBAR_OPEN ',
            mainState
        })
        return Promise.resolve();
    }

    toggleRunProductDemo = (mainState) => dispatch => {
        dispatch({
            type: "TOGGLE_RUN_PRODUCT_DEMO",
            mainState
        })
        return Promise.resolve();
    }

    toggleProductMenuOpen = () => {
        const currOpen = this.state.productMenuOpen;

        this.setState({
            productMenuOpen: !currOpen
        })
    }

    toggleServicesMenuOpen = () => {
        const currOpen = this.state.servicesMenuOpen;

        this.setState({
            servicesMenuOpen: !currOpen
        })
    }

    runDemo = (e) => {
        this.props.dispatch(this.toggleSidebar({"isMobileSidebarOpen": false})).then(
            this.props.dispatch(this.toggleRunProductDemo({"runProductDemo": true}))
        )
    }

    render() {
        const styles = {
            fadeInRight: {
                animation: 'x 0.5s',
                animationName: Radium.keyframes(fadeInRight, 'fadeInRight')
            },
            fadeIn: {
                animation: 'x 0.2s',
                animationName: Radium.keyframes(fadeIn, 'fadeIn')
            }
        };

        if (this.props.isMobileSidebarOpen){
            {/*<StyleRoot>*/}
            {/*<div style={styles.fadeInRight}>*/}

            let iconName;
            if (this.state.productMenuOpen){
                iconName = "fa fa-chevron-down"
            } else {
                iconName = "fa fa-chevron-right"
            }

            let servicesIconName;
            if (this.state.servicesMenuOpen){
                servicesIconName = "fa fa-chevron-down"
            } else {
                servicesIconName = "fa fa-chevron-right"
            }

            return (
                <div className="mobileSidebar" id="mobileSidebar">
                    <div style={{ borderBottom: "1px solid #F0F0F0" }}>
                        <MobileNavbarHeader isSidebar={true} />
                    </div>
                    <div style={{ padding: "12px 16px 12px 16px"}}>
                        {/*<AuthComponent*/}
                            {/*auth={<a href="/projects" className="mobileNavLink">My Projects</a>}*/}
                            {/*nonAuth={<div className="mobileNavLink">*/}
                                {/*<span onClick={this.toggleProductMenuOpen}><i className={iconName} aria-hidden="true"> </i> Products</span>*/}
                                {/*{*/}
                                    {/*this.state.productMenuOpen ?*/}
                                        {/*<ProductMenu isMobile={true}/> : null*/}
                                {/*}*/}
                            {/*</div>}*/}
                        {/*/>*/}
                        {
                            this.props.hidePricing ? null :
                                <a href="/pricing" className="mobileNavLink">Pricing</a>
                        }
                        {/*<AuthComponent*/}
                            {/*auth={<a href="/profile" className="mobileNavLink">Profile</a>}*/}
                            {/*nonAuth={<span className="mobileNavLink" style={{ color: styles.blue}}><ModalLink linkText="Log in" modalName="loginModal"/></span>}*/}
                        {/*/>*/}
                        {/*<div className="mobileNavLink">*/}
                            {/*<span onClick={this.toggleServicesMenuOpen}>*/}
                                {/*<i className={servicesIconName} aria-hidden="true"> </i> Resources</span>*/}
                            {/*{*/}
                                {/*this.state.servicesMenuOpen ?*/}
                                    {/*<ResourcesMenu*/}
                                        {/*showMenu={this.state.servicesMenuOpen}*/}
                                        {/*isMobile={true}*/}
                                    {/*/> : null*/}
                            {/*}*/}
                        {/*</div>*/}
                        {/*<AuthComponent*/}
                            {/*auth={<a href="/contact" className="mobileNavLink">Contact Us</a>}*/}
                            {/*nonAuth={<a href="/services" className="mobileNavLink">Custom Automation Service</a>}*/}
                        {/*/>*/}
                        {/*<AuthComponent*/}
                            {/*auth={<a href="/projects" className="mobileNavLink">My Projects</a>}*/}
                            {/*nonAuth={<span className="mobileNavLink"><ModalLink linkText="Demo" modalName="demoModal" /></span>}*/}
                        {/*/>*/}
                        {/*<a href="/free-ai-tools" className="mobileNavLink">Free AI Tools </a>*/}
                        {/*<AuthComponent*/}
                            {/*auth={this.props.userPlan === null ? <UpgradeBtn isMobile />: null}*/}
                            {/*nonAuth={<span className="mobileNavLink"><ModalLink linkText="Log in" modalName="loginModal"/></span>}*/}
                        {/*/>*/}
                        {/*<div>*/}
                        <AuthComponent
                            auth={<LogoutButton isMobile />}
                            nonAuth={<LoginButton isMobile />}
                        />
                        {/*</div>*/}
                    </div>
                </div>
                // </div>
                // </StyleRoot>
            )
        } else {
            return null
        }
    }
}

const mapStateToProps = (state) => ({
    isMobileSidebarOpen: state.mainState.isMobileSidebarOpen,
    userPlan: state.mainState.userPlan
})

const mapActionsToProps = dispatch => ({
    toggleIsMobileSidebarOpen: toggleIsMobileSidebarOpen,
    toggleRunProductDemo: toggleRunProductDemo,
    dispatch: dispatch
})

export default connect(mapStateToProps, mapActionsToProps)(MobileSidebar)