import React, {Component} from 'react'
import Select from 'react-select'

const reportFrequencies = [{value: "daily", label: "Daily"}, {value: "daily_weekday", label: "Daily (weekdays only)"}, {value: "weekly", label: "Weekly"}, {value: "monthly", label: "Monthly"}, {value: "yearly", label: "Yearly"}]

class ChangeReportFrequency extends Component {
    constructor(props) {
        super(props);
    }

    change = (freqObj) => {
        this.props.changeState({report_frequency: freqObj.value})
    }

    getValue = () => {
        for (let i=0; i<reportFrequencies.length; i++){
            if (reportFrequencies[i].value === this.props.report_frequency){
                return reportFrequencies[i]
            }
        }

        return this.props.report_frequency
    }

    getReportFrequencyOptions = () => {
        switch (this.props.userReportFrequency){
            case "monthly":
                return [{value: "monthly", label: "Monthly"}, {value: "yearly", label: "Yearly"}]
            case "daily":
                return [{value: "daily", label: "Daily"}, {value: "daily_weekday", label: "Daily (weekdays only)"}, {value: "weekly", label: "Weekly"}, {value: "monthly", label: "Monthly"}, {value: "yearly", label: "Yearly"}]
            default:
                return [{value: "daily", label: "Daily"}, {value: "daily_weekday", label: "Daily (weekdays only)"}, {value: "weekly", label: "Weekly"}, {value: "monthly", label: "Monthly"}, {value: "yearly", label: "Yearly"}]
        }
    }

    render() {
        return (
            <Select
                onChange={this.change}
                value={this.getValue()}
                options={this.getReportFrequencyOptions()}
            />
        )
    }
}

export default ChangeReportFrequency