import React, {Component} from 'react'
import PerksIndex from "./PerksIndex";
import JobOpeningsIndex from "./JobOpeningsIndex";

class PerksAndJobOpeningsIndex extends Component {
    render() {
        const isMobile = 'isMobile' in this.props;

        return (
            <div>
                <div className="row">
                    <div className='col-sm-4'>
                        <div style={ this.props.isMobile ? { paddingTop: "60px" } : null }>
                            <PerksIndex isMobile={isMobile}/>
                        </div>
                    </div>
                    <div className='col-sm-8'>
                        <div style={ this.props.isMobile ? { paddingTop: "60px" } : null }>
                            <JobOpeningsIndex isMobile={isMobile}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PerksAndJobOpeningsIndex