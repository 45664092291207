import React, {Component} from 'react'
import Select from 'react-select'

class CustomTimeRow extends Component {
    getTimeValue = () => {
        if (this.props.timeInfo.time === null){
            return null
        } else {
            return {value: this.props.timeInfo.time, label: this.getLabelForTimeVal(this.props.timeInfo.time)}
        }
    }

    getLabelForTimeVal = (timeVal) => {
        const hour = parseInt(timeVal.split(":")[0]);
        const minutes = parseInt(timeVal.split(":")[1]);

        return (hour % 12 === 0 ? 12 : hour%12).toString() + ":" + (minutes < 10 ? "0":"") + minutes.toString() + (hour < 12 ? "AM" : "PM")
    }

    change = (timeObj) => {
        console.log(timeObj)
        this.props.changeTime(timeObj, this.props.idx)
    }

    getTimeOptions = () => {
        let timeSelection = [];

        for (let hour=0; hour<24; hour++){
            if (this.props.userReportFrequency === "hourly"){
                const timeVal = (hour<10 ? "0" : "") + hour.toString() + ":00"

                if (!this.props.allTimes.includes(timeVal)){
                    timeSelection.push({
                        value: timeVal,
                        label:this.getLabelForTimeVal(timeVal)
                    })
                }
            } else {
                for (let minutes=0; minutes<=45; ){
                    const timeVal = (hour<10 ? "0" : "") + hour.toString() + ":" + (minutes < 10 ? "0":"") + minutes.toString();

                    if (!this.props.allTimes.includes(timeVal)){
                        timeSelection.push({
                            value: timeVal,
                            label:this.getLabelForTimeVal(timeVal)
                        })
                    }


                    minutes += 15
                }
            }
        }

        return timeSelection
    }

    render() {
        return (
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: "8px"}}>
                <div>
                    <Select
                        isSearchable={true}
                        onChange={this.change}
                        value={this.getTimeValue()}
                        options={this.getTimeOptions()}
                    />
                </div>
                <div style={{ paddingTop: "4px"}}>
                    {
                       this.props.idx > 0 ?
                           <span style={{ color: "red", cursor: "pointer", fontSize: "12px"}} onClick={e => this.props.deleteCustomTimeRow(this.props.idx)}>
                               <i className="fa-solid fa-x"></i>
                           </span>
                           : null
                    }
                </div>
            </div>
        )
    }
}

export default CustomTimeRow