import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleRunProductDemo} from "../../Actions/ToggleRunProductDemo";
import {toggleModal} from "../../Actions/ToggleModal";
import styles from "../../Constants/styles";
import EndDemoButton from "../EndDemoButton"

class MainDemoScreen extends Component {
    constructor(props) {
        super(props);
    }

    startDemo = () => {
        this.props.toggleModal(null)
        this.props.toggleRunProductDemo(true);
    }

    render() {
        return (
            <div>
                <div style={{ textAlign: "center", fontWeight: "700" }}>
                    <span style={{ fontSize: '18px', color: styles.mainText}}>Welcome to <span style={{ color: styles.red }}>Love Spreadsheets</span></span>
                    <br/>
                    <span style={{ fontSize: '14px', color: styles.subText}}>An AI chat bot for your spreadsheets</span>
                </div>
                {/*<div style={{ marginTop: "24px", textAlign: "center" }}>*/}
                    {/*<img src={LoveSpreadsheetsGif}  style={{ width: '100%', maxWidth: '640px'}}/>*/}
                {/*</div>*/}
                <div style={{ marginTop: "24px", textAlign: "center"}}>
                    <button onClick={this.startDemo} className="actionButton" style={{ backgroundColor: styles.mainGreen, color: "white" }}>
                        See how it works <i className="fa-solid fa-arrow-right"></i>
                    </button>
                    <div>
                        <EndDemoButton btn={<span className="linkStyle">No thanks, I just want to get started</span>}
                        style={{ marginTop: "12px" }} />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleRunProductDemo: toggleRunProductDemo,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(MainDemoScreen)