import React, {Component} from 'react'
import API_Root from "../Constants/API_Root";
import { isMobile } from "react-device-detect";
import styles from "../Constants/styles";

class NewsletterSignUp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            message: null
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.signUpNewsletter = this.signUpNewsletter.bind(this);
        this.closeMessage = this.closeMessage.bind(this);
    }

    handleInputChange(e){
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    closeMessage(){
        this.setState({
            message: null
        })
    }

    signUpNewsletter(e){
        fetch(API_Root + 'api/love-newsletter-signup/', {
            method: "POST",
            body: JSON.stringify({
                email: this.state.email
            }),
            headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}
        }).then(
            (res) => {
                if (res.status === 200){
                    alert("Thank you for signing up for Love Spreadsheets Product updates!")
                }
                else{
                    alert("Thank you for signing up for Love Spreadsheets Product updates!")
                }
            }).catch(err => alert("Thank you for signing up for Love Spreadsheets Product updates!"))
    }

    render() {
        if (isMobile) {
            return null
        }
        return (
            <div style={{ backgroundColor: styles.mainText, border: "none", padding: "16px", marginBottom: "24px", borderRadius: "4px"}}>
                <div style={{ fontWeight: "bold", color: "white", marginBottom: "8px", fontSize: "16px" }}>
                    Sign Up For Product News
                </div>
                <div style={{ marginBottom: "8px" }}>
                    <input
                        type="text"
                        style={{ width: "100%"}}
                        value={this.state.email}
                        name="email"
                        onChange={this.handleInputChange}
                        placeholder="Enter your Email"

                    />
                </div>
                <div>
                    <button
                        onClick={this.signUpNewsletter}
                        style={{ backgroundColor: styles.orange, color: "white", borderRadius: "4px", border: "none", padding: "4px"}}
                    >Sign Up</button>
                </div>
            </div>
        )
    }
}

export default NewsletterSignUp