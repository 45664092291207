import React from 'react'

export const faqTexts = {
    general: [
        {
            question: "What is this tool?",
            answer: "It is a web app where you can upload your file and ask questions. Our AI then shows you the data that provides your answer"
        },
        {
            question: "How does it work?",
            answer: <div><ol><li>Upload an Excel or CSV file (or use test data)</li><li>Ask your spreadsheet in natural language to filter your data</li><li>Download your results or save your results and ask more questions</li></ol></div>
        },
        {
            question: "What kind of questions can I ask?",
            answer: <div>You an ask things like: <ol><li>Show me the top performing sales people</li><li>Find the top 10 most populated countries</li><li>I want all records where James was an account executive</li></ol></div>
        }
    ],
    upload: [
        {
            question: "What kind of files can I upload?",
            answer: "You can upload .xlsx or .csv files"
        },
        {
            question: "How many files can I upload?",
            answer: "You can upload one file at a time. BUT you can upload as many files as you want. BUT you can also ask questions to 1 file"
        },
        {
            question: "How are sheets read in an Excel file?",
            answer: "ONLY the first sheet is read in an Excel file. If your data is on another sheet, please move that sheet to the first position"
        },
        {
            question: "How are .csv files read?",
            answer: "CSV files are read with the comma (,) as the delimiter. If your file has another delimiter, please change it to the comma (,) before uploading"
        }
    ],
    ask: [
        {
            question: "My questions are not working. What can I do?",
            answer: <div>You can update your column names. Make sure <ol><li>your file has column names on the first row</li><li>the column names are descriptive. Example: <b>Sales Person Name</b> is much better than <b>SP_Name</b></li></ol></div>
        },
        {
            question: "I updated column names but it still does not work. Help!",
            answer: <div>Make sure all the data within your columns is of the same type. For example, in a column called <b>Profit</b> it should have all numbers or all text</div>
        },
        {
            question: "I updated column names and the data type. It still does not work!",
            answer: <div>Sometimes the question can be a bit complicated for our AI to translate into insights. Feel free to email us at info@lovespreadsheets.com and we can take a look</div>
        }
    ],
    profile: [
        {
            question: "What is a lifetime account?",
            answer: "A lifetime account gives you access to the Love Spreadsheets web app and any future updates for its lifetime"
        },
        {
            question: "I signed up for a lifetime account, but I do not use it. Can I get refund?",
            answer: "Sorry, there are no refunds on the lifetime account"
        },
        {
            question: "I signed up for a lifetime account, and I want to cancel the account",
            answer: "You can email us at info@lovepsreadsheets.com from the account email that you wish to cancel. Remember, there will be no refunds but we will gladly cancel the account"
        },
        {
            question: "I want to change the email I signed up with. How do I do that?",
            answer: "Email us at info@lovepsreadsheets.com from the account email that you signed up with, and include your new email. We will change it right away"
        }
    ],
    integrate: [
        {
            question: "What integrations do you offer?",
            answer: "We do custom integrations with any of your data sources. Whether it is Salesforce, Postgres or MySQL database, BigQuery, or anything else!"
        },
        {
            question: "How does integration work?",
            answer: <div><a href="https://calendly.com/love-spreadsheets/25min" target="_blank" rel="noopener noreferrer">Schedule a call</a> with us or <a href="/integrate">read more</a> to find out how we can integrate our data chat bot for your organization's data</div>
        }
    ]
}