import React, {Component} from 'react'
import styles from "../../../../Constants/styles";
import isEmailValid from "../../../../Constants/isEmailValid";

class EmailToList extends Component {
    constructor(props) {
        super(props);
    }

    getEmailsList = () => {
        if ("emails" in this.props.email_report_info){
            return this.props.email_report_info['emails']
        } else {
            return [""]
        }
    }

    handleEmailChange = (e, idx) => {
        const copiedReportInfo = JSON.parse(JSON.stringify(this.props.email_report_info));

        let emails;
        if ("emails" in copiedReportInfo){
            emails = copiedReportInfo['emails'];

            emails[idx] = e.target.value;
        } else {
            emails = [e.target.value];
        }

        copiedReportInfo['emails'] = emails

        this.props.changeState({ email_report_info: copiedReportInfo })
    }

    addEmail = () => {
        const copiedReportInfo = JSON.parse(JSON.stringify(this.props.email_report_info));

        let emails;
        if ("emails" in copiedReportInfo){
            emails = copiedReportInfo['emails'];

        } else {
            emails = [];
        }

        emails.push("")

        copiedReportInfo['emails'] = emails

        this.props.changeState({ email_report_info: copiedReportInfo })
    }

    removeEmail = (idx) => {
        const copiedReportInfo = JSON.parse(JSON.stringify(this.props.email_report_info));

        let emails;
        if ("emails" in copiedReportInfo){
            emails = copiedReportInfo['emails'];

            emails.splice(idx, 1)

            copiedReportInfo['emails'] = emails

            this.props.changeState({ email_report_info: copiedReportInfo })
        }
    }

    render() {
        const emailsList = this.getEmailsList();
        console.log(emailsList)
        return (
            <div>
                <div style={{fontFamily: "Lato", fontWeight: "400", fontSize: "14px", color: "#5E645E"}}>Send to these emails</div>
                {
                    emailsList.map((x, idx) => <div>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr auto", columnGap: "4px", marginBottom: "4px"}}>
                            <div>
                                <div>
                                    <input className="inputGray"
                                           value={x}
                                           onChange={e => this.handleEmailChange(e, idx)}
                                           style={{width: "100%"}}
                                           placeholder={"email" + (idx + 1).toString() + "@yourcompany.com"}
                                    />
                                </div>
                                {
                                    !isEmailValid(x) ? <div><small style={{ color: "red"}}>Enter a valid email</small></div> : null
                                }
                            </div>
                            <div>
                                {
                                    idx > 0 ? <i className='bx bx-x'
                                                 style={{color: "red", cursor: 'pointer'}}
                                                 onClick={e => this.removeEmail(idx)}
                                    /> : null
                                }
                            </div>
                        </div>
                    </div>)
                }
                <button style={{
                    border: "none",
                    backgroundColor: styles.mainGreen,
                    borderRadius: "24px",
                    padding: "4px 8px",
                    color: "white",
                    fontSize: "12px"
                }}
                        onClick={this.addEmail}
                >
                    <i className="fa fa-plus" aria-hidden="true" /> Add more emails
                </button>
            </div>
        )
    }
}

export default EmailToList