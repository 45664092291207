import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifyAiInput} from "../../Actions/ModifyAiInput";
import {modifyAppName} from "../../Actions/ModifyAppName";
import AceEditor from 'react-ace';
import 'brace/mode/sql';
import 'brace/theme/monokai';
import 'brace/mode/python';
import {toggleModal} from "../../Actions/ToggleModal";

const allInputs = ["Show me the top performing sales people            ",
    "Find the top 10 sales territories by ratio of revenue to executives            ",
    "I want all records where James was an account executive            "]

class UserInputArea extends Component {
    constructor(props) {
        super(props);

        this.state = {
            animatedInput: "",
            inputIdx: 0
        }
    }

    isDemo = () => ('demo' in this.props)

    componentDidMount(){
        if (this.isDemo()){
            this.interval = setInterval(this.updateInput, 40)
        }
    }

    componentWillUnmount(){
        if (this.isDemo()){
            clearInterval(this.interval);
        }
    }

    updateInput = () => {
        const input = allInputs[this.state.inputIdx]

        if (this.state.animatedInput === input){
            this.setState({
                inputIdx: (this.state.inputIdx + 1) % 3,
                animatedInput: ""
            })
        } else {
            if (this.state.animatedInput === ""){
                this.setState({
                    animatedInput: input.substring(0, 1)
                })
            } else {
                this.setState({
                    animatedInput: input.substring(0, input.indexOf(this.state.animatedInput) + this.state.animatedInput.length + 1)
                })
            }
        }
    }

    changeUserInput = (e) => {
        let val;
        if (['python', 'sql'].includes(this.props.appName)){
            val = e
        } else {
            val = e.target.value
        }
        // const valTest = e.target.value.toLowerCase().trim();

        this.props.modifyAiInput(val)
        // if (valTest.includes("graph") || valTest.includes("plot") || valTest.includes("diagram") || valTest.includes("figure")){
        //     this.props.modifyAppName("visualize")
        // } else {
        //     this.props.modifyAppName("ask")
        // }

        // console.log(e.currentTarget.textContent)
        // this.props.modifyAiInput(e.currentTarget.textContent)

    }

    render() {
        if (['python', 'sql'].includes(this.props.appName)){
            return (
                <div>
                    <div style={{fontSize: "12px"}}>
                            Write SQL code on your files. {
                        this.props.aiFiles.length > 0 ? <span>Refer to your files by their table names {<span className="linkStyle" onClick={e => this.props.toggleModal("sqlFileNameModal")}>as seen here</span>}</span> : null
                    }
                    </div>
                    <AceEditor
                        mode={this.props.appName}
                        theme="monokai"
                        onChange={this.changeUserInput}
                        value={this.props.aiInput}
                        name="UNIQUE_ID_OF_DIV"
                        editorProps={{$blockScrolling: true}}
                        height={125}
                        width="auto"
                    />
                </div>
            )
        }

        return (
            <textarea
                className="inputGray"
                rows={this.props.isMobile ? 2 : 2}
                style={this.isDemo() ? {width: '100%', fontWeight: '700'} : { width: "100%"}}
                placeholder={this.isDemo() ? "" : "Show me top 5 best performing sales people in 2022"}
                value={this.isDemo() ? this.state.animatedInput : this.props.aiInput}
                readOnly={this.isDemo()}
                onChange={this.changeUserInput}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    aiInput: state.mainState.aiInput,
    appName: state.mainState.appName,
    aiFiles: state.mainState.aiFiles
})

const mapActionsToProps = {
    modifyAiInput: modifyAiInput,
    modifyAppName: modifyAppName,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(UserInputArea)