import React, {Component} from 'react'
import styles from "../../Constants/styles";
import ConnectDatabaseBody from "./ModalBody/ConnectDatabase/ConnectDatabaseBody";
import PromptDatabaseBody from "./ModalBody/PromptDatabaseBody";
import ModalHeaders from "./ModalHeaders";
import GetDataGoogleSheets from "./GetDataGoogleSheets";
import TeamHelpBody from "./TeamHelpBody"

class LandingPageDescModalBody extends Component {
    getBody = () => {
        const bodyMap = {
            connectDatabase: <ConnectDatabaseBody/>,
            promptDatabase: <PromptDatabaseBody/>,
            getDataGoogleSheets: <GetDataGoogleSheets />,
            teamHelp: <TeamHelpBody />
        }

        if (this.props.bodyType in bodyMap){
            return bodyMap[this.props.bodyType]
        } else {
            return null
        }
    }

    render() {
        return (
            <div style={{ paddingBottom: "36px"}}>
                <ModalHeaders
                    header={this.props.header}
                />
                {this.getBody()}
            </div>
        )
    }
}

export default LandingPageDescModalBody