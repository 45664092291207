import React, {Component} from 'react'
import ModalApp from "../../SharedComponents/Modal"
import DeleteReportModalBody from "./DeleteReportModalBody"

class DeleteReportModal extends Component {
    render() {
        return (
            <ModalApp name="deleteReportModal"
                      header={null}
                      size="small"
                      body={<DeleteReportModalBody />}
            />
        )
    }
}

export default DeleteReportModal