import React, {Component} from 'react'
import styles from "../../../../Constants/styles";
import {images} from "../../../../Constants/images";

const dbTypeText = {
    mysql: "MySQL",
    postgres: "Postgres",
    mariadb: "MariaDB",
    sqlserver: "SQL Server",
    mongodb: "MongoDB",
    snowflake: "Snowflake",
    googleBigQuery: "Big Query",
    redshift: "AWS Redshift",
    salesforce: "Salesforce",
    hubspot: 'Hubspot',
    quickbooks: 'Quickbooks',
    googleAnalytics: "Google Analytics",
    "stripe": "Stripe",
    "outreach": "Outreach",
    "api": "APIs",
    "shopify": "Shopify",
    "mailchimp": "Mailchimp",
    "jira": "Jira",
    "airtable": "Airtable",
    "facebookAds": "Facebook Ads",
    "website": "Web Scraping",
    "oracle": "Oracle DB",
    "saasTools": "SaaS Tools"
}

class DatabaseBox extends Component {
    render() {
        let border = "1px solid #F0F0F0"
        let headerText = "Coming soon"

        if ('supported' in this.props){
            border = "2px solid " + styles.mainGreen
            headerText = "Supported"
        }

        const segmentStyle = { backgroundColor: "white", padding: "12px", border: border, borderRadius: "4px", boxShadow: "0px 5px 6px -4px rgba(2, 34, 19, 0.02)"}
        const headerStyle = { color: 'supported' in this.props ? styles.mainGreen : styles.subText, fontWeight: "700", marginBottom: "12px", textAlign: "center"}

        return (
            <div style={segmentStyle}>
                <div style={headerStyle}>
                    {headerText}
                </div>
                <div>
                    <div style={{ textAlign: "center"}}>
                        <img style={{ height: '40px', maxWidth: '80px' }} src={images[this.props.dbType + "Logo"]} alt={this.props.dbType + "Logo"}/>
                        <div style={{fontFamily: "Lato", fontSize: "14px", fontWeight: "700", color: "#161E16"}}>{dbTypeText[this.props.dbType]}</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DatabaseBox