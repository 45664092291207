import React, {Component} from 'react'

class WoyeraInc extends Component {
    render() {
        return (
            <span style={{ fontSize: "12px" }}>Made with <span role="img" aria-label="heart emoji">❤️</span> by Woyera Inc <span>© {((new Date()).getFullYear()).toString()}</span></span>
        )
    }
}

export default WoyeraInc