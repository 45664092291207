import React, {Component} from 'react'

class IconTitle extends Component {
    render() {
        return (
            <div style={{ display: "grid", gridTemplateColumns: "auto 1fr", columnGap: "16px"}}>
                <div>
                    {this.props.icon}
                </div>
                <div style={{ fontSize: "24px", fontWeight: "900", marginTop: "-2px"}}>
                    {this.props.label}
                </div>
            </div>
        )
    }
}

export default IconTitle