import React, { Component } from 'react';
import Navbar from './Navbar/Navbar';
import FooterIndex from "./Footer/FooterIndex";
import Routes from './Routes';
import { connect } from 'react-redux';
import {toggleIsAuth} from "./Actions/ToggleIsAuth";
import API_Root from "./Constants/API_Root";
import ForgetPasswordModal from "./Auth/ForgetPasswordModal";
import LoadingModal from "./SharedComponents/LoadingModal";
import DemoModal from "./Demo/DemoModal"
import FAQsModal from "./FAQs/FAQsModal"
import UpgradeModal from "./SharedComponents/UpgradeModal/UpgradeModal"
import {toggleFetchingAllAiResults} from "./Actions/ToggleFetchingAllAiResults";
import {modifyUserPlan} from "./Actions/ModifyUserPlan";
import LoginModal from "./Auth/LoginModal"
import {toggleModal} from "./Actions/ToggleModal";
import SignUpModal from "./Auth/SignUpModal"
import GetSupportModal from "./Apps/Conversations/ResultModals/GetSupport/GetSupportModal"
import {modifyUserIntegrations} from "./Actions/ModifyUserIntegrations";
import {getQueryParamValue} from "./Constants/getQueryParamValue";
import LanguageModal from "./SharedComponents/LanguageModal/LanguageModal";
import {modifyAllDBReports} from "./Actions/ModifyAllDBReports";
import {modifyAllDatabases} from "./Actions/ModifyAllDatabases";
import {getTimeOffset} from "./Constants/getTimeOffset";
import {modifyDBSelectedIdx} from "./Actions/ModifyDBSelectedIdx";
import LandingPageDescModal from "./Landing/LandingPageDescModal/LandingPageDescModal"
import SetupTimeModal from "./Database/Reports/SetupTime/SetupTimeModal"
import {initIsGoogleUser} from "./Actions/InitIsGoogleUser";
import {initGoogleUserInfo} from "./Actions/Upload/InitGoogleUserInfo";
import DeleteDatabaseModal from "./Database/DeleteDatabaseModal"
import CustomToastContainer from "./SharedComponents/CustomToastContainer"
import {toggleToast} from "./Actions/ToggleToast";
import {modifyAllTeamsInfo} from "./Actions/ModifyAllTeamsInfo";
import {modifySelectedTeamPK} from "./Actions/ModifySelectedTeamPK";
import {modifyTeamSelectedIdx} from "./Actions/ModifyTeamSelectedIdx";
import {modifySelectedTeamRole} from "./Actions/ModifySelectedTeamRole";
import DeleteReportModal from "./Apps/Conversations/DeleteReportModal";
import {modifyUserTeamPK} from "./Actions/ModifyUserTeamPK";
import {getEmailFromUsername} from "./Constants/getEmailFromUsername";
import GuidesModal from "./SharedComponents/GuidesModal/GuidesModal";
import ViewTablesAndColumnModal from "./SharedComponents/ViewTablesAndColumn/ViewTablesAndColumnModal";
import EditDatabaseSettingsModal from "./Database/EditDatabaseSettingsModal";
import LifetimeDealBanner from "./SharedComponents/LifetimeDealBanner";
import ImageModal from "./SharedComponents/ImageModal"
import ConnectAPIAuthInfoModal from "./ConnectDataSources/API/ConnectAPIAuthInfoModal";
import ConnectHelpModal from "./ConnectDataSources/Help/ConnectHelpModal"

class App extends Component {
    componentDidMount(){
        if (window.location.href.includes("upgradedPlanIdx")){
            this.props.toggleModal("loadingModalUpgradingAccount")
            const url = window.location.href;

            const planIdx = getQueryParamValue("upgradedPlanIdx", url)
            const userEmail = getQueryParamValue("email", url)

            const error = () => {
                this.props.toggleModal(null);
                alert('Your payment was successful! But there was an error upgrading your account in our system. Please email us @ admin@lovespreadsheets.com ASAP')
            }

            fetch(API_Root + "api/set-pricing-plan-for-user/", {
                method: 'POST',
                body: JSON.stringify({
                    planIdx: parseInt(planIdx),
                    email: userEmail
                })
            }).then(res => {
                if (res.status === 200){
                    this.props.toggleModal(null)
                    this.refreshLink.click()
                } else {
                    error()
                }
            }).catch(err => error())
        }

        // window.addEventListener('resize', this.handleResize);

        if (window.location.href.includes('download-email-report')){

        } else{
            this.toggleUserAuth()
            this.initTawkTo()
            this.setUserSource()
        }
    }

    getEmailAndResults = (teamPK=null) => {
        let token;
        let id;

        try {
            id = localStorage.getItem("email");
        } catch (e) {
            id = null
        }

        try{
            token = localStorage.getItem("token")
        } catch (e) {
            token = null
        }

        if (id !== null){
            this.getAllAiResults(id, token, teamPK)
        }
    }

    toggleUserAuth = () => {
        const userNotAuth = () => this.props.toggleIsAuth(false)

        const checkUserAuthBody = {
            email: localStorage.getItem("email"),
            token: localStorage.getItem("token"),
            sessionId: localStorage.getItem("sessionId"),
            getIntegrations: true,
            getTeamInfo: true
        }

        if (window.location.href.includes("teamPK=")) {
            checkUserAuthBody.teamPK = parseInt(getQueryParamValue("teamPK", window.location.href));
        }

        fetch(API_Root + 'api/check-user-auth/', {
            method: "POST",
            body: JSON.stringify(checkUserAuthBody),
            headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}
        }).then((res) => {
            if (res.status === 200){
                res.json().then(data => {
                    try{
                        if ("pricingPlan" in data.pricing){
                            this.props.modifyUserPlan(data.pricing.pricingPlan)
                        }
                    } catch (e) { }

                    try{
                        if ("integrations" in data){
                            this.props.modifyUserIntegrations(data.integrations)
                        }
                    } catch (e) { }

                    let pkChanged = false;
                    let teamPK = null;
                    try{
                        this.props.modifyAllTeamsInfo(data.teamInfo)

                        if (data.teamInfo.length > 0){
                            if (window.location.href.includes("teamPK=")){
                                teamPK = parseInt(getQueryParamValue("teamPK", window.location.href));
                                this.props.modifySelectedTeamPK(teamPK)
                                pkChanged = true

                                for (let i=0; i<data.teamInfo.length; i++){
                                    let teamInfo = data.teamInfo[i];

                                    if (teamInfo.love_team__pk === teamPK){
                                        this.props.modifyTeamSelectedIdx(i)
                                        this.props.modifySelectedTeamRole(teamInfo.role)
                                        break;
                                    }
                                }

                            } else{
                                for (let i=0; i<data.teamInfo.length; i++){
                                    let teamInfo = data.teamInfo[i];

                                    if (teamInfo.love_team__is_placeholder && teamInfo.role === "admin"){
                                        teamPK = teamInfo.love_team__pk

                                        this.props.modifySelectedTeamPK(teamPK)
                                        this.props.modifyTeamSelectedIdx(i)
                                        this.props.modifySelectedTeamRole(teamInfo.role)
                                        pkChanged = true;
                                        break;
                                    }
                                }

                                if (!pkChanged){
                                    teamPK = data.teamInfo[0].love_team__pk
                                    this.props.modifyTeamSelectedIdx(0)
                                    this.props.modifySelectedTeamPK(teamPK)
                                    this.props.modifySelectedTeamRole(data.teamInfo[0].role)
                                    pkChanged = true;
                                }
                            }

                            // set user's default team id
                            for (let i=0; i<data.teamInfo.length; i++){
                                let teamInfo = data.teamInfo[i];

                                if (getEmailFromUsername(teamInfo.love_team__admin_username) === localStorage.getItem("email")){
                                    this.props.modifyUserTeamPK(teamInfo.love_team__pk);
                                    break
                                }
                            }

                        }
                    } catch (e) {

                    }

                    try{
                        this.props.initIsGoogleUser(data.isGoogleUser)
                        this.props.initGoogleUserInfo(data.googleUserInfo)
                    } catch (e) {

                    }

                    this.getEmailAndResults(teamPK)
                }).catch(err => {
                    this.props.toggleIsAuth(true)
                })

                this.props.toggleIsAuth(true)


            } else {
                userNotAuth()
            }
        }).catch(err => {
            userNotAuth();
        })
    }

    getAllAiResults = (id, token, teamPK) => {
        this.props.toggleFetchingAllAiResults(true);

        const error = () => {
            this.props.toggleFetchingAllAiResults(false);
            this.props.toggleToast({show: true, message: "There was an unusual error in getting your account data. Please refresh and try again or contact us at info@lovespreadsheets.com if this error persists", type: "error"});
        }

        fetch(API_Root + "api/get-all-lovespreadsheets-input-sources-and-reports/", {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: id,
                token: token,
                utcOffset: getTimeOffset(),
                teamPK: teamPK
            })
        }).then(res => {
            if (res.status === 200){
                res.json().then(data => {
                    this.props.modifyAllDatabases(data.allDatabases);
                    this.props.modifyAllDBReports(data.allDBReports)

                    if (data.allDatabases.length > 0){
                        this.props.modifyDBSelectedIdx(0)
                    }

                    this.props.toggleFetchingAllAiResults(false);
                })
            }
        }).catch(err => error())
    }

    initTawkTo = () => {
        // (function(){
        //     let s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        //     s1.async=true;
        //     s1.src='https://embed.tawk.to/5e8cbeed35bcbb0c9aaec852/default';
        //     s1.charset='UTF-8';
        //     s1.setAttribute('crossorigin','*');
        //     s0.parentNode.insertBefore(s1,s0);
        // })();
    }

    setUserSource = () => {
        if (localStorage.getItem("source") === null){
            let source = "";
            let url = window.location.href;

            if (url.includes("source=")){
                let srcSubstr = url.substring(url.indexOf("source="), url.length)

                let endIdx = srcSubstr.length;

                if (srcSubstr.includes("&")){
                    endIdx = srcSubstr.indexOf("&")
                }

                source = srcSubstr.substring(srcSubstr.indexOf("=") + 1, endIdx)
            } else {
                source = document.referrer
            }

            localStorage.setItem("source", source)
        }
    }

    padBody = () => {
        // const url = window.location.href;

        // if (this.props.isAuth){
        //     return true
        // } else {
        //     if (url.includes("/login") || url.includes("/reset-password") || url.includes("/signup")){
        //         return false
        //     } else {
        //         return true
        //     }
        // }

        // if (isMobile || isTablet){
        //     return true
        // } else {
            return false
        // }
    }

    handleResize = () => {

    }

    render(){
        return(
            <div>
                <a href="/" ref={refreshLink => this.refreshLink = refreshLink} style={{ display: "none" }}> </a>
                <DeleteReportModal/>
                <ConnectHelpModal />
                <SetupTimeModal />
                <LandingPageDescModal />
                <DeleteDatabaseModal />
                <LanguageModal />
                <GetSupportModal />
                <SignUpModal />
                <FAQsModal/>
                <UpgradeModal />
                <DemoModal />
                <LoginModal />
                <ViewTablesAndColumnModal />
                <GuidesModal/>
                <EditDatabaseSettingsModal/>
                <ImageModal />
                <ConnectAPIAuthInfoModal/>
                <LoadingModal name="loadingModalRefreshTables" header="Getting your database tables and columns info..." message="Getting your database tables and columns info..." noClose/>
                <LoadingModal name="loadingModalVidChat" header="Setting up the video chat link. Please wait up to 2 minutes..." message="Setting up the video chat link. Please wait up to 2 minutes..." noClose/>
                <LoadingModal name="loadingModalGoogleSheets" header="Connecting to your Google Sheets..." message="Connecting to your Google Sheets..." noClose/>
                <LoadingModal name="loadingModalUpgradingAccount" header="Finishing account upgrade..." message="Finishing account upgrade..." noClose/>
                <LoadingModal name="loadingModalPreparingPayment" header="Preparing your payment link..." message="Preparing your payment link..." noClose/>
                <LoadingModal name="loadingModalCreateFileFromResults" header="Creating a file from your results..." message="Creating a file from your results..." noClose/>
                <LoadingModal name="loadingModalAsk" header="Asking our AI your questions..." message="Asking our AI your questions..." noClose/>
                <LoadingModal name="loadingModalImportFile" header="Reading your files..." message="Reading your files..." noClose/>
                <LoadingModal name="loadingModalDownloadFile" header="Downloading your files..." message="Downloading your files..." noClose/>
                <LoadingModal name="loadingModalCreatingFakeData" header="Generating your data with AI..." message="Generating your data with AI..." noClose/>
                <LoadingModal name="loadingModalDeletingResult" header="Deleting your result..." message="Deleting your result..." noClose/>
                <LoadingModal name="loadingModalDownloadEmailReport" header="Downloading your report..." message="Downloading your report..." noClose/>
                <ForgetPasswordModal/>
                <div className="main">
                    <CustomToastContainer />
                    {/*<LifetimeDealBanner/>*/}
                    <Navbar/>
                    <div style={{ paddingTop: this.padBody() ? "60px" : "0"}}>
                        <Routes />
                    </div>
                </div>
                <FooterIndex/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    isAuth: state.mainState.isAuth,
})

const mapActionsToProps = {
    toggleIsAuth: toggleIsAuth,
    toggleFetchingAllAiResults: toggleFetchingAllAiResults,
    modifyUserPlan: modifyUserPlan,
    toggleModal: toggleModal,
    modifyUserIntegrations: modifyUserIntegrations,
    modifyAllDBReports: modifyAllDBReports,
    modifyAllDatabases: modifyAllDatabases,
    modifyDBSelectedIdx: modifyDBSelectedIdx,
    initIsGoogleUser: initIsGoogleUser,
    initGoogleUserInfo: initGoogleUserInfo,
    toggleToast: toggleToast,
    modifyAllTeamsInfo: modifyAllTeamsInfo,
    modifySelectedTeamPK: modifySelectedTeamPK,
    modifyTeamSelectedIdx: modifyTeamSelectedIdx,
    modifySelectedTeamRole: modifySelectedTeamRole,
    modifyUserTeamPK: modifyUserTeamPK
}

export default connect(mapStateToProps, mapActionsToProps)(App);
