import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../Constants/styles";
import UserPlanTag from "../Navbar/UserPlanTag";

class ProfileIndex extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const labelStyle = { fontWeight: "700", fontSize: "18px", color: styles.mainText}
        const email = localStorage.getItem("email")
        const token = localStorage.getItem("token")

        return (
            <div style={{ minHeight: "100vh", padding: "5% 10%" }}>
                <div style={{ marginBottom: "32px" }}>
                    <div className="row">
                        <div className="col-sm-6">
                            <div style={labelStyle}>
                                <i className="fa fa-user-circle-o" aria-hidden="true"></i> Email
                            </div>
                        </div>
                        <div className="col-sm-6">
                            {email}
                        </div>
                    </div>
                </div>
                <div style={{ marginBottom: "32px" }}>
                    <div className="row">
                        <div className="col-sm-6">
                            <div style={labelStyle}>
                                <i className="fa fa-key" aria-hidden="true"></i> Reset password
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <a href={"/reset-password?email=" + email + "&code=" + token}>Click here to reset your password</a>
                        </div>
                    </div>
                </div>
                {
                    this.props.userPlan !== null ?
                        <div style={{ marginBottom: "32px" }}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div style={labelStyle}>
                                        <i className="fas fa-credit-card" aria-hidden="true"></i> Plan
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <UserPlanTag userPlan={this.props.userPlan}/>
                                    <div style={{ marginTop: "12px", color: "red"}}>
                                        Email us at admin@lovespreadsheets.com from your account email to upgrade, downgrade or cancel your account
                                    </div>
                                </div>
                            </div>

                        </div> : null
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userPlan: state.mainState.userPlan
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ProfileIndex)