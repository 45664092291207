import React, {Component} from 'react'

class ToggleCheckbox extends Component {
    toggle = () => {
        this.props.toggle()
    }

    render() {
        let gridStyle;
        let alignType;
        if ('left' in this.props && 'right' in this.props){
            gridStyle = "1fr auto 1fr"
            alignType = 'center'
        } else{
            gridStyle = 'auto 1fr'
            if ('left' in this.props && !('right' in this.props)){
                alignType = 'left'
            } else {
                alignType = 'right'
            }
        }

        let labelStyle = {}
        if ('labelStyle' in this.props){
            labelStyle = this.props.labelStyle
        }

        return (
            <div style={{ display: "grid", gridTemplateColumns: gridStyle }}>
                {
                    alignType === 'center' || alignType === 'left' ?
                        <div style={{...{ textAlign: "right", paddingRight: "12px"}, ...labelStyle}}>
                            {this.props.left}
                        </div> : null
                }
                <div>
                    <label className="switch">
                        <input type="checkbox" checked={this.props.checked} onChange={this.toggle}/>
                        <span className="slider round"></span>
                    </label>
                </div>
                {
                    alignType === 'center' || alignType === 'right' ?
                        <div style={{...{ textAlign: "left", paddingLeft: "12px" }, ...labelStyle}}>
                            {this.props.right}
                        </div> : null
                }
            </div>
        )
    }
}

export default ToggleCheckbox