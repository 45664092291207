import React, {Component} from 'react'

class PrivacyPolicy extends Component {
    render() {
        const product = "DB Spreadsheets";

        return (
            <div id="privacyPolicyDiv">
                <div className="privacyRow">
                    <h1>Privacy Policy</h1>
                    <br/>
                    <p><span style={{fontSize:'150%', fontWeight: '800'}}>TLDR;</span> 1. You own all your data completely; 2. We delete all instances related to your data right away as soon as you press delete; 3. We never share or use your data under any circumstance</p>
                    <p>We at <span>{product}</span> care extensively about your privacy. Please read the following to learn more about our Privacy Policy. By using or accessing <span>{product}</span> in any manner, you acknowledge that you accept the practices and policies outlined in this Privacy Policy, and you hereby consent that we will collect, use, and share your information in the following ways.</p>
                    <p>Remember that your use of <span>{product}</span> is at all times subject to the Terms of Use, which incorporates this Privacy Policy. Any terms we use in this Policy without defining them have the definitions given to them in the Terms of Use.</p>
                </div>
                <div className="privacyRow">
                    <h3>What does this Privacy Policy cover?</h3>
                    <p>This Privacy Policy covers our treatment of data that we gather when you are accessing or using our Website, but not to the practices of companies we don’t own or control, or people that we don’t manage. We gather various types of Personal Information from our users, as explained in more detail below, and we use this Personal Information internally in connection with our Website, including to contact you, to fulfill your requests for certain products and services, to provide and improve the Service, and to analyze how you use the Website. In certain cases, we may also share some Personal Information with third parties, but only as described below.</p>
                    <p>As noted in the Terms of Use, we do not knowingly collect or solicit personal information from anyone under the age of 13. If you are under 13, please do not attempt to send any personal information about yourself to us. If we learn that we have collected personal information from a child under age 13, we will delete that information as quickly as possible. If you believe that a child under 13 may have provided us personal information, please contact us at <a href="mailto:admin@woyera.com">admin@woyera.com</a></p>
                </div>
                <div className="privacyRow">
                    
                        <h3>Will <span>{product}</span> ever change this Privacy Policy?</h3>
                        <p>We’re constantly trying to improve our Website, so we may need to change this Privacy Policy from time to time as well, but we will alert you to changes by placing a notice on <span>{product}</span>, by sending you an email, and/or by some other means. Please note that if you’ve opted not to receive legal notice emails from us (or you haven’t provided us with your email address), those legal notices will still govern your use of the Website, and you are still responsible for reading and understanding them. If you use the Website after any changes to the Privacy Policy have been posted, that means you agree to all of the changes.</p>
                    
                </div>
                <div className="privacyRow">
                    
                        <h3>Information You Provide to Us</h3>
                        <p>We receive and store any information you provide to us related to files for an hour maximum. We then delete all information. We do store your personally identifiable email such as email and password if you upgrade to a pro account.</p>
                    
                </div>
                <div className="privacyRow">
                        <h3>Information Collected Automatically</h3>
                        <p>Whenever you interact with our Website, we automatically receive and record information on our server logs from your browser or device, which may include your IP address, device identification, “cookie” information, the type of browser and/or device you’re using to access our Website, and the page or feature you requested. “Cookies” are identifiers we transfer to your browser or device that allow us to recognize your browser or device and tell us how and when pages and features in our Website are visited and by how many people. You may be able to change the preferences on your browser or device to prevent or limit your device’s acceptance of cookies, but this may prevent you from taking advantage of some of our features. If you click on a link to a third party website or service, such third party may also transmit cookies to you. Again, this Privacy Policy does not cover the use of cookies by any third parties, and we aren’t responsible for their privacy policies and practices.</p>
                        <p>When we collect the usage information described above, we only use this data in aggregate form, and not in a manner that would identify you personally. For example, this aggregate data can tell us how often users use a particular feature of the Website, and we can use that knowledge to make the Website interesting to as many users as possible.</p>
                        <p>We use both Google Analytics and FullStory for third party analytics. You can go on their websites to learn more about the services.</p>
                </div>
                <div className="privacyRow">
                        <h3>Will <span>{product}</span> Share Any of the data it Receives?</h3>
                        <p>You will always own your Data fully. We do not have any ownership of your data. We neither rent nor sell any of the data you provide to us to anyone. We will not allow third parties to access your data without your signed consent. <span>{product}</span> will not access your data except for the purposes of debugging and improving the service. However, we may share your data with third parties as described in this section:</p>
                        <p>Aggregated usage statistics. We may summarize, aggregate and share information about  your use of the service and how the data was processed by <span>{product}</span> to our partners to understand how often and in what ways people use our Website. This information will be anonymized so that neither you nor your data can be identified.</p>
                        <p>Protection of <span>{product}</span> and Others: We reserve the right to access, read, preserve, and disclose any information that we reasonably believe is necessary to comply with law or court order; enforce or apply our Terms of Use and other agreements; or protect the rights, property, or safety of <span>{product}</span>, our employees, our users, or others.</p>
                </div>
                <div className="privacyRow">
                    <h3>How does <span>{product}</span> handle data from Google™ sign in and authorization?</h3>
                    <p>We obtain your profile information, and information about any Google Sheets™ you select to connect to the platform. We store your profile information, Google Sheets™ access keys, metadata about your Google Sheets™ such as the spreadsheet ID, and descriptive stats regarding your Google Sheets™ data such as column names and column types</p>
                    <p>We use your Google™ Account and sheets data to read your Google Sheets™, and write to your Google Sheets™, and delete your Google Sheets™ as you specify in the chatbot. NOTE: we DO NOT store your full Google Sheets™ data</p>
                    <p>All metadata is encrypted when stored and when in transit. Google Sheets™ metadata is deleted as soon as you delete a given Google Sheet™. And Google account data is deleted as soon as you disconnect your Google™ account from the application</p>
                    <p><span>{product}</span> (use and transfer to any other app of information received from Google APIs will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank" rel="noopener noreferrer">Google API Services User Data Policy</a>, including the Limited Use requirements.</p>
                </div>
                <div className="privacyRow">
                        <h3>What if I have questions about this policy?</h3>
                        <p>If you have any questions or concerns regarding our privacy policies, please send us a detailed message to <a href="mailto:admin@woyera.com">admin@woyera.com</a>, and we will try to resolve your concerns.</p>
                </div>
            </div>
        )
    }
}

export default PrivacyPolicy