import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleIsAuth} from "../Actions/ToggleIsAuth";
import { push } from 'react-router-redux'
import styles from "../Constants/styles";
import {toggleModal} from "../Actions/ToggleModal";
import Button from "../SharedComponents/Button"

class LoginButton extends Component {
    openLoginModal = () => {
        this.props.toggleModal("signUpModal")
    }

    render() {
        if ('isMobile' in this.props){
           return(<div style={{marginTop:"40px"}}>
                <button
                    className="heroButton"
                    onClick={this.openLoginModal}
                    style={{border: "1px solid " + styles.blue, color: "white", backgroundColor: styles.blue,}}>
                    Sign Up
                </button>
            </div>)
        } else {
            return (
                <div style={{ marginTop: "-4px" }}>
                    <button
                        style={{ border: "1px solid " + styles.blue, color: "white", backgroundColor: styles.blue, padding: "4px 8px 4px 8px", borderRadius: "4px", fontWeight: "700" }}
                        onClick={this.openLoginModal}>
                        Sign Up
                    </button>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleIsAuth: toggleIsAuth,
    navigateTo: push,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(LoginButton)