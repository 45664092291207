import React, {Component} from 'react'
import styles from "../../../Constants/styles";
import {connect} from 'react-redux'
import {toggleModal} from "../../../Actions/ToggleModal";
import BannerAlert from "../../../SharedComponents/BannerAlert";
import DataSourcesDropdown from "./DataSourcesDropdown"
import RequestDataForm from "./RequestDataForm"
import SignUpLoginLink from "../../../SharedComponents/SignUpLogInLink"

class RequestDataConnectionIndex extends Component {
    render() {
        return (
            <div>
                <div style={{ fontSize: "16px", fontWeight: "700", color: styles.mainText, marginBottom: "12px"}}>
                    <i className="fa-solid fa-plug"/> <span style={{ paddingLeft: '4px'}}>Request data sources connection</span>
                </div>
                {
                    !this.props.isAuth ?
                        <div style={{ }}>
                            <div>
                                <SignUpLoginLink msg="to request a data connection" />
                            </div>
                            <div style={{ marginTop: '16px'}}>
                                <DataSourcesDropdown notInclude={['mysql', 'postgres', 'mariadb', 'sqlserver', 'snowflake', 'oracle', 'mongodb', 'api']} />
                            </div>
                            <div style={{ marginTop: '16px', color: styles.subText}}>
                                Each data source added within 72 hours for paying customers. <span style={{fontWeight: "700"}}> Guaranteed.</span>
                            </div>
                        </div> :
                        <div>
                            {
                                this.props.userPlan === null || this.props.userPlan === 0 ?
                                    <BannerAlert
                                        type="error"
                                        header="FREE plan does not guarantee data source"
                                        content={<span><a href="/pricing">Upgrade</a> to get data source added within 72 hours. <span style={{ fontWeight: "700" }}>Guaranteed.</span></span>}
                                    /> :
                                    <BannerAlert
                                        type="display"
                                        header="Congrats! Your plan guarantees data source addition"
                                        content={<span>We will add each data source within 72 hours. <span style={{ fontWeight: "700" }}>Guaranteed.</span></span>}
                                    />
                            }
                            <div style={{ marginTop: "16px" }}>
                                <RequestDataForm />
                            </div>
                        </div>

                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(RequestDataConnectionIndex)