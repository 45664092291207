import React, {Component} from 'react'
import AuthComponent from "../SharedComponents/AuthComponent"
import ProfileIndex from "./ProfileIndex"
import AppsIndex from "../Apps/AppsIndex"

class ProfileAuthIndex extends Component {
    render() {
        return (
            <AuthComponent
                auth={<ProfileIndex />}
                nonAuth={<AppsIndex />}
            />
        )
    }
}

export default ProfileAuthIndex