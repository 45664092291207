import React, {Component} from 'react'
import {connect} from 'react-redux'
import Button from "../SharedComponents/Button"
import API_Root from "../Constants/API_Root"
import TestDatabaseMessage from "./TestDatabaseMessage";
import InfoPopup from "../SharedComponents/InfoPopup";
import DatabaseTypeTag from "./DatabaseTypeTag";
import { images } from "../Constants/images";
import {toggleModal} from "../Actions/ToggleModal";
import { push } from 'react-router-redux'
import {modifySidebarSelection} from "../Actions/ModifySidebarSelection";
import {modifyModalInfo} from "../Actions/ModifyModalInfo";
import {doesUserNeedToUpgrade} from "../Constants/doesUserNeedToUpgrade";
import {showUpgradeModal} from "../Constants/showUpgradeModal";
import {toggleToast} from "../Actions/ToggleToast";

class ConnectDatabase extends Component {
    constructor(props) {
        super(props)

        if ('edit' in this.props){
            this.state = {
                host: this.props.dbInfo['host'],
                user: this.props.dbInfo['user'],
                password: '',
                database: this.props.dbInfo['database'],
                port: this.props.dbInfo['port'],
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                connectionValid: null,
                connectionValidMessage: "",
                fetching: false,
                isDSN: 'isDSN' in this.props.dbInfo ? this.props.dbInfo['isDSN'] : false,
                schema: 'schema' in this.props.dbInfo ? this.props.dbInfo['schema'] : '',
                databasetype: 'databasetype' in this.props.dbInfo ? this.props.dbInfo['databasetype'].toUpperCase() : ''
            }
        } else {
            const dbTypeAndPort = this.getDBTypeAndPort(this.props.type)

            this.state = {
                host:'',
                user:'',
                password:'',
                database: '',
                port: dbTypeAndPort.port,
                databasetype: dbTypeAndPort.type,
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                connectionValid: null,
                connectionValidMessage: "",
                fetching: false,
                description: "",
                isDSN: false,
                schema: ''

            };
        }

        this.onChange = this.onChange.bind(this);
        this.changeDBType = this.changeDBType.bind(this);

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ('type' in this.props){
            if (prevProps.type !== this.props.type){
                const dbTypeAndPort = this.getDBTypeAndPort(this.props.type)
                this.setState({
                    databasetype: this.props.type,
                    port: dbTypeAndPort.port
                })
            }
        }
        // if (JSON.stringify(prevProps.results.results.sampleData) !== JSON.stringify(this.props.results.results.sampleData)) {
        //     this.setState({
        //         data: this.props.results.results.sampleData,
        //         fetching: false,
        //         columnNames: this.props.results.results.columnNames
        //     })
        // }
    }

    getDBTypeAndPort = (dbType) => {
        let port = "3306";
        let type = "MYSQL"
        if ('type' in this.props){
            type = dbType
            if (type === "MYSQL" || type === "MARIADB"){
                port = "3306"
            } else if (type === "POSTGRES"){
                port = "5432"
            } else if (type === "SQLSERVER"){
                port = "1433"
            } else if (type === "ORACLE") {
                port = "1521"
            } else {
                port = ""
            }
        }

        return {"type": type, "port": port}
    }

    changeDBType(e, dbType){
        const getTypeAndPort = this.getDBTypeAndPort(dbType)
        this.setState({ databasetype: dbType, port: getTypeAndPort.port })
    }

    isFromImport = () => {
        let isFromImport = false;

        if ('isFromImport' in this.props){
            isFromImport = this.props.isFromImport
        }

        return isFromImport
    }

    isDBOracle = () => (this.state.databasetype.toString().toLowerCase() === 'oracle')

    isDBSnowflake = () => (this.state.databasetype.toString().toLowerCase() === 'snowflake')

    isDBMongo = () => (this.state.databasetype.toString().toLowerCase() === 'mongodb')

    onConnect = () => {
        if ('edit' in this.props){
            const dbInfo = JSON.parse(JSON.stringify(this.state));
            dbInfo['teamPK'] = this.props.selectedTeamPK

            this.props.testDBConnection(dbInfo)
        } else {
            const dbLengths = this.props.allDatabases.length;

            if (doesUserNeedToUpgrade(this.props.userPlan, "databases", dbLengths)){
                showUpgradeModal(this.props.toggleModal, this.props.modifyModalInfo, this.props.userPlan, "databases", dbLengths, this.props.userTeamPK, this.props.selectedTeamPK, this.props.allTeamsInfo)
            } else {
                this.setState({ fetching: true })
                const unknownError = () => this.setState({ fetching: false, connectionValid: false, connectionErrorMessage: "Try again! If this problem persists contact us at our support"})

                const body = JSON.parse(JSON.stringify(this.state));
                body['teamPK'] = this.props.selectedTeamPK

                fetch(API_Root + "api/connect-to-api-db/", {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json, text/plain, */*',
                    },
                    body: JSON.stringify(body)
                }).then((res) => {
                    if (res.status === 200) {
                        res.json().then((data) => {
                            this.setState({
                                connectionValid: data.connectionValid,
                                connectionValidMessage: data.connectionValidMessage,
                                fetching: false
                            })

                            if (data.connectionValid) {
                                this.props.toggleModal(null)
                                this.props.toggleToast({
                                    show: true,
                                    type: "success",
                                    message: "Database successfully connected :)"
                                })
                                window.location.reload()
                            }
                        }).catch(err => unknownError())
                    } else {
                        unknownError()
                    }
                }).catch(err => unknownError())
            }
        }

    };

    onChange(event){
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    // handleTeamSelection(e, { value }){
    //     this.setState({ teamPK: value })
    // }

    inputErrorText = () => {
        let inputError = "";


        if (this.isDBSnowflake()) {
            if (this.state.host.trim() === "") {
                inputError += "Enter an Account ID. "
            }
        } else if (this.isDBMongo()){
            if (this.state.host.trim() === "") {
                inputError += "Enter a Hostname. "
            }
        } else {
            if (this.state.host.trim() === "") {
                inputError += "Enter a Hostname. "
            }
        }

        if (!this.isDBMongo()){
            if (this.state.database.trim() === ""){
                inputError += "Enter a Database Name. "
            }
        }

        if (!('edit' in this.props)){
            if (this.state.description.trim() === ""){
                inputError += "Enter a Description. "
            }
        }

        if (!this.isDBSnowflake() && !this.isDBMongo()){
            if (isNaN(this.state.port) || this.state.port.trim() === ""){
                inputError += "Enter a valid numerical value for port. "
            }
        }

        if (this.isDBMongo()){
            if (this.state.user.trim() === "" || this.state.password.trim() === ""){
                inputError += "Enter username & password."
            }
        }

        return inputError
    }

    hasInfoChanged = () => {
        if ('edit' in this.props){
            const infoKeys = ['host', 'user', 'database', 'port']

            for (let i=0; i<infoKeys.length; i++){
                if (this.state[infoKeys[i]] !== this.props.dbInfo[infoKeys[i]]){
                    return true
                }
            }
        }

        return false
    }

    resetParameters = () => {
        this.setState({
            host: this.props.dbInfo['host'],
            user: this.props.dbInfo['user'],
            database: this.props.dbInfo['database'],
            port: this.props.dbInfo['port']
        })
    }

    render() {
        let mySQLSelectionStyle = {borderRadius: '4px', color: 'black', border: '1px solid #F0F0F0', cursor: 'pointer', padding: '4px 8px 16px 8px'}
        let postgresSelectionStyle = {borderRadius: '4px', color:'black', border: '1px solid #F0F0F0', cursor: 'pointer', padding: '4px 8px 16px 8px'}


        if (this.state.databasetype === "MYSQL"){
            mySQLSelectionStyle['border'] = '2px solid #5499F8'
        } else {
            postgresSelectionStyle['border'] = '2px solid #5499F8'
        }

        const databaseTypeHeader = {fontFamily: "Lato", fontSize: "14px", fontWeight: "700", color: "#161E16"}

        if (!('edit' in this.props)){
            let disabled = false;
        }

        let btnDisabled = false;
        let errorText = null;

        let inputErrorText = this.inputErrorText();

        if (!this.props.isAuth){
            btnDisabled = true;
            errorText = <h6 style={{color: 'red', marginTop: "1"}}>Sign up to connect a database</h6>
        } else {
            if (this.props.selectedTeamRole === "analyst" || this.props.selectedTeamRole === null){
                btnDisabled = true
                errorText = <h6 style={{color: 'orange', marginTop: "1"}}>You do not have permission to connect a database for this team</h6>
            } else {
                if (inputErrorText !== ""){
                    btnDisabled = true;
                    errorText = <h6 style={{color: 'red', marginTop: "1"}}>{inputErrorText}</h6>
                }
            }
        }

        const displayHeader = {fontFamily: "Lato", fontSize: "14px", fontWeight: "400", color: "#5E645E", marginBottom: "8px"}

        let body = <div style={{ paddingBottom: "40px" }}>
            {
                this.isDBMongo() ? null :
                    <div style={{marginBottom: "16px"}}>
                        <div style={displayHeader}>Database Name<span style={{color: 'red'}}>*</span> <InfoPopup info={<span>The name of your database. Ex: <b>prod_database_01</b></span>}/></div>
                        <input style={{width: '100%'}} className="inputGray"
                               value={this.state.database} type='text' placeholder='organization_db_1' name='database' onChange={this.onChange}/>
                    </div>
            }
            <div style={{marginBottom: "16px"}}>
                <div style={displayHeader}>{this.isDBSnowflake() ? "Account ID" : this.isDBMongo() ? "Connection String" : "Hostname"}<span style={{color: 'red'}}>*</span> <InfoPopup info={this.isDBSnowflake() ? <span>Account ID from the url. Ex: <b>zcwvfxe-web67790</b></span> : this.isDBMongo() ? <span>Get your connection string <b>mongodb+srv://&lt;username&gt;:&lt;password&gt;@apispreadsheets</b></span> : <span>The hostname is a url of your database. Ex: <b>testrds.hostname.amazonaws.com</b></span>}/></div>
                <input style={{width: '100%'}}
                       className="inputGray"
                       type='text'
                       value={this.state.host}
                       placeholder={this.isDBSnowflake() ? "zcwvfxe-web67790" : this.isDBMongo() ? "mongodb+srv://&lt;username&gt;:&lt;password&gt;@apispreadsheets" : "testrds.hostname.amazonaws.com"} name='host' onChange={this.onChange}/>
            </div>
            {
                this.isDBSnowflake() ?
                    <div style={{marginBottom: "16px"}}>
                        <div style={displayHeader}>Schema <InfoPopup info={<span>Schema of the database</span>}/></div>
                        <input
                            style={{width: '100%'}} className="inputGray"
                            type='text' placeholder='TPCDS_SF10TCL' name='schema' value={this.state.schema} onChange={this.onChange}/>
                    </div>
                    :
                this.isDBMongo() ?
                    null :
                    <div style={{marginBottom: "16px"}}>
                        <div style={displayHeader}>Port<span style={{color: 'red'}}>*</span> <InfoPopup info={<span>Usual defaults are <b>3306</b> for MySQL or <b>5432</b> for Postgres. Must be a number</span>}/></div>
                        <input
                            style={{width: '100%'}} className="inputGray"
                            type='text' placeholder='3306' name='port' value={this.state.port} onChange={this.onChange}/>
                    </div>
            }
            <div style={{marginBottom: "16px"}}>
                <div style={displayHeader}>Username <span style={{color: this.isDBMongo() ? 'red' : 'orange', fontSize: "12px"}}>{this.isDBMongo() ? "*" : "required, if it exists"}</span> <InfoPopup info={this.isDBMongo() ? "Enter a valid username" : <span>Depending on your setup you may or may not need a username</span>}/></div>
                <input
                    style={{width: '100%'}}
                    className="inputGray"
                    type='text' value={this.state.user} placeholder='John_username_for_db_provider' name='user' onChange={this.onChange}/>
            </div>
            <div style={{marginBottom: "16px"}}>
                <div style={displayHeader}>Password <span style={{color: this.isDBMongo() ? 'red' : 'orange', fontSize: '12px'}}>{this.isDBMongo() ? "*" : "required, if it exists"}</span> <InfoPopup info={this.isDBMongo() ? "Enter a valid password" : <span>Depending on your setup you may or may not need a password</span>}/></div>
                <input
                    style={{width: '100%'}}
                    className="inputGray"
                    type='password' value={this.state.password} placeholder='********' name='password' onChange={this.onChange}/>
            </div>
            {
                'edit' in this.props ?
                    null :
                    <div style={{marginBottom: "16px"}}>
                        <div style={displayHeader}>Short Description of Database<span style={{color: 'red'}}><i>*</i></span></div>
                        <input style={{width: '100%'}}
                               className="inputGray"
                               type='text' placeholder='Production Database for Finance App' name='description' onChange={this.onChange}/>
                    </div>
            }
            <div id='dbfields'>
                {
                    'edit' in this.props ?
                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: "8px"}}>
                            <div>
                                <Button disabled={btnDisabled}
                                        style={{backgroundColor: "#6FBB98", color: "white", cursor: btnDisabled ? "not-allowed" : "pointer", border: "none", borderRadius: "4px"}}
                                        onClick={this.onConnect}
                                        loading={this.props.saving}
                                        icon="plug"
                                        label="Test Connection"
                                />
                            </div>
                            <div>
                                <Button disabled={!this.hasInfoChanged()}
                                        style={{backgroundColor: 'white', border: "1px solid #6FBB98", color: "#6FBB98", cursor: btnDisabled ? "not-allowed" : "pointer", borderRadius: "4px"}}
                                        onClick={this.resetParameters}
                                        icon="plug"
                                        label="Reset Parameters"
                                />
                            </div>
                        </div>:
                        <Button disabled={btnDisabled}
                                style={{backgroundColor: "#6FBB98", color: "white", cursor: btnDisabled ? "not-allowed" : "pointer", border: "none", borderRadius: "4px"}}
                                onClick={this.onConnect}
                                loading={this.state.fetching}
                                icon="plug"
                                label="Connect DB"
                        />
                }
            </div>
            {errorText}
            {
                'edit' in this.props ? null :
                    <TestDatabaseMessage
                        connectionValid={this.state.connectionValid}
                        connectionValidMessage={this.state.connectionValidMessage}
                    />
            }
        </div>

        return (
            body
        )


    }
}

const mapStateToProps = (state) => ({
    isAuth: state.mainState.isAuth,
    selectedTeamPK: state.mainState.selectedTeamPK,
    selectedTeamRole: state.mainState.selectedTeamRole,
    allDatabases: state.mainState.allDatabases,
    userPlan: state.mainState.userPlan,
    userTeamPK: state.mainState.userTeamPK,
    allTeamsInfo: state.mainState.allTeamsInfo
})

const mapActionsToProps = {
    navigateTo: push,
    modifySidebarSelection: modifySidebarSelection,
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo,
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(ConnectDatabase)