import React, {Component} from 'react'

class PromoCountdown extends Component {
    render() {
        if (this.props.timeLeft.day === null){
            return(
                <span>This Promo Has Expired</span>
            )
        }
        else{
            return (
                <span style={{display: "inline"}}>{this.props.timeLeft.day} <span className="promoCountdownLabel">days</span> {this.props.timeLeft.hour} <span className="promoCountdownLabel">hours</span> {this.props.timeLeft.minute} <span className="promoCountdownLabel">minutes</span> {this.props.timeLeft.second} <span className="promoCountdownLabel">seconds</span></span>
            )
        }
    }
}

export default PromoCountdown