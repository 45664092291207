import React, {Component} from 'react'
import {connect} from 'react-redux'
import BannerAlert from "../BannerAlert";

class UpgradeModalHeader extends Component {
    constructor(props) {
        super(props);
    }

    getFeatureAddlText = (featureName) => {
        if (featureName === "team"){
            return "members"
        }

        return ""
    }

    render() {
        let planValue = this.props.planValue;
        let userPlanName = this.props.userPlanName.toUpperCase();
        let featureName = this.props.featureName;
        let featureNameLabel = this.props.featureName;

        if (featureNameLabel === 'databases'){
            featureNameLabel = 'data sources'
        }
        let featureAddlText = this.getFeatureAddlText(featureName)

        let header = `You can only have ${planValue} ${featureNameLabel} ${featureAddlText} on the ${userPlanName} plan`

        if (featureName === "team"){
            if (userPlanName === "FREE"){
                header = "You can not add team members on the FREE plan"
            }
        }

        let content;

        if (this.props.isUserTeamSelected){
            content = "Upgrade below to increase your limit"
        } else {
            content = "Contact your team's admin to upgrade your account"
        }


        return (
            <div>
                <BannerAlert
                    type="error"
                    header={header}
                    content={content}
                    icon="fa-solid fa-triangle-exclamation"
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(UpgradeModalHeader)