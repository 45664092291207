import React, {Component} from 'react'
import styles from "../Constants/styles";
import PromoTimer from './PromoTimer'
import {isMobile} from "../Constants/isMobile";
import {hidePricing} from "../Constants/hidePricing";

class LifetimeDealBanner extends Component {
    render() {
        if (hidePricing()){
            return null
        }

        return (
            <a href="/pricing?lifetime=true" style={{ textDecoration: "none"}}>
                <div style={{ backgroundColor: styles.red, color: "white", textAlign: 'center', fontWeight: '700', cursor: "pointer", padding: isMobile() ? "8px" : "0" }}>
                    We are offering a lifetime deal till the end of the year! Click here to advantage
                </div>
            </a>
        )
    }
}

export default LifetimeDealBanner