import React, {Component} from 'react'
import styles from "../../../Constants/styles";

const menuItems = [
    {value: 'urlParams', label: 'URL Params'},
    {value: 'auth', label: 'Authorization'},
    {value: 'headers', label: 'Headers'},
    {value: 'body', label: 'Body'}
]

class APINoCodeMenu extends Component {
    constructor(props) {
        super(props);
    }

    getItemDiv = (itemObj) => {
        const style = {
            padding: '8px 16px',
            // borderBottom: '1px solid #F0F0F0',
            cursor: 'pointer',
            fontSize: "14px",
            fontWeight: "400",
            color: "#5E645E"
        }

        if (this.props.activeMenuItem === itemObj.value){
            style['color'] = styles.mainText
            style['fontWeight'] = '700'
            style['borderBottom'] = '2px solid ' + styles.blue
        }

        return <div style={style} onClick={e => this.props.changeState({ activeMenuItem: itemObj.value })}>{itemObj.label}</div>
    }

    render() {

        return (
            <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto auto 1fr', borderBottom: '1px solid #F0F0F0' }}>
                {menuItems.map((x, idx) => <div key={idx}>{this.getItemDiv(x)}</div>)}
                <div> </div>
            </div>
        )
    }
}

export default APINoCodeMenu