import React, {Component} from 'react'
import styles from "../Constants/styles";

class RulesRow extends Component {
    render() {
        return (
            <div style={{ marginBottom: "16px"}}>
                <div style={{ fontSize: "16px" }}>
                    {this.props.rule}
                </div>
                <div style={{ fontSize: "14px", color: styles.subText, border: "1px solid #F0F0F0", marginTop: "4px", padding: "4px" }}>
                    {this.props.example}
                </div>
            </div>
        )
    }
}

export default RulesRow