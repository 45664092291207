import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleIsAuth} from "../Actions/ToggleIsAuth";
import { push } from 'react-router-redux'
import styles from "../Constants/styles";
import {resetAllStates} from "../Actions/ResetAllStates";

class LogoutButton extends Component {
    constructor(props) {
        super(props);
    }

    logout = (e) => {
        localStorage.removeItem("email")
        localStorage.removeItem("token")
        this.props.toggleIsAuth(false)
        this.props.navigateTo("/")
        this.props.resetAllStates()
    }

    render() {
        if ('isMobile' in this.props){
            return (
                <div style={{marginTop:"40px"}}>
                    <button
                        className="heroButton mobileSidebarLogoutButton"
                        onClick={this.logout}>
                        Log Out
                    </button>
                </div>
            )
        } else {
            return (
                <div style={{ marginTop: "-4px" }}>
                    <button
                        style={{ border: "none", color: "white", backgroundColor: styles.orange, padding: "4px 8px 4px 8px", borderRadius: "4px", fontWeight: "700" }}
                        onClick={this.logout}>
                        Log Out
                    </button>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleIsAuth: toggleIsAuth,
    navigateTo: push,
    resetAllStates: resetAllStates
}

export default connect(mapStateToProps, mapActionsToProps)(LogoutButton)