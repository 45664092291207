import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleModal} from "../Actions/ToggleModal";
import {toggleRunProductDemo} from "../Actions/ToggleRunProductDemo";

class EndDemoButton extends Component {
    constructor(props) {
        super(props);
    }

    endDemo = () => {
        this.props.toggleRunProductDemo(false);
        this.props.toggleModal(false);
    }

    render() {
        let style = { textAlign: 'center', marginTop: "24px" };

        if ('style' in this.props){
            style = {...style, ...this.props.style}
        }

        if ('btn' in this.props){
            return <div style={style}>
                <span onClick={this.endDemo}>{this.props.btn}</span>
            </div>
        } else {
            return (
                <div style={style}>
                    <button onClick={this.endDemo}>
                        End Demo
                    </button>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {
    toggleRunProductDemo: toggleRunProductDemo,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(EndDemoButton)