import React, {Component} from 'react'
import DatabaseBox from "./DatabaseBox"

class DatabaseLogos extends Component {
    render() {
        if (this.props.isMobile){
            return (
                <div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: "16px"}}>
                        <DatabaseBox
                            dbType="mysql"
                            supported
                        />
                        <DatabaseBox
                            dbType="postgres"
                            supported
                        />
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: "16px", marginTop: "8px"}}>
                        <DatabaseBox
                            dbType="mariadb"
                            supported
                        />
                        <DatabaseBox
                            dbType="snowflake"
                            supported
                        />
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: "16px", marginTop: "8px"}}>
                        <DatabaseBox
                            dbType="oracle"
                            supported
                        />
                        <DatabaseBox
                            dbType="sqlserver"
                            supported
                        />
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: "16px", marginTop: "8px"}}>
                        <DatabaseBox
                            dbType="mongodb"
                        />
                        <DatabaseBox
                            dbType="googleBigQuery"
                        />
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: "16px", marginTop: "8px"}}>
                        <DatabaseBox
                            dbType="redshift"
                        />
                        <DatabaseBox
                            dbType="airtable"
                        />
                    </div>
                </div>
            )
        }

        return (
            <div>
                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", columnGap: "16px"}}>
                    <DatabaseBox
                        dbType="mysql"
                        supported
                    />
                    <DatabaseBox
                        dbType="postgres"
                        supported
                    />

                    <DatabaseBox
                        dbType="oracle"
                        supported
                    />
                    <DatabaseBox
                        dbType="snowflake"
                        supported
                    />
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", columnGap: "16px", marginTop: "12px"}}>
                    <DatabaseBox
                        dbType="sqlserver"
                        supported
                    />
                    <DatabaseBox
                        dbType="mariadb"
                        supported
                    />
                    <DatabaseBox
                        dbType="mongodb"
                    />
                    <DatabaseBox
                        dbType="googleBigQuery"
                    />

                </div>
                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", columnGap: "16px", marginTop: "12px"}}>
                    <DatabaseBox
                        dbType="redshift"
                    />
                    <DatabaseBox
                        dbType="salesforce"
                    />
                    <DatabaseBox
                        dbType="airtable"
                    />
                    <DatabaseBox
                        dbType="hubspot"
                    />
                    {/*<DatabaseBox*/}
                        {/*dbType="airtable"*/}
                    {/*/>*/}
                    {/*<DatabaseBox*/}
                        {/*dbType="shopify"*/}
                    {/*/>*/}
                </div>
                {/*<div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", columnGap: "16px", marginTop: "12px"}}>*/}
                    {/*<DatabaseBox*/}
                        {/*dbType="mailchimp"*/}
                    {/*/>*/}
                    {/*<DatabaseBox*/}
                        {/*dbType="airtable"*/}
                    {/*/>*/}
                    {/*<DatabaseBox*/}
                        {/*dbType="hubspot"*/}
                    {/*/>*/}
                    {/*<DatabaseBox*/}
                        {/*dbType="googleAnalytics"*/}
                    {/*/>*/}
                {/*</div>*/}
                {/*<div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", columnGap: "16px", marginTop: "12px"}}>*/}
                    {/**/}
                    {/**/}
                    {/*<DatabaseBox*/}
                        {/*dbType="googleBigQuery"*/}
                    {/*/>*/}
                    {/*<DatabaseBox*/}
                        {/*dbType="redshift"*/}
                    {/*/>*/}
                {/*</div>*/}
            </div>
        )
    }
}

export default DatabaseLogos