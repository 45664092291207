import React, {Component} from 'react'
import SecurityBox from "./SecurityBox";
import Encryption from "../../../../Illustrations/encrypt-info.svg"
import ReadConnection from "../../../../Illustrations/read-connection.svg"
import StaticIP from "../../../../Illustrations/static-ip.svg"

class SecurityRow extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-sm-4">
                    <SecurityBox
                        header="End-to-End Encryption"
                        img={Encryption}
                        desc="We encrypt all your information in-rest and in-transit. We do NOT store any data read from your data sources"
                    />
                </div>
                <div className="col-sm-4" style={this.props.isMobile ? {marginTop: "24px"} : null}>
                    <SecurityBox
                        header="Static IP"
                        img={StaticIP}
                        desc="We provide static IP addresses of our servers. So you can allow access to only those specific IPs"
                    />
                </div>
                <div className="col-sm-4" style={this.props.isMobile ? {marginTop: "24px"} : null}>
                    <SecurityBox
                        header="Read-only Connection"
                        img={ReadConnection}
                        desc="We only allow read-only queries to be run. So you don't have to worry about your data getting messed up"
                    />
                </div>
            </div>
        )
    }
}

export default SecurityRow