import React, {Component} from 'react'
import ColumnInfoRow from "./ColumnInfoRow"

class ColumnInfoIndex extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.columnInfo.length === 0){
            return null
        } else {
            return (
                <div>
                    {
                        this.props.columnInfo.map((x,idx) => <div key={idx}>
                            <ColumnInfoRow
                                info={x}
                            />
                        </div>)
                    }
                </div>
            )
        }
    }
}

export default ColumnInfoIndex