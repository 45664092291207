import React, {Component} from 'react'
import API_Root from "../Constants/API_Root";
import styles from "../Constants/styles";
import Button from "../SharedComponents/Button"
import BannerAlert from "../SharedComponents/BannerAlert"
import isEmailValid from "../Constants/isEmailValid";

class MobileSendLinkToYourself extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            message: null,
            sending: false
        }

        this.sendEmail = this.sendEmail.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event){
        this.setState({
            email: event.target.value
        })
    }

    sendEmail(e){
        this.setState({
            sending: true,
            email: '',
        })

        const error = () => {
            this.setState({
                sending: false,
                message: <BannerAlert type="error"
                                      header="There was an error sending an email to you"
                                      content="Don't worry, just visit www.lovespreadsheets.com on your desktop!" />,
            })
        }
        fetch(API_Root + "api/send-email/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: this.state.email,
                fromEmail: 'info@lovespreadsheets.com'
            })
        }).then(res =>
        {
            if (res.status === 201){
                this.setState({
                    message: <BannerAlert type="success"
                                          header="Email sent successfully!"
                                          content="It is possible the email is sent to the spam or junk folder" />,
                    email: '',
                    sending: false
                })
            } else {
                error()
            }
        }).catch(err => error());
    }

    render() {
        const segmentStyle = { backgroundColor: "white", padding: "12px", border: "1px solid #F0F0F0", borderRadius: "4px", boxShadow: "0px 5px 6px -4px rgba(2, 34, 19, 0.02)"}
        const displayText = {color: styles.subText, fontSize: "16px", marginBottom: "24px"}

        return (
            <div style={{marginTop: '48px', marginBottom: '24px', marginLeft: '20px', marginRight: '20px', textAlign: "center"}}>
                <div style={{...segmentStyle, ...{ marginTop: "48px"}}}>
                    <div style={displayText}>Thanks for signing up!<span role="img" aria-label="heart">❤️</span> <br/>This app is meant to be used on the desktop </div>
                    <div style={{color: styles.mainText, fontWeight: "700", marginBottom: "12px"}}>Email yourself the website link</div>
                    <div style={{ marginBottom: "12px"}}>
                        <div>
                            <input onChange={this.handleInputChange} value={this.state.email} placeholder="Enter Email" style={{ width: "100%"}} className="inputGray" />
                        </div>
                        {
                            this.state.email.trim().length > 0 && !isEmailValid(this.state.email) ?
                                <div>
                                    <small style={{ color: "red"}}>Please enter a valid email</small>
                                </div> : null
                        }
                    </div>
                    <div style={{ marginBottom: "12px"}}>
                        <Button onClick={this.sendEmail}
                                loading={this.state.sending}
                                disabled={!isEmailValid(this.state.email)}
                                style={{width: "100%", textAlign: "center", backgroundColor: "white", color: styles.mainGreen, border: "1px solid " + styles.mainGreen}}
                                label="Email yourself"/>
                    </div>
                    {this.state.message}
                    <br/>
                </div>
            </div>
        )
    }
}

export default MobileSendLinkToYourself