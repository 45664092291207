import React, {Component} from 'react'
import Select from 'react-select'

const utcOptions = Intl.supportedValuesOf('timeZone').map(x => ({ value: x, label: x }));

class TimezoneDropdown extends Component {
    change = (timezoneObj) => {
        this.props.changeState({ report_timezone: timezoneObj.value })
    }


    render() {
        return (
            <div>
                <Select
                    onChange={this.change}
                    value={{value: this.props.report_timezone, label: this.props.report_timezone }}
                    options={utcOptions}
                />
            </div>
        )
    }
}

export default TimezoneDropdown