import React, {Component} from 'react'
import CreditCardDetails from './CreditCardDetails'
import {Elements, StripeProvider} from 'react-stripe-elements';
import { connect } from 'react-redux'
import {modifyUserPlan} from "../../Actions/ModifyUserPlan";
import {toggleIsAuth} from "../../Actions/ToggleIsAuth";

class PaymentForm extends Component {
    changeProcess = (process) => {
        this.props.toggleIsAuth(true)
        this.props.changeProcess(process);
        this.props.modifyUserPlan("lifetime")
    }

    toggleAuth = (auth) => this.props.toggleIsAuth(auth)

    render() {
        return (
            <div>
                <StripeProvider apiKey={window.location.href.includes("localhost") ? "pk_test_1rS8QGgeDU1Ewoyjc8AjaTx0" : "pk_live_nmeaYWsNdAbSbOTRd8MgnGLL"}>
                    <div className="example">
                        <Elements>
                            <CreditCardDetails changeProcess={this.changeProcess}
                                               toggleAuth={this.toggleAuth}
                                               changeState={this.props.changeState}
                                               isCode={this.props.isCode}
                                               code={this.props.code}
                                               currency="usd"
                                               currencyAmount={99}
                                               isAuth={this.props.isAuth}
                            />
                        </Elements>
                    </div>
                </StripeProvider>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    isAuth: state.mainState.isAuth
});

const mapActionsToProps = {
    modifyUserPlan: modifyUserPlan,
    toggleIsAuth: toggleIsAuth
};

export default connect(mapStateToProps, mapActionsToProps)(PaymentForm)
