// TODO: remember to always keep this updated with woyera-api-backend

export const pricing = [{
    databases: 1,
    reports: 3,
    price: 0,
    lifetimePrice: 0,
    name: "free",
    internalName: "free",
    rowsPerReport: 50,
    support: 'FAQs',
    freeConsultingHours: 0,
    desc: "Always free",
    team: 0,
    reportFrequency: "monthly",
    customIntegrations: false
},
{
    databases: 2,
    reports: 250,
    price: 49,
    lifetimePrice: 149,
    name: "individual",
    internalName: "pro",
    rowsPerReport: 10000,
    support: 'Email (2 days)',
    freeConsultingHours: 10,
    desc: "Individual user",
    team: 2,
    reportFrequency: "daily",
    customIntegrations: false
}, {
    databases: 5,
    reports: 1000,
    price: 99,
    lifetimePrice: 299,
    name: "team",
    internalName: "team",
    rowsPerReport: 100000,
    support: 'Priority (same day)',
    freeConsultingHours: 25,
    desc: "Small organization",
    team: 5,
    reportFrequency: "hourly",
    customIntegrations: false
}, {
    databases: 10,
    reports: 10000,
    price: 199,
    lifetimePrice: 599,
    name: "business",
    internalName: "business",
    rowsPerReport: 1000000,
    support: 'Dedicated',
    freeConsultingHours: 40,
    desc: "SMBs",
    team: 10,
    reportFrequency: "15-minutes",
    customIntegrations: true
}]