import React, {Component} from 'react'
import styles from "../Constants/styles";

class Tag extends Component {
    render() {
        let bgColor = "gray";
        let color = styles.mainText;
        let fontWeight = "400"

        if ("bgColor" in this.props){
            bgColor = this.props.bgColor;
        }

        if ("color" in this.props){
            color = this.props.color;
        }

        if ("bold" in this.props){
            fontWeight = "700"
        }

        let style = { backgroundColor: bgColor, color: color, fontWeight: fontWeight, padding: "2px 4px", borderRadius: "4px" }

        if ('style' in this.props){
            style = {...style, ...this.props.style}
        }

        return (
            <span style={style}>
                {this.props.label}
            </span>
        )
    }
}

export default Tag