export const getLastParamValue = (url) => {
    let param;

    try{
        url = url.toString();

        if (url.charAt(url.length -1) === "/"){
            url = url.substring(0, url.length - 1)
        }

        param = url.substring(url.lastIndexOf("/") + 1, url.length)
    } catch (e){
        param = ""
    }

    return param
}