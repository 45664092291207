import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifyAllDatabases} from "../Actions/ModifyAllDatabases";
import Loader from "../Loader"
import {modifySidebarSelection} from "../Actions/ModifySidebarSelection";
import DatabaseListItem from "./DatabaseListItem"
import styles from "../Constants/styles";

class DatabaseList extends Component {
    render() {
        let body = null;
        if (this.props.fetchingAllAiResults){
            body = <Loader/>
        } else {
            if (this.props.allDatabases.length === 0){
                body = <p>You do not have any databases connected. Please <span className="linkStyle" onClick={e => this.props.modifySidebarSelection("connectDatabase")}>connect a database</span></p>
            } else {
                body = this.props.allDatabases.map((x, idx) => <DatabaseListItem info={x} idx={idx} key={idx}/>)
            }
        }



        return (
            <div>
                <div style={{ fontSize: "16px", fontWeight: "700", color: styles.mainText, marginBottom: "12px"}}>
                    <i className="fa-solid fa-database"/> <span style={{ paddingLeft: '4px'}}>Your Databases</span>
                </div>
                {body}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    fetchingAllAiResults: state.mainState.fetchingAllAiResults,
    allDatabases: state.mainState.allDatabases
})

const mapActionsToProps = {
    modifyAllDatabases: modifyAllDatabases,
    modifySidebarSelection: modifySidebarSelection
}

export default connect(mapStateToProps, mapActionsToProps)(DatabaseList)