import React, {Component} from 'react'
import styles from "../Constants/styles";
import {pricing} from "../SharedComponents/Pricing/pricing";

class UserPlanTag extends Component {
    render() {
        let planDisplay = "enterprise";
        let bgColor = "black";

        if (typeof this.props.userPlan === "string"){
            planDisplay = this.props.userPlan
            bgColor = styles.red
        } else {
            if (Number.isInteger(this.props.userPlan)){
                planDisplay = pricing[this.props.userPlan].name
                bgColor = styles.mainGreen
            }
        }

        return (
            <div style={{ fontSize: "14px", backgroundColor: bgColor, color: "white", padding: "4px", fontWeight: "700", textAlign: 'center'}}>
                {planDisplay.toUpperCase()}
            </div>
        )
    }
}

export default UserPlanTag