import React, {Component} from 'react'
import ModalApp from '../Modal'
import ViewTablesAndColumnBody from "./ViewTablesAndColumnBody"

class ViewTablesAndColumnModal extends Component {
    render() {
        return (
            <ModalApp name="viewTablesAndColumnModal"
                      header={<span style={{ fontSize: "16px", fontWeight: '700'}}><i className="fa-solid fa-table"></i><span style={{ paddingLeft: "8px" }}>Database Tables</span></span>}
                      body={<div>
                          <ViewTablesAndColumnBody/>
                      </div>}
            />
        )
    }
}

export default ViewTablesAndColumnModal