import React, {Component} from 'react'
import styles from "../../Constants/styles";
import Payments from "../../Illustrations/Payments.svg"

class UpgradeTeamDisplay extends Component {
    render() {
        const mailToString = `mailto:${this.props.adminEmail}?Subject=Upgrade%20Love%20Spreadsheets%20Plan%20for%20higher%20${this.props.featureName}%20limit`

        return (
            <div>
                <div style={{fontSize: "20px", fontWeight: '700', color: styles.subText, marginBottom: "24px" }}>
                    Your team admin {this.props.adminEmail} needs to upgrade your account
                </div>
                <div style={{ marginBottom: "24px", textAlign: "center" }}>
                    <img src={Payments} style={{ maxWidth: "200px"}}/>
                </div>
                <div style={{ marginBottom: "24px"}}>
                    <a href={mailToString} style={{ appearance: 'button', textDecoration: "none",  border: "none",
                        backgroundColor: styles.orange,
                        color: "white",
                        cursor: "pointer",
                        borderRadius: "4px",
                        padding: "8px 32px", textAlign: 'center' }}>
                            Email your team admin
                    </a>
                </div>
            </div>

        )
    }
}

export default UpgradeTeamDisplay